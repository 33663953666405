"use strict";
import * as React from "react";
import { useSelector } from "react-redux";
import { titleName, viewId, table, periodKind, taskStatus, dataFixStatus } from "../../_constants/Code";
import { errorLog } from "../../common/logger/Logger";
import { getDynamoDB, getDataCreateTask, getGroupCompany } from "../../common/utils/DynamoDbUtils";
import { formatDate } from "../../common/utils/DateUtils";
import { getRefAuthInfo } from "../../services/auth/AuthorityService";

const DataUpdateStatusContent = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [updateStatusList, setUpdateStatusList] = React.useState([]);

  React.useEffect(() => {
    init();
  }, []);

  /**
   * 初期表示
   */
  const init = async () => {
    const recordList = [];
    try {
      // 会社情報取得
      const companyData = await getDynamoDB(table.Company,
        null, props.corporationNumber, null, null
      );
      recordList.push({
        corporationNumber: companyData[0].CorporationNumber,
        name: companyData[0].CompName,
      });
      // 連結会社&セグメント取得
      const analysisTargetData = await getDynamoDB(table.AnalysisTargetInfo,
        null, props.corporationNumber
      );
      // 親会社判定
      if (analysisTargetData.length) {
        // 参照権限のある会社
        const compNumberList = await _getAuthCompanyNumber(props.userId, props.corporationNumber);
        // 表示対象グループ会社情報抽出
        analysisTargetData[0].Corporation.forEach(groups => {
          // グループ会社一覧
          groups.CorporationList.forEach(corp => {
            if (compNumberList.includes(corp.Number) && recordList.every(rec => rec.corporationNumber !== corp.Number)) {
              recordList.push({
                corporationNumber: corp.Number,
                name: corp.Name,
              });
            }
          });
        });
      }
      // 表示データ生成
      for (const rec of recordList) {
        // データ作成タスク管理を取得
        const dataCreateTask = await _getDataCreateTask(rec.corporationNumber);
        // 月次データ取得
        const month = _findDataCreateTask(dataCreateTask, periodKind.monthly);
        // 四半期データ取得
        const quarter = _findDataCreateTask(dataCreateTask, periodKind.quarter);
        // 年次データ取得
        const year = _findDataCreateTask(dataCreateTask, periodKind.year);
        // 確定状況判定
        rec.monthIcon = _getIcon(month);
        rec.quarterIcon = _getIcon(quarter);
        rec.yearIcon = _getIcon(year);
        // 表示テキスト
        rec.monthText = _getText(month);
        rec.quarterText = _getText(quarter);
        rec.yearText = _getText(year);
      }
      setUpdateStatusList(recordList);
      setIsLoaded(true);
    } catch (error) {
      errorLog("E000001", props.userId, viewId.dataUpdateStatus, "init", error);
    }
  }

  /**
   * 表示対象グループ会社抽出
   * @param {String} userId ユーザーID
   * @param {String} corporationNumber 法人番号
   * @returns 法人番号配列
   */
  const _getAuthCompanyNumber = async (userId, corporationNumber) => {
    try {
      // グループ会社を取得
      const groupCompanyMaster = await getGroupCompany(corporationNumber);
      // ユーザー情報取得
      const userData = await getDynamoDB(table.User, null, corporationNumber, { UserId: userId });
      // 権限情報取得
      const authInfo = await getRefAuthInfo(userData[0], groupCompanyMaster);
      return authInfo.authCompany;
    } catch (error) {
      errorLog("E000001", props.userId, viewId.dataUpdateStatus, "_getAuthCompanyNumber", error);
    }
  }

  /**
   * データ作成タスク管理を取得
   * @param {String} searchKey 法人番号
   * @returns データ作成タスク管理
   */
  const _getDataCreateTask = async (searchKey) => {

    let dataCreateTask = [];
    try {
      dataCreateTask = await getDataCreateTask(props.corporationNumber, searchKey)
    } catch (error) {
      errorLog("E000001", props.userId, viewId.dataUpdateStatus, "_getDataCreateTask", error);
    } finally {
      return dataCreateTask.sort((a, b) => {
        if (a.Key > b.Key) return -1;
        return 1;
      });
    }
  }

  /**
   * データ作成タスク管理検索処理
   * @param {Object} dataCreateTask データ作成タスク管理
   * @param {periodKind} kind 期間種別
   * @returns データ作成タスク管理レコード
   */
  const _findDataCreateTask = (dataCreateTask, kind) => {
    return dataCreateTask.find(data => {
      return data.PeriodKind === kind.value
        && [taskStatus.doing.value, taskStatus.done.value].includes(data.TaskStatus)
        && data.DataCreateDateTime
    });
  }

  /**
   * アイコンを取得
   * @param {Object} dataCreateTask データ作成タスク管理
   * @returns アイコン
   */
  const _getIcon = (dataCreateTask) => {
    if (dataCreateTask) {
      if (dataCreateTask.DataFixStatus === dataFixStatus.fix.value) {
        return 'icon-padlock-close grey2--text';
      }
      if (dataCreateTask.RequiredFileCount === dataCreateTask.RegisteredFileCount) {
        return 'icon-padlock-open yellow--text';
      }
      return 'icon-padlock-open red--text';
    }
  }

  /**
   * 表示テキストを取得
   * @param {Object} dataCreateTask データ作成タスク管理
   * @returns 表示テキスト
   */
  const _getText = (dataCreateTask) => {
    if (dataCreateTask) {
      const fData = formatDate(new Date(dataCreateTask.DataCreateDateTime)).slice(0, -3);
      return dataCreateTask.PeriodValue + '（' + fData + '）';
    }
  }

  /**
   * データ更新状況確認モーダルを開く
   */
  const _openModal = () => {
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.add("is-active");
    const modalUploading = document.querySelector("#modal-window--data-update-status");
    if (!modalUploading.classList.contains("animate__fadeIn")) {
      modalUploading.classList.remove("animate__fadeOut");
      modalUploading.classList.add("animate__fadeIn");
    }
    modalUploading.classList.remove("animate__fadeOut");
    modalUploading.classList.add("animate__fadeIn");
    modalUploading.classList.add("is-active");
  }

  /**
   * データ更新状況確認モーダルを閉じる
   */
  const _closeModal = () => {
    const modal = document.querySelector("#modal-window--data-update-status");
    if (!modal.classList.contains("animate__fadeOut")) {
      modal.classList.remove("animate__fadeIn");
      modal.classList.add("animate__fadeOut");
    }
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      document.querySelector(".bg-modal-window.is-active").classList.remove("is-active");
      document.querySelector(".modal-window.is-active").classList.remove("is-active");
    }, fadeOutTimeoutSec);
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <button type="button"
        className="btn-modal-data-update-status btn link rounded-lg px-6 py-2.5 white--text text-h7 ml-4"
        onClick={() => _openModal()}>詳細</button>
      <div className="bg-modal-window w-screen h-screen fixed top-0 left-0 grey lighten-70 z-50"></div>
      <div id="modal-window--data-update-status"
        className="modal-window modal-window__wide is-w1456 fixed bg sub z-50 rounded-2xl animate__fadeIn">
        <div className="modal-window__header">
          <div className="modal-window__title flex items-center justify-center py-10">
            <span className="modal-window__title-text text-h3 font-medium">{titleName.dataUpdateStatus}</span>
          </div>
          <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => _closeModal()}>
            <span className="icon-close"></span>
          </button>
        </div>
        <div className="modal-window__content px-8 pb-10">
          <div className="text-caption-1 font-medium text-center mb-8 link--text">
            {props.isParent && "グループ各社の"}データ更新状況を表示します。<br />
            <span className="icon-padlock-open red--text text-2xl align-sub"></span>：未確定データ（取込残あり）、
            <span className="icon-padlock-open yellow--text text-2xl align-sub"></span>：未確定データ（取込完了）、
            <span className="icon-padlock-close grey2--text text-2xl align-sub"></span>：確定データ<br />
            カッコ内はデータの最終更新日時です。
          </div>
          <div className="setting-table is-data-update-status">
            <table className="setting-table__table w-full">
              <thead>
                <tr>
                  <th className="setting-table__col-name">会社名</th>
                  <th className="setting-table__col-monthly">月次</th>
                  <th className="setting-table__col-quarter">四半期</th>
                  <th className="setting-table__col-annual">年次</th>
                </tr>
              </thead>
              <tbody className="text-caption-1">
                {updateStatusList.map((rec, idx) => {
                  return (
                    <tr key={idx}>
                      <th className="text-caption-1 font-bold blue1 lighten-7 link--text">{rec.name}</th>
                      <td className="text-caption-1 left text-nowrap"><span className={`text-2xl align-sub mr-2 ${rec.monthIcon}`}></span>{rec.monthText}</td>
                      <td className="text-caption-1 left text-nowrap"><span className={`text-2xl align-sub mr-2 ${rec.quarterIcon}`}></span>{rec.quarterText}</td>
                      <td className="text-caption-1 left text-nowrap"><span className={`text-2xl align-sub mr-2 ${rec.yearIcon}`}></span>{rec.yearText}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="text-center mt-8">
            <button type="button" className="btn btn-modal-data-update-status-ok white--text grey rounded-lg px-16 text-h5 font-medium" onClick={() => _closeModal()}>OK</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const DataUpdateStatus = () => {
  const state = useSelector((state) => state);

  return <DataUpdateStatusContent
    corporationNumber={state.company.corporationNumber}
    isParent={state.company.isParent}
    userId={state.user.sub}
  />;
}

export default DataUpdateStatus;