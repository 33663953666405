"use strict";
import * as React from "react";
import { getMessage } from "../../../common/utils/MessageUtils";
import { Checkbox } from "@mui/material";
import CheckBoxRountedIcon from "@mui/icons-material/CheckBoxRounded";

/**
 * 共通パーツ「基本情報」
 */
const basicInfo = {
  Title: '',
  Description: '',
  SharedFlag: false,
};

/**
 * 基本情報エラー項目
 */
const basicInfoError = {
  Title: null,
  Description: null,
  SharedFlag: null,
};

const BasicInfoContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  // 画面入力情報
  const [benchmarkTargetInfo, setBenchmarkTargetInfo] = React.useState();
  const [inputError, setInputError] = React.useState();

  React.useEffect(() => {
    init();
  }, []);

  /**
   * 初期表示
   */
  const init = () => {
    if (props.viewMode === 'new') {
      Object.keys(basicInfo).filter(key => key !== 'SharedFlag').forEach(key => basicInfo[key] = '');
      basicInfo.SharedFlag = false;
    }
    setBenchmarkTargetInfo(basicInfo);
    setInputError(basicInfoError);
    setIsLoaded(true);
  }

  /**
   * タイトルの値を変更する
   * @param {イベント} e 
   */
  const changeTitle = (e) => {
    basicInfo.Title = e.target.value;
    setBenchmarkTargetInfo({ ...benchmarkTargetInfo, Title: e.target.value });
  }

  /**
   * 説明の値を変更する
   * @param {イベント} e 
   */
  const changeDescription = (e) => {
    basicInfo.Description = e.target.value;
    setBenchmarkTargetInfo({ ...benchmarkTargetInfo, Description: e.target.value });
  }

  /**
   * 共有の値を変更する
   * @param {イベント} e 
   */
  const changeSharedFlag = (e) => {
    basicInfo.SharedFlag = e.target.checked;
    setBenchmarkTargetInfo({ ...benchmarkTargetInfo, SharedFlag: e.target.checked });
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }

  const isReference = props.viewMode === 'reference';
  return (
    <React.Fragment>
      {/* ===== 基本情報フォーム ===== */}
      {isReference && <div className="text-caption-1 mt-8 -mb-4 grey--text text--lighten-70">他のユーザーが作成したベンチマーク設定です。編集できません。</div>}
      {/* ボーダー枠：リード文 */}
      <div className="text-body-2-medium font-medium mt-10 mb-12">
        この設定のタイトル、説明、共有の有無を入力してください。
      </div>
      <table className="form-table w-full">
        <tbody>
          <tr>
            <th className="form-table__col-head">
              <span className="text-h7 font-bold">タイトル</span>
              <span className="red--text">*</span>
            </th>
            <td className="form-table__col-data">
              <input id="name" placeholder="タイトルを入力（全角30文字以内）" type="text"
                className={`text-caption-2 block rounded-lg px-4 pt-2 pb-2.5 w-full !border-0 ${inputError.Title ? "is-error" : ""}`}
                disabled={isReference}
                value={benchmarkTargetInfo.Title} onChange={(e) => changeTitle(e)} />
              <span className="red--text">{inputError.Title}</span>
            </td>
          </tr>
          <tr>
            <th className="form-table__col-head">
              <span className="text-h7 font-bold">説明</span>
            </th>
            <td className="form-table__col-data">
              <textarea id="description" rows="4" placeholder="説明を入力（全角200文字程度）" autoComplete="off"
                className={`text-caption-2 block rounded-lg px-4 pt-2 pb-2.5 w-full !border-0 h-28 ${inputError.Description ? "is-error" : ""}`} maxLength="200"
                disabled={isReference}
                value={benchmarkTargetInfo.Description} onChange={(e) => changeDescription(e)} />
              <span className="red--text">{inputError.Description}</span>
            </td>
          </tr>
          <tr>
            <th className="form-table__col-head">
              <span className="text-h7 font-bold">共有設定</span>
            </th>
            <td className="form-table__col-data">
              <label className="ui-checkbox">
                <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                  disabled={isReference}
                  checked={benchmarkTargetInfo.SharedFlag}
                  onChange={(e) => changeSharedFlag(e)} />
                <span className="ui-checkbox__label text-h7 font-Regular">共有する　　※他のユーザーと設定を共有する場合はチェックをONにしてください</span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

/**
 * 基本情報を取得
 * @returns 基本情報
 */
export const getBasicInfo = () => {
  return { ...basicInfo };
}

/**
 * 基本情報を設定
 * @param {基本情報} info 
 */
export const setBasicInfo = (info) => {
  Object.keys(basicInfo).filter(key => key !== 'SharedFlag').forEach(key => basicInfo[key] = info[key] ? info[key] : '');
  basicInfo.SharedFlag = info.SharedFlag;
}

export const clearCheck = () => {
  // 基本情報部のエラー情報をクリア
  Object.keys(basicInfoError).forEach(key => basicInfoError[key] = null);
}

/**
 * 基本情報入力チェック
 * @returns エラー情報
 */
export const checkBasicInfo = () => {
  // 基本情報部のエラー情報をクリア
  clearCheck();
  // -- 必須 --
  if (!basicInfo.Title) {
    basicInfoError.Title = getMessage("E010001", "タイトル");
  }
  // -- 文字長 --
  if (basicInfo.Title.length > 30) {
    basicInfoError.Title = getMessage("E010006", "タイトル", 30);
  }
  if (basicInfo.Description.length > 200) {
    basicInfoError.Description = getMessage("E010006", "説明", 200);
  }
  return Object.keys(basicInfoError).some(key => basicInfoError[key] !== null);
}

export default (props) => <BasicInfoContent {...props} />;
