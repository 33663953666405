import * as React from "react";
import { Link } from "react-router-dom";

import { downloadGuide } from "../common/utils/FileUtils";
import { viewId } from "../_constants/Code";
import { errorLog } from "../common/logger/Logger";
import { getLatestStdFmtData } from "../common/utils/DataSetUtils";
import { PathInfoList } from "../_constants/PathInfoList";
import logo from '../assets/img/logo.svg';

export const FooterListItems = (props) => {
  const [latestStdFmtData, setLatestStdFmtData] = React.useState({});

  React.useEffect(() => {
    async function fetchData() {
      try {
        const latestStdFmtDatad = await getLatestStdFmtData(props.bucketName, props.accPeriod);
        setLatestStdFmtData(latestStdFmtDatad);
      } catch (error) {
        errorLog("E000001", props.userId, viewId.footer, "fetchData", error);
      }
    }
    setLatestStdFmtData({});
    fetchData();
  }, [props.DateTime]);

  const fileDownload = (e) => {
    e.preventDefault();
    try {
      downloadGuide(process.env.REACT_APP_S3_WEB_CHROFY_COMMON, PathInfoList.Folder.guideFolder + PathInfoList.Objectkeyname.guide);
    } catch (error) {
      errorLog("E000001", props.userId, viewId.footer, "fileDownload", error);
    }
  };
  const handleAbout = async (e) => {
    e.preventDefault();
    window.open(process.env.REACT_APP_URL_ABOUT, '_blank');
  };
  const handleInquiry = async (e) => {
    e.preventDefault();
    window.open(process.env.REACT_APP_URL_INQUIRY, '_blank');
  };
  return (
    <div>
      <footer className="footer pt-14 pb-10 grey">
        {Object.keys(latestStdFmtData).length && (
        <p className="text-base text-center leading-7 mb-16">
          <span className="white--text">【最終更新】</span><br />
          <span className="white--text text--lighten-70">月次データ：{latestStdFmtData.monthData} ／ 四半期データ：{latestStdFmtData.quaterData} ／ 年次データ：{latestStdFmtData.annualData}</span>
        </p>
        )}
        <ul className="footer__nav flex justify-center">
          <li className="footer__nav-item text-caption-1">
            <Link color="inherit" underline="none" component={Link} to="/legal/tos">
              <div className="footer__nav-link white--text block px-12">利用規約</div>
            </Link>
          </li>
          <li className="footer__nav-item text-caption-1">
            <Link color="inherit" underline="none" component={Link} to="/legal/privacyPolicy">
              <div className="footer__nav-link white--text block px-12">プライバシーポリシー</div>
            </Link>
          </li>
          <li className="footer__nav-item text-caption-1">
            <a href="#" className="footer__nav-link white--text block px-12" onClick={handleAbout}>運営会社について</a>
          </li>
          <li className="footer__nav-item text-caption-1">
            <a href="#" className="footer__nav-link white--text block px-12" onClick={handleInquiry}>お問合せフォーム</a>
          </li>
          <li className="footer__nav-item text-caption-1">
            <a href="#" className="footer__nav-link white--text block px-12" onClick={(e) => fileDownload(e)}>ご利用ガイド［PDF］</a>
          </li>
        </ul>
        <div className="footer__logo mt-10 flex flex-col items-center">
          <div className="footer__logo-img"><a href="/"><img src={logo} width="178" height="32" alt="CHROFY"/></a></div>
          <div className="footer__logo-text text-catch-en red--text mt-1">The Human Capital Reporting</div>
        </div>
        <div className="footer__copyright text-center text-caption-1-en white--text text--lighten-40 mt-5"><small>©2022 CHROFY Inc. All Rights Reserved.</small></div>
      </footer>
    </div>
  )
};
