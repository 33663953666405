import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { viewId, table, analysisMode } from "../_constants/Code";
import { Checkbox } from "@mui/material";
import CheckBoxRountedIcon from "@mui/icons-material/CheckBoxRounded";
import { getDynamoDB, registDynamoDB, updateDynamoDB, getGroupCompany } from "../common/utils/DynamoDbUtils";
import { formatDate, formatStringYm } from "../common/utils/DateUtils";
import { errorLog } from "../common/logger/Logger";
import { getRefAuthInfo } from "../services/auth/AuthorityService";

let analysisTargetData = [];
let groupCompanyMaster = [];
let uniqueCompanyList = [];
let segmentSortedList = [];

export const SubHeaderItems = () => {
  const authorityState = useSelector((state) => state.authority);
  const isFirstRender = React.useRef(true);

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isChanged, setIsChanged] = React.useState(false);
  const [condition, setCondition] = React.useState("nothing");
  const [confirmCondition, setConfirmCondition] = React.useState("nothing");
  const [updateDateTime, setUpdateDateTime] = React.useState();
  // 絞り込み条件：なし
  const [authority, setAuthority] = React.useState();
  const [unauthCompany, setUnauthCompany] = React.useState([]);
  const [unauthSegment, setUnauthSegment] = React.useState([]);
  // 絞り込み条件：グループ会社
  const [companyList, setComapnyList] = React.useState([]);
  const [checkCompanys, setCheckCompanys] = React.useState([]);
  const [companySegmentList, setCompanySegmentList] = React.useState([]);
  // 絞り込み条件：セグメント
  const [checkSegments, setCheckSegments] = React.useState([]);
  const [segmentCompanyList, setSegmentCompanyList] = React.useState([]);
  // 表示用
  const [checkedCompany, setCheckedCompany] = React.useState([]);
  const [checkedSegment, setcheckedSegment] = React.useState([]);
  // 条件保存の処理中フラグ
  const isSaveCondition = React.useRef(false);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const corporationNumber = state.company.corporationNumber;
  const userId = state.user.sub;

  React.useEffect(() => {
    if (state.analysis.mode === analysisMode.consolidated) {
      init();
    }
  }, []);

  React.useEffect(() => {
    // 初回レンダリング時は実行しない
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    init();
  }, [authorityState]);

  /**
   * 初期表示
   */
  const init = async () => {
    // 連結会社&セグメントを取得
    analysisTargetData = await getDynamoDB(
      table.AnalysisTargetInfo, null, corporationNumber
    );
    // グループ会社を取得
    groupCompanyMaster = await getGroupCompany(corporationNumber);
    // 表示設定
    await setDisplay();
    setIsLoaded(true);
  }

  const setDisplay = async () => {
    // ユーザー情報取得
    const userData = await getDynamoDB(
      table.User, null, corporationNumber, { UserId: userId }
    );
    // 権限情報
    const authInfo = await getRefAuthInfo(userData[0], groupCompanyMaster);
    setAuthority(authInfo.authority);
    // 会社一覧
    const company = [];
    const unauthCompany = [];
    analysisTargetData[0].Corporation.forEach(corp => {
      const childList = corp.CorporationList;
      // 権限あり
      const auth = childList.filter(child => authInfo.authCompany.includes(child.Number));
      if (auth.length) {
        company.push({
          category: corp.Category,
          childList: auth.map(child => {
            const master = groupCompanyMaster.find(mas => child.Number === mas.CorporationNumber);
            return {
              "number": child.Number,
              "name": child.Name,
              "segment": master.Segment.map(seg => {
                return {
                  "key": seg.Code,
                  "name": seg.Name
                }
              }),
            }
          }).sort((a, b) => a.name.localeCompare(b.name))
        });
      }
      // 権限なし
      const unauth = childList.filter(child => !authInfo.authCompany.includes(child.Number));
      unauth.forEach(child => {
        // 重複なし
        if (unauthCompany.every(com => com.number !== child.Number)) {
          unauthCompany.push({
            "number": child.Number,
            "name": child.Name,
          });
        }
      });
    });
    // ソートして会社リストを設定
    setComapnyList(company);
    uniqueCompanyList = company.reduce((accumulator, currentValue) => {
      currentValue.childList.forEach(child => {
        if (accumulator.every(node => node.number !== child.number)) {
          accumulator.push(child);
        }
      });
      return accumulator;
    }, []).sort((a, b) => a.name.localeCompare(b.name));
    setUnauthCompany(unauthCompany.sort((a, b) => a.name.localeCompare(b.name)));
    // セグメント一覧
    const segment = [];
    const unauthSegment = [];
    analysisTargetData[0].TargetSegment.forEach(target => {
      // 権限あり
      if (authInfo.authSegment.includes(target.Code)) {
        segment.push({
          "key": target.Code,
          "name": target.Name,
          "segmentValidStartMonth": target.SegmentValidStartMonth,
          "segmentValidEndMonth": target.SegmentValidEndMonth,
          "displayOrder": target.DisplayOrder
        });
      }
      // 権限なし
      else {
        unauthSegment.push({
          "key": target.Code,
          "name": target.Name,
          "segmentValidStartMonth": target.SegmentValidStartMonth,
          "segmentValidEndMonth": target.SegmentValidEndMonth,
          "displayOrder": target.DisplayOrder
        });
      }
    });
    // 会社を付与
    segment.forEach(seg => {
      seg.companyList = groupCompanyMaster.filter(Company => {
        return Company.Segment.some(e => e.Code === seg.key);
      }).map(Company => {
        return {
          "number": Company.CorporationNumber,
          "name": Company.CompName,
        }
      }).sort((a, b) => a.name.localeCompare(b.name));
    });
    // 名前でソートしてセグメントリストを設定
    segmentSortedList = segment.sort((a, b) => a.displayOrder - b.displayOrder);
    setUnauthSegment(unauthSegment.sort((a, b) => a.displayOrder - b.displayOrder));

    let condition = "";
    let checkCompanys = [];
    let checkSegments = [];
    // DBを検索
    const saveData = await _getCondition();
    // セッションを確認
    if (state.analysis.condition) {
      // 分析対象が保存されている
      condition = state.analysis.condition;
      setCondition(condition);
      setConfirmCondition(state.analysis.condition);
      if (state.analysis.condition === "nothing") {
        checkCompanys = uniqueCompanyList.map(com => com.number);
        checkSegments = segmentSortedList.map(seg => seg.key);
      } else {
        const corps = state.analysis.targets?.find(t => t.Name === "corpNum");
        const segments = state.analysis.targets?.find(t => t.Name === "segmentCode");
        checkCompanys = corps.Values.filter(v => uniqueCompanyList.some(u => u.number === v));
        checkSegments = segments.Values.filter(v => segmentSortedList.some(s => s.key === v));
      }
      _updateTargetCompanyList(checkCompanys);
      setCheckCompanys(checkCompanys);
      _updateTargetSegmentList(checkSegments);
      setCheckSegments(checkSegments);
      setIsChanged(true);
    } else {
      // 初回
      if (saveData) {
        // レコードあり：保存状態を復元
        condition = saveData.Condition;
        checkCompanys = saveData.CheckCompanys;
        checkSegments = saveData.CheckSegments;
      } else {
        // レコードなし：全選択
        condition = "nothing";
        checkCompanys = uniqueCompanyList.map(com => com.number);
        checkSegments = segmentSortedList.map(seg => seg.key);
      }
      setCondition(condition);
      _updateTargetCompanyList(checkCompanys);
      setCheckCompanys(checkCompanys);
      _updateTargetSegmentList(checkSegments);
      setCheckSegments(checkSegments);
      // セッション値を更新
      changeAnalysisSetting(condition, checkCompanys, checkSegments);
      setIsChanged(false);
    }
    // 表示設定
    let corpValues = _getCorpValues(condition, checkCompanys, checkSegments);
    let segmentValues = _getSegmentValues(condition, checkCompanys, checkSegments);
    setCheckedCompany(corpValues.map(corp => corp.name));
    setcheckedSegment(segmentValues.map(seg => seg.name));
  };

  /**
   * 選択した会社に応じて分析対象のリストを更新する
   */
  const _updateTargetCompanyList = async (checked) => {
    // セグメントを抽出
    const list = segmentSortedList.map(seg => {
      return {
        "key": seg.key,
        "name": seg.name,
        "displayOrder": seg.displayOrder,
        "cntCheck": seg.companyList.filter(com => checked.includes(com.number)).length,
        "cntAll": seg.companyList.filter(com => {
          // 権限のある会社に限定
          return uniqueCompanyList.some(uni => uni.number === com.number);
        }).length
      }
    });
    // 分析対象のリストを設定
    setCompanySegmentList(list.sort((a, b) => a.displayOrder - b.displayOrder));
  }

  /**
   * 選択したセグメントに応じて分析対象のリストを更新する
   */
  const _updateTargetSegmentList = async (checked) => {
    // 会社を抽出
    const list = uniqueCompanyList.map(com => {
      return {
        "number": com.number,
        "name": com.name,
        "cntCheck": com.segment.filter(seg => checked.includes(seg.key)).length,
        "cntAll": com.segment.length,
      }
    });
    // 分析対象のリストを設定
    setSegmentCompanyList(list.sort((a, b) => a.name.localeCompare(b.name)));
  }

  const _getCondition = async () => {
    try {
      const conditionData = await getDynamoDB(
        table.AnalysisCondition, null, corporationNumber, { UserId: userId }
      );
      if (conditionData.length) {
        // 更新日時を保持
        setUpdateDateTime(conditionData[0].UpdateDateTime)
        return conditionData[0]
      }
    } catch (error) {
      errorLog("E000001", userId, viewId.subHeader, "_getCondition", error);
    }
  }

  /**
   * 分析対象の保存
   */
  const saveCondition = async () => {
    if (isSaveCondition.current) {
      return;
    }
    try {
      isSaveCondition.current = true;
      const nowDate = formatDate(new Date());
      const data = {
        Condition: condition,
        CheckCompanys: checkCompanys,
        CheckSegments: checkSegments,
      }
      if (!updateDateTime) {
        // 登録
        data.UserId = userId;
        data.CreateDateTime = nowDate;
        data.UpdateDateTime = nowDate;
        await registDynamoDB(
          table.AnalysisCondition,
          'CorporationNumber',
          corporationNumber,
          [data],
          userId
        );
        setUpdateDateTime(nowDate);
      } else {
        // 更新
        data.UpdateDateTime = nowDate;
        await updateDynamoDB(
          table.AnalysisCondition,
          'CorporationNumber',
          corporationNumber,
          {
            CorporationNumber: corporationNumber,
            UserId: userId
          },
          data,
          userId
        );
      }
      setIsChanged(false);
      // トースト表示
      const buttonMenu = document.getElementById("box-subheader-save-toast");
      buttonMenu.classList.add('is-active');
      setTimeout(() => {
        buttonMenu.classList.remove('is-active');
      }, 4000);
    } catch (error) {
      errorLog("E000001", userId, viewId.subHeader, "saveCondition", error);
    } finally {
      isSaveCondition.current = false;
    }
  }

  /**
   * デフォルトに戻す
   */
  const resetCondition = async () => {
    try {
      // DBを検索
      const saveData = await _getCondition();
      setCondition(saveData.Condition);
      // 会社選択
      if (saveData.Condition === "company") {
        _updateTargetCompanyList(saveData.CheckCompanys);
        setCheckCompanys(saveData.CheckCompanys);
      }
      // セグメント選択
      else if (saveData.Condition === "segment") {
        _updateTargetSegmentList(saveData.CheckSegments);
        setCheckSegments(saveData.CheckSegments);
      }
      setIsChanged(false);
    } catch (error) {
      errorLog("E000001", userId, viewId.subHeader, "resetCondition", error);
    }
  }

  /**
   * 分析対象の変更
   */
  const changeCondition = (e) => {
    setCondition(e.target.value);
    setIsChanged(true);
  }

  /**
   * グループ会社で絞り込み：すべて
   */
  const changeCampanyAll = (someCheck) => {
    const set = new Set();
    if (!someCheck) {
      companyList.forEach(company => {
        company.childList.forEach(node => set.add(node.number));
      });
    }
    _updateTargetCompanyList(Array.from(set));
    setCheckCompanys(Array.from(set));
    setIsChanged(true);
  }

  /**
   * グループ会社で絞り込み：カテゴリ選択
   */
  const changeCategory = (childList) => {
    let checked = checkCompanys;
    const fil = childList.filter((child) => {
      return checked.includes(child.number);
    });
    if (fil.length) {
      checked = checked.filter((node) => {
        return !childList.some(corp => corp.number === node);
      });
    } else {
      checked = checked.concat(childList.map(node => { return node.number }));
    }
    _updateTargetCompanyList(Array.from(new Set(checked)));
    setCheckCompanys(Array.from(new Set(checked)));
    setIsChanged(true);
  }

  /**
   * グループ会社で絞り込み：会社選択
   */
  const changeCampany = (e) => {
    let checked = checkCompanys;
    if (checked.includes(e.target.value)) {
      checked = checkCompanys.filter(corp => corp !== e.target.value);
    } else {
      checked.push(e.target.value);
    }
    _updateTargetCompanyList(Array.from(new Set(checked)))
    setCheckCompanys(Array.from(new Set(checked)));
    setIsChanged(true);
  }
  /**
   * セグメントで絞り込み：すべて
   */
  const changeSegmentAll = () => {
    let checked = []
    if (!checkSegments.length) {
      checked = segmentSortedList.map(seg => seg.key);
    }
    _updateTargetSegmentList(checked);
    setCheckSegments([...checked]);
    setIsChanged(true);
  }


  /**
   * セグメントで絞り込み：セグメント選択
   */
  const changeSegment = (e) => {
    let checked = checkSegments
    if (checked.includes(e.target.value)) {
      checked = checkSegments.filter(seg => seg !== e.target.value);
    } else {
      checked.push(e.target.value);
    }
    _updateTargetSegmentList(checked);
    setCheckSegments([...checked]);
    setIsChanged(true);
  }

  /**
   * 分析設定対象の会社を取得
   */
  const _getCorpValues = (condition, checkCompanys, checkSegments) => {
    let corpValues = [];
    // 絞り込み条件：すべて
    if (condition === "nothing") {
      uniqueCompanyList.forEach(company => {
        corpValues.push(company);
      });
    }
    // 絞り込み条件：会社
    else if (condition === "company") {
      uniqueCompanyList.forEach(company => {
        if (checkCompanys.includes(company.number)) {
          corpValues.push(company);
        }
      });
    }
    // 絞り込み条件：セグメント
    else if (condition === "segment") {
      uniqueCompanyList.forEach(company => {
        if (company.segment.filter(seg => checkSegments.includes(seg.key)).length) {
          // 重複していない会社を追加
          if (!corpValues.some(e => e.number === company.number)) {
            corpValues.push(company);
          }
        }
      });
    }
    return corpValues;
  }

  /**
   * 分析設定対象のセグメントを取得
   */
  const _getSegmentValues = (condition, checkCompanys, checkSegments) => {
    let segmentValues = [];
    // 絞り込み条件：すべて
    if (condition === "nothing") {
      // セグメントが取得できない場合は権限のあるセグメントを設定
      if (segmentSortedList.length) {
        segmentSortedList.forEach(segment => {
          segmentValues.push(segment);
        });
      } else {
        segmentValues = authorityState.referenceAuth.authSegment;
      }
    }
    // 絞り込み条件：会社
    else if (condition === "company") {
      // セグメントが取得できない場合は権限のあるセグメントを設定
      if (segmentSortedList.length) {
        segmentSortedList.forEach(segment => {
          if (segment.companyList.filter(com => checkCompanys.includes(com.number)).length) {
            // 重複していないセグメントを追加
            if (!segmentValues.some(e => e.key === segment.key)) {
              segmentValues.push(segment);
            }
          }
        });
      } else {
        segmentValues = authorityState.referenceAuth.authSegment;
      }
    }
    // 絞り込み条件：セグメント
    else if (condition === "segment") {
      segmentSortedList.forEach(segment => {
        if (checkSegments.includes(segment.key)) {
          segmentValues.push(segment);
        }
      });
    }
    return segmentValues;
  }

  /**
   * 絞り込み条件の変更
   */
  const changeAnalysisSetting = (condition, checkCompanys, checkSegments) => {
    setConfirmCondition(condition);
    try {
      // 絞り込み条件に応じて設定を切り替える
      let corpValues = _getCorpValues(condition, checkCompanys, checkSegments);
      let segmentValues = _getSegmentValues(condition, checkCompanys, checkSegments);
      dispatch({
        type: "SET_ANALISYS_MODE",
        payload: {
          analysis: {
            mode: state.analysis.mode,
            condition: condition,
            targets: [
              {
                "Name": "corpNum",
                "Values": corpValues.map(corp => corp.number)
              },
              {
                "Name": "segmentCode",
                "Values": segmentValues.map(seg => seg.key)
              }
            ],
            checkedIndex: state.analysis.checkedIndex,
            checkedNames: {
              "comp": corpValues.map(corp => corp.name),
              "seg": segmentValues.map(seg => seg.name)
            },
          }
        },
      });
      // 表示設定
      setCheckedCompany(corpValues.map(corp => corp.name));
      setcheckedSegment(segmentValues.map(seg => seg.name));
    } catch (error) {
      console.error(error);
      errorLog("E000001", state.user.sub, viewId.subHeader, "changeAnalysisSetting", error);
    }
  };

  /**
   * モーダル表示
   */
  const _openModal = () => {
    const modalBg = document.querySelector(".subheader-bg-modal-window");
    modalBg.classList.add("is-active");
    const modalUploading = document.querySelector(".subheader-modal-window");
    modalUploading.classList.remove("animate__fadeOut");
    modalUploading.classList.add("animate__fadeIn");
    modalUploading.classList.add("is-active");
  }

  /**
   * モーダル非表示
   */
  const _closeModal = () => {
    const modal = document.querySelector(".subheader-modal-window");
    if (modal && !modal.classList.contains("animate__fadeOut")) {
      modal.classList.remove("animate__fadeIn");
      modal.classList.add("animate__fadeOut");
    }
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      document.querySelector(".subheader-bg-modal-window.is-active")?.classList.remove("is-active");
      document.querySelector(".modal-window.is-active")?.classList.remove("is-active");
    }, fadeOutTimeoutSec);
  }

  /**
   * 描画処理
   */
  if (!isLoaded && state.analysis) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="subheader z-20 is-active">
        {/* 絞り込み条件常時 */}
        {
          (() => {
            // 会社名を40文字内に設定
            const ellipsisCompany = [];
            checkedCompany.forEach(e => {
              if ((ellipsisCompany.join("、") + e).length <= 40) {
                ellipsisCompany.push(e);
              }
            });
            // セグメント名を40文字内二設定
            const ellipsisSegment = [];
            checkedSegment.forEach(e => {
              if ((ellipsisSegment.join("、") + e).length <= 40) {
                ellipsisSegment.push(e);
              }
            });
            return (
              <>
                <span className="subheader__title">分析対象</span>
                {/* 分析対象：すべて */}
                {(confirmCondition === "nothing") &&
                  <div className="subheader__target">
                    <span className="subheader__target-title">すべて</span>
                    <span className="subheader__target-icon">»</span>
                    {/* 権限：制限なし */}
                    {authority === 'all' && <>
                      <span className="subheader__target-content"><span className="link--text">グループ全社のデータ</span>を分析対象としてグラフを表示します</span>
                      <div className="subheader__target-detail">{checkedCompany.join("、")}</div>
                    </>}
                    {/* 権限：グループ会社 */}
                    {authority === 'company' && <>
                      <span className="subheader__target-content"><span className="link--text">グループ全{uniqueCompanyList.length + unauthCompany.length}社中{uniqueCompanyList.length}社のデータ</span>を分析対象としてグラフを表示します</span>
                      <div className="subheader__target-detail">{checkedCompany.join("、")}</div>
                    </>}
                    {/* 権限：セグメント */}
                    {authority === 'segment' && <>
                      <span className="subheader__target-content"><span className="link--text">グループ全{segmentSortedList.length + unauthSegment.length}セグメント中{segmentSortedList.length}セグメントのデータ</span>を分析対象としてグラフを表示します</span>
                      <div className="subheader__target-detail">{checkedSegment.join("、")}</div>
                    </>}
                  </div>
                }
                {/* 分析対象：グループ会社 */}
                {confirmCondition === "company" &&
                  <div className="subheader__target">
                    <span className="subheader__target-title">グループ会社</span>
                    <span className="subheader__target-icon">»</span>
                    <span className="subheader__target-content">
                      {ellipsisCompany.length === checkedCompany.length ? <>
                        <span className="link--text">{checkedCompany.join("、")}</span> のデータで絞り込み中
                      </> : <>
                        <span className="link--text">{ellipsisCompany.join("、")} ほか{checkedCompany.length - ellipsisCompany.length}社</span> のデータで絞り込み中
                      </>
                      }
                    </span>
                    <div className="subheader__target-detail">{checkedCompany.join("、")}</div>
                  </div>
                }
                {/* 分析対象：セグメント */}
                {confirmCondition === "segment" &&
                  <div className="subheader__target">
                    <span className="subheader__target-title">セグメント</span>
                    <span className="subheader__target-icon">»</span>
                    <span className="subheader__target-content">
                      {ellipsisSegment.length === checkedSegment.length ? <>
                        <span className="link--text">{checkedSegment.join("、")}</span> のデータで絞り込み中
                      </> : <>
                        <span className="link--text">{ellipsisSegment.join("、")} ほか{checkedSegment.length - ellipsisSegment.length}セグメント</span> のデータで絞り込み中
                      </>
                      }
                    </span>
                    <div className="subheader__target-detail">{checkedSegment.join("、")}</div>
                  </div>
                }
                <button className="subheader__button" onClick={async () => { await setDisplay(); _openModal(); }}>条件確認 / 変更</button>
                <div className="subheader__btn-save-container">
                  <button className="subheader__btn-save" title="設定中の条件を保存"
                    onClick={() => saveCondition()}>
                    <span className="icon-save"></span>
                  </button>
                </div>
              </>
            )
          })()
        }
      </div>
      <div className="subheader-bg-modal-window w-screen h-screen fixed top-0 left-0 grey lighten-70 z-40" onClick={() => _closeModal()}></div>
      <div id="box-subheader-update-toast" className="box-subheader-toast flex items-center pl-8 blue1 rounded-b-2xl fixed left-1/2 z-50">
        <div className="box-toast__text text-h6 white--text text-nowrap mr-1">絞り込み条件を変更しました。画面右の保存アイコンで現在の設定を保存できます。</div>
        <button type="button" className="box-toast__btn white--text p-5 leading-none" onClick={() => document.getElementById("box-subheader-update-toast").classList.remove('is-active')}><span className="icon-close-s"></span></button>
      </div>
      <div id="box-subheader-save-toast" className="box-subheader-toast flex items-center pl-8 blue1 rounded-b-2xl fixed left-1/2 z-50">
        <div className="box-toast__text text-h6 white--text text-nowrap mr-1">設定中の絞り込み条件を保存しました。次回アクセス時はこの条件で表示します。</div>
        <button type="button" className="box-toast__btn white--text p-5 leading-none" onClick={() => document.getElementById("box-subheader-save-toast").classList.remove('is-active')}><span className="icon-close-s"></span></button>
      </div>
      {/* ===== 分析対象の設定 ===== */}
      <div className="subheader-modal-window modal-window modal-window__wide modal-window--analysis-setting fixed bg sub z-40 rounded-2xl" id="modal-window--analysis-setting">
        <div className="modal-window__header">
          <div className="modal-window__title flex items-center justify-center pt-10">
            <span className="icon-filter icon-32px mr-2"></span>
            <span className="modal-window__title-text text-h4 font-medium">分析対象の絞り込み</span>
          </div>
          <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => _closeModal()}>
            <span className="icon-close"></span>
          </button>
        </div>
        {/* 保存した設定に復帰 */}
        <div className="modal-window__content !overflow-y-hidden px-8 py-10">
          <div className="modal-window__btn-revert-setting relative">
            <button className={`btn-link absolute -top-6 right-8 flex items-center px-2.5 py-0.5 rounded-t ${updateDateTime && isChanged ? "" : "grey2--text"}`}
              disabled={updateDateTime ? !isChanged : true}
              onClick={() => resetCondition()}>
              <span className="icon-arrow-return mr-2.5"></span>
              <span className="text-h7">保存した設定に復帰</span>
            </button>
          </div>
          <div className="box-xy-bordered rounded-xl">
            <div className="modal-window__filter-select flex justify-center items-center py-8 px-10 border-b border-grey-lighten-45">
              <div className="text-h6 font-bold mr-12">分析対象：</div>
              <ul className="flex gap-x-8">
                <li>
                  <label className="subheader-ui ui-radio is-nothing">
                    <input type="radio" className="ui-radio__input" name="conditions" value="nothing"
                      checked={condition === "nothing"} onChange={(e) => changeCondition(e)} />
                    <span className="ui-radio__icon"></span>
                    <span className="ui-radio__ripple"></span>
                    <span className="ui-radio__label text-h7 font-Regular">すべて</span>
                  </label>
                </li>
                <li>
                  <label className="subheader-ui ui-radio is-group">
                    <input type="radio" className="ui-radio__input" name="conditions" value="company"
                      checked={condition === "company"} onChange={(e) => changeCondition(e)} />
                    <span className="ui-radio__icon"></span>
                    <span className="ui-radio__ripple"></span>
                    <span className="ui-radio__label text-h7 font-Regular">グループ会社で絞り込む</span>
                  </label>
                </li>
                <li>
                  <label className="subheader-ui ui-radio is-segment">
                    <input type="radio" className="ui-radio__input" name="conditions" value="segment"
                      checked={condition === "segment"} onChange={(e) => changeCondition(e)} />
                    <span className="ui-radio__icon"></span>
                    <span className="ui-radio__ripple"></span>
                    <span className="ui-radio__label text-h7 font-Regular">セグメントで絞り込む</span>
                  </label>
                </li>
              </ul>
            </div>
            <div className="modal-window__filter-detail pt-8">
              <div className="subheader-modal__content">
                {/* 絞り込み条件：なし */}
                {condition === "nothing" && <>
                  <div className="condition-nothing">
                    {/* 権限設定：会社 */}
                    {authority === "company" &&
                      <div className="auth-company">
                        <div className="flex items-center mt-0.5 border-b-2 border-green pb-8 px-10">
                          <span className="icon-filter-group-segment icon-32px green--text mr-2"></span>
                          <span className="text-h4 font-medium mr-6">すべてを対象</span>
                          <span className="text-caption-2 font-medium pl-6 pt-1 pb-0.5 border-l border-blue2-lighten-30">参照権限のあるすべての会社が分析対象となります。</span>
                        </div>
                        <div className="modal-window__filter-detail-content overflow-y-auto overscroll-none pt-8 pb-8 px-10">
                          <div className="box-xy-bordered is-green rounded-t-lg rounded-b-lg pt-10 px-6">
                            <div className="box-bordered-heading is-green text-h6 font-medium pt-3 pb-3.5 px-12 -mt-10 -mx-6 rounded-t-lg">
                              参照権限のある会社 ［ {uniqueCompanyList.length} / {uniqueCompanyList.length + unauthCompany.length} ］
                            </div>
                            <ul className="pt-5 pb-6 grid grid-cols-2 gap-x-8 gap-y-0.5 text-pulldown">
                              {uniqueCompanyList.map((company, idx) => {
                                return (
                                  <li key={idx}>{company.name}</li>
                                )
                              })}
                            </ul>
                          </div>
                          <div className="box-xy-bordered is-grey rounded-t-lg rounded-b-lg pt-10 px-6 mt-8">
                            <div className="box-bordered-heading is-grey text-h6 font-medium pt-3 pb-3.5 px-12 -mt-10 -mx-6 rounded-t-lg">
                              参照権限のない会社 ［ {unauthCompany.length} / {uniqueCompanyList.length + unauthCompany.length} ］
                            </div>
                            <ul className="pt-5 pb-6 grid grid-cols-2 gap-x-8 gap-y-0.5 text-pulldown">
                              {unauthCompany.map((company, idx) => {
                                return (
                                  <li key={idx}>{company.name}</li>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    }
                    {/* 権限設定：セグメント */}
                    {authority === "segment" &&
                      <div className="auth-segment">
                        <div className="flex items-center mt-0.5 border-b-2 border-green pb-8 px-10">
                          <span className="icon-filter-group-segment icon-32px green--text mr-2"></span>
                          <span className="text-h4 font-medium mr-6">すべてを対象</span>
                          <span className="text-caption-2 font-medium pl-6 pt-1 pb-0.5 border-l border-blue2-lighten-30">参照権限のあるすべてのセグメントが分析対象となります。</span>
                        </div>
                        <div className="modal-window__filter-detail-content overflow-y-auto overscroll-none pt-8 pb-8 px-10">
                          <div className="modal-window__filter-detail-content overflow-y-auto overscroll-none pt-8 pb-8 px-10">
                            <div className="box-xy-bordered is-green rounded-t-lg rounded-b-lg pt-10 px-6">
                              <div className="box-bordered-heading is-green text-h6 font-medium pt-3 pb-3.5 px-12 -mt-10 -mx-6 rounded-t-lg">
                                参照権限のあるセグメント ［ {segmentSortedList.length} / {segmentSortedList.length + unauthSegment.length} ］
                              </div>
                              <ul className="pt-5 pb-6 grid grid-cols-2 gap-x-8 gap-y-0.5 text-pulldown">
                                {segmentSortedList.map((segment, idx) => {
                                  return (
                                    <li key={idx}>{segment.name}</li>
                                  )
                                })}
                              </ul>
                            </div>
                            <div className="box-xy-bordered is-grey rounded-t-lg rounded-b-lg pt-10 px-6 mt-8">
                              <div className="box-bordered-heading is-grey text-h6 font-medium pt-3 pb-3.5 px-12 -mt-10 -mx-6 rounded-t-lg">
                                参照権限のないセグメント ［ {unauthSegment.length} / {segmentSortedList.length + unauthSegment.length} ］
                              </div>
                              <ul className="pt-5 pb-6 grid grid-cols-2 gap-x-8 gap-y-0.5 text-pulldown">
                                {unauthSegment.map((segment, idx) => {
                                  return (
                                    <li key={idx}>{segment.name}</li>
                                  )
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {/* 権限設定：すべて */}
                    {authority === "all" &&
                      <div className="auth-all">
                        <div className="flex items-center mt-0.5 border-b-2 border-green pb-8 px-10">
                          <span className="icon-filter-group-segment icon-32px green--text mr-2"></span>
                          <span className="text-h4 font-medium mr-6">すべてを対象</span>
                          <span className="text-caption-2 font-medium pl-6 pt-1 pb-0.5 border-l border-blue2-lighten-30">下記すべての会社が分析対象となります。</span>
                          <span className="text-h4 font-medium ml-auto">
                            {
                              (() => {
                                const nums = [];
                                companyList.forEach(company => {
                                  company.childList.forEach(child => {
                                    nums.push(child.number);
                                  });
                                });
                                return (
                                  <span>{Array.from(new Set(nums)).length}社 / {segmentSortedList.length}セグメント</span>
                                )
                              })()
                            }
                          </span>
                        </div>
                        <div className="modal-window__filter-detail-content overflow-y-auto overscroll-none pt-8 pb-8 px-10">
                          {segmentSortedList.length ?
                            segmentSortedList.map((segment, idx) => {
                              return (
                                <div key={idx}>
                                  <div className="green--text green2 lighten-6 text-h6 font-bold py-2 px-4 rounded">【{segment.name}】</div>
                                  <ul className="pt-2.5 px-6 pb-6 grid grid-cols-2 gap-x-8  gap-y-0.5 text-pulldown">
                                    {segment.companyList.map((company, idx2) => {
                                      return (
                                        <li key={idx2}>{company.name}</li>
                                      )
                                    })}
                                  </ul>
                                </div>
                              )
                            })
                           : 
                            <div>
                              <ul className="pt-2.5 px-6 pb-6 grid grid-cols-2 gap-x-8  gap-y-0.5 text-pulldown">
                                {groupCompanyMaster.sort((a, b) => a.CompName.localeCompare(b.CompName)).map((company, idx) => {
                                  return (
                                    <li key={idx}>{company.CompName}</li>
                                  )
                                })}
                              </ul>
                            </div>
                           }
                        </div>
                      </div>
                    }
                    {!authority &&
                      <div className="undefined pl-5 pb-8">
                        <span className="red--text">データの参照権限がありません。</span>
                      </div>
                    }
                  </div>
                </>
                }
                {/* 絞り込み条件：会社 */}
                {condition === "company" && <>
                  <div className="condition-conpany">
                    <div className="flex items-center mt-0.5 border-b-2 border-green pb-8 px-10">
                      <span className="icon-filter-group icon-32px green--text mr-2"></span>
                      <span className="text-h4 font-medium mr-6">グループ会社で絞り込み</span>
                      {
                        (() => {
                          const nums = [];
                          companyList.forEach(company => {
                            company.childList.forEach(child => {
                              nums.push(child.number);
                            });
                          });
                          const checkedSegment = companySegmentList.filter(com => com.cntCheck > 0).length;
                          return (<>
                            <span className="text-h4 font-medium ml-auto">{checkCompanys.length} / {Array.from(new Set(nums)).length}社 選択中</span>
                            <span className="text-h7">［ <span className="green--text">対象セグメント {checkedSegment} / {segmentSortedList.length}</span> ］</span>
                          </>)
                        })()
                      }
                    </div>
                    <div className="modal-window__filter-detail-content is-no-vertical-padding overflow-y-auto overscroll-none mt-8 mb-8 px-10">
                      <div className="grid grid-cols-2 gap-x-8">
                        {/* 左側の内容 */}
                        <div className="box-xy-bordered is-green rounded-lg py-6 px-8">
                          {
                            (() => {
                              const someCheck = companyList.some(company => {
                                return company.childList.some((node) => {
                                  return checkCompanys.includes(node.number);
                                });
                              });
                              const isCheckAll = companyList.every(company => {
                                return company.childList.every((node) => {
                                  return checkCompanys.includes(node.number);
                                });
                              });
                              if (companyList.length) {
                                return <>
                                  <label className="ui-checkbox cheked-green">
                                    <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                      indeterminate={someCheck && !isCheckAll}
                                      checked={isCheckAll}
                                      onChange={(e) => changeCampanyAll(someCheck)} />
                                    <span className="ui-checkbox__label text-h7 font-Regular">すべてを選択</span>
                                  </label>
                                </>
                              } else {
                                return <>
                                  <div className="undefined pl-5 pb-8">
                                    <span className="red--text">データの参照権限がありません。</span>
                                  </div>
                                </>
                              }
                            })()
                          }
                          {companyList.map((company, idx) => {
                            const someCheck = company.childList.some((node) => {
                              return checkCompanys.includes(node.number);
                            });
                            const isCheckAll = company.childList.every((node) => {
                              return checkCompanys.includes(node.number);
                            });
                            return (
                              <div key={idx} className="mt-3.5 ml-6">
                                {company.category && (
                                  <label className="checkbox-list__item ui-checkbox cheked-green">
                                    <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                      indeterminate={someCheck && !isCheckAll}
                                      checked={isCheckAll}
                                      onChange={(e) => changeCategory(company.childList)} />
                                    <span className="ui-checkbox__label text-pulldown font-bold">{company.category}</span>
                                  </label>
                                )}
                                <div className="ml-6">
                                  {company.childList.map((child, idx2) => {
                                    return (
                                      <label key={idx2} className="checkbox-list__item ui-checkbox cheked-green">
                                        <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                          value={child.number}
                                          checked={checkCompanys.includes(child.number)}
                                          onChange={(e) => changeCampany(e)} />
                                        <span className="ui-checkbox__label text-h7 font-Regular">{child.name}</span>
                                      </label>
                                    )
                                  })}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        {/* 右側の内容 */}
                        <div className="box-xy-bordered is-grey rounded-lg py-6 px-8">
                          <div className="text-h6 font-bold blue1--text mb-5">分析対象会社のセグメントと対象会社数</div>
                          <p className="text-caption-2"><span className="red--text">赤字のセグメント</span>は、ダッシュボード画面でセグメントを軸に集計した際、選択されていない会社のデータは分析対象から除外されます。</p>
                          <ul className="border-t border-blue1-lighten-20 mt-6 pt-6 flex flex-col gap-y-1">
                            {companySegmentList.map((segment, idx) => {
                              const isGrey = segment.cntCheck === 0;
                              const isRed = !isGrey && segment.cntCheck !== segment.cntAll;
                              return (
                                <li key={idx} className={isGrey ? "blue2--text text--lighten-30" : isRed ? "red--text" : ""}>
                                  <span className="text-h7 font-bold">【 {segment.name} 】</span>
                                  <span className="ml-2.5 text-pulldown">{segment.cntCheck} / {segment.cntAll}</span>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                }
                {/* 絞り込み条件：セグメント */}
                {condition === "segment" && <>
                  <div className="condition-segment">
                    <div className="flex items-center mt-0.5 border-b-2 border-green pb-8 px-10">
                      <span className="icon-filter-segment icon-32px green--text mr-2"></span>
                      <span className="text-h4 font-medium mr-6">セグメントで絞り込み</span>
                      {
                        (() => {
                          const nums = [];
                          companyList.forEach(company => {
                            company.childList.forEach(child => {
                              nums.push(child.number);
                            });
                          });
                          const checkedCompany = segmentCompanyList.filter(seg => seg.cntCheck > 0).length;
                          return (<>
                            <span className="text-h4 font-medium ml-auto">{checkSegments.length} / {segmentSortedList.length}セグメント 選択中</span>
                            <span className="text-h7">［ <span className="green--text">対象 {checkedCompany} / {groupCompanyMaster.length - unauthCompany.length}社</span> ］</span>
                          </>)
                        })()
                      }
                    </div>
                    <div className="modal-window__filter-detail-content is-no-vertical-padding overflow-y-auto overscroll-none mt-8 mb-8 px-10">
                      <div className="grid grid-cols-2 gap-x-8">
                        {/* 左側の内容 */}
                        <div className="box-xy-bordered is-green rounded-lg py-6 px-8">
                          {segmentSortedList.length !== 0 ? (
                            <label className="checkbox-list__item ui-checkbox cheked-green">
                              <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                indeterminate={checkSegments.length ? checkSegments.length !== segmentSortedList.length : false}
                                checked={checkSegments.length === segmentSortedList.length}
                                onChange={(e) => changeSegmentAll(e)} />
                              <span className="ui-checkbox__label text-h7 font-Regular">すべてを選択</span>
                            </label>
                          ) : (
                            <div className="undefined pl-5 pb-8">
                              <span className="red--text">データの参照権限がありません。</span>
                            </div>
                          )}
                          {segmentSortedList.map((segment, idx) => {
                            return (
                              <div key={idx} className="mt-1.5 ml-6">
                                <label className="checkbox-list__item ui-checkbox cheked-green">
                                  <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                    value={segment.key}
                                    checked={checkSegments.includes(segment.key)}
                                    onChange={(e) => changeSegment(e)} />
                                  <span className="ui-checkbox__label text-h7 font-Regular">{segment.name}</span>
                                  {segment.segmentValidEndMonth && <span className="badge-segment ml-3">{formatStringYm(segment.segmentValidEndMonth)}まで</span>}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                        {/* 右側の内容 */}
                        <div className="box-xy-bordered is-grey rounded-lg py-6 px-8">
                          <div className="text-h6 font-bold blue1--text mb-5">分析対象の会社と対象セグメント数</div>
                          <p className="text-caption-2">
                            <span className="red--text">赤字の会社</span>は、ダッシュボード画面で会社を軸に集計した際に、選択されていないセグメントのデータは分析対象から除外されます。<br />
                            データ参照権限のない会社は表示されません。
                          </p>
                          <ul className="border-t border-blue1-lighten-20 mt-6 pt-6 flex flex-col gap-y-1">
                            {segmentCompanyList.map((company, idx) => {
                              const isGrey = company.cntCheck === 0;
                              const isRed = !isGrey && company.cntCheck !== company.cntAll;
                              return (
                                <li key={idx} className={isGrey ? "blue2--text text--lighten-30" : isRed ? "red--text" : ""}>
                                  <span className="text-pulldown">{company.name}</span>
                                  <span className="ml-2.5 text-pulldown">[ {company.cntCheck}&nbsp;/&nbsp;{company.cntAll} ]</span>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                }
              </div>
            </div>
          </div>
          <div className="modal-window__btns text-center">
            <div className="modal-content-footer">
              <div className="footer_button_area">
                <button className="footer-btn button_back" onClick={() => _closeModal()}>戻る</button>
                <button className="btn footer-btn button_base"
                  disabled={condition === "nothing" ? false : condition === "company" && checkCompanys.length ? false : condition === "segment" && checkSegments.length ? false : true}
                  onClick={() => {
                    changeAnalysisSetting(condition, checkCompanys, checkSegments);
                    // サブヘッダーの表示更新
                    _closeModal();
                    // トースト表示
                    const buttonMenu = document.getElementById("box-subheader-update-toast");
                    buttonMenu.classList.add('is-active');
                    setTimeout(() => {
                      buttonMenu.classList.remove('is-active');
                    }, 4000);
                  }}>設定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===== /分析対象の設定 ===== */}
    </React.Fragment >
  )
};