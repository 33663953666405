import * as React from "react";
import {
  withAuthenticator,
  Heading,
  useTheme,
  Flex,
  useAuthenticator,
} from "@aws-amplify/ui-react";
// import "../assets/css/login.css";
import "@aws-amplify/ui-react/styles.css";
import { Translations } from "@aws-amplify/ui-components";
import { useDispatch } from "react-redux";
import { Amplify, I18n } from "aws-amplify";
import awsExports from "../../aws-exports";
import I18nConf from "../../_constants/I18nConf";
import { viewId, titleName, table, analysisMode } from "../../_constants/Code";
import { errorLog } from "../../common/logger/Logger";
import { Message } from "../../_components/MessagePage";
import { getDynamoDB } from "../../common/utils/DynamoDbUtils";
import { getAuthority } from "../../services/auth/AuthorityService";

import logo from "../../assets/img/logo.svg";

Amplify.configure(awsExports);

I18n.putVocabularies(I18nConf);
I18n.setLanguage("ja");

const Login = ({ user }) => {
  const messageList = [
    "ログイン処理に失敗しました。再ログインしてください。",
    "エラーが改善しない場合は管理者までお問い合わせください。"
  ];
  const [error, setError] = React.useState();
  const dispatch = useDispatch();
  React.useEffect(() => {
    try {
      const f = async () => {
        try {
        let company = await getDynamoDB(
          table.Company,
          null,
          user.attributes["custom:CorporationNumber"],
          null,
          null
        );
        if (company.length == 1) {
          company = company[0];
        } else {
          throw("会社情報の取得に失敗しました");
        }
        const analysisTargetInfo = await getDynamoDB(
          table.AnalysisTargetInfo,
          null,
          user.attributes["custom:CorporationNumber"],
          null,
          null
        );
        let isParent;
        let groupName = null;
        if (analysisTargetInfo.length == 0) {
          isParent = false;
        } else {
          isParent = true;
          groupName = analysisTargetInfo[0].GroupName;
        }
        const authority = await getAuthority(
          analysisMode.standalone,
          user.attributes["custom:CorporationNumber"],
          user.attributes.sub
        );
        dispatch({
          type: "SET_LOGIN",
          payload: {
            user: {
              sub: user.attributes.sub,
              userName: user.attributes.name,
              role: user.signInUserSession.idToken.payload["cognito:groups"][0],
            },
            company: {
              name: company.CompName,
              isParent: isParent,
              groupName: isParent ? groupName : "",
              corporationNumber: user.attributes["custom:CorporationNumber"],
              accPeriod: company.AccountingPeriod,
              kpiStartYear: company.KpiStartYear,
            },
            analysis: {
              mode: analysisMode.standalone,
            },
            authority: authority
          },
        });
        localStorage.removeItem("chrofyLastActionTime");
        window.location.href = "/";
        } catch (error) {
          setError(error);
          errorLog("E000001", "someuser", viewId.login, "Login", error);
        }
      }
      f();
    } catch (error) {
      setError(error);
      errorLog("E000001", "someuser", viewId.login, "Login", error);
    }
  }, []);
  if (error != null) {
    return (
      <Message
        title="ログインエラー"
        messageList={messageList}
        loginBtn={true}
        topBtn={false}
      />
    );
  }
};

const SignInHeader = () => {
  const { tokens } = useTheme();
  // タイトル設定
  document.title = titleName.login + titleName.common;
  return (
    <React.Fragment>
      <Heading
        level={6}
        padding={tokens.space.small}
        marginTop="32px"
        textAlign="center"
        backgroundColor="white"
      >
        <img
          className="loginLogo"
          src={logo}
          width="178"
          height="32"
          alt="CHROFY"
        />
      </Heading>
      <Flex justifyContent="center" padding={`0 0 ${tokens.space.small}`}>
        <p className="text-caption-1 loginText">ユーザーIDとパスワードを入力してログイン</p>
      </Flex>
    </React.Fragment>
  );
};

const SignInFooter = () => {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();
  const footerText = I18n.get([Translations.RESET_YOUR_PASSWORD]);

  const onClickToResetPassword = (e) => {
    try {
      e.preventDefault();
      toResetPassword();
    } catch (error) {
      errorLog("E000001", "someuser", viewId.login, "SignInFooter", error);
    }
  };

  return (
    <div>
      <Flex
        justifyContent="center"
        textDecoration="underline"
        padding={`0 0 ${tokens.space.medium}`}
      >
        <a className="text-caption-1 loginLink" color="blue" href="#" onClick={(e) => onClickToResetPassword(e)}>
          {footerText}
        </a>
      </Flex>
      <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`} marginBottom="28px">
        <p className="text-caption-1 loginText">
          ユーザーIDをお忘れの場合は
          <br />
          <a
            className="loginLink"
            color="blue"
            href={process.env.REACT_APP_URL_ID_INQUIRY}
            target="_blank"
          >
            こちらから
          </a>
          お問い合わせください
        </p>
      </Flex>
    </div>
  );
};

const ResetPasswordHeader = () => {
  const { tokens } = useTheme();
  return (
    <React.Fragment>
      <Heading
        level={6}
        padding={tokens.space.small}
        textAlign="center"
        backgroundColor="white"
      >
        <img
          className="loginLogo"
          src={logo}
          width="178"
          height="32"
          alt="CHROFY"
        />
      </Heading>
      <Flex justifyContent="center" padding={`0 0 ${tokens.space.small}`}>
        <p className="text-caption-1 loginText">
          ユーザーIDを入力してください。
          <br />
          パスワード再設定用のコードを
          <br />
          メールでお送りします。
        </p>
      </Flex>
    </React.Fragment>
  );
};

const ConfirmResetPasswordHeader = () => {
  const { tokens } = useTheme();
  return (
    <React.Fragment>
      <Heading
        level={6}
        padding={tokens.space.small}
        textAlign="center"
        backgroundColor="white"
      >
        <img
          className="loginLogo"
          src={logo}
          width="178"
          height="32"
          alt="CHROFY"
        />
      </Heading>
      <Flex justifyContent="center" padding={`0 0 ${tokens.space.small}`}>
        <p className="text-caption-1 loginText">
          認証コードと新しいパスワードを
          <br />
          入力してください。
        </p>
      </Flex>
    </React.Fragment>
  );
};

const ConfirmSignInHeader = () => {
  const { tokens } = useTheme();
  return (
    <React.Fragment>
      <Heading
        level={6}
        padding={tokens.space.small}
        textAlign="center"
        backgroundColor="white"
      >
        <img
          className="loginLogo"
          src={logo}
          width="178"
          height="32"
          alt="CHROFY"
        />
      </Heading>
      <Flex justifyContent="center" padding={`0 0 ${tokens.space.small}`}>
        <p className="text-caption-1 loginText">
          SMSを送信しました。
          <br />
          メッセージに記載された認証コードを
          <br />
          入力してください。
        </p>
      </Flex>
    </React.Fragment>
  );
};

// https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/
export default withAuthenticator(Login, {
  components: {
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter,
    },
    ResetPassword: {
      Header: ResetPasswordHeader,
    },
    ConfirmResetPassword: {
      Header: ConfirmResetPasswordHeader,
    },
    ConfirmSignIn: {
      Header: ConfirmSignInHeader,
    },
  },
  hideSignUp: true,
});
