"use strict";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Checkbox } from "@mui/material";
import CheckBoxRountedIcon from "@mui/icons-material/CheckBoxRounded";
import { useSelector } from "react-redux";
import { viewId, titleName, table, periodKind as periodKindCode, taskStatus as taskStatusCode, dataCreateStatus, dataFixStatus, accountingUploadTaskStatus } from "../../_constants/Code";
import { getDynamoDB, getFileUploadTask, getAccountingUploadTask } from "../../common/utils/DynamoDbUtils";
import { formatDate } from "../../common/utils/DateUtils";
import { errorLog } from "../../common/logger/Logger";

import Forbidden from "../../_components/Forbidden";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  disableScrollLock: true,
};

const periodKind = [];
for (var k in periodKindCode) {
  periodKind.push(periodKindCode[k]);
}

const taskStatus = [];
for (var k in taskStatusCode) {
  taskStatus.push(k);
}

const TableData = (dataCreateTaskList, fileUploadTaskList, accountingUploadTaskList) => {
  if (dataCreateTaskList.length > 0) {
    return (
      dataCreateTaskList.map((d, idx) => {
        let allFileList = fileUploadTaskList.filter((f) => f.SuperKey === d.Key);
        let allAccountingList = accountingUploadTaskList.filter((f) => f.SuperKey === d.Key);
        let registeredFileList = allFileList.filter((f) => f.Status === "check-ok" || f.NoDataFlag === true);
        let registeredAccountingList = allAccountingList.filter((f) => f.Status === accountingUploadTaskStatus.registered.value);
        return (
          <tr key={idx}>
            <td>{d.PeriodValue}</td>
            <td>{taskStatusCode[d.TaskStatus].name}</td>
            <td>
              {registeredFileList.length + registeredAccountingList.length}
              &nbsp;／&nbsp;
              {allFileList.length + allAccountingList.length}
              </td>
            <td>{d.FileRegistDeadline}</td>
            <td>{dataCreateStatus[d.DataCreateStatus].name}</td>
            <td>{d.DataCreateDateTime && formatDate(new Date(d.DataCreateDateTime)).slice(0, -3)}</td>
            <td>{dataFixStatus[d.DataFixStatus].name}</td>
            <td>{d.Message}</td>
            <td className="text-center">
              <Link variant="button" underline="none" component={RouterLink} to={"/data/analysisData/" + d.Key}>
                <span>詳細</span>
              </Link>
            </td>
          </tr>
        )
      })
    );
  }
}

const TaskListContent = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [taskStatusList, setTaskStatusList] = React.useState(taskStatus.slice(0, 2));
  const [dataCreateTaskList, setDataCreateTaskList] = React.useState([]);
  const [fileUploadTaskList, setFileUploadTaskList] = React.useState([]);
  const [accountingUploadTaskList, setAccountingUploadTaskList] = React.useState([]);
  const authInfo = props.authority.Upload_AuthInfo;

  /**
   * データ作成タスク管理情報を取得
   * @param {Array} task タスク状態
   */
  const _getDataTask = async (task) => {
    try {
      let dataCreateTask = await getDynamoDB(
        table.DataCreateTask,
        null,
        props.corporationNumber,
        {
          CorporationNumber: props.corporationNumber,
        },
        {
          DeleteFlag: 0,
        }
      );
      dataCreateTask = dataCreateTask.filter(d => task.includes(d.TaskStatus));
      let fileUploadTask = await getFileUploadTask(
        'CorporationNumber-index',
        props.corporationNumber,
        {
          CorporationNumber: props.corporationNumber,
        }
      );
      let accountingUploadTask = await getAccountingUploadTask(props.corporationNumber);
      setDataCreateTaskList(dataCreateTask);
      setFileUploadTaskList(fileUploadTask);
      setAccountingUploadTaskList(accountingUploadTask);
    } catch (error) {
      errorLog("E000001", props.userId, viewId.taskList, "getDataTask", error);
    }
  }

  // 初期表示の処理
  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    window.scrollTo(0, 0);
    init();
  }, []);

  const init = async () => {
    await _getDataTask(taskStatusList);
    setIsLoaded(true);
  }

  /**
   * タスク状態の値を変更する
   * @param {Object} e イベント
   */
  const changeTaskStatusList = async (e) => {
    try {
      let list = taskStatusList;
      if (list.includes(e.target.value)) {
        list = list.filter(node => node !== e.target.value);
      } else {
        list.push(e.target.value);
      }
      setTaskStatusList(list);
      await _getDataTask(list);
    } catch (error) {
      errorLog("E000001", props.userId, viewId.taskList, "changeTaskStatusList", error);
    }
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <div id="wrapper">
        {/* ========== コンテンツ ========== */}
        <main className="page-container">
          <section className="page-content">
            <div className="centered-container">
              {/* コンテンツヘッダ */}
              <header className="page-header flex justify-center py-1">
                <h1 className="page-header__name text-h1 grey--text py-8">{titleName.taskList}</h1>
              </header>
              {/* コンテンツボディ */}
              <div className="page-body">
                <div className="centered-container-1456">
                  {/*  ===== リード ===== */}
                  <p className="text-body text-center mb-10">
                    データファイルのアップロード、及びレポートデータの更新を行うメニューです。<br />
                    データのアップロード権限が付与されたユーザーには、あらかじめ設定した登録期限に応じてアップロードを促すメールが自動配信されます。
                  </p>
                  {/*  ===== /リード ===== */}
                  <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-16 px-16 border-blue1">
                    <div className="content-area">
                      <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">データ更新タスク一覧</h2>
                      <div className="flex my-5">
                        <ul className="text-body-2-medium grow">
                          <li>各期間の「詳細」ボタンをクリックすると、データファイルのアップロード及びレポートデータの更新が行えます。</li>
                        </ul>
                      </div>
                      <div className="box-xy-bordered rounded-t-2xl rounded-b-2xl mb-5">
                        <div className="flex mt-3 ml-4">
                          <div>タスク状態で絞り込み：</div>
                          <div className="flex mt-1">
                            {taskStatus.map((status, idx) => (
                              <label key={idx} className="ui-checkbox mb-3 ml-5 mr-5">
                                <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                  value={status} checked={taskStatusList.includes(status)}
                                  onChange={(e) => changeTaskStatusList(e)} />
                                <span className="ui-checkbox__label text-h6 font-Regular">{taskStatusCode[status].name}</span>
                              </label>
                            ))}
                          </div>
                        </div></div>
                      {periodKind.map((p, idx) => {
                        return (
                          <div key={idx} className="content-area">
                            <h3 className="text-h5 mb-2 font-bold">{idx + 1}. {p.name}データ作成</h3>
                            {/* 表組み */}
                            <table className="base-table report-list-table w-full">
                              <thead className="text-h7">
                                <tr>
                                  <th rowSpan="2">期間</th>
                                  <th rowSpan="2">タスク状態</th>
                                  <th colSpan="2">データファイルアップロード</th>
                                  <th colSpan="3">レポートデータ更新</th>
                                  <th rowSpan="2">メッセージ</th>
                                  <th rowSpan="2">操作</th>
                                </tr>
                                <tr>
                                  <th>進捗状況</th>
                                  <th>期限</th>
                                  <th>更新状況</th>
                                  <th>更新日時</th>
                                  <th>確定状況</th>
                                </tr>
                              </thead>
                              <tbody className="text-caption-2">
                                {
                                  (() => {
                                    let dataList = dataCreateTaskList.filter((d) => d.PeriodKind === p.value);
                                    return TableData(dataList, fileUploadTaskList, accountingUploadTaskList);
                                  })()
                                }
                              </tbody>
                            </table>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  {/* ===== /一覧 ===== */}
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* ========== /コンテンツ ========== */}
      </div>
    </ React.Fragment>
  );
}

const TaskList = () => {
  const state = useSelector((state) => state);
  if (state.authority.Upload_AuthInfo.Availability && state.authority.Upload_AuthInfo.Auth_Conf.View) {
    return <TaskListContent
      userId={state.user.sub}
      pageTitle={titleName.taskList + titleName.common}
      corporationNumber={state.company.corporationNumber}
      authority={state.authority}
    />;
  } else {
    return <Forbidden />
  }
};

export default TaskList;
