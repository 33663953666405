// アニメーション用
const animateCSS = (element, animation, prefix = 'animate__') =>
// We create a Promise and return it
new Promise((resolve, reject) => {
  const animationName = `${prefix}${animation}`;
  const node = document.querySelector(element);

  node.classList.add(`${prefix}animated`, animationName);

  // When the animation ends, we clean the classes and resolve the Promise
  function handleAnimationEnd(event) {
    event.stopPropagation();
    node.classList.remove(`${prefix}animated`, animationName);
    resolve('Animation ended');
  }

  node.addEventListener('animationend', handleAnimationEnd, {once: true});
});

// 共通関数の定義
const customFunc = window.customFunc || {};
// モーダルを閉じる
customFunc.hideModal = () => {
  animateCSS('.bg-modal-window', 'fadeOut');
  animateCSS('.modal-window.is-active', 'fadeOut')
    .then(() => {
      document.querySelector('.bg-modal-window').classList.remove('is-active');
      document.querySelector('.modal-window.is-active').classList.remove('is-active');
    });
};
customFunc.showModal = (modalSelector) => {
  document.querySelector('.bg-modal-window').classList.add('is-active');
  document.querySelector(modalSelector).classList.add('is-active');
  animateCSS(modalSelector, 'fadeIn');
  animateCSS('.modal-window', 'fadeIn');
};

// ページロード
window.addEventListener('load', function() {
  // パンくずナビがあるかどうか確認
  // const $header = document.querySelector('.header');
  // const hasBreadNav = !!document.querySelector('.nav-directory');
  // if (!hasBreadNav) {
  //   // パンくずナビがなければヘッダにシャドウを表示
  //   $header.classList.add('has-box-shadow');
  // }

  // サイドメニューの開閉
  const $btnMenu = document.querySelector('.header__btn-menu');
  const $nav = document.querySelector('.nav');
  const $navBg = document.querySelector('.nav__bg');
  const $dropdownContentWrappers = $nav.querySelectorAll('.nav__dropdown-content-wrapper');
  const hideMenu = () => {
    animateCSS('.nav__bg', 'fadeOut');
    animateCSS('.nav__container', 'slideOutLeft')
    .then(() => {
      $btnMenu.classList.remove('is-active');
      $nav.classList.remove('is-active');
    });
  };
  $btnMenu.addEventListener('click', function() {
    if (this.classList.contains('is-active')) {
      hideMenu();
    } else {
      $btnMenu.classList.add('is-active');
      $nav.classList.add('is-active');
      $dropdownContentWrappers.forEach((element) => {
        element.style.setProperty('--height', `${element.scrollHeight}px`);
      });
      animateCSS('.nav__bg', 'fadeIn');
      animateCSS('.nav__container', 'slideInLeft');
    }
  });
  $navBg.addEventListener('click', function() {
    hideMenu();
  });

  // サブヘッダーに関する処理
  const $html = document.querySelector('html');
  if ($html.classList.contains('multiple')) {
    // サブヘッダーの開閉
    const $subheader = document.querySelector('.subheader');
    let previousPosition = 0;
    $subheader.classList.add('is-active');
    // スクロールしてもサブヘッダーが消えないようにする
    // window.addEventListener('scroll', function() {
    //   const currentPosition = document.documentElement.scrollTop;
    //   if (previousPosition < currentPosition) {
    //     if ($subheader.classList.contains('is-active') && currentPosition >= 72) {
    //       animateCSS('.subheader', 'slideOutUp').then(() => {
    //         $subheader.classList.remove('is-active');
    //       });
    //     }
    //   } else {
    //     if (!$subheader.classList.contains('is-active')) {
    //       $subheader.classList.add('is-active');
    //       animateCSS('.subheader', 'slideInDown');
    //     }
    //   }
    //   previousPosition = currentPosition;
    // });

    // 分析対象設定モーダルの開閉
    const $subheaderBtn = $subheader.querySelector('.subheader__button');
    const $btnModalSettingApply = document.querySelector('.btn-modal-analysis-setting-apply');
    $subheaderBtn.addEventListener('click', function() {
      customFunc.showModal('#modal-window--analysis-setting');
    });
    $btnModalSettingApply.addEventListener('click', function(e) {
      e.preventDefault();
      customFunc.hideModal();
      showToast('絞り込み条件を変更しました。画面右の保存アイコンで現在の設定を保存できます。');
    });
  }

  // サイドメニューに関する処理
  document.querySelectorAll('.nav__dropdown').forEach(($dropdownContainer) => {
    // ドロップダウンリストの開閉
    const $dropdownBtn = $dropdownContainer.querySelector('.nav__dropdown-button');
    $dropdownBtn.addEventListener('click', function() {
      if ($dropdownContainer.classList.contains('is-open')) {
        $dropdownContainer.classList.remove('is-open');
      } else {
        $dropdownContainer.classList.add('is-open');
      }
    });
  });

  // ヘッダのユーザーメニューの開閉
  const $headerUserBtn = document.querySelector('.header__user-btn');
  const hideUserMenu = () => {
    animateCSS('.header__user-menu', 'fadeOut')
    .then(() => {
      $headerUserBtn.classList.remove('is-active');
    });
  };
  $headerUserBtn.addEventListener('click', function() {
    if (this.classList.contains('is-active')) {
      hideUserMenu();
    } else {
      $headerUserBtn.classList.add('is-active');
      animateCSS('.header__user-menu', 'fadeIn');
    }
  });

  // パスワード変更ボタンクリック時の挙動
  const $btnChangePassword = document.querySelector('.btn-change-password');
  const $boxToast = document.querySelector('.box-toast');
  const $boxToastText = document.querySelector('.box-toast__text');
  const $boxToastBtn = document.querySelector('.box-toast__btn');
  const boxToastTimeoutSec = 4000;
  let boxToastTimeoutId = '';
  // .btn-change-passwordがあればイベントを設定
  if (!!$btnChangePassword) {
    $btnChangePassword.addEventListener('click', () => {
      showToast('パスワードが変更されました');
    });
  }

  // トースト内の×ボタンクリック時の挙動
  $boxToastBtn.addEventListener('click', () => {
    $boxToast.classList.remove('is-active');
    clearTimeout(boxToastTimeoutId);
  });

  // （VVV 本番には反映不要 VVV）アップロードページでのトースト表示確認用
  const $boxFileUpload = document.querySelector('.box-file-upload');
  if(!!$boxFileUpload) {
    document.querySelector('.box-file-upload').addEventListener('click', () => {
      // showToast('アップロードが完了しました');
      customFunc.showModal('#modal-window--upload-confirm');
    });
    document.querySelector('.btn-modal-upload').addEventListener('click', function() {
      customFunc.hideModal();
    });
  }
  // （AAA 本番には反映不要 AAA）アップロードページでのトースト表示確認用


  // トースト表示関数
  const showToast = (text) => {
    $boxToastText.textContent = text;
    $boxToast.classList.add('is-active');
    boxToastTimeoutId = setTimeout(() => {
      $boxToast.classList.remove('is-active');
    }, boxToastTimeoutSec);
  }

  // スクロールしたときの挙動
  window.addEventListener('scroll', () => {
    // パンくずナビがある場合、ある程度スクロールしたらヘッダにシャドウを表示/非表示
    // if (hasBreadNav) {
    //   if (window.scrollY >= 54) {
    //     $header.classList.add('has-box-shadow');
    //   } else {
    //     $header.classList.remove('has-box-shadow');
    //   }
    // }
  });

  // ブラウザ上でどこかクリックしたときの挙動
  document.addEventListener('click', (e) => {
    // ヘッダのユーザーメニュー以外をクリックしたらヘッダのユーザーメニューを閉じる
    if(!e.target.closest('.header__user-btn') && $headerUserBtn.classList.contains('is-active')) {
      hideUserMenu();
    }
  });

  // モーダルに関する共通処理
  // モーダル内の閉じるボタンクリック時
  document.querySelectorAll('.modal-window__btn-close')?.forEach(function (button) {
    button.addEventListener('click', function () {
      customFunc.hideModal();
    });
  });
  // モーダル背景をクリック時
  document.querySelector('.bg-modal-window')?.addEventListener('click', function() {
    customFunc.hideModal();
  });

  // マイダッシュボードのすべて表示切り替え
  // 初期表示の height を const initialHeight に設定してください
  // 初期ロード時は li には display:none が設定されています。ul のheight が initialHeight に達するまで liに順にclass show を付加します
  // すべて表示を クリックすると ul に class show-all を付加します。 show-all の子要素の li には display : list-item が設定されています
  // 1行の高さは 42.39px
  const initialHeight = 128;
  const $myDashboardToggleText = document.querySelector('.my-dashboard-toggle-text');
  const $myDashboardToggle = document.querySelector('.my-dashboard-toggle');
  const $myDashboard = document.querySelector('.my-dashboard');
  const $myDashboardList = $myDashboard?.children;
  const saveText = $myDashboardToggleText?.textContent;
  const resetMyDashboard = () => {
    animateCSS('.nav-graph', 'fadeIn')
    .then(() => {
      $myDashboard.classList.remove('show-all');
      $myDashboardToggleText.textContent = saveText;
    });
  };
  // 初回 overflow処理
  var lastitem;
  for (var i = 0; i < $myDashboardList?.length; i++) {
      var $myDashboardListItem = $myDashboardList[i];
      if ($myDashboard.clientHeight -42 >= initialHeight) {
        break;
      } else {
          $myDashboardListItem.classList.add('show');
          lastitem = $myDashboardListItem;
      }
  }
  // 不要な1件がレンダリングされてしまうので show を除去する
  lastitem?.classList.remove('show');

  // すべて表示切り替え
  $myDashboardToggle?.addEventListener('click', function() {
    if ($myDashboard.classList.contains('show-all')) {
      resetMyDashboard();
    } else {
      $myDashboard.classList.add('show-all');
      $myDashboardToggleText.textContent = ('一部を表示');
      animateCSS('.nav-graph', 'fadeOut');
    }
  });

  // ダッシュボード・マイダッシュボード表示 用途タグ/バッジ表示部の最大幅切り替え
  // ブックマーク指定がない場合は class  .nobm をつける
  const $dashboardFilterBoxInnerTags = document.querySelector('.tags');
  const $dashboardFilterBoxInnerListBookmark = document.querySelector('.bookmark');
  if ($dashboardFilterBoxInnerListBookmark == null){
    $dashboardFilterBoxInnerTags?.classList.add('nobm');
    var nobm = true;
  }
  //  .tags.usage の li のoverflow 調整
  const $tagsUsage = document.querySelector('.usage');
  const $tagsUsageList = $tagsUsage?.children;
  var usageLeft = $tagsUsage?.getBoundingClientRect().left;
  var usageWidth = $tagsUsage ? $tagsUsage?.clientWidth:0;
  const $tagsBadges = document.querySelector('.badges');
  var badgesLeft = $tagsBadges?.getBoundingClientRect().left;
  var badgesWidth = $tagsBadges ? $tagsBadges?.clientWidth:0; // バッジ幅
  // 用途タグ指定があってバッジ指定がない場合は class .nobadge をつける
  if ($tagsBadges == null){
    $tagsUsage?.classList.add('nobadge');
    var nobadge = true;
  }
  const $tagsBadgesList = $tagsBadges?.children;
  var badgesLeft = $tagsBadges?.getBoundingClientRect().left;
  var badgesWidth = $tagsBadges ? $tagsBadges?.clientWidth:0; // バッジ幅
//  var usageOnly = ((nobm) && (nobadge)) ? true:false;
  // console.log("tags",$dashboardFilterBoxInnerTags?.clientWidth, "usage", usageWidth,  $tagsUsageList?.length, "badges", badgesWidth, $tagsBadgesList?.length);
  if ($tagsUsageList) {
    // console.log("tags",$dashboardFilterBoxInnerTags?.clientWidth, "usage","l", usageLeft,"w", usageWidth,"t",usageLeft + usageWidth , "items", $tagsUsageList?.length);
    listOverFlow($tagsUsageList, usageLeft + usageWidth, ((nobm) && (nobadge)) );
  }
  if ($tagsBadgesList) {
    // ToDo : バッジの text-overflow 処理
    // console.log("tags",$dashboardFilterBoxInnerTags?.clientWidth, "badges","l", badgesLeft,"w",  badgesWidth,"t", badgesLeft + badgesWidth, "items", $tagsBadgesList?.length);
    listOverFlow($tagsBadgesList, badgesLeft + badgesWidth + 16, false);
  }

  function listOverFlow(list, target, usageOnly) {
    var isHidden = false;
    var pad = 0;
    if (usageOnly) {
      pad = 64; // usageonly の調整
    }
    for (var i = 0; i < list.length; i++) {
      var $listItem = list[i];
      // li の位置を取得
      var listItemRight = $listItem.getBoundingClientRect().right;
      // console.log(i," ", listItemRight, $listItem.textContent, "w", $listItem.clientWidth, isHidden );
      if (isHidden) {  // .over 以降は非表示
        lastitem.classList.add('hidden');
        if ( i == list.length-1 ) {
          $listItem.classList.add('hidden');
        }
      }
      else {
        /* li の right の位置が target を超えていないか検査し 超えていれば 対象に .over classを付加
          (ループの現在のひとつ前を対象にしないとうまくいかない)
          次のアイテム以降は .hidden を付加して非表示にする */
        if ((listItemRight) >= (target + pad)  ) { // px-4 = padding 16px x2
          lastitem.classList.add('over');
          if (usageOnly) {
            lastitem.classList.add('usage-only');
          }
          lastitem.classList.remove('add-comma');
          $listItem.classList.add('hidden');
          isHidden = true;
        }
      }
      lastitem = $listItem;
    }
    return;
  }

  // usege, badgesにマウスオーバー時、表示するフキダシの横位置調整
  this.setTimeout(() => {
    const $usageSelectedItems = document.querySelector('.usage-selected-items');
    if (!!$usageSelectedItems) {
      $usageSelectedItems.style.left = $tagsUsage?.clientWidth / 2 + "px";
    }
    const $badgesSelectedItems = document.querySelector('.badges-selected-items');
    if (!!$badgesSelectedItems) {
      $badgesSelectedItems.style.right = $tagsBadges?.clientWidth / 2 + "px";
    }
  } , 100);

  // .btn-change-passwordがあればイベントを設定
  const $btnSaveMyPageCondition = this.document.querySelector('.btn-save-filter-conditions');
  if (!!$btnSaveMyPageCondition) {
    $btnSaveMyPageCondition.addEventListener('click', () => {
      showToast('保存しました。次回アクセス時にこの条件で表示します');
    });
  }

  // .btn-register-kpi-targetがあればイベントを設定
  const $btnRegisterKpiTarget = this.document.querySelector('.btn-register-kpi-target');
  if (!!$btnRegisterKpiTarget) {
    $btnRegisterKpiTarget.addEventListener('click', () => {
      showToast('目標設定を保存しました');
    });
  }

  // .btn-register-kpi-targetがあればイベントを設定
  const $btnRegisterKgiTreeSetting = this.document.querySelector('.btn-register-kgi-tree-setting');
  if (!!$btnRegisterKgiTreeSetting) {
    $btnRegisterKgiTreeSetting.addEventListener('click', () => {
      showToast('経営計画を登録しました');
    });
  }

  // .btn-register-kpi-targetがあればイベントを設定
  const $subheaderBtnSave = this.document.querySelector('.subheader__btn-save');
  if (!!$subheaderBtnSave) {
    $subheaderBtnSave.addEventListener('click', () => {
      showToast('設定中の絞り込み条件を保存しました。次回アクセス時はこの条件で表示します。');
    });
  }

  // .btn-register-kpi-targetがあればイベントを設定
  const $btnRegisterBenchmarkSetting = this.document.querySelector('.btn-register-benchmark-setting');
  if (!!$btnRegisterBenchmarkSetting) {
    $btnRegisterBenchmarkSetting.addEventListener('click', () => {
      showToast('ベンチマーク設定を登録しました');
    });
  }

    // ===== 「ベンチマーク設定の削除確認」モーダル =====
  // 「ベンチマーク設定」ページで「削除」ボタンクリック時
  const $btnDeleteBenchmarkSetting = this.document.querySelector('.btn-delete-benchmark-setting');
  const $btnModalKgiTreeSettingCancel = this.document.querySelector('.btn-modal-benchmark-setting-cancel');
  const $btnModalKgiTreeSettingDelete = this.document.querySelector('.btn-modal-benchmark-setting-delete');
  if (!!$btnDeleteBenchmarkSetting) {
    $btnDeleteBenchmarkSetting.addEventListener('click', function() {
      customFunc.showModal('#modal-window--benchmark-setting-delete-confirm');
    });
  }

  // 「ベンチマーク設定の削除確認」モーダルで「キャンセル」ボタンクリック時
  if (!!$btnModalKgiTreeSettingCancel) {
    $btnModalKgiTreeSettingCancel.addEventListener('click', function() {
      customFunc.hideModal();
    });
  }

  // 「ベンチマーク設定の削除確認」モーダルで「削除」ボタンクリック時
  if (!!$btnModalKgiTreeSettingDelete) {
    $btnModalKgiTreeSettingDelete.addEventListener('click', function() {
      customFunc.hideModal();
    });
  }

});

