"use strict";
import * as React from "react";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { Link as RouterLink, useParams, useLocation } from "react-router-dom";
import { Link, Checkbox, IconButton } from "@mui/material"
import { Delete } from "@mui/icons-material";
import CheckBoxRountedIcon from "@mui/icons-material/CheckBoxRounded";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'

import { SpinnerButton, RefleshButton } from "../common/CustomButton"
import { getMessage } from "../../common/utils/MessageUtils";
import { formatDate, formatDateHHmmdd, getSystemDatetime } from "../../common/utils/DateUtils";
import { putFile, uploadFile as upload, dataCreate, dataFix, dataFixCancel, download } from "../../common/utils/FileUtils";
import { getDynamoDB, updateDynamoDB, getFileUploadTask, getAccountingUploadTask } from "../../common/utils/DynamoDbUtils";
import { UnitChangeForByte } from "../../common/utils/StringUtils";
import { formatCommaDigit } from "../../common/utils/NumberUtils";
import { viewId, titleName, table, analysisMode, periodKind as periodKindCode, taskStatus, operationKind, dataCreateStatus, dataFixStatus, fileUploadTaskStatus, accountingUploadTaskStatus } from "../../_constants/Code";
import { errorLog } from "../../common/logger/Logger";
import { PathInfoList } from "../../_constants/PathInfoList";
import { useModal } from "../../_components/Modal";
import { useToast } from "../../_components/Toast";
import Forbidden from "../../_components/Forbidden";

const periodKind = [];
for (var k in periodKindCode) {
  periodKind.push(periodKindCode[k]);
}

// データ確定解除モーダル
const DataFixCancelModal = (props) => {
  const [inputError, setInputError] = React.useState({});  // 入力エラーメッセージ

  const _closeModal = () => {
    const modal = document.querySelector("#modal-window--data-fix-cancel");
    if (!modal.classList.contains("animate__fadeOut")) {
      modal.classList.remove("animate__fadeIn");
      modal.classList.add("animate__fadeOut");
    }
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      document
        .querySelector(".bg-modal-window.is-active")
        .classList.remove("is-active");
      document
        .querySelector(".modal-window.is-active")
        .classList.remove("is-active");
    }, fadeOutTimeoutSec);
  }

  const _checkInput = () => {
    let inputError = {};
    try {
      // 必須チェック
      if (!props.permitUser) {
        inputError.permitUser = getMessage("E010001", "許諾者名");
      }
      if (['', null].includes(props.permitDate)) {
        inputError.permitDate = getMessage("E010001", "許諾日");
      }
      // エラー判定
      if (Object.keys(inputError).length === 0) {
        return false;
      } else {
        inputError.errorMessage = '入力内容に誤りがあります。'
        return true;
      }
    } finally {
      setInputError(inputError);
    }
  }

  const _fixCancel = async () => {
    let error = {
      analysisData: []
    };
    try {
      if (_checkInput()) {
        return;
      }
      // 確定解除処理
      let key = {
        CorporationNumber: props.corporationNumber,
        Key: props.dataCreateTaskKey,
      };
      let message = await dataFixCancel(
        props.corporationNumber,
        props.userSub,
        props.userName,
        props.permitUser,
        props.permitDate.replaceAll('-', '/'),
        key);
      if (message === null) {
        props.showToast('レポートデータの確定を解除しました。');
        await props.init();
        _closeModal();
      } else {
        // エラー
        error.analysisData.push(message);
      }
      props.setError(error);
    } catch (error) {
      error.analysisData.push('分析データ確定処理でエラーが発生しました。');
      error.analysisData.push('暫くしてから再度お試しください。それでも解決しない場合は管理者までお問い合わせください。');
      setError(error);
      errorLog("E000001", props.userSub, viewId.analysisData, "_fixCancel", error);
    }
  }

  return (
    <React.Fragment>
      <div
        className="modal-window modal-window__wide fixed bg sub z-50 rounded-2xl animate__fadeIn"
        id="modal-window--data-fix-cancel"
      >
        <div className="modal-window__header">
          <div className="modal-window__title flex items-center justify-center pt-10">
            <span className="modal-window__title-text text-h3 font-medium">
              データ確定解除の確認
            </span>
          </div>
          <div className="text-body text-caption-2 text-center grey--text text--lighten-70 pt-4">
            <div className="flex my-5">
              <ul className="text-body-2-medium grow">
                <li>データの確定を解除します。よろしいですか？</li>
                <li>※許諾者情報を入力してください。</li>
              </ul>
            </div>
          </div>
          <button
            type="button"
            className="modal-window__btn-close absolute top-5 right-5"
            onClick={() => _closeModal()}
          >
            <span className="icon-close"></span>
          </button>
        </div>
        <div className="modal-window__content px-8 pb-10 !overflow-y-hidden">
          <div className="modal-window__upload-result box-xy-bordered rounded-xl px-10 pt-4 pb-14 mx-auto overflow-y-auto">
            <div>
              <span className="red--text">{inputError.errorMessage}</span>
            </div>
            <div className="text-center pb-2">
              <h3 className="text-h5 mt-5 mb-2 font-bold">【許諾者情報】</h3>
            </div>
            <table className="form-table form-control w-full">
              <tbody className="text-caption-2">
                <tr>
                  <th>
                    <label htmlFor="permitUser" className="block text-caption-1">許諾者名<span className="red--text">*</span></label>
                  </th>
                  <td>
                    <input id="permitUser" placeholder="許諾者名を入力" type="text" className="block rounded-lg px-3 py-1 w-full"
                      value={props.permitUser} onChange={(e) => props.changePermitUser(e)} />
                    <span className="red--text">{inputError.permitUser}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor="permitDate" className="block text-caption-1">許諾日<span className="red--text">*</span></label>
                  </th>
                  <td>
                    <input id="permitDate" type="date" className="block rounded-lg px-3 py-1"
                      value={props.permitDate} onChange={(e) => props.changePermitDate(e)} />
                    <span className="red--text">{inputError.permitDate}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-content-footer mt-8">
            <div className="footer_button_area">
              <button className="btn footer-btn button_back" onClick={() => _closeModal()}>キャンセル </button>
              <SpinnerButton name="実行" className="btn footer-btn button_base" onClick={() => _fixCancel()} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const AnalysisDataContent = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [dataCreateTask, setDataCreateTask] = React.useState([]);
  const [fileUploadTaskList, setFileUploadTaskList] = React.useState([]);
  const [dmaneFileUploadTaskList, setDmaneFileUploadTaskList] = React.useState([]);
  const [inputFileList, setInputFileList] = React.useState([]);  // アップロードファイル
  const [accountingUploadTaskList, setAccountingUploadTaskList] = React.useState([]);
  const [error, setError] = React.useState({});  // エラーメッセージ
  const dataCreateTaskKey = isNaN(props.dataCreateTaskKey) ? null : Number(props.dataCreateTaskKey);  // urlから取得したデータ更新タスクキー
  const [permitUser, setPermitUser] = React.useState('');  // 許諾者名
  const [permitDate, setPermitDate] = React.useState(getSystemDatetime().toISOString().slice(0, 10));  // 許諾日
  const [lastFetchDatetime, setLastFetchDatetime] = React.useState();
  const [lastFetchDatetimeDmane, setLastFetchDatetimeDmane] = React.useState();
  const [lastFetchDatetimeAccounting, setLastFetchDatetimeAccounting] = React.useState();
  const [systemUsage, setSystemUsage] = React.useState("Chrofy");

  const authConf = props.authConf;

  const fileInputRefs = React.useRef([]);  // ファイル参照用ref

  // 初期表示の処理
  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    window.scrollTo(0, 0);
    init();
    if (props.message) {
      props.showToast(props.message);
    }
  }, []);

  const init = async () => {
    await _setDataCreateTask();
    await _setFileUploadTask();
    await _setAccountingUploadTask();
    await _setChrofyUsage();
    setIsLoaded(true);
  }
  const handleDmane = async (e) => {
    e.preventDefault();
    window.open(process.env.REACT_APP_URL_DMANE, '_blank');
  };

  const _getFileUploadTask = async () => {
    let fileList = dataCreateTaskKey ? await getFileUploadTask(
      null,
      props.corporationNumber,
      {
        SuperKey: dataCreateTaskKey,
      },
      {
        CorporationNumber: props.corporationNumber,
      }
    ) : [];
    return fileList;
  }

  const _setChrofyUsage = async () => {
    const companyInfo = await getDynamoDB(
      table.Company,
      null,
      props.corporationNumber,
      {
        CorporationNumber: props.corporationNumber,
      },
    );
    setSystemUsage(companyInfo[0].SystemUsage)
  }
  
  // ファイルアップロードタスクの更新（Dマネ）
  const _setDmaneFileUploadTask = async () => {
    let fileList = await _getFileUploadTask();
    let datamangeyFileList = fileList.filter(e => e.UseSystem === "Datamanegy")
    setDmaneFileUploadTaskList(datamangeyFileList);
    setLastFetchDatetimeDmane(formatDateHHmmdd(getSystemDatetime()));
  }

   // ファイルアップロードタスクの更新（CHROFY）
   const _setChrofyFileUploadTask = async () => {
    let fileList = await _getFileUploadTask();
    let chrofyFileList = fileList.filter(e => e.UseSystem === "Chrofy" || e.UseSystem === "" || e.UseSystem === undefined )
    // inputFileListの要素を初期化
    let initInputFileList = chrofyFileList.map((f) => {
      return {
        fileData: null,
        noFileFlg: f.NoDataFlag,
      };
    });
    // refの生成
    chrofyFileList.forEach((_, idx) => {
      fileInputRefs.current[idx] = React.createRef();
    });

    setFileUploadTaskList(chrofyFileList);
    setInputFileList(
      initInputFileList
    );
    setLastFetchDatetime(formatDateHHmmdd(getSystemDatetime()));
  } 

  // ファイルアップロードタスクの更新（Chrofy、Dマネ一括更新）
  const _setFileUploadTask = async () => {
    let fileList = await _getFileUploadTask();
    let chrofyFileList = fileList.filter(e => e.UseSystem === "Chrofy" || e.UseSystem === "" || e.UseSystem === undefined )
    let datamangeyFileList = fileList.filter(e => e.UseSystem === "Datamanegy")
    // inputFileListの要素を初期化
    let initInputFileList = chrofyFileList.map((f) => {
      return {
        fileData: null,
        noFileFlg: f.NoDataFlag,
      };
    });
    // refの生成
    chrofyFileList.forEach((_, idx) => {
      fileInputRefs.current[idx] = React.createRef();
    });

    setFileUploadTaskList(chrofyFileList);
    setDmaneFileUploadTaskList(datamangeyFileList);
    setInputFileList(
      initInputFileList
    );
    setLastFetchDatetime(formatDateHHmmdd(getSystemDatetime()));
    setLastFetchDatetimeDmane(formatDateHHmmdd(getSystemDatetime()));
  }

  const _setAccountingUploadTask = async () => {
    let fileList = dataCreateTaskKey ? await getAccountingUploadTask(
      props.corporationNumber, dataCreateTaskKey,
    ) : [];
    if (!props.isParent) {
      fileList = fileList.filter(e => e.Mode === analysisMode.standalone);
    }
    setAccountingUploadTaskList(fileList);
    setLastFetchDatetimeAccounting(formatDateHHmmdd(getSystemDatetime()));
  }

  const _getDataCreateTask = async () => {
    let task = dataCreateTaskKey ? await getDynamoDB(
      table.DataCreateTask,
      null,
      props.corporationNumber,
      {
        CorporationNumber: props.corporationNumber,
        Key: dataCreateTaskKey,
      },
      {
        DeleteFlag: 0,
      }
    ) : [];
    return task;
  }

  const _setDataCreateTask = async () => {
    let task = await _getDataCreateTask();
    setDataCreateTask(task[0]);
  }

  const _deleteFile = (idx) => {
    setInputFileList(
      inputFileList.map((f, i) => {
        if (i === idx) {
          f.fileData = null;
        }
        return f;
      })
    );
  }

  const _changeNoFile = (idx) => {
    setInputFileList(
      inputFileList.map((f, i) => {
        if (i === idx) {
          f.noFileFlg = !f.noFileFlg;
        }
        return f;
      })
    );
  }

  /**
   * 許諾者名の値を変更する
   * @param {イベント} e 
   */
  const changePermitUser = (e) => {
    setPermitUser(e.target.value);
  }

  /**
   * 許諾日の値を変更する
   * @param {イベント} e 
   */
  const changePermitDate = (e) => {
    setPermitDate(e.target.value);
  }

  /**
   * アップロードエラーモーダル
   * @param {エラー行} errorRow 
   */
  const openUploadErrorModal = (errorRow) => {
    const title = 'エラー情報';
    const description = (
      <div className="flex my-5">
        <ul className="text-body-2-medium grow">
          <li>ファイルチェック処理でエラーが発生しました。</li>
          <li>エラーを修正のうえ、再度ファイルを登録してください。</li>
        </ul>
      </div>);
    const body = (
      <div className="content-area">
        <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-4 -mx-10 rounded-t-2xl">エラー内容一覧</h2>
        <table className="base-table form-control w-full mt-3">
          <tbody className="text-caption-2">
            <tr>
              <th width="20%">ファイル名</th>
              <td width="80%">{errorRow?.FileName}</td>
            </tr>
          </tbody>
        </table>
        <table className="base-table w-full mt-3">
          <thead className="text-caption-2">
            <tr>
              <th width="10%">行番号</th>
              <th width="30%">項目名</th>
              <th width="60%">エラー内容</th>
            </tr>
          </thead>
          <tbody className="text-caption-2">
            {errorRow?.ErrorInfo.sort((a, b) => {
              if (a.RowNum > b.RowNum) return 1;
              if (b.RowNum > a.RowNum) return -1;
              return a.ColumnName.localeCompare(b.ColumnName);
            }).map((r, idx) => {
              return (
                <tr key={idx}>
                  <td className="title text-center">{formatCommaDigit(String(r.RowNum))}</td>
                  <td>{r.ColumnName ? r.ColumnName : '-'}</td>
                  <td>{r.Message}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );
    props.showModal({ title: title, description: description, body: body, cancelName: "閉じる" });
  }

  /**
   * 財務アップロードエラーモーダル
   */
  const openAccountErrorModal = (fileName, errorMsg) => {
    const title = 'エラー情報';
    const description = (
      <div className="flex my-5">
        <ul className="text-body-2-medium grow">
          <li>ファイルチェック処理でエラーが発生しました。</li>
          <li>エラーを修正のうえ、再度ファイルを登録してください。</li>
        </ul>
      </div>);
    const body = (
      <div className="content-area">
        <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-4 -mx-10 rounded-t-2xl">エラー内容一覧</h2>
        <table className="base-table form-control w-full mt-3">
          <tbody className="text-caption-2">
            <tr>
              <th width="20%">ファイル名</th>
              <td width="80%">{fileName}</td>
            </tr>
          </tbody>
        </table>
        <table className="base-table w-full mt-3">
          <thead className="text-caption-2">
            <tr>
              <th>エラー内容</th>
            </tr>
          </thead>
          <tbody className="text-caption-2">
            <tr>
              <td>{errorMsg}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    props.showModal({ title: title, description: description, body: body, cancelName: "閉じる" });
  }

  /**
   * データ登録モーダル
   */
  const openUploadConfirmModal = async () => {
    // 入力チェック
    let errorList = [];
    let newFileCnt = 0;
    inputFileList.forEach((f, i) => {
      let message = "";
      if (f.fileData !== null && f.noFileFlg === false) {
        newFileCnt += 1;
        if (f.fileData.name !== fileUploadTaskList[i].FileName) {
          message = "ファイル名が不正です。";
        }
        if (f.fileData.size > 1073741824) {
          message = (message !== "" ? message + " / " : "") + "ファイルサイズが上限値(1GB)を超えています。";
        }
        // メッセージ設定
        if (message !== "") {
          errorList.push({ "kind": fileUploadTaskList[i].FileKind, "name": f.fileData.name, "size": f.fileData.size, "message": message });
        }
      } else if (fileUploadTaskList[i].Status !== fileUploadTaskStatus['no-data'].value && f.noFileFlg === true) {
        newFileCnt += 1;
      } else if (fileUploadTaskList[i].Status === fileUploadTaskStatus['no-data'].value && f.noFileFlg === false) {
        newFileCnt += 1;
      }
    });
    if (newFileCnt === 0) {
      errorList.push({ "name": "", "size": null, "message": "登録対象のファイルがありません。" });
    }

    // モーダルを開く
    let dataCreationFlg = false;
    const title = 'アップロードの確認';
    let description = null;
    let body = null;
    let button = [];
    if (errorList.length === 0) {  // 非エラーの場合
      const fileList =
        fileUploadTaskList
          .map((f, idx) => {
            if (f.Status === fileUploadTaskStatus.checking.value) return;  // チェック中のファイルは表示しない
            let superKey, key, kind, name, size, message, data, nextStatus, noDataFlag, rowStyle;
            superKey = f.SuperKey;
            key = f.Key;
            kind = f.FileKind;
            name = f.FileName;
            data = inputFileList[idx]?.fileData;
            size = null;
            noDataFlag = false;
            if (inputFileList[idx].noFileFlg === false && data !== null) {
              size = UnitChangeForByte(data?.size);
              dataCreationFlg = true;
              // 上書き
              if (f.Status === fileUploadTaskStatus['check-ok'].value) {
                message = "登録済みファイルを上書きします。";
                rowStyle = "blue1--text";
                nextStatus = fileUploadTaskStatus.checking.value;
                // 新規
              } else {
                message = "ファイルを新規登録します。";
                nextStatus = fileUploadTaskStatus.checking.value;
              };
            } else {
              if (inputFileList[idx].noFileFlg === true) {
                noDataFlag = true;
                // 削除
                if (f.Status === fileUploadTaskStatus['check-ok'].value) {
                  message = "登録済みファイルを削除して、データ無しで登録します。";
                  rowStyle = "red--text";
                  nextStatus = fileUploadTaskStatus['no-data'].value;
                  dataCreationFlg = true;
                  // データ無　→　未登録
                } else if (f.Status === fileUploadTaskStatus.unregistered.value || f.Status === fileUploadTaskStatus['check-ng'].value) {
                  message = "データ無しで登録します。";
                  nextStatus = fileUploadTaskStatus['no-data'].value;
                  if (f.Status === fileUploadTaskStatus['check-ng'].value) {
                    dataCreationFlg = true;
                  }
                } else {
                  return;
                }
                // 未登録　→　データ無
              } else if (inputFileList[idx].noFileFlg === false && f.Status === fileUploadTaskStatus['no-data'].value) {
                message = "データ無しを取り消します。";
                nextStatus = fileUploadTaskStatus.unregistered.value;
              } else {
                return;
              }
            }
            return {
              superKey: superKey,
              key: key,
              kind: kind,
              name: name,
              size: size,
              message: message,
              data: data,
              nextStatus: nextStatus,
              noDataFlag: noDataFlag,
              rowStyle: rowStyle,
            }
          })
          .filter(f => f !== undefined);  // 未変更行は最後に削除（mapの前だとidxがずれるため）
      description =
        <div>
          <div className="text-body grey--text text--lighten-70 pt-2">
            以下のファイルをアップロードします。よろしいですか？<br />
          </div>
          <div className="text-body text-caption-2 grey--text text--lighten-70 pt-4">
            ※受付可能なファイルはCSV形式(カンマ区切りのテキストファイル)、およびXLSX形式(マイクロソフトのエクセルファイル)です。<br />
            ※同じ名前のファイルをアップロードすると、ファイルが上書きされ、古いファイルは削除されます。<br />
            ※ファイル無がチェックされている場合、登録済みのファイルは削除されます。<br />
          </div>
        </div>;
      body =
        <div id="upload-modal-table" className="box-upload-complete">
          <h3 className="text-h6 text-center mb-5 green--text flex items-center justify-center">
            <span className="text-h5">アップロード可</span>
          </h3>
          <table className="data-table w-full grey--text">
            <tbody className="text-caption-2">
              {
                fileList.map((f, idx) => {
                  return (
                    <tr key={idx} className={f?.rowStyle}>
                      <td>{f.kind}</td>
                      <td>{f.name}</td>
                      <td>{f.size}</td>
                      <td className="!text-left">{f.message}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>;
      button = [
        {
          name: '実行',
          className: 'btn footer-btn button_base',
          click: async () => {
            try {
              // ファイルアップロード
              let fileUploadTask = [];
              const user = await Auth.currentCredentials();
              for await (const f of fileList) {
                if (f.data !== null) {
                  await putFile('', f.data);
                }
                fileUploadTask.push(
                  {
                    super_key: f.superKey,
                    key: f.key,
                    next_status: f.nextStatus,
                    no_data_flag: f.noDataFlag,
                  }
                )
              };
              // DB更新など
              const body = {
                body: {
                  request: {
                    id_pool: {
                      identity_id: user.identityId,
                    },
                    user_pool: {
                      user_sub: props.userSub,
                      user_name: props.userName,
                    },
                    corporation_number: props.corporationNumber,
                    data_create_task_key: dataCreateTask.Key,
                    file_upload_task: fileUploadTask,
                    data_creation_flag: dataCreationFlg,
                  },
                },
                headers: {},
              };
              let message = await upload(props.corporationNumber, body);
              let error = {};
              if (message.length === 0) {
                props.showToast('ファイルを登録しました。');
                await init();
              } else {
                // エラー
                error.regist = message;
              }
              setError(error);
            } catch (error) {
              errorLog("E000001", props.userSub, viewId.analysisData, "uploadFile", error);
            }
          }
        }];
    } else {  // エラーの場合
      description =
        <div className="text-body text-caption-2 text-center red--text text--lighten-70 pt-4">
          アップロードファイルが不正です。ファイル内容をご確認のうえ、再度やり直してください。
        </div>;
      body =
        <div id="upload-modal-table" className="box-upload-error">
          <h3 className="text-h6 text-center mb-5 red--text flex items-center justify-center">
            <span className="icon-error mr-0.5 translate-y-px"></span>
            <span className="text-h5">【アップロード不可】</span>
          </h3>
          <table className="data-table w-full red--text">
            <tbody className="text-caption-2">
              {
                errorList.map((error, i) => {
                  return (
                    <tr key={i}>
                      <td>{error.kind}</td>
                      <td>{error.name}</td>
                      <td>{UnitChangeForByte(error.size)}</td>
                      <td className="text-left">{error.message}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>;
    }
    props.showModal({ title: title, description: description, body: body, button: button });
  };

  /**
   * データ更新モーダル
   */
  const openDataCreateModal = async () => {
    const title = 'データ更新の確認';
    const description =
      <p>
        登録済みファイルを基にデータ更新を実行します。よろしいですか？<br /><br />
        ※作成済みデータは上書きされます<br />
        ※ファイルの追加、更新が発生した場合は、再度「データ更新」を実行してください
      </p>;
    const button = [
      {
        name: '実行',
        className: 'btn footer-btn button_base',
        click: async () => {
          let error = {
            analysisData: Array(5)
          };
          try {
            let task = await getDynamoDB(
              table.DataCreateTask,
              null,
              props.corporationNumber,
              {
                CorporationNumber: props.corporationNumber,
              },
              {
                DeleteFlag: 0,
              }
            );
            let fileList = await getFileUploadTask(
              null,
              props.corporationNumber,
              {
                SuperKey: dataCreateTaskKey,
              },
              {
                CorporationNumber: props.corporationNumber,
              }
            );
            // データ更新実行可否チェック
            // (1)
            const tmpTask = task.filter(f => f.Key === dataCreateTask.Key)[0];
            const isWait = [dataCreateStatus.await.value, dataCreateStatus.recreate.value].includes(tmpTask.DataCreateStatus)
            // データファイルのステータスをチェック
            for (let i = 0; i < fileList.length; i++) {
              // (1)
              if (!error.analysisData[0]) {
                if (fileList[i].RequiredFlag) {
                  if (!isWait && fileList[i].Status !== fileUploadTaskStatus['check-ok'].value) {
                    error.analysisData[0] = '必須ファイルが未登録のためデータ更新できません。';
                  }
                }
              }
              // (2)
              if (!error.analysisData[1]) {
                if ([fileUploadTaskStatus.checking.value, fileUploadTaskStatus['check-ng'].value].includes(fileList[i].Status)) {
                  error.analysisData[1] = 'チェック中またはチェックエラーのファイルが存在するためデータ更新できません。';
                }
              }
            };
            // 財務データのステータスをチェック
            if (!error.analysisData[0] && !error.analysisData[1]) {
              // 財務データ
              let accountingList = await getAccountingUploadTask(props.corporationNumber, dataCreateTaskKey);
              for (let i = 0; i < accountingList.length; i++) {
                if ([accountingUploadTaskStatus.processing.value, accountingUploadTaskStatus.error.value].includes(accountingList[i].Status)) {
                  error.analysisData[1] = '処理中またはエラーのファイルが存在するためデータ更新できません。';
                }
              }
            }
            // (3)
            task.forEach((t) => {
              if (t.DataCreateStatus === dataCreateStatus.creating.value) {
                error.analysisData[2] = '他の期間のデータが更新中です。データ更新完了を待ってから再度実行してください。';
              }
            });
            // (4)
            let t = task.filter((t) => {
              return t.PeriodKind === tmpTask.PeriodKind && t.Key < dataCreateTaskKey
            });
            if (!(t.length === 0 || t[t.length - 1].DataCreateStatus === dataCreateStatus.created.value)) {
              error.analysisData[3] = '未作成の過去データがあるためデータ更新できません。';
            }
            // (5)
            if (tmpTask.DataFixStatus !== dataFixStatus.pending.value) {
              error.analysisData[4] = 'データ確定済のためデータ更新できません。';
            }

            error.analysisData = error.analysisData.filter(e => e != undefined);
            if (error.analysisData.length === 0) {
              // DB更新
              const now = formatDate(getSystemDatetime());
              const pk = 'CorporationNumber';
              const key = {
                CorporationNumber: props.corporationNumber,
                Key: dataCreateTaskKey,
              };
              let operationHistory = tmpTask.OperationHistory;
              operationHistory.push(
                {
                  OperationKind: operationKind.create.value,
                  OperationUser: props.userName,
                  OperationDateTime: now,
                }
              );
              try {
                // データ更新バッチ実行
                let tableKey = props.dataCreateTaskKey;
                await dataCreate(props.userSub, props.corporationNumber, tmpTask.PeriodKind, tableKey);
                // ステータス更新
                const item = {
                  TaskStatus: taskStatus.doing.value,
                  DataCreateStatus: dataCreateStatus.creating.value,
                  Message: "",
                  OperationHistory: operationHistory,
                  UpdateUserName: props.userName,
                  UpdateDateTime: now,
                };
                await updateDynamoDB(table.DataCreateTask, pk, props.corporationNumber, key, item, props.userSub);
                props.showToast('レポートの更新を開始しました。');
                // 再描画
                await init();
              } catch (e) {
                error.analysisData.push('レポートデータ更新処理でエラーが発生しました。');
                error.analysisData.push('暫くしてから再度お試しください。それでも解決しない場合は管理者までお問い合わせください。');
              }
            }
          } catch (error) {
            errorLog("E000001", props.userSub, viewId.analysisData, "dataCreate", error);
          } finally {
            setError(error);
          }
        }
      }
    ];
    props.showModal({ title: title, description: description, button: button });
  }

  /**
   * データ確定モーダル
   */
  const openDataFixMordal = async () => {
    // データ確定実行可否チェック
    const title = 'データ確定の確認';
    const description = <React.Fragment><div>データを確定します。よろしいですか？</div></React.Fragment>
    const button = [
      {
        name: '実行',
        className: 'btn footer-btn button_base',
        click: async () => {
          let error = {
            analysisData: []
          };
          try {
            let key = {
              CorporationNumber: props.corporationNumber,
              Key: dataCreateTaskKey,
            };
            let message = await dataFix(props.corporationNumber, props.userSub, props.userName, key);
            if (message === null) {
              props.showToast('レポートデータを確定しました。');
              await init();
            } else {
              // エラー
              error.analysisData.push(message);
            }
            setError(error);
          } catch (error) {
            error.analysisData.push('分析データ確定処理でエラーが発生しました。');
            error.analysisData.push('暫くしてから再度お試しください。それでも解決しない場合は管理者までお問い合わせください。');
            setError(error);
            errorLog("E000001", props.userSub, viewId.analysisData, "dataFix", error);
          }
        }
      }
    ];
    props.showModal({ title: title, description: description, button: button });
  }

  /**
   * データ確定解除モーダルを開く
   */
  const openDataFixCancelMordal = () => {
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.add("is-active");
    const modalUploading = document.querySelector("#modal-window--data-fix-cancel");
    if (!modalUploading.classList.contains("animate__fadeIn")) {
      modalUploading.classList.remove("animate__fadeOut");
      modalUploading.classList.add("animate__fadeIn");
    }
    modalUploading.classList.remove("animate__fadeOut");
    modalUploading.classList.add("animate__fadeIn");
    modalUploading.classList.add("is-active");
  }

  /**
   * ファイル選択ITEM
   */
  const InputItem = React.forwardRef(({ props }, ref) => {
    const inputRef = ref;
    const setInputFile = (idx, e) => {
      if (e.target.value.length) {
        setInputFileList(
          inputFileList.map((f, i) => {
            if (i === idx) {
              f.fileData = e.target.files[0];
            }
            return f;
          })
        );
      };
    }
    return (
      <React.Fragment>
        <button className={!props.isDisabled ? "button_file_select" : "button_file_select_disabled"} onClick={() => inputRef.current.click()} disabled={props.isDisabled}>参照</button>
        <input type="file" ref={inputRef} style={{ display: "none" }} onChange={(e) => setInputFile(props.idx, e)}></input>
      </React.Fragment>
    )
  });

  /**
   * データファイル登録ガイドダウンロード処理
   * @param {イベント} e 
   */
  const fileDownload = (e) => {
    e.preventDefault();
    try {
      download(props.bucketName,
        PathInfoList.Folder.uploadGuideFolder + PathInfoList.Objectkeyname.dataFileUploadGuide);
    } catch (error) {
      errorLog("E000001", props.userId, viewId.footer, "fileDownload", error);
    }
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <div id="wrapper">
        {/* ========== コンテンツ ========== */}
        <main className="page-container">
          <section className="page-content">
            <div className="centered-container">
              {/* 上階層に戻る */}
              <div className="bg main">
                <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                  <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/data/taskList">
                    <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                      <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                      <span className="text-h5 font-bold">{titleName.taskList}に戻る</span>
                    </span>
                  </Link>
                </div>
              </div>
              {/* コンテンツヘッダ */}
              <header className="page-header flex justify-center py-1">
                <h1 className="page-header__name text-h1 grey--text py-8">{titleName.analysisData}</h1>
              </header>
              {/* コンテンツボディ */}
              <div className="page-body">
                <div className="centered-container-1456">
                  {dataCreateTask ? (
                    <React.Fragment>
                      {/*  ===== リード ===== */}
                      <p className="text-body text-center mb-10">
                        データファイルのアップロード、レポートデータの更新を行います。<br />
                        必須ファイルを全てアップロードするとレポートデータの更新ができます。<br />
                        レポートデータの更新を行うと、各ダッシュボードのグラフのデータが更新されます。<br />
                        <Link underline="none" href="#" onClick={(e) => fileDownload(e)}>データアップロードガイド(PDFファイル)</Link>をご覧ください。<br />
                      </p>
                      <div className="border-t border-lightgray blue1 lighten-3"></div>
                      <div className="sub-title-area flex justify-center py-10">
                        <h3 className="text-h4 mt-1 font-bold">更新対象データ：</h3>
                        {/* 表組み */}
                        <table className="base-table ml-5">
                          <tbody className="text-h5 text-center">
                            <tr>
                              <td width="100px" className="blue1 white--text">種類</td>
                              <td width="100px" className="border-blue1">{periodKind.find(p => p.value === dataCreateTask.PeriodKind).name}</td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="base-table ml-5">
                          <tbody className="text-h5 text-center">
                            <tr>
                              <td width="100px" className="blue1 white--text">期間</td>
                              <td width="200px" className="border-blue1">{dataCreateTask.PeriodValue}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/*  ===== /リード ===== */}
                      <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-8 border-blue1">
                        {fileUploadTaskList.length !== 0 || dmaneFileUploadTaskList.length !== 0 ? (
                          <div className="content-area">
                            <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-8 rounded-t-2xl">データファイルのアップロード</h2>
                            {systemUsage === "Chrofy_Datamanegy" && dmaneFileUploadTaskList.length >= 1 &&
                              <div className="box-xy-bordered collapse-list mt-5">
                                <dl className="collapse-node collapse-parent w-full is-open">
                                  <dt className="flex shrink-0 px-3 pt-3">
                                    <label className="mb-3 text-h6" style={{ "width": "280px" }}>人的資本データのアップロード（標準データ）</label>
                                    <button className="dropdown-button" onClick={(e) => {
                                      const node = e.target.closest(".collapse-parent");
                                      if (node.classList.contains("is-open")) {
                                        node.classList.remove("is-open");
                                      } else {
                                        node.classList.add("is-open");
                                      }
                                    }} />
                                  </dt>
                                  <dd className="content">
                                    <div className="text-h6 font-Regular my-3 mx-5">
                                      <div className="flex mt-2">
                                        <ul className="grow text-caption-1">
                                          <li>Datamanegyからデータファイルを登録してください。
                                            <Link underline="none" href="#" onClick={(e) => handleDmane(e)}>Datamanegy >></Link><br />
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="flex mb-1">
                                        <div className="base-table-header div-mid mb-1">
                                          <span className="text-caption-2 pr-2">{lastFetchDatetimeDmane} 時点</span>
                                          <div className="btn-group">
                                            <RefleshButton className="btn header-btn small-btn white--text flex"
                                              name="一覧更新" onClick={() => _setDmaneFileUploadTask()} />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="setting-table relative">
                                        {/* 表組み */}
                                        <table className="base-table dmane-data-task-list-table w-full" border="1">
                                          <thead className="text-h7">
                                            <tr>
                                              <th>ファイル種類</th>
                                              <th>ファイル名</th>
                                              <th>登録日時</th>
                                              <th>レコード数</th>
                                              <th>ステータス</th>
                                            </tr>
                                          </thead>
                                          <tbody className="text-caption-2">
                                            {dmaneFileUploadTaskList.map((f, idx) => {
                                              let uploadable = false;
                                              if (dataCreateTask.PeriodKind === periodKindCode.monthly.value) {
                                                if (authConf.Uploadable_Monthly.Files.includes(f.PermissionKey)) {
                                                  uploadable = true;
                                                }
                                              } else if (dataCreateTask.PeriodKind === periodKindCode.quarter.value) {
                                                if (authConf.Uploadable_Quarter.Files.includes(f.PermissionKey)) {
                                                  uploadable = true;
                                                }
                                              } else if (dataCreateTask.PeriodKind === periodKindCode.year.value) {
                                                if (authConf.Uploadable_Year.Files.includes(f.PermissionKey)) {
                                                  uploadable = true;
                                                }
                                              };
                                              return (
                                                <tr key={idx} className="border_black">
                                                  <td>{f.FileKind}{f.RequiredFlag && <span className="red--text font-bold pl-1">[必須]</span>}</td>
                                                  <td>{f.FileName}</td>
                                                  <td className="text-nowrap">{f.FileUpdateDateTime.slice(0, -3)}</td>
                                                  <td className="right">{formatCommaDigit(String(f.RecordCount))}</td>
                                                  <td className="text-nowrap">
                                                    {f.Status !== fileUploadTaskStatus['check-ng'].value && fileUploadTaskStatus[f.Status].name}
                                                    {f.Status === fileUploadTaskStatus['check-ng'].value && <button className="button_status_error" onClick={() => openUploadErrorModal(f)}>{fileUploadTaskStatus[f.Status].name}</button>}
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                            }
                            {fileUploadTaskList.length >= 1 &&
                              <div className="box-xy-bordered collapse-list mt-5">
                                <dl className="collapse-node collapse-parent w-full is-open">
                                  <dt className="flex shrink-0 px-3 pt-3">
                                    <label className="mb-3 text-h6" style={{ "width": "280px" }}>
                                      {systemUsage === "Chrofy_Datamanegy"  ? "人的資本データのアップロード（その他データ）" : "人的資本データのアップロード"}
                                    </label>
                                    <button className="dropdown-button" onClick={(e) => {
                                      const node = e.target.closest(".collapse-parent");
                                      if (node.classList.contains("is-open")) {
                                        node.classList.remove("is-open");
                                      } else {
                                        node.classList.add("is-open");
                                      }
                                    }} />
                                  </dt>
                                  <dd className="content">
                                    <div className="text-h6 font-Regular my-3 mx-5">
                                      <div className="flex mt-2">
                                        <ul className="grow text-caption-1">
                                          <li>「参照」ボタンをクリックしデータファイルを指定してください。対象期間のデータファイルが無い場合は「ファイル無」をチェックしてください。</li>
                                          <li>ファイルの指定が完了したら「アップロード」ボタンをクリックしてください。</li>
                                          <li>ただし、ファイル種類の末尾に[必須]と記載されたものは「ファイル無」とすることはできません。</li>
                                        </ul>
                                      </div>
                                      <div className="flex mb-1">
                                        <div className="base-table-header div-mid mb-1">
                                          <span className="text-caption-2 pr-2">{lastFetchDatetime} 時点</span>
                                          <div className="btn-group">
                                            <RefleshButton className="btn header-btn small-btn white--text flex"
                                              name="一覧更新" onClick={() => _setChrofyFileUploadTask()} />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="setting-table relative">
                                        {/* 表組み */}
                                        <table className="base-table data-task-list-table w-full" border="1">
                                          <thead className="text-h7">
                                            <tr>
                                              <th>ファイル種類</th>
                                              <th>ファイル名</th>
                                              <th>ファイル選択</th>
                                              <th>ファイル無</th>
                                              <th>登録者</th>
                                              <th>登録日時</th>
                                              <th>レコード数</th>
                                              <th>ステータス</th>
                                            </tr>
                                          </thead>
                                          <tbody className="text-caption-2">
                                            {fileUploadTaskList.map((f, idx) => {
                                              let uploadable = false;
                                              if (dataCreateTask.PeriodKind === periodKindCode.monthly.value) {
                                                if (authConf.Uploadable_Monthly.Files.includes(f.PermissionKey)) {
                                                  uploadable = true;
                                                }
                                              } else if (dataCreateTask.PeriodKind === periodKindCode.quarter.value) {
                                                if (authConf.Uploadable_Quarter.Files.includes(f.PermissionKey)) {
                                                  uploadable = true;
                                                }
                                              } else if (dataCreateTask.PeriodKind === periodKindCode.year.value) {
                                                if (authConf.Uploadable_Year.Files.includes(f.PermissionKey)) {
                                                  uploadable = true;
                                                }
                                              };
                                              return (
                                                <tr key={idx} className="border_black">
                                                  <td>{f.FileKind}{f.RequiredFlag && <span className="red--text font-bold pl-1">[必須]</span>}</td>
                                                  <td>{f.FileName}</td>
                                                  <td className="r-small">
                                                    <div className="flex middle">
                                                      <div>
                                                        {inputFileList[idx]?.fileData?.name ?
                                                          inputFileList[idx]?.fileData?.name : <span className="grey2--text">未選択</span>}
                                                      </div>
                                                      <div className="text-nowrap" style={{ marginLeft: "auto" }}>
                                                        {inputFileList[idx]?.fileData?.name ?
                                                          <IconButton size="small" onClick={() => _deleteFile(idx)}>
                                                            <Delete fontSize="small" />
                                                          </IconButton> : ""}
                                                        <InputItem props={{ "idx": idx, "isDisabled": (!uploadable || f.Status === fileUploadTaskStatus.checking.value || inputFileList[idx].noFileFlg) }} ref={fileInputRefs.current[idx]} />
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td className="l-large text-center">
                                                    <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                                      disabled={(!uploadable || f.Status === fileUploadTaskStatus.checking.value || f.RequiredFlag || inputFileList[idx].fileData !== null)}
                                                      checked={inputFileList[idx].noFileFlg}
                                                      onChange={(e) => _changeNoFile(idx)} />
                                                  </td>
                                                  <td>{f.FileUpdateUserName}</td>
                                                  <td className="text-nowrap">{f.FileUpdateDateTime.slice(0, -3)}</td>
                                                  <td className="right">{formatCommaDigit(String(f.RecordCount))}</td>
                                                  <td className="text-nowrap">
                                                    {f.Status !== fileUploadTaskStatus['check-ng'].value && fileUploadTaskStatus[f.Status].name}
                                                    {f.Status === fileUploadTaskStatus['check-ng'].value && <button className="button_status_error" onClick={() => openUploadErrorModal(f)}>{fileUploadTaskStatus[f.Status].name}</button>}
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </table>
                                        {error?.regist?.map((e, idx) => {
                                          return (
                                            <div key={idx}>
                                              <span className="red--text">{e}</span>
                                            </div>
                                          );
                                        })}
                                        <div className="content-footer">
                                          <div className="footer_button_area">
                                            {(() => {
                                              let disabled = !authConf.Regist;
                                              if (!disabled) {
                                                switch (dataCreateTask.PeriodKind) {
                                                  case periodKindCode.monthly.value:
                                                    disabled = !authConf.Uploadable_Monthly.Availability;
                                                    break;
                                                  case periodKindCode.quarter.value:
                                                    disabled = !authConf.Uploadable_Quarter.Availability;
                                                    break;
                                                  case periodKindCode.year.value:
                                                    disabled = !authConf.Uploadable_Year.Availability;
                                                    break;
                                                }
                                              }
                                              if (!disabled) {
                                                disabled = dataCreateTask.DataFixStatus === dataFixStatus.fix.value;
                                              }
                                              return (
                                                <button className="btn footer-btn button_base" disabled={disabled} onClick={() => openUploadConfirmModal()}>
                                                  <div>アップロード</div>
                                                </button>
                                              )
                                            })()}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                            }
                            {accountingUploadTaskList.length !== 0 &&
                              <div className="box-xy-bordered collapse-list mt-5">
                                <dl className="collapse-node collapse-parent w-full is-open">
                                  <dt className="flex shrink-0 px-3 pt-3">
                                    <label className="mb-3 text-h6" style={{ "width": "280px" }}>財務データのアップロード</label>
                                    <button className="dropdown-button" onClick={(e) => {
                                      const node = e.target.closest(".collapse-parent");
                                      if (node.classList.contains("is-open")) {
                                        node.classList.remove("is-open");
                                      } else {
                                        node.classList.add("is-open");
                                      }
                                    }} />
                                  </dt>
                                  <dd className="content">
                                    <div className="text-h6 font-Regular my-3 mx-5">
                                      <div className="flex mt-2">
                                        <ul className="grow text-caption-1">
                                          <li>財務データファイルのアップロード、およびCHROFY利用科目へのデータマッピングを行ってください。</li>
                                          <li>財務データの登録、および詳細の確認は財務データ登録画面で行ってください。</li>
                                        </ul>
                                      </div>
                                      <div className="flex mb-1">
                                        <div className="base-table-header div-mid mb-1">
                                          <span className="text-caption-2 pr-2">{lastFetchDatetimeAccounting} 時点</span>
                                          <div className="btn-group">
                                            <RefleshButton className="btn header-btn small-btn white--text flex"
                                              name="一覧更新" onClick={() => _setAccountingUploadTask()} />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="setting-table relative">
                                        <table className="base-table data-accounting-list-table w-full">
                                          <thead className="text-h7">
                                            <tr>
                                              <th>対象</th>
                                              <th>ファイル名</th>
                                              <th>登録者</th>
                                              <th>登録日時</th>
                                              <th>科目数</th>
                                              <th>ステータス</th>
                                            </tr>
                                          </thead>
                                          <tbody className="text-caption-2">
                                            {accountingUploadTaskList.map((f, idx) => {
                                              return (
                                                <tr key={idx} className="border_black">
                                                  <td>
                                                    <div className="flex middle data-upload">
                                                      <div style={{ margin: "0 auto 0 0" }}>{f.Mode === analysisMode.standalone ? "単体" : "連結"}</div>
                                                      {f.Status !== accountingUploadTaskStatus['processing'].value ? (
                                                        <Link variant="button" underline="none" component={RouterLink} to={"/data/analysisData/" + f.SuperKey + "/accountingData/" + f.Key}>
                                                          <span>アップロード</span>
                                                        </Link>
                                                      ) : (<span></span>)}
                                                    </div>
                                                  </td>
                                                  <td>{f.FileName}</td>
                                                  <td>{f.FileUpdateUserName}</td>
                                                  <td className="text-nowrap">{f.FileUpdateDateTime ? f.FileUpdateDateTime.slice(0, -3) : ""}</td>
                                                  <td className="right">{formatCommaDigit(String(f.AccountInfo.filter(a => a.CurrentAmount !== null).length))}</td>
                                                  <td className="text-nowrap">
                                                    {f.Status !== accountingUploadTaskStatus['error'].value && accountingUploadTaskStatus[f.Status].name}
                                                    {f.Status === accountingUploadTaskStatus['error'].value && <button className="button_status_error" onClick={() => openAccountErrorModal(f.FileName, f.ErrorInfo)}>{accountingUploadTaskStatus[f.Status].name}</button>}
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                            }
                          </div>
                        ) : (
                          <div className="content-area">
                            <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-8 rounded-t-2xl">データファイルの登録</h2>
                            <div className="flex my-5">
                              <ul className="link--text text-body-2-medium grow">
                                <li>登録対象のファイルがないため、データ更新のみ実行できます。</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <div className="content-area">
                          <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mx-8">レポートデータの更新／確定</h2>
                          <div className="flex my-10 text-nowrap">
                            <h3 className="text-h5 mt-2 mr-5 font-bold">状態：</h3>
                            {/* 表組み */}
                            {(() => {
                              const tableContent = (name, style, arrowIcon) => {
                                return (
                                  <React.Fragment>
                                    <table className="base-table">
                                      <tbody className="text-h4 text-center">
                                        <tr>
                                          <td width="150px" className={style}>{name}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    {!arrowIcon ? '' : (
                                      <div className="mt-1 px-2"><KeyboardDoubleArrowRightIcon fontSize="large" /></div>
                                    )}
                                  </React.Fragment>
                                )
                              }
                              const isFix = dataCreateTask.DataFixStatus === dataFixStatus.fix.value;
                              // 状態リスト
                              const steps = [];
                              // アップロード待ち
                              if (dataCreateStatus.uncreated.value === dataCreateTask.DataCreateStatus) {
                                steps.push(tableContent(dataCreateStatus.uncreated.name, 'blue1 white--text', true));
                              } else {
                                steps.push(tableContent(dataCreateStatus.uncreated.name, 'border-blue1 text-link', true));
                              }
                              // 再更新待ち
                              if (dataCreateStatus.recreate.value === dataCreateTask.DataCreateStatus) {
                                steps.push(tableContent(dataCreateStatus.recreate.name, 'blue1 white--text', true));
                              } else {
                                // 更新待ち
                                if (dataCreateStatus.await.value === dataCreateTask.DataCreateStatus) {
                                  steps.push(tableContent(dataCreateStatus.await.name, 'blue1 white--text', true));
                                } else {
                                  steps.push(tableContent(dataCreateStatus.await.name, 'border-blue1 text-link', true));
                                }
                              }
                              // 更新エラー
                              if (dataCreateStatus.error.value === dataCreateTask.DataCreateStatus) {
                                steps.push(tableContent(dataCreateStatus.error.name, 'blue1 white--text', true));
                              } else {
                                // 更新中
                                if (dataCreateStatus.creating.value === dataCreateTask.DataCreateStatus) {
                                  steps.push(tableContent(dataCreateStatus.creating.name, 'blue1 white--text', true));
                                } else {
                                  steps.push(tableContent(dataCreateStatus.creating.name, 'border-blue1 text-link', true));
                                }
                              }
                              // 更新済
                              if (dataCreateStatus.created.value === dataCreateTask.DataCreateStatus && !isFix) {
                                steps.push(tableContent(dataCreateStatus.created.name, 'blue1 white--text', true));
                              } else {
                                steps.push(tableContent(dataCreateStatus.created.name, 'border-blue1 text-link', true));
                              }
                              // 確定
                              if (isFix) {
                                steps.push(tableContent(dataFixStatus.fix.name, 'blue1 white--text', false));
                              } else {
                                steps.push(tableContent(dataFixStatus.fix.name, 'border-blue1 text-link', false));
                              }
                              return steps.map((step, idx) => (
                                <React.Fragment key={idx}>
                                  {step}
                                </React.Fragment>
                              ));
                            })()}
                          </div>
                          <div className="border-t border-blue1" />
                          <h3 className="text-h5 pt-6 mb-2 font-bold">操作履歴</h3>
                          {dataCreateTask.OperationHistory.length !== 0 ? (
                            <table className="base-table data-history-table w-full">
                              <thead className="text-h7">
                                <tr>
                                  <th>日時</th>
                                  <th>操作内容</th>
                                  <th>操作者</th>
                                  <th>許諾者</th>
                                  <th>許諾日</th>
                                </tr>
                              </thead>
                              <tbody className="text-caption-2">
                                {dataCreateTask.OperationHistory.map((history, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td className="text-nowrap">
                                        <span>{history.OperationDateTime.slice(0, -3)}</span>
                                      </td>
                                      <td>
                                        <span>{operationKind[history.OperationKind].name}</span>
                                      </td>
                                      <td>
                                        <span>{history.OperationUser}</span>
                                      </td>
                                      <td>
                                        {history.PermitUser &&
                                          <span>{history.PermitUser}</span>
                                        }
                                      </td>
                                      <td className="text-nowrap">
                                        {history.PermitDateTime &&
                                          <span>{history.PermitDateTime}</span>
                                        }
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <div className="text-center my-10 pt-10">
                              <span className="text-h5 red--text">操作履歴はありません。</span>
                            </div>
                          )}
                          {error?.analysisData?.map((e, idx) => {
                            return (
                              <div key={idx}>
                                <span className="text-center red--text">{e}</span>
                              </div>
                            );
                          })}
                          <div className="content-footer mt-16 mb-8">
                            <div className="footer_button_area">
                              <button className="btn footer-btn button_base" disabled={dataCreateTask.DataFixStatus === dataFixStatus.fix.value || !authConf.Data.Create} onClick={() => openDataCreateModal()}>
                                <div>データ更新</div>
                              </button>
                              {dataCreateTask.DataFixStatus === dataFixStatus.pending.value &&
                                <button className="btn footer-btn button_base" disabled={!authConf.Data.Lock} onClick={() => openDataFixMordal()}>
                                  <div>データ確定</div>
                                </button>
                              }
                              {dataCreateTask.DataFixStatus === dataFixStatus.fix.value &&
                                <button className="btn footer-btn button_base" disabled={!authConf.Data.Unlock} onClick={() => openDataFixCancelMordal()}>
                                  <div>データ確定解除</div>
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ===== /一覧 ===== */}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                        <div className="content-area">
                          <h2 className="text-h4-em text-center text--lighten-70 mb-2">選択した分析データはすでに削除されているか存在しません。</h2>
                          <h2 className="text-h4-em text-center text--lighten-70 mb-2">{titleName.taskList}に戻って操作をやり直してください。</h2>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
              {/* 上階層に戻る */}
              <div className="bg main">
                <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                  <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/data/taskList">
                    <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                      <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                      <span className="text-h5 font-bold">{titleName.taskList}に戻る</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* ========== /コンテンツ ========== */}
        <div className="bg-modal-window w-screen h-screen fixed top-0 left-0 grey lighten-70 z-50"></div>
        <DataFixCancelModal
          permitUser={permitUser}
          permitDate={permitDate}
          dataCreateTaskKey={dataCreateTaskKey}
          corporationNumber={props.corporationNumber}
          userName={props.userName}
          userSub={props.userSub}
          changePermitUser={changePermitUser}
          changePermitDate={changePermitDate}
          setError={setError}
          init={init}
          showToast={props.showToast}
        />
      </div>
    </ React.Fragment>
  );
}

const AnalysisData = (props) => {
  const state = useSelector((state) => state);
  const location = useLocation();
  const dataCreateTaskKey = props.params.dataCreateTaskKey;
  const showModal = useModal();
  const showToast = useToast();

  if (state.authority.Upload_AuthInfo.Availability && state.authority.Upload_AuthInfo.Auth_Conf.View) {
    return (
      <AnalysisDataContent
        dataCreateTaskKey={dataCreateTaskKey}
        userSub={state.user.sub}
        userName={state.user.userName}
        pageTitle={titleName.analysisData + titleName.common}
        corporationNumber={state.company.corporationNumber}
        isParent={state.company.isParent}
        accPeriod={state.company.accPeriod}
        bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber}
        showModal={showModal}
        showToast={showToast}
        authConf={state.authority.Upload_AuthInfo.Auth_Conf}
        message={location.state?.message}
      />
    );
  } else {
    return <Forbidden />
  };
};

export default (props) => <AnalysisData {...props} params={useParams()} />;
