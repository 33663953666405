import { Auth, API, Amplify } from "aws-amplify";


// 手動でAmplifyの設定を変更する。ここでは、APIのエンドポイントを変更している。
Amplify.configure({
    API: {
        endpoints: [
            {
                name: "CHROFY_REST_API",
                endpoint: process.env.REACT_APP_URL_BASE,
            },
            {
                name: "CHROFY_REST_API_COMMON",
                endpoint: process.env.REACT_APP_URL_BASE_CHROFY

            }
        ]
    }
});

export const httpGet = async (key, path, init) => {
    await Auth.currentAuthenticatedUser().then(user => {
        init.headers["chrofy-jwt-authorization"] = user.signInUserSession.idToken.jwtToken
    }
    )
    return API.get(key, path, init)
}
export const httpPost = async (key, path, init) => {
    await Auth.currentAuthenticatedUser().then(user => {
        init.headers["chrofy-jwt-authorization"] = user.signInUserSession.idToken.jwtToken
    }
    )
    return API.post(key, path, init)
}
export const httpPut = async (key, path, init) => {
    await Auth.currentAuthenticatedUser().then(user => {
        init.headers["chrofy-jwt-authorization"] = user.signInUserSession.idToken.jwtToken
    }
    )
    return API.put(key, path, init)
}