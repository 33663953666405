import img_category_s1 from "../assets/img/category/img_category_s1.png";
import img_category_s2 from "../assets/img/category/img_category_s2.png";
import img_category_01 from "../assets/img/category/img_category_01.png";
import img_category_02 from "../assets/img/category/img_category_02.png";
import img_category_03 from "../assets/img/category/img_category_03.png";
import img_category_04 from "../assets/img/category/img_category_04.png";
import img_category_05 from "../assets/img/category/img_category_05.png";
import img_nav__category_s1 from '../assets/img/img_nav__category_s1.png';
import img_nav__category_s1_ov from '../assets/img/img_nav__category_s1_ov.png';
import img_nav__category_s2 from '../assets/img/img_nav__category_s2.png';
import img_nav__category_s2_ov from '../assets/img/img_nav__category_s2_ov.png';
import img_nav__category_01 from '../assets/img/img_nav__category_01.png';
import img_nav__category_01_ov from '../assets/img/img_nav__category_01_ov.png';
import img_nav__category_02 from '../assets/img/img_nav__category_02.png';
import img_nav__category_02_ov from '../assets/img/img_nav__category_02_ov.png';
import img_nav__category_03 from '../assets/img/img_nav__category_03.png';
import img_nav__category_03_ov from '../assets/img/img_nav__category_03_ov.png';
import img_nav__category_04 from '../assets/img/img_nav__category_04.png';
import img_nav__category_04_ov from '../assets/img/img_nav__category_04_ov.png';
import img_nav__category_05 from '../assets/img/img_nav__category_05.png';
import img_nav__category_05_ov from '../assets/img/img_nav__category_05_ov.png';

import img_category_s2_01 from '../assets/img/category/img_category_s2_01.png'
import img_category_s2_02 from '../assets/img/category/img_category_s2_02.png'
import img_category_s2_03 from '../assets/img/category/img_category_s2_03.png'
import img_category_s2_04 from '../assets/img/category/img_category_s2_04.png'
import img_category_s2_05 from '../assets/img/category/img_category_s2_05.png'
import img_category_s2_06 from '../assets/img/category/img_category_s2_06.png'
import img_category_01_01 from '../assets/img/category/img_category_01_01.png'
import img_category_01_02 from '../assets/img/category/img_category_01_02.png'
import img_category_01_03 from '../assets/img/category/img_category_01_03.png'
import img_category_02_01 from '../assets/img/category/img_category_02_01.png'
import img_category_02_02 from '../assets/img/category/img_category_02_02.png'
import img_category_02_03 from '../assets/img/category/img_category_02_03.png'
import img_category_02_04 from '../assets/img/category/img_category_02_04.png'
import img_category_03_01 from '../assets/img/category/img_category_03_01.png'
import img_category_03_02 from '../assets/img/category/img_category_03_02.png'
import img_category_03_03 from '../assets/img/category/img_category_03_03.png'
import img_category_03_04 from '../assets/img/category/img_category_03_04.png'
import img_category_04_01 from '../assets/img/category/img_category_04_01.png'
import img_category_04_02 from '../assets/img/category/img_category_04_02.png'
import img_category_04_03 from '../assets/img/category/img_category_04_03.png'
import img_category_05_01 from '../assets/img/category/img_category_05_01.png'
import img_category_05_02 from '../assets/img/category/img_category_05_02.png'
import img_category_05_03 from '../assets/img/category/img_category_05_03.png'
import img_category_05_04 from '../assets/img/category/img_category_05_04.png'
import img_category_06_01 from '../assets/img/category/img_category_06_01.png'
import img_category_06_02 from '../assets/img/category/img_category_06_02.png'
import img_category_06_03 from '../assets/img/category/img_category_06_03.png'
import img_category_06_04 from '../assets/img/category/img_category_06_04.png'
import img_category_06_05 from '../assets/img/category/img_category_06_05.png'

import img_category_01_01_01 from '../assets/img/preview/1.1.1_screenshot.png'
import img_category_01_02_01 from '../assets/img/preview/1.2.1_screenshot.png'
import img_category_01_02_02 from '../assets/img/preview/1.2.2_screenshot.png'
import img_category_01_02_03 from '../assets/img/preview/1.2.3_screenshot.png'
import img_category_01_03_01 from '../assets/img/preview/1.3.1_screenshot.png'
import img_category_02_01_01 from '../assets/img/preview/2.1.1_screenshot.png'
import img_category_02_02_01 from '../assets/img/preview/2.2.1_screenshot.png'
import img_category_02_02_02 from '../assets/img/preview/2.2.2_screenshot.png'
import img_category_02_02_03 from '../assets/img/preview/2.2.3_screenshot.png'
import img_category_02_03_01 from '../assets/img/preview/2.3.1_screenshot.png'
import img_category_02_03_02 from '../assets/img/preview/2.3.2_screenshot.png'
import img_category_02_03_03 from '../assets/img/preview/2.3.3_screenshot.png'
import img_category_02_03_04 from '../assets/img/preview/2.3.4_screenshot.png'
import img_category_02_03_05 from '../assets/img/preview/2.3.5_screenshot.png'
import img_category_02_04_01 from '../assets/img/preview/2.4.1_screenshot.png'
import img_category_02_04_02 from '../assets/img/preview/2.4.2_screenshot.png'
import img_category_03_01_01 from '../assets/img/preview/3.1.1_screenshot.png'
import img_category_03_01_02 from '../assets/img/preview/3.1.2_screenshot.png'
import img_category_03_01_03 from '../assets/img/preview/3.1.3_screenshot.png'
import img_category_03_01_04 from '../assets/img/preview/3.1.4_screenshot.png'
import img_category_03_01_05 from '../assets/img/preview/3.1.5_screenshot.png'
import img_category_03_01_06 from '../assets/img/preview/3.1.6_screenshot.png'
import img_category_03_01_07 from '../assets/img/preview/3.1.7_screenshot.png'
import img_category_03_01_08 from '../assets/img/preview/3.1.8_screenshot.png'
import img_category_03_01_09 from '../assets/img/preview/3.1.9_screenshot.png'
import img_category_03_02_01 from '../assets/img/preview/3.2.1_screenshot.png'
import img_category_03_02_02 from '../assets/img/preview/3.2.2_screenshot.png'
import img_category_03_02_03 from '../assets/img/preview/3.2.3_screenshot.png'
import img_category_03_02_04 from '../assets/img/preview/3.2.4_screenshot.png'
import img_category_03_02_05 from '../assets/img/preview/3.2.5_screenshot.png'
import img_category_03_02_06 from '../assets/img/preview/3.2.6_screenshot.png'
import img_category_03_03_01 from '../assets/img/preview/3.3.1_screenshot.png'
import img_category_03_03_02 from '../assets/img/preview/3.3.2_screenshot.png'
import img_category_03_03_03 from '../assets/img/preview/3.3.3_screenshot.png'
import img_category_03_03_04 from '../assets/img/preview/3.3.4_screenshot.png'
import img_category_03_03_05 from '../assets/img/preview/3.3.5_screenshot.png'
import img_category_03_04_01 from '../assets/img/preview/3.4.1_screenshot.png'
import img_category_04_01_01 from '../assets/img/preview/4.1.1_screenshot.png'
import img_category_04_02_01 from '../assets/img/preview/4.2.1_screenshot.png'
import img_category_04_03_01 from '../assets/img/preview/4.3.1_screenshot.png'
import img_category_05_01_01 from '../assets/img/preview/5.1.1_screenshot.png'
import img_category_05_02_01 from '../assets/img/preview/5.2.1_screenshot.png'
import img_category_05_02_02 from '../assets/img/preview/5.2.2_screenshot.png'
import img_category_05_02_03 from '../assets/img/preview/5.2.3_screenshot.png'
import img_category_05_03_01 from '../assets/img/preview/5.3.1_screenshot.png'
import img_category_05_03_02 from '../assets/img/preview/5.3.2_screenshot.png'
import img_category_05_03_03 from '../assets/img/preview/5.3.3_screenshot.png'
import img_category_05_04_01 from '../assets/img/preview/5.4.1_screenshot.png'
import img_category_05_04_02 from '../assets/img/preview/5.4.2_screenshot.png'

import img_category_06_01_01 from '../assets/img/preview/6.1.1_screenshot.png'
import img_category_06_01_02 from '../assets/img/preview/6.1.2_screenshot.png'
import img_category_06_01_03 from '../assets/img/preview/6.1.3_screenshot.png'
import img_category_06_01_04 from '../assets/img/preview/6.1.4_screenshot.png'
import img_category_06_01_05 from '../assets/img/preview/6.1.5_screenshot.png'
import img_category_06_01_06 from '../assets/img/preview/6.1.6_screenshot.png'
import img_category_06_02_01 from '../assets/img/preview/6.2.1_screenshot.png'
import img_category_06_02_02 from '../assets/img/preview/6.2.2_screenshot.png'
import img_category_06_02_03 from '../assets/img/preview/6.2.3_screenshot.png'
import img_category_06_02_04 from '../assets/img/preview/6.2.4_screenshot.png'
import img_category_06_02_05 from '../assets/img/preview/6.2.5_screenshot.png'
import img_category_06_02_06 from '../assets/img/preview/6.2.6_screenshot.png'
// import img_category_07_01_01 from '../assets/img/preview/7.1.1_screenshot.png'
// import img_category_07_02_01 from '../assets/img/preview/7.1.1_screenshot.png'
import img_category_07_03_01 from '../assets/img/preview/7.3.1_screenshot.png'
import img_category_07_03_02 from '../assets/img/preview/7.3.2_screenshot.png'
// import img_category_07_04_01 from '../assets/img/preview/7.1.1_screenshot.png'
// import img_category_07_05_01 from '../assets/img/preview/7.1.1_screenshot.png'
// import img_category_07_06_01 from '../assets/img/preview/7.1.1_screenshot.png'
// import img_category_07_07_01 from '../assets/img/preview/7.1.1_screenshot.png'
import img_category_07_08_01 from '../assets/img/preview/7.8.1_screenshot.png'
import img_category_07_08_02 from '../assets/img/preview/7.8.2_screenshot.png'
// import img_category_07_09_01 from '../assets/img/preview/7.1.1_screenshot.png'
// import img_category_07_10_01 from '../assets/img/preview/7.1.1_screenshot.png'
// import img_category_07_11_01 from '../assets/img/preview/7.1.1_screenshot.png'
import img_category_08_01_01 from '../assets/img/preview/8.1.1_screenshot.png'
import img_category_08_01_02 from '../assets/img/preview/8.1.2_screenshot.png'
import img_category_08_01_03 from '../assets/img/preview/8.1.3_screenshot.png'
import img_category_08_01_04 from '../assets/img/preview/8.1.4_screenshot.png'
import img_category_08_01_05 from '../assets/img/preview/8.1.5_screenshot.png'
import img_category_08_01_06 from '../assets/img/preview/8.1.6_screenshot.png'
import img_category_08_01_07 from '../assets/img/preview/8.1.7_screenshot.png'
import img_category_08_01_08 from '../assets/img/preview/8.1.8_screenshot.png'
import img_category_08_01_09 from '../assets/img/preview/8.1.9_screenshot.png'
import img_category_08_01_10 from '../assets/img/preview/8.1.10_screenshot.png'
import img_category_08_01_11 from '../assets/img/preview/8.1.11_screenshot.png'
import img_category_08_01_12 from '../assets/img/preview/8.1.12_screenshot.png'
import img_category_09_01_01 from '../assets/img/preview/9.1.1_screenshot.png'
import img_category_09_01_02 from '../assets/img/preview/9.1.2_screenshot.png'
import img_category_09_01_03 from '../assets/img/preview/9.1.3_screenshot.png'

import img_category_10_01_01 from '../assets/img/preview/10.1.1_screenshot.png'
// import img_category_10_01_02 from '../assets/img/dashboard/img_noimage.jpg'
import img_category_10_01_03 from '../assets/img/preview/10.1.3_screenshot.png'
// import img_category_10_01_04 from '../assets/img/dashboard/img_noimage.jpg'
import img_category_10_01_05 from '../assets/img/preview/10.1.5_screenshot.png'
import img_category_10_01_06 from '../assets/img/preview/10.1.6_screenshot.png'
// import img_category_10_01_07 from '../assets/img/dashboard/img_noimage.jpg'
// import img_category_10_01_08 from '../assets/img/dashboard/img_noimage.jpg'
import img_category_10_02_01 from '../assets/img/preview/10.2.1_screenshot.png'
import img_category_10_03_01 from '../assets/img/preview/10.3.1_screenshot.png'
// import img_category_10_03_02 from '../assets/img/dashboard/img_noimage.jpg'
import img_category_10_03_03 from '../assets/img/preview/10.3.3_screenshot.png'
import img_category_10_03_04 from '../assets/img/preview/10.3.4_screenshot.png'
import img_category_10_03_05 from '../assets/img/preview/10.3.5_screenshot.png'
import img_category_10_04_01 from '../assets/img/preview/10.4.1_screenshot.png'
// import img_category_10_04_02 from '../assets/img/dashboard/img_noimage.jpg'
import img_category_10_05_01 from '../assets/img/preview/10.5.1_screenshot.png'

import img_category_S2_01_01 from '../assets/img/preview/S2.1.1_screenshot.png'
import img_category_S2_01_02 from '../assets/img/preview/S2.1.2_screenshot.png'
import img_category_S2_01_03 from '../assets/img/preview/S2.1.3_screenshot.png'
import img_category_S2_02_01 from '../assets/img/preview/S2.2.1_screenshot.png'
import img_category_S2_02_02 from '../assets/img/preview/S2.2.2_screenshot.png'
import img_category_S2_02_03 from '../assets/img/preview/S2.2.3_screenshot.png'
import img_category_S2_02_04 from '../assets/img/preview/S2.2.4_screenshot.png'
import img_category_S2_03_01 from '../assets/img/preview/S2.3.1_screenshot.png'
import img_category_S2_03_02 from '../assets/img/preview/S2.3.2_screenshot.png'
import img_category_S2_03_03 from '../assets/img/preview/S2.3.3_screenshot.png'
import img_category_S2_03_04 from '../assets/img/preview/S2.3.4_screenshot.png'
import img_category_S2_04_01 from '../assets/img/preview/S2.4.1_screenshot.png'
import img_category_S2_04_02 from '../assets/img/preview/S2.4.2_screenshot.png'
import img_category_S2_04_03 from '../assets/img/preview/S2.4.3_screenshot.png'
import img_category_S2_04_04 from '../assets/img/preview/S2.4.4_screenshot.png'
import img_category_S2_04_05 from '../assets/img/preview/S2.4.5_screenshot.png'
import img_category_S2_04_06 from '../assets/img/preview/S2.4.6_screenshot.png'
import img_category_S2_04_07 from '../assets/img/preview/S2.4.7_screenshot.png'
import img_category_S2_04_08 from '../assets/img/preview/S2.4.8_screenshot.png'
import img_category_S2_04_09 from '../assets/img/preview/S2.4.9_screenshot.png'
import img_category_S2_04_10 from '../assets/img/preview/S2.4.10_screenshot.png'
//import img_category_S2_05_01 from '../assets/img/preview/S2.5.1_screenshot.png'
//import img_category_S2_05_02 from '../assets/img/preview/S2.5.2_screenshot.png'
//import img_category_S2_05_03 from '../assets/img/preview/S2.5.3_screenshot.png'
//import img_category_S2_05_04 from '../assets/img/preview/S2.5.4_screenshot.png'
import img_category_S2_05_05 from '../assets/img/preview/S2.5.5_screenshot.png'
//import img_category_S2_05_06 from '../assets/img/preview/S2.5.6_screenshot.png'
import img_category_S2_05_07 from '../assets/img/preview/S2.5.7_screenshot.png'
import img_category_S2_05_08 from '../assets/img/preview/S2.5.8_screenshot.png'
//import img_category_S2_05_09 from '../assets/img/preview/S2.5.9_screenshot.png'
//import img_category_S2_05_10 from '../assets/img/preview/S2.5.10_screenshot.png'
//import img_category_S2_05_11 from '../assets/img/preview/S2.5.11_screenshot.png'
//import img_category_S2_05_12 from '../assets/img/preview/S2.5.12_screenshot.png'
import img_category_S2_06_01 from '../assets/img/preview/S2.6.1_screenshot.png'
import img_category_S2_06_02 from '../assets/img/preview/S2.6.2_screenshot.png'
import img_category_S2_06_03 from '../assets/img/preview/S2.6.3_screenshot.png'
//import img_category_S2_06_04 from '../assets/img/preview/S2.6.4_screenshot.png'
import img_category_S2_06_05 from '../assets/img/preview/S2.6.5_screenshot.png'
import img_category_S2_06_06 from '../assets/img/preview/S2.6.6_screenshot.png'
import img_category_S2_06_07 from '../assets/img/preview/S2.6.7_screenshot.png'

import img_category_noimage from '../assets/img/dashboard/img_noimage.jpg'

export const kgiPageInfoList = [
  // S1.経営戦略KGI
  {
    key: "S1",
    img: img_category_s1,
    url: "/indicator/S1",
    menuIcon: img_nav__category_s1,
    menuIconOver: img_nav__category_s1_ov,
    dashboardList: [],
  },
]

export const mainPageInfoList = [
  // S2.人的資本KGI
  {
    key: "S2",
    img: img_category_s2,
    url: "/indicator/S2/",
    menuIcon: img_nav__category_s2,
    menuIconOver: img_nav__category_s2_ov,
    dashboardList: [
      {
        key: "S2.1",
        img: img_category_s2_01,
        dashboard: [
          {
            key: "S2.1.1",
            img: img_category_S2_01_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.1.2",
            img: img_category_S2_01_02,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.1.3",
            img: img_category_S2_01_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.1.4",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "S2.2",
        img: img_category_s2_02,
        dashboard: [
          {
            key: "S2.2.1",
            img: img_category_S2_02_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.2.2",
            img: img_category_S2_02_02,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.2.3",
            img: img_category_S2_02_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: true,
              consolidate: false,
            },
          },
          {
            key: "S2.2.4",
            img: img_category_S2_02_04,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.2.5",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "S2.3",
        img: img_category_s2_03,
        dashboard: [
          {
            key: "S2.3.1",
            img: img_category_S2_03_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.3.2",
            img: img_category_S2_03_02,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.3.3",
            img: img_category_S2_03_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.3.4",
            img: img_category_S2_03_04,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "S2.4",
        img: img_category_s2_04,
        dashboard: [
          {
            key: "S2.4.1",
            img: img_category_S2_04_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.4.2",
            img: img_category_S2_04_02,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.4.3",
            img: img_category_S2_04_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.4.4",
            img: img_category_S2_04_04,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.4.5",
            img: img_category_S2_04_05,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.4.6",
            img: img_category_S2_04_06,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.4.7",
            img: img_category_S2_04_07,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.4.8",
            img: img_category_S2_04_08,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.4.9",
            img: img_category_S2_04_09,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.4.10",
            img: img_category_S2_04_10,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "S2.5",
        img: img_category_s2_05,
        dashboard: [
          {
            key: "S2.5.1",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.5.2",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.5.3",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.5.4",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.5.5",
            img: img_category_S2_05_05,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.5.6",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.5.7",
            img: img_category_S2_05_07,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.5.8",
            img: img_category_S2_05_08,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.5.9",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.5.10",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.5.11",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.5.12",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "S2.6",
        img: img_category_s2_06,
        dashboard: [
          {
            key: "S2.6.1",
            img: img_category_S2_06_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.6.2",
            img: img_category_S2_06_02,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.6.3",
            img: img_category_S2_06_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: true,
              consolidate: false,
            },
          },
          {
            key: "S2.6.4",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "S2.6.5",
            img: img_category_S2_06_05,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: true,
              consolidate: true,
            },
          },
          {
            key: "S2.6.6",
            img: img_category_S2_06_06,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: true,
              consolidate: true,
            },
          },
          {
            key: "S2.6.7",
            img: img_category_S2_06_07,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
    ],
  },
  // 1.基礎情報
  {
    key: "1",
    img: img_category_01,
    url: "/report/1/",
    menuIcon: img_nav__category_01,
    menuIconOver: img_nav__category_01_ov,
    dashboardList: [
      {
        key: "1.1",
        img: img_category_01_01,
        dashboard: [
          {
            key: "1.1.1",
            img: img_category_01_01_01,
            yearFilterFlg: false,
            quarterFlg: true,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: true,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "1.2",
        img: img_category_01_02,
        dashboard: [
          {
            key: "1.2.1",
            img: img_category_01_02_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "1.2.2",
            img: img_category_01_02_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "1.2.3",
            img: img_category_01_02_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "1.3",
        img: img_category_01_03,
        dashboard: [
          {
            key: "1.3.1",
            img: img_category_01_03_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
    ],
  },

  // 2.戦力（人材）配置
  {
    key: "2",
    img: img_category_02,
    url: "/report/2/",
    menuIcon: img_nav__category_02,
    menuIconOver: img_nav__category_02_ov,
    dashboardList: [
      {
        key: "2.1",
        img: img_category_02_01,
        dashboard: [
          {
            key: "2.1.1",
            img: img_category_02_01_01,
            yearFilterFlg: false,
            quarterFlg: true,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "2.2",
        img: img_category_02_02,
        dashboard: [
          {
            key: "2.2.1",
            img: img_category_02_02_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "2.2.2",
            img: img_category_02_02_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "2.2.3",
            img: img_category_02_02_03,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "2.3",
        img: img_category_02_03,
        dashboard: [
          {
            key: "2.3.1",
            img: img_category_02_03_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "2.3.2",
            img: img_category_02_03_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "2.3.3",
            img: img_category_02_03_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "2.3.4",
            img: img_category_02_03_04,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "2.3.5",
            img: img_category_02_03_05,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "2.4",
        img: img_category_02_04,
        dashboard: [
          {
            key: "2.4.1",
            img: img_category_02_04_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "2.4.2",
            img: img_category_02_04_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
    ],
  },

  // 3.制度・運用の確認
  {
    key: "3",
    img: img_category_03,
    url: "/report/3/",
    menuIcon: img_nav__category_03,
    menuIconOver: img_nav__category_03_ov,
    dashboardList: [
      {
        key: "3.1",
        img: img_category_03_01,
        dashboard: [
          {
            key: "3.1.1",
            img: img_category_03_01_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.1.2",
            img: img_category_03_01_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.1.3",
            img: img_category_03_01_03,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.1.4",
            img: img_category_03_01_04,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.1.5",
            img: img_category_03_01_05,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.1.6",
            img: img_category_03_01_06,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.1.7",
            img: img_category_03_01_07,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.1.8",
            img: img_category_03_01_08,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.1.9",
            img: img_category_03_01_09,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "3.2",
        img: img_category_03_02,
        dashboard: [
          {
            key: "3.2.1",
            img: img_category_03_02_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.2.2",
            img: img_category_03_02_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.2.3",
            img: img_category_03_02_03,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.2.4",
            img: img_category_03_02_04,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.2.5",
            img: img_category_03_02_05,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.2.6",
            img: img_category_03_02_06,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "3.3",
        img: img_category_03_03,
        dashboard: [
          {
            key: "3.3.1",
            img: img_category_03_03_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.3.2",
            img: img_category_03_03_02,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.3.3",
            img: img_category_03_03_03,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.3.4",
            img: img_category_03_03_04,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "3.3.5",
            img: img_category_03_03_05,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "3.4",
        img: img_category_03_04,
        dashboard: [
          {
            key: "3.4.1",
            img: img_category_03_04_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
    ],
  },

  // 4.採用と定着
  {
    key: "4",
    img: img_category_04,
    url: "/report/4/",
    menuIcon: img_nav__category_04,
    menuIconOver: img_nav__category_04_ov,
    dashboardList: [
      {
        key: "4.1",
        img: img_category_04_01,
        dashboard: [
          {
            key: "4.1.1",
            img: img_category_04_01_01,
            yearFilterFlg: false,
            quarterFlg: true,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: true,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "4.2",
        img: img_category_04_02,
        dashboard: [
          {
            key: "4.2.1",
            img: img_category_04_02_01,
            yearFilterFlg: false,
            quarterFlg: true,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: true,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "4.3",
        img: img_category_04_03,
        dashboard: [
          {
            key: "4.3.1",
            img: img_category_04_03_01,
            yearFilterFlg: false,
            quarterFlg: true,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: true,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
    ],
  },

  // 5.人材の維持
  {
    key: "5",
    img: img_category_05,
    url: "/report/5/",
    menuIcon: img_nav__category_05,
    menuIconOver: img_nav__category_05_ov,
    dashboardList: [
      {
        key: "5.1",
        img: img_category_05_01,
        dashboard: [
          {
            key: "5.1.1",
            img: img_category_05_01_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "5.2",
        img: img_category_05_02,
        dashboard: [
          {
            key: "5.2.1",
            img: img_category_05_02_01,
            yearFilterFlg: false,
            quarterFlg: true,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "5.2.2",
            img: img_category_05_02_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "5.2.3",
            img: img_category_05_02_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "5.3",
        img: img_category_05_03,
        dashboard: [
          {
            key: "5.3.1",
            img: img_category_05_03_01,
            yearFilterFlg: true,
            quarterFlg: true,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: true,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "5.3.2",
            img: img_category_05_03_02,
            yearFilterFlg: false,
            quarterFlg: true,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: true,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "5.3.3",
            img: img_category_05_03_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "5.4",
        img: img_category_05_04,
        dashboard: [
          {
            key: "5.4.1",
            img: img_category_05_04_01,
            yearFilterFlg: false,
            quarterFlg: true,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: true,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "5.4.2",
            img: img_category_05_04_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
    ],
  },
]

export const monitoringInfoList = [
  // 月次モニタリング
  {
    key: "6",
    img: "",
    url: "/report/6/",
    monitoring: 1,
    justMenu: 0,
    dashboardList: [
      {
        key: "6.1",
        img: img_category_06_01,
        dashboard: [
          {
            key: "6.1.1",
            img: img_category_06_01_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "6.1.2",
            img: img_category_06_01_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "6.1.3",
            img: img_category_06_01_03,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "6.1.4",
            img: img_category_06_01_04,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "6.1.5",
            img: img_category_06_01_05,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "6.1.6",
            img: img_category_06_01_06,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "6.2",
        img: img_category_06_02,
        dashboard: [
          {
            key: "6.2.1",
            img: img_category_06_02_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: true,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "6.2.2",
            img: img_category_06_02_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "6.2.3",
            img: img_category_06_02_03,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "6.2.4",
            img: img_category_06_02_04,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "6.2.5",
            img: img_category_06_02_05,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "6.2.6",
            img: img_category_06_02_06,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
    ],
  },

  // ISO30414:2018 レポート
  {
    key: "7",
    img: "",
    url: "/report/7/",
    monitoring: 1,
    justMenu: 0,
    dashboardList: [
      {
        key: "7.1",
        img: img_category_06_02,
        dashboard: [
          {
            key: "7.1.1",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "7.2",
        img: img_category_06_02,
        dashboard: [
          {
            key: "7.2.1",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "7.3",
        img: img_category_06_02,
        dashboard: [
          {
            key: "7.3.1",
            img: img_category_07_03_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "7.3.2",
            img: img_category_07_03_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "7.4",
        img: img_category_06_02,
        dashboard: [
          {
            key: "7.4.1",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: true,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "7.5",
        img: img_category_06_02,
        dashboard: [
          {
            key: "7.5.1",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: true,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "7.6",
        img: img_category_06_02,
        dashboard: [
          {
            key: "7.6.1",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "7.7",
        img: img_category_06_02,
        dashboard: [
          {
            key: "7.7.1",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "7.8",
        img: img_category_06_02,
        dashboard: [
          {
            key: "7.8.1",
            img: img_category_07_08_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "7.8.2",
            img: img_category_07_08_02,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "7.9",
        img: img_category_06_02,
        dashboard: [
          {
            key: "7.9.1",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "7.10",
        img: img_category_06_02,
        dashboard: [
          {
            key: "7.10.1",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "7.11",
        img: img_category_06_02,
        dashboard: [
          {
            key: "7.11.1",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
    ],
  },

  // DEIレポート
  {
    key: "8",
    img: "",
    url: "/report/8/",
    monitoring: 1,
    justMenu: 0,
    dashboardList: [
      {
        key: "8.1",
        img: img_category_06_03,
        dashboard: [
          {
            key: "8.1.1",
            img: img_category_08_01_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "8.1.2",
            img: img_category_08_01_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "8.1.3",
            img: img_category_08_01_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "8.1.4",
            img: img_category_08_01_04,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "8.1.5",
            img: img_category_08_01_05,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "8.1.6",
            img: img_category_08_01_06,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "8.1.7",
            img: img_category_08_01_07,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "8.1.8",
            img: img_category_08_01_08,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "8.1.9",
            img: img_category_08_01_09,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "8.1.10",
            img: img_category_08_01_10,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "8.1.11",
            img: img_category_08_01_11,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "8.1.12",
            img: img_category_08_01_12,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
    ],
  },

  // 健康経営レポート
  {
    key: "9",
    img: "",
    url: "/report/9/",
    monitoring: 1,
    justMenu: 0,
    dashboardList: [
      {
        key: "9.1",
        img: img_category_06_04,
        dashboard: [
          {
            key: "9.1.1",
            img: img_category_09_01_01,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "9.1.2",
            img: img_category_09_01_02,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "9.1.3",
            img: img_category_09_01_03,
            yearFilterFlg: true,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: true,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: true,
              ISO: true,
              GRI: true,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
    ],
  },

  // 法定開示レポート
  {
    key: "10",
    img: "",
    url: "/report/10/",
    monitoring: 1,
    justMenu: 0,
    dashboardList: [
      {
        key: "10.1",
        img: img_category_06_05,
        dashboard: [
          {
            key: "10.1.1",
            img: img_category_10_01_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "10.1.2",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "10.1.3",
            img: img_category_10_01_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "10.1.4",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "10.1.5",
            img: img_category_10_01_05,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: true,
              consolidate: false,
            },
          },
          {
            key: "10.1.6",
            img: img_category_10_01_06,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: true,
              consolidate: false,
            },
          },
          {
            key: "10.1.7",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "10.1.8",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "10.2",
        img: img_category_06_05,
        dashboard: [
          {
            key: "10.2.1",
            img: img_category_10_02_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: true,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "10.3",
        img: img_category_06_05,
        dashboard: [
          {
            key: "10.3.1",
            img: img_category_10_03_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "10.3.2",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "10.3.3",
            img: img_category_10_03_03,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: true,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: true,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: true,
              consolidate: false,
            },
          },
          {
            key: "10.3.4",
            img: img_category_10_03_04,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "10.3.5",
            img: img_category_10_03_05,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "10.4",
        img: img_category_06_05,
        dashboard: [
          {
            key: "10.4.1",
            img: img_category_10_04_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
          {
            key: "10.4.2",
            img: img_category_noimage,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: false,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
      {
        key: "10.5",
        img: img_category_06_05,
        dashboard: [
          {
            key: "10.5.1",
            img: img_category_10_05_01,
            yearFilterFlg: false,
            quarterFlg: false,
            consolidatedYear: {
              totalFlg: false,
              individualFlg: false,
            },
            consolidatedQuarter: {
              totalFlg: false,
              individualFlg: false,
            },
            groupFlg: false,
            activeFlg: true,
            badge: {
              CAS: false,
              ISO: false,
              GRI: false,
            },
            benchmarkTarget: {
              standalone: false,
              consolidate: false,
            },
          },
        ],
      },
    ],
  },
]
