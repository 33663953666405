"use strict";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { getDynamoDB } from "../../common/utils/DynamoDbUtils";
import { formatDate } from "../../common/utils/DateUtils";
import { titleName, table, kgiStatus } from "../../_constants/Code";
import { useToast } from "../../_components/Toast";

import Forbidden from "../../_components/Forbidden";

const KgiListContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [businessPlanListData, setBusinessPlanListData] = React.useState([]);

  const authConf = props.authConf;

  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    init();
    if (props.message) {
      props.showToast(props.message);
    }
  }, []);

  /**
   * 初期表示
   */
  const init = async () => {
    // 経営計画マスタを取得
    const businessPlanList = await getDynamoDB(table.BusinessPlan, null,
      props.corporationNumber, null,
      { DeleteFlg: 0 }
    );
    setBusinessPlanListData(businessPlanList);
    setIsLoaded(true);
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{titleName.kgiList}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              {/*  ===== リード ===== */}
              <p className="text-body text-center mb-10">
                S1 経営戦略KGI画面の表示内容を設定します。<br />
                表示対象となる経営計画を登録してください。
              </p>
              {/*  ===== /リード ===== */}
              <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-16 px-16 border-blue1">
                <div className="content-area">
                  <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">経営計画設定</h2>
                  <div className="flex my-5">
                    <ul className="text-body-2-medium grow">
                      <li>経営計画の登録／変更と、KPIツリーの設定ができます。</li>
                    </ul>
                    <div className="base-table-header">
                      <div className="btn-group pl-10 border-l border-blue2-lighten-20">
                        {(() => {
                          const content = (isDisabled) => {
                            // return <span className={`header-btn white--text text-h7 text-center ${isDisabled ? "is-disabled" : ""}`}>新規登録</span>
                            return (
                              <button type="button" className={`btn header-btn white--text py-1.5 pl-7 pr-10 inline-flex items-center ${isDisabled ? "is-disabled" : ""}`} disabled={isDisabled}>
                                <span className="icon-plus-line icon-32px mr-1.5 my-px"></span>
                                <span className="-translate-y-px text-h7">新規登録</span>
                              </button>
                            )
                          };
                          if (authConf.Regist) {
                            return (
                              <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/indicator/kgi/businessPlan/new">
                                {content(false)}
                              </Link>
                            );
                          } else {
                            return content(true);
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                  {/*  ===== 経営計画設定 ===== */}
                  {businessPlanListData.length !== 0 &&
                    <table className={`base-table w-full ${props.isParent ? "kgi-list-table" : "kgi-list-table-s"}`}>
                      <thead className="text-h7">
                        <tr>
                          <th>タイトル</th>
                          <th>期間</th>
                          {props.isParent && <th>対象</th>}
                          <th>状態</th>
                          <th>最終更新者</th>
                          <th>最終更新日時</th>
                        </tr>
                      </thead>
                      <tbody className="text-caption-1">
                        {businessPlanListData.sort((a, b) => {
                          if (a.StartPeriod > b.StartPeriod) return -1;
                          if (b.StartPeriod > a.StartPeriod) return 1;
                          if (a.Key > b.Key) return -1;
                          return 1;
                        }).map((data) => {
                          return (
                            <tr key={data.Key}>
                              <td className="title">
                                <Link variant="button" underline="none" component={RouterLink} to={"/indicator/kgi/businessPlan/edit/" + data.Key}>
                                  <span className="font-Medium" style={{ textTransform: "none" }}>{data.Title}</span>
                                </Link>
                              </td>
                              <td>{data.StartPeriod}{props.accPeriod}期 ～ {data.EndPeriod}{props.accPeriod}期</td>
                              {props.isParent && <td className="text-center">{data.Mode === 'standalone' ? "単体" : "連結"}</td>}
                              <td >{kgiStatus[data.Status]?.name}</td>
                              <td>{data.UpdateUserName}</td>
                              <td>{data.UpdateDateTime && formatDate(new Date(data.UpdateDateTime)).slice(0, -3)}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  }
                  {businessPlanListData.length === 0 &&
                    <div className="text-center my-10 pt-10">
                      <span className="text-h5 red--text">経営計画は登録されていません</span>
                    </div>
                  }
                  {/*  ===== /経営計画設定 ===== */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  )
}

const KgiList = () => {
  const state = useSelector((state) => state);
  const location = useLocation();
  const showToast = useToast();

  if (state.authority.Kgi_AuthInfo.Availability && state.authority.Kgi_AuthInfo.Auth_Conf.View) {
    return <KgiListContent
      pageTitle={titleName.kgiList + titleName.common}
      corporationNumber={state.company.corporationNumber}
      isParent={state.company.isParent}
      accPeriod={state.company.accPeriod}
      message={location.state?.message}
      showToast={showToast}
      authConf={state.authority.Kgi_AuthInfo.Auth_Conf}
    />;
  } else {
    return <Forbidden />
  }
};

export default KgiList;
