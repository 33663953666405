"use strict";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAuthority } from "../services/auth/AuthorityService";
import { ToastProvider } from "../_components/Toast";
import { ModalProvider } from "../_components/Modal";

import '../assets/css/vendor/normalize.css'
import '../assets/css/vendor/animate.min.css'
import '../assets/css/vendor/hamburgers.css'
import '../assets/icon/style.css'
import '../assets/css/style.css'
import '../assets/js/tailwindcss.js'
import '../assets/js/adobe_fonts.js'
import '../assets/js/script.js'

const Contents = ({ Component }) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  (async () => {
    // 権限情報再設定
    const authority = await getAuthority(
      state.analysis.mode,
      state.company.corporationNumber,
      state.user.sub
    );
    state.authority = authority;
    setIsLoaded(true);
  })();

  React.useEffect(() => {
    setIsLoaded(false);
    init();
  }, []);

  const init = async () => {
    const authority = await getAuthority(
      state.analysis.mode,
      state.company.corporationNumber,
      state.user.sub
    );
    dispatch({
      type: "SET_AUTHORITY",
      payload: {
        authority: authority
      },
    });
    setIsLoaded(true);
  }

  // 初期表示時に「leader-line-new」のLineを削除する
  const lines = document.querySelectorAll(".leader-line");
  if (lines) {
    lines.forEach(e => e.remove());
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <div>
      <ToastProvider>
        <ModalProvider>
          <Component />
        </ModalProvider>
      </ToastProvider>
    </div>
  );
};

export default Contents;