import * as React from "react";
import { Link } from "react-router-dom";
import { useWindowDimensions } from "./Window";
import { definedValue } from "../_constants/Code";

export const Message = (props) => {
  // 高さ再設定
  const { width, height } = useWindowDimensions();
  const newHeight = height - definedValue.footerHeight < definedValue.containerHeight ?
    definedValue.containerHeight : height - definedValue.footerHeight;
  // メッセージ内容を取得
  const messages = props.messageList.map((message, index) => (
    <li key={index} className="flex justify-center">{message}</li>
  ));
  return (
    <React.Fragment>
      <main className="page-container" style={{ height: newHeight+'px' }}>
        <section className="page-content">
          <div className="centered-container">
            <div>
              <header className="page-header flex justify-center py-1">
                <h1 className="page-header__name text-h1 grey--text py-16">
                  {props.title}
                </h1>
              </header>
              <div className="page-body">
                <div className="centered-container-1056">
                  <div className="flex justify-center mb-10">
                    <ul className="text-list">
                      {messages}
                      {props.loginBtn && (
                        <li className="flex justify-center">
                          <button className="white--text grey rounded-lg px-10">
                            <Link to="/logout">ログイン画面へ</Link>
                          </button>
                        </li>
                      )}
                      {props.topBtn && (
                        <li className="flex justify-center">
                          <button className="white--text grey rounded-lg px-10">
                            <Link to="/">トップページへ</Link>
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};
