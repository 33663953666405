"use strict";
import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { SpinnerButton } from "../common/CustomButton"
import { mainPageInfoList } from "../../_constants/PageInfoList";
import { getDynamoDB, updateDynamoDB, getKpi } from "../../common/utils/DynamoDbUtils";
import { viewId, titleName, table, analysisMode } from "../../_constants/Code";
import { errorLog } from "../../common/logger/Logger";
import { formatDate } from "../../common/utils/DateUtils";
import { parseCommaDigit } from "../../common/utils/NumberUtils";
import { useModal } from "../../_components/Modal";
import { TreeView } from "../common/TreeView";
import BasicInfo, { getBasicInfo, setBasicInfo, clearCheck, checkBasicInfo } from "./parts/BasicInfo";
import MaterialityInfo, { setMateriality } from "./parts/MaterialityInfo";
import Forbidden from "../../_components/Forbidden";

// マスタ情報
let masterKpiInfo = [];

const BusinessPlanEditContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [sortKey, setSortKey] = React.useState();
  const [businessPlanInfo, setBusinessPlanInfo] = React.useState();
  const [masterTreeInfo, setMasterTreeInfo] = React.useState();
  const [kpiTreeInfo, setKpiTreeInfo] = React.useState([]);
  const [conitoringTreeInfo, setConitoringTreeInfo] = React.useState([]);
  const [inputError, setInputError] = React.useState({});
  const authConf = props.authConf;

  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    init();
  }, []);

  // 入力エラー時にスクロールをトップへ
  React.useEffect(() => {
    if (inputError['errorMessage']) {
      window.scrollTo(0, 0);
    }
  }, [inputError]);

  /**
   * 初期表示
   */
  const init = async () => {

    clearCheck();
    if (props.id) {
      // KGIマスタを取得
      const dynamoDBData = isNaN(props.id) ? [] :
        await getDynamoDB(table.BusinessPlan, null, props.corporationNumber,
          { Key: parseInt(props.id) }
        );
      if (dynamoDBData.length) {
        setSortKey(props.id);
        const businessPlanData = dynamoDBData[0];
        // キー項目の削除
        delete businessPlanData.CorporationNumber
        delete businessPlanData.Key
        // KGIツリーにID属性を付与
        businessPlanData.KgiTree.forEach(kgi => {
          kgi.id = crypto.randomUUID();
          kgi.Sub_Kgi.forEach(subKgi => {
            subKgi.id = crypto.randomUUID();
          });
        });
        // 共通パーツ「基本情報」を設定
        setBasicInfo(businessPlanData);
        // 共通パーツ「マテリアリティ情報」を設定
        setMateriality(businessPlanData.KgiTree);
        // KPIマスタを取得
        masterKpiInfo = await getKpi(props.corporationNumber);
        masterKpiInfo.sort((a, b) => {
          // 整数部で比較
          if (Number(a.Key.split('.')[0]) < Number(b.Key.split('.')[0])) return -1;
          if (Number(a.Key.split('.')[0]) > Number(b.Key.split('.')[0])) return 1;
          // 小数部で比較
          a.tmpKey = a.Key.split('.').length > 1 ? Number(a.Key.split('.')[1]) : 0;
          b.tmpKey = b.Key.split('.').length > 1 ? Number(b.Key.split('.')[1]) : 0;
          if (a.tmpKey < b.tmpKey) return -1;
          if (a.tmpKey > b.tmpKey) return 1;
          return 0;
        })
        // 有効データに絞り込み
        const s2 = mainPageInfoList.find(p => p.key === 'S2');
        masterKpiInfo = masterKpiInfo.filter(master => {
          if (!master.SuperKey) return true;
          const parent = s2.dashboardList.find(d => d.key === 'S2.' + master.Key.split(".")[0]);
          const dashboard = parent.dashboard.find(d => d.key === 'S2.' + master.Key);
          if (businessPlanData.Mode === analysisMode.standalone && dashboard.activeFlg) {
            return true;
          }
          if (businessPlanData.Mode === analysisMode.consolidated && dashboard.groupFlg) {
            return true;
          }
        });
        // 子要素無しをさらに絞り込む
        masterKpiInfo = masterKpiInfo.filter(master => {
          if (master.SuperKey) return true;
          return masterKpiInfo.some(m => m.SuperKey === master.Key)
        });
        setMasterTreeInfo(masterKpiInfo.map((d) => {
          return {
            'id': d.Key,
            'kpiKey': d.Key,
            'text': d.Key + ' ' + d.Name,
            'parent': d.SuperKey ? d.SuperKey : 'master_tree_root',
            'type': d.SuperKey ? 'KPI' : undefined,
            'canDelete': true,
            'tree': 'master_tree'
          }
        }));
        // KGIツリー
        setKpiTreeInfo(_createTreeData(businessPlanData.KgiTree));
        // 定常モニタリングKPI
        setConitoringTreeInfo(
          businessPlanData.ContinuousMonitoringKpi.map(id => {
            return {
              'id': id,
              'text': id + ' ' + masterKpiInfo.find(m => m.Key === id)?.Name,
              'parent': 'conitoring_tree_root',
              'type': 'KPI',
              'canDelete': true,
              'tree': 'conitoring_kpi_tree',
            }
          })
        );
        // 経営計画情報
        setBusinessPlanInfo(businessPlanData);
      }
    }
    setIsLoaded(true);
  }

  const _createTreeData = (kgiTree) => {
    const treeData = [];
    kgiTree.forEach(tree => {
      // KGI
      treeData.push({
        'id': tree.id,
        'text': tree.Title,
        'parent': 'kpi_tree_root',
        'type': 'KGI',
        'tree': 'kpi_tree'
      });
      // KPI
      tree.Kpi_Key.forEach(id => {
        treeData.push({
          'id': tree.id + '_' + id,
          'kpiKey': id,
          'text': id + ' ' + masterKpiInfo.find(m => m.Key === id)?.Name,
          'parent': tree.id,
          'type': 'KPI',
          'tree': 'kpi_tree',
          'canDelete': true
        });
      });
      // 配下KGI
      tree.Sub_Kgi.forEach(sub => {
        treeData.push({
          'id': sub.id,
          'text': sub.Title,
          'parent': tree.id,
          'type': 'KGI',
          'tree': 'kpi_tree'
        });
        // KPI
        sub.Kpi_Key.forEach(id => {
          treeData.push({
            'id': sub.id + '_' + id,
            'kpiKey': id,
            'text': id + ' ' + masterKpiInfo.find(m => m.Key === id)?.Name,
            'parent': sub.id,
            'type': 'KPI',
            'tree': 'kpi_tree',
            'canDelete': true
          });
        });
      });
    });
    return treeData;
  }

  /**
   * 経営戦略KGI / マテリアリティ設定の追加
   * @param {Object} materialityInfo マテリアリティ
   * @param {String} parentId 上位KGIのID
   */
  const handleAddKgiTree = (materialityInfo, parentId) => {

    //経営計画情報
    if (!parentId) {
      businessPlanInfo.KgiTree.push(materialityInfo);
    }
    // KPIツリー
    kpiTreeInfo.push({
      'id': materialityInfo.id,
      'text': materialityInfo.Title,
      'parent': parentId ? parentId : 'kpi_tree_root',
      'type': 'KGI',
      'tree': 'kpi_tree'
    });
    setKpiTreeInfo([...kpiTreeInfo]);
  }

  /**
   * 経営戦略KGI / マテリアリティ設定の変更
   * @param {Object} materialityInfo マテリアリティ
   * @param {String} parentId 上位KGIのID
   * @param {String} oldParentId 変更前の上位KGIのID
   */
  const handleChangeKgiTree = (materialityInfo, parentId, oldParentId) => {

    // 経営計画情報（下位から下位はコールバック元で入れ替え済）
    if (parentId && !oldParentId) {
      // 上位から下位へ変更した際に、コールバック元で上位KGIが消えないため削除
      businessPlanInfo.KgiTree = businessPlanInfo.KgiTree.filter(node => node.id !== materialityInfo.id);
    }
    if (!parentId && oldParentId) {
      // 下位から上位へ変更した際に、コールバック元で上位KGIが作られないため追加
      businessPlanInfo.KgiTree.push(materialityInfo);
    }
    // 変更内容を反映
    const changeValue = (tree) => {
      return tree.map(node => {
        if (node.id === materialityInfo.id) {
          return { ...materialityInfo }
        }
        return node;
      });
    };
    if (parentId) {
      const parent = businessPlanInfo.KgiTree.find(node => node.id === parentId);
      parent.Sub_Kgi = changeValue(parent.Sub_Kgi);
    } else {
      businessPlanInfo.KgiTree = changeValue(businessPlanInfo.KgiTree);
    }
    // KPIツリー
    const beforeData = kpiTreeInfo.map(node => {
      if (node.id === materialityInfo.id) {
        return {
          ...node,
          'text': materialityInfo.Title,
          'parent': parentId ? parentId : 'kpi_tree_root',
        }
      }
      return node;
    });
    let treeData = [];
    // 並び替え
    businessPlanInfo.KgiTree.forEach(tree => {
      treeData.push(beforeData.find(node => node.id === tree.id));
      treeData = treeData.concat(beforeData.filter(node => node.parent === tree.id && node.type === 'KPI'));
      tree.Sub_Kgi.forEach(sub => {
        treeData.push(beforeData.find(node => node.id === sub.id));
        treeData = treeData.concat(beforeData.filter(node => node.parent === sub.id && node.type === 'KPI'));
      });
    });
    setKpiTreeInfo(treeData);
  }

  /**
   * 経営戦略KGI / マテリアリティ設定の削除
   * @param {String} id ID
   * @param {String} parentId 上位KGIのID
   */
  const handleRemoveKgiTree = (id, parentId) => {

    // 経営計画情報
    if (parentId) {
      const parent = businessPlanInfo.KgiTree.find(node => node.id === parentId);
      parent.Sub_Kgi = parent.Sub_Kgi.filter(node => node.id !== id);
    } else {
      businessPlanInfo.KgiTree = businessPlanInfo.KgiTree.filter(node => node.id !== id);
    }
    // KPIツリー
    setKpiTreeInfo(kpiTreeInfo.filter(node => node.id !== id));
  }

  const _removeComma = (value_list) => {

    Object.keys(value_list).forEach((key) => {
      value_list[key].TargetValue = parseCommaDigit(value_list[key].TargetValue);
      value_list[key].ActualValue = parseCommaDigit(value_list[key].ActualValue);
    });
  }

  /**
   * 入力チェック
   */
  const _checkInput = () => {

    // 基本情報チェック
    if (checkBasicInfo(kpiTreeInfo, conitoringTreeInfo)) {
      setInputError({ errorMessage: '入力内容に誤りがあります。' });
      return true;
    }
    setInputError({});
    return false;
  }

  /**
   * 登録処理
   */
  const updateBusinessPlan = async () => {

    if (_checkInput()) {
      return;
    }
    try {
      // 日時取得
      const nowDate = formatDate(new Date());
      delete businessPlanInfo.DeleteFlg;
      // KGIツリー修正
      businessPlanInfo.KgiTree.forEach(kgi => {
        kgi.Kpi_Key = [];
        _removeComma(kgi.Value_List);
        kgi.Sub_Kgi.forEach(subKgi => {
          subKgi.Kpi_Key = [];
          _removeComma(subKgi.Value_List);
        });
      });
      // KPIツリーを反映  
      kpiTreeInfo.forEach(tree => {
        if (tree.type !== 'KPI') { return; }
        businessPlanInfo.KgiTree.forEach(kgi => {
          if (kgi.id === tree.parent) {
            kgi.Kpi_Key.push(tree.kpiKey);
          } else {
            const sub = kgi.Sub_Kgi.find(sub => sub.id === tree.parent);
            if (sub) {
              sub.Kpi_Key.push(tree.kpiKey);
            }
          }
        });
      });
      // 余分な項目を削除
      businessPlanInfo.KgiTree.forEach(kgi => {
        delete kgi.id;
        kgi.Sub_Kgi.forEach(subKgi => delete subKgi.id);
      });
      // 定常モニタリングKPIを反映 
      businessPlanInfo.ContinuousMonitoringKpi = conitoringTreeInfo.map(node => node.id);
      // カンマ値変換
      _removeComma(businessPlanInfo.KgiTree);
      // データ整形
      const businessPlan = {
        ...businessPlanInfo,
        ...getBasicInfo(),
        UpdateUserName: props.userName,
        UpdateDateTime: nowDate
      };

      // 更新
      await updateDynamoDB(
        table.BusinessPlan,
        'CorporationNumber',
        props.corporationNumber,
        {
          CorporationNumber: props.corporationNumber,
          Key: parseInt(sortKey)
        },
        businessPlan,
        props.userId
      );
      // 経営計画設定画面へ遷移
      props.navigate('/indicator/kgi/businessPlan',
        { state: { message: '経営計画を登録しました。' } }
      );
    } catch (error) {
      errorLog("E000001", props.userId, viewId.businessPlanEdit, "updateBusinessPlan", error);
    }
  }

  /**
   * 削除処理
   */
  const deleteBusinessPlan = async () => {

    const title = '経営計画設定の削除確認';
    const body = <React.Fragment>
      <div className="text-center">経営計画設定を削除してもよろしいでしょうか？</div>
    </React.Fragment>;
    const button = [{
      name: '削除',
      className: 'footer-btn button_delete',
      click: async () => {
        try {
          // 日時取得
          const nowDate = formatDate(new Date());
          businessPlanInfo.UpdateUserName = props.userName;
          businessPlanInfo.UpdateDateTime = nowDate;
          businessPlanInfo.DeleteFlg = 1;

          // 論理削除
          await updateDynamoDB(
            table.BusinessPlan,
            'CorporationNumber',
            props.corporationNumber,
            {
              CorporationNumber: props.corporationNumber,
              Key: parseInt(sortKey)
            },
            businessPlanInfo,
            props.userId
          );
          props.navigate('/indicator/kgi/businessPlan', { state: { message: '経営計画設定を削除しました。' } });
        } catch (error) {
          errorLog("E000001", props.userId, viewId.businessPlanEdit, "deleteBusinessPlan", error);
        }
      }
    }];
    props.showModal({ title: title, body: body, button: button, isWide: false });
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  // 人的資本KPI
  const treeViewKpi = {
    "rootId": "master_tree_root",
    "tree": masterTreeInfo,
    "canDrag": (node) => {
      return node.type;
    },
    "canDrop": () => {
      return false;
    },
    "nodeStyles": ""
  }
  // KPIツリー
  const treeViewKgi = {
    "rootId": "kpi_tree_root",
    "tree": kpiTreeInfo,
    "canDrag": (node) => {
      return node.type === 'KPI';
    },
    "canDrop": (tree, { dropTargetId, dropTarget, dragSourceId, dragSource }) => {
      // KGI以外へはドロップ不可
      if (dropTarget?.type !== 'KGI') {
        return false;
      }
      // 同じ階層に同じKPIがあればドロップ不可（ドロップ前の階層を除く）
      const id = dragSource.tree === 'kpi_tree'
        ? dropTargetId + dragSourceId.replace(dragSource.parent, '')
        : dropTargetId + '_' + dragSourceId;
      if (tree.some(node => node.id === id && dragSource.parent !== node.parent)) {
        return false;
      }
      return true
    },
    "onDrop": (tree, { dragSourceId, dragSource }) => {
      setKpiTreeInfo(tree.map(node => {
        if (node.id === dragSourceId) {
          const id = dragSource.tree === 'kpi_tree'
            ? node.parent + node.id.replace(dragSource.parent, '')
            : node.parent + '_' + node.id;
          return {
            ...node,
            'id': id,
            'tree': 'kpi_tree'
          }
        }
        return node;
      }));
    },
    "onDelete": (id) => {
      setKpiTreeInfo(kpiTreeInfo.filter(node => node.id !== id));
    },
    "nodeStyles": ""
  }
  // 定常モニタリングKPI
  const treeViewConitoringKpi = {
    "rootId": "conitoring_tree_root",
    "tree": conitoringTreeInfo,
    "canDrag": () => {
      return true;
    },
    "canDrop": (tree, { dragSource, dropTarget }) => {
      // 直下以外はドロップ不可
      if (dropTarget) {
        return false;
      }
      // KPIツリーからはドロップ不可
      if (dragSource.tree === 'kpi_tree') {
        return false;
      }
      return true;
    },
    "onDrop": (tree) => {
      setConitoringTreeInfo(tree);
    },
    "onDelete": (id) => {
      setConitoringTreeInfo(conitoringTreeInfo.filter(node => node.id !== id));
    },
    "nodeStyles": ""
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* 上階層に戻る */}
            <div className="bg main">
              <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/indicator/kgi/businessPlan">
                  <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                    <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                    <span className="text-h5 font-bold">{titleName.kgiList}に戻る</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{titleName.kgiInfo}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div className="form-control">
                {sortKey ? (
                  <React.Fragment>
                    <div>
                      <span style={{ color: "red" }}>{inputError.errorMessage}</span>
                    </div>
                    <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                      {/*  ===== 基本情報の設定 ===== */}
                      <div className="content-area">
                        <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">基本情報の設定</h2>
                        <div className="flex my-5">
                          <ul className="text-body-2-medium grow">
                            <li>経営戦略の概要を入力してください。</li>
                            <li>「S1 経営戦略KGI」画面には、状態が「公開」で最新の経営計画が表示されます。</li>
                          </ul>
                        </div>
                        <BasicInfo newMode={false} mode={props.mode} isParent={props.isParent} accPeriod={props.accPeriod} kpiStartYear={props.kpiStartYear} />
                      </div>
                      {/*  ===== 経営戦略KGI / マテリアリティ設定 ===== */}
                      <div className="content-area">
                        <MaterialityInfo accPeriod={props.accPeriod} kpiStartYear={props.kpiStartYear}
                          onAddMateriality={handleAddKgiTree}
                          onChangeMateriality={handleChangeKgiTree}
                          onRemoveMateriality={handleRemoveKgiTree}
                          authConf={authConf} />
                      </div>
                      {/*  ===== KPIツリーの設定 ===== */}
                      <div className="content-area">
                        <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 mt-10 -mx-16">KPIツリーの設定</h2>
                        <div className="flex my-5">
                          <ul className="text-body-2-medium grow">
                            <li>経営戦略KGI / マテリアリティとKPIの関連付けを行うには「人的資本KPI」（右）から項目をドラッグし、「KPIツリー」（左）内の対応する項目の上にドロップしてください。</li>
                          </ul>
                        </div>
                        {/* ===== KPIツリーの設定フォーム ===== */}
                        <table className="indicator-tree-table w-full">
                          <tbody>
                            <tr>
                              {/* <!-- KPIツリー「トップ」 --> */}
                              <td className="indicator-tree-table-td-top is-active is-target">
                                <div className="indicator-tree-table-td-top-inner pl-12 relative flex justify-center flex-col h-full">
                                  <div className="indicator-tree-name blue1--text font-bold text-h5">KPIツリー</div>
                                </div>
                              </td>
                              <td className="w-12"></td>
                              {/* <!-- 人的資本KPI「トップ」 --> */}
                              <td className="indicator-tree-table-td-top is-active">
                                <div className="indicator-tree-table-td-top-inner pl-12 relative flex justify-center flex-col h-full">
                                  <div className="indicator-tree-name blue1--text font-bold text-h5">人的資本KPI</div>
                                  <div className="indicator-tree-desc red--text text-caption-2">KPIツリー / 定常モニタリングKPIにドロップ</div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              {/* <!-- KPIツリー「ボトム」 --> */}
                              <td className="indicator-tree-table-td-bottom is-active is-target">
                                <div className="indicator-tree-item2">
                                  <div className="indicator-tree-content">
                                    <TreeView {...treeViewKgi} />
                                  </div>
                                </div>
                              </td>
                              {/* <!-- 矢印「←」画像 --> */}
                              <td className="!align-middle text-right">
                                <span className="icon-direction icon-40px rotate-180 inline-block -translate-y-2 is-active"></span>
                              </td>
                              {/* <!-- 人的資本KPI「ボトム」 --> */}
                              <td className="indicator-tree-table-td-bottom is-active" rowSpan="4">
                                <div className="indicator-tree-item">
                                  <div className="indicator-tree-content">
                                    <TreeView {...treeViewKpi} />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            {/* <!-- 定常モニタリングKPIの上の空白 --> */}
                            <tr><td colSpan="2" className="h-10"></td></tr>
                            <tr>
                              {/* <!-- 定常モニタリングKPI「トップ」 --> */}
                              <td className="indicator-tree-table-td-top">
                                <div className="indicator-tree-table-td-top-inner pl-12 relative flex justify-center flex-col h-full">
                                  <div className="indicator-tree-name blue1--text font-bold text-h5">定常モニタリングKPI</div>
                                </div>
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              {/* <!-- 定常モニタリングKPI「ボトム」 --> */}
                              <td className="indicator-tree-table-td-bottom">
                                <div className="indicator-tree-item3">
                                  <div className="indicator-tree-content">
                                    <TreeView {...treeViewConitoringKpi} />
                                  </div>
                                </div>
                              </td>
                              {/* <!-- 矢印「←」画像 --> */}
                              <td className="!align-middle text-right">
                                <span className="icon-direction icon-40px rotate-180 inline-block -translate-y-2 is-active"></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="content-footer mt-16 mb-8">
                        <div className="border-t border-blue1" />
                        {/*  ===== フッターボタン ===== */}
                        <div className="footer_button_area mt-8">
                          <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/indicator/kgi/businessPlan">
                            <button type="button" className="btn footer-btn button_back">キャンセル</button>
                          </Link>
                          <SpinnerButton name="登録" className={`btn footer-btn button_base ${authConf.Regist ? "" : "is-disabled"}`}
                            disabled={!authConf.Regist}
                            onClick={() => updateBusinessPlan()} />
                          <button type="button" className={`btn footer-btn button_delete ${authConf.Regist ? "" : "is-disabled"}`}
                            disabled={!authConf.Regist}
                            onClick={() => deleteBusinessPlan()}>削除</button>
                        </div>
                        {/*  ===== /フッターボタン ===== */}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                      <div className="content-area">
                        <h2 className="text-h4-em text-center text--lighten-70 mb-2">選択した経営計画設定はすでに削除されているか存在しません。</h2>
                        <h2 className="text-h4-em text-center text--lighten-70 mb-2">{titleName.kgiList}に戻って操作をやり直してください。</h2>
                      </div>
                      <div className="content-footer mt-16 mb-8">
                        <div className="border-t border-blue1" />
                        {/*  ===== フッターボタン ===== */}
                        <div className="footer_button_area mt-8">
                          <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/indicator/kgi/businessPlan">
                            <button type="button" className="btn footer-btn button_back">戻る</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  )
}

const BusinessPlanEdit = (props) => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const showModal = useModal();

  if (state.authority.Kgi_AuthInfo.Availability && state.authority.Kgi_AuthInfo.Auth_Conf.View) {
    return <BusinessPlanEditContent
      id={props.params.key}
      userId={state.user.sub}
      userName={state.user.userName}
      pageTitle={titleName.kgiInfo + titleName.common}
      corporationNumber={state.company.corporationNumber}
      isParent={state.company.isParent}
      accPeriod={state.company.accPeriod}
      kpiStartYear={state.company.kpiStartYear}
      mode={state.analysis.mode}
      navigate={navigate}
      showModal={showModal}
      authConf={state.authority.Kgi_AuthInfo.Auth_Conf}
    />;
  } else {
    return <Forbidden />
  }
};

export default (props) => <BusinessPlanEdit {...props} params={useParams()} />;
