"use strict";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { SpinnerButton } from "../common/CustomButton"
import { registDynamoDB, nextSequence } from "../../common/utils/DynamoDbUtils";
import { viewId, titleName, table } from "../../_constants/Code";
import { errorLog } from "../../common/logger/Logger";
import { formatDate } from "../../common/utils/DateUtils";
import BasicInfo, { clearCheck, checkBasicInfo, getBasicInfo } from "./parts/BasicInfo";
import Forbidden from "../../_components/Forbidden";

const BusinessPlanNewContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [inputError, setInputError] = React.useState({});
  const authConf = props.authConf;

  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    init();
  }, []);

  // 入力エラー時にスクロールをトップへ
  React.useEffect(() => {
    if (inputError['errorMessage']) {
      window.scrollTo(0, 0);
    }
  }, [inputError]);

  /**
   * 初期表示
   */
  const init = async () => {
    clearCheck();
    setIsLoaded(true);
  }

  /**
   * 入力チェック
   */
  const _checkInput = () => {

    // 基本情報チェック
    if (checkBasicInfo()) {
      setInputError({ errorMessage: '入力内容に誤りがあります。' });
      return true;
    }
    setInputError({});
    return false;
  }

  /**
   * 登録処理
   */
  const registBusinessPlan = async () => {

    if (_checkInput()) {
      return;
    }
    try {
      // シーケンス発行、日時取得
      const sequence = await nextSequence(table.BusinessPlan, props.corporationNumber, props.userId)
      const nowDate = formatDate(new Date());
      // データ整形
      const businessPlan = {
        Key: sequence.key,
        ...getBasicInfo(),
        KgiTree: [],
        ContinuousMonitoringKpi: [],
        CreateUserName: props.userName,
        CreateDateTime: nowDate,
        UpdateUserName: props.userName,
        UpdateDateTime: nowDate,
        DeleteFlg: 0,
      };

      // 登録
      await registDynamoDB(
        table.BusinessPlan,
        'CorporationNumber',
        props.corporationNumber,
        [businessPlan],
        props.userId
      );
      // 経営計画設定画面へ遷移
      props.navigate('/indicator/kgi/businessPlan/edit/' + sequence.key,
        { state: { message: '経営計画を登録しました。' } }
      );
    } catch (error) {
      errorLog("E000001", props.userId, viewId.businessPlanNew, "registBusinessPlan", error);
    }
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment >
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* 上階層に戻る */}
            <div className="bg main">
              <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/indicator/kgi/businessPlan">
                  <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                    <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                    <span className="text-h5 font-bold">{titleName.kgiList}に戻る</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{titleName.kgiInfo}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div className="form-control">
                <div>
                  <span style={{ color: "red" }}>{inputError.errorMessage}</span>
                </div>
                <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                  {/*  ===== 基本情報の設定 ===== */}
                  <div className="content-area">
                    <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">基本情報の設定</h2>
                    <div className="flex my-5">
                      <ul className="text-body-2-medium grow">
                        <li>経営戦略の概要を入力してください。</li>
                        <li>「S1 経営戦略KGI」画面には、状態が「公開」で最新の経営計画が表示されます。</li>
                        {props.isParent &&
                          <li><span className="red--text">※「対象」は一度登録すると変更できません。</span></li>
                        }
                      </ul>
                    </div>
                    <BasicInfo newMode={true} mode={props.mode} isParent={props.isParent} accPeriod={props.accPeriod} kpiStartYear={props.kpiStartYear} />
                  </div>
                  <div className="content-footer mt-16 mb-8">
                    <div className="border-t border-blue1" />
                    {/*  ===== フッターボタン ===== */}
                    <div className="footer_button_area mt-8">
                      <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/indicator/kgi/businessPlan">
                        <button type="button" className="btn footer-btn button_back">キャンセル</button>
                      </Link>
                      <SpinnerButton name="登録" className={`btn footer-btn button_base ${authConf.Regist ? "" : "is-disabled"}`}
                        disabled={!authConf.Regist}
                        onClick={() => registBusinessPlan()} />
                    </div>
                    {/*  ===== /フッターボタン ===== */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment >
  )
}

const BusinessPlanNew = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  if (state.authority.Kgi_AuthInfo.Availability && state.authority.Kgi_AuthInfo.Auth_Conf.View) {
    return <BusinessPlanNewContent
      userId={state.user.sub}
      userName={state.user.userName}
      pageTitle={titleName.kgiInfo + titleName.common}
      corporationNumber={state.company.corporationNumber}
      isParent={state.company.isParent}
      accPeriod={state.company.accPeriod}
      kpiStartYear={state.company.kpiStartYear}
      mode={state.analysis.mode}
      navigate={navigate}
      authConf={state.authority.Kgi_AuthInfo.Auth_Conf}
    />;
  } else {
    return <Forbidden />
  }
};

export default BusinessPlanNew;
