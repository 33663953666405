"use strict";

/**
 * 数値をカンマ区切りの数字に変換
 * @param {String} val 値
 * @param {Number} numDecPlaces 少数桁数
 * @returns 変換後数時
 */
export const formatCommaDigit = (val, numDecPlaces = 0) => {
    if (!val || val === "") {
        return val;
    }
    //入力値を数値だけ半角変換
    let halfWidthVal = String(val).replace(/[０-９]/g, function (x) {
        return String.fromCharCode(x.charCodeAt(0) - 0xfee0);
    });
    //カンマを除去（入力値が数値か否かを確認のため）
    let commaRemovedVal = halfWidthVal.replaceAll(",", "").replaceAll("、", "");
    if (isNaN(commaRemovedVal)) {
        //数値でなければ空白にする
        return "";
    } else {
        //入力値をカンマ入り数字テキストに再変換
        return new Intl.NumberFormat("ja-JP", {
            maximumFractionDigits: numDecPlaces,
            minimumFractionDigits: 0,  // 小数点以下がなければ表示しない
            roundingMode: "trunc", // 切り捨て
        }).format(commaRemovedVal);
    }
}

/**
 * カンマ区切りの数字を数値に変換
 * @param {String} val 値
 * @returns 変換後数値
 */
export const parseCommaDigit = (val) => {
    if (!val || val === "") {
        return "";
    }
    return val.replaceAll(",", "");
}

/**
 * KPI数値変換
 * @param {String} val 値
 * @param {String} unit KPI単位
 * @param {String} defaultVal 値無し時の返却値（省略時"-"）
 */
export const formatKpiNumber = (val, unit, defaultVal) => {
    if (val !== 0 && !val) {
        return defaultVal ? defaultVal : '-';
    }
    if (unit === 'yen') {
        const numLen = parseInt(val).toString().length;
        if (numLen <= 6) {
            // 6桁以内
            return '\xA5' + formatCommaDigit(Math.round(val * 0.001 * 10) / 10, 1) + 'K'
        } else if (numLen <= 9) {
            // 9桁以内
            return '\xA5' + formatCommaDigit(Math.round(val * 0.000001 * 10) / 10, 1) + 'M'
        } else if (numLen <= 23) {
            // 12桁以内
            return '\xA5' + formatCommaDigit(Math.round(val * 0.000000001 * 10) / 10, 1) + 'B'
        } else {
            // 13桁以上
            return '\xA5' + formatCommaDigit(Math.round(val * 0.000000000001 * 10) / 10, 1) + 'T'
        };
    }
    if (unit === 'hour') {
        return formatCommaDigit(Math.round(val * 10) / 10, 1) + 'h';
    }
    if (unit === 'percent') {
        return formatCommaDigit(Math.round(val * 10) / 10, 1) + '%';
    }
    if (unit === 'times') {
        return formatCommaDigit(val, 1) + '回';
    }
    if (unit === 'items') {
        return formatCommaDigit(val, 1) + '件';
    }
    if (unit === 'people') {
        return formatCommaDigit(val, 1) + '人';
    }
    return val;
}