"use strict";
import * as React from "react";
import { useParams } from "react-router-dom";

class StaticPageContent extends React.Component {
  constructor(props) {
    super(props);
    async function fetchData() {
      let url = null;
      switch (props.pageId) {
        case "1":
          url = process.env.REACT_APP_URL_TOS;
          break;
        case "2":
          url = process.env.REACT_APP_URL_PRIVACY_POLICY;
          break;
        default:
          null;
      }
      window.location.replace(url);
    }
    fetchData();
  }
  componentDidMount() {
  }
  render() {
  }
}

const StaticPage = (props) => {
  return (
    <StaticPageContent
      pageId={props.params.pageId}
    />
  );
};

export default (props) => <StaticPage {...props} params={useParams()} />;