import * as React from "react";
import { Auth } from "aws-amplify";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { viewId } from "../../_constants/Code";
import { errorLog } from "../../common/logger/Logger";

class LogoutMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount = async () => {
    await this.logout();
  };

  logout = async () => {
    try {
      this.props.dispatchLogout();
      const result = await Auth.signOut();
      this.setState({
        isLoaded: true,
      });
      return result;
    } catch (error) {
      errorLog("E000001", this.props.userId, viewId.logout, "logout", error);
    }
  };

  render() {
    if (!this.state.isLoaded) {
      return null;
    }
    return <Navigate to="/login" />;
  }
}

const Logout = () => {
  // ログアウト時に「leader-line-new」のLineを削除する
  const lines = document.querySelectorAll(".leader-line");
  if (lines) {
    lines.forEach(e => e.remove());
  }
  let userId = "";
  const state = useSelector((state) => state);
  if (state.user != null) {
    userId = state.user.sub;
  } else {
    userId = "someuser";
  }
  const dispatch = useDispatch();
  const dispatchLogout = () => {
    dispatch({
      type: "SET_LOGOUT",
    });
    localStorage.removeItem("chrofyLastActionTime");
  };
  return <LogoutMain dispatchLogout={() => dispatchLogout()} userId={userId} />;
};

export default Logout;
