"use strict";
import * as React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { kgiPageInfoList, mainPageInfoList } from "../../_constants/PageInfoList";
import { getPageText } from "../../common/utils/FileUtils";
import { formatCommaDigit, formatKpiNumber } from "../../common/utils/NumberUtils";
import { titleName, table, kgiUnit, analysisMode, operator } from "../../_constants/Code";
import { getDynamoDB, getKpi } from "../../common/utils/DynamoDbUtils";

/**
 * 10112_経営戦略KGI画面
 * @param {Object} props プロパティ値
 * @returns React.Fragment
 */
const KgiContent = (props) => {
  // 変数の初期化
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [pageText, setPageText] = React.useState();
  const [tabId, setTabId] = React.useState();
  const [mode, setMode] = React.useState();
  // マスタ情報
  const [masterKpiInfo, setMasterKpiInfo] = React.useState();
  const [kpiActualInfo, setKpiActualInfo] = React.useState();
  const [kpiTargetInfo, setKpiTargetInfo] = React.useState();
  // 経営計画
  const [businessPlanList, setBusinessPlanList] = React.useState([]);
  const [businessPlanInfo, setBusinessPlanInfo] = React.useState();
  // 画面情報
  const [yearList, setYearList] = React.useState([]);
  const [actualYear, setActualYear] = React.useState();
  const [targetYear, setTargetYear] = React.useState();
  const authConf = props.authConf.find(conf => conf.Key === 'S2');

  // 初期表示の処理
  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setMode(props.mode)
    // タイトル設定
    const pageText = await getPageText();
    document.title = pageText[props.kgiPage.key].name + titleName.common;
    setPageText(pageText);
    // 経営計画
    const dynamoDBData = await getDynamoDB(
      table.BusinessPlan, null, props.corporationNumber, null,
      { Mode: props.mode, Status: 'release', DeleteFlg: 0 }
    )
    dynamoDBData.sort((a, b) => {
      if (a.StartPeriod > b.StartPeriod) return -1;
      if (b.StartPeriod > a.StartPeriod) return 1;
      if (a.Key > b.Key) return -1;
      return 1;
    });
    setBusinessPlanList(dynamoDBData.map(d => {
      return {
        Key: d.Key,
        Title: d.Title
      }
    }));
    if (dynamoDBData.length) {
      await changeBusinessPnalInfo(dynamoDBData[0].Key);
    }
    // KPIマスタを取得
    setMasterKpiInfo(await getKpi(props.corporationNumber));
    setIsLoaded(true);
  }

  /**
   * 経営計画を変更
   * @param {String} key ソートキー
   */
  const changeBusinessPnalInfo = async (key) => {

    setTabId('KGI');
    // 経営計画を取得
    const dynamoDBData = await getDynamoDB(
      table.BusinessPlan, null, props.corporationNumber,
      { Key: parseInt(key) }
    );
    if (dynamoDBData.length) {
      setBusinessPlanInfo(dynamoDBData[0]);
      const list = [];
      const startPeriod = dynamoDBData[0].StartPeriod.slice(0, 4);
      const endPeriod = dynamoDBData[0].EndPeriod.slice(0, 4);
      setActualYear(startPeriod);
      setTargetYear(startPeriod);
      for (let i = startPeriod; i <= endPeriod; i++) {
        list.push(i);
        // 進行年度を判定
        if (i === new Date().getFullYear()) {
          setActualYear(i);
          setTargetYear(i);
        }
      }
      setYearList(list);
      // KPI実績値情報
      setKpiActualInfo(await getDynamoDB(
        table.KpiActualInfo, null, props.corporationNumber, null,
        { Mode: props.mode }
      ));
      // KPI目標値情報
      const kpiTargetDBData = await getDynamoDB(
        table.KpiTargetInfo, null, props.corporationNumber,
        { Mode: props.mode }
      );
      if (kpiTargetDBData.length) {
        setKpiTargetInfo(kpiTargetDBData[0]);
      }
    }
  }

  /**
   * 概要を開く／閉じる 切り替え
   * @param {Object} e イベント
   */
  const changeSummaryView = (e) => {
    const $el = e.target.closest('.kgi-summary');
    if ($el.classList.contains('is-active')) {
      $el.classList.remove('is-active');
    } else {
      $el.classList.add('is-active');
    }
  }

  /**
   * [React.Fragment] KGIカード情報[定量]エリア
   * @param {Object} props KGIオブジェクト
   * @returns React.Fragment
   */
  const KgiCardQuantitativeArea = (props) => {
    const kgiInfo = props.kgiInfo;
    // 実績値
    let actualValue = kgiInfo.Value_List[actualYear + '年'].ActualValue;
    if (actualYear === new Date().getFullYear()) {
      for (let i = actualYear; i >= businessPlanInfo.StartPeriod.slice(0, 4); i--) {
        const valList = kgiInfo.Value_List[i + '年'];
        const val = valList?.ActualValue;
        if (val) {
          actualValue = val;
          break;
        }
      }
    }
    // 目標値
    let targetValue = kgiInfo.Value_List[targetYear + '年'].TargetValue;
    if (targetYear === new Date().getFullYear()) {
      for (let i = targetYear; i >= businessPlanInfo.StartPeriod.slice(0, 4); i--) {
        const valList = kgiInfo.Value_List[i + '年'];
        const val = valList?.TargetValue;
        if (val) {
          targetValue = val;
          break;
        }
      }
    }
    // 達成度
    const achieveValue = actualValue && targetValue &&
      formatCommaDigit(String(actualValue / targetValue * 100), kgiUnit.percent.numDecPlaces);

    actualValue = formatCommaDigit(actualValue, kgiUnit[kgiInfo.Unit].numDecPlaces);
    targetValue = formatCommaDigit(targetValue, kgiUnit[kgiInfo.Unit].numDecPlaces);
    return (
      <React.Fragment>
        <div className="kgi-card__main grow">
          <div className="kgi-card__title py-4 px-6 flex gap-x-2.5 items-center">
            <div className="kgi-card__title-icon icon-32px kgi--text">
              <span className="icon-graph"></span>
            </div>
            <div className="kgi-card__title-text text-h4 font-bold">{kgiInfo.Title}</div>
          </div>
          <div className="kgi-card__title-sub text-caption-2 font-medium blue1--text pb-4 px-6">{kgiInfo.Description}</div>
          <div className="kgi-card__kpi pt-2 pb-2.5 pl-7 pr-6 flex gap-x-1 border-t border-blue2-lighten-20">
            <div className="kgi-card__title-icon flex flex gap-x-1 kpi--text">
              <span className="icon-person text-2xl leading-none"></span>
              <span className="text-caption-2 font-medium">KPI：</span>
            </div>
            <div className="kgi-card__title-title blue2--text text-caption-2 font-medium">
              {kgiInfo.Kpi_Key.map((key, idx) => {
                return (idx !== 0 ? "、" : "") + masterKpiInfo.find(m => m.Key === key)?.Name;
              })}
            </div>
          </div>
        </div>
        <div className="kgi-card__sub border-l border-blue2-lighten-20">
          <div className="kgi-card__value text-h7 font-bold px-4 py-2.5 blue1 lighten-7 border-b border-blue2-lighten-20">
            <span className="blue2--text">実績：</span>
            <span className={`grey--text ${actualValue ? "" : "text--lighten-45"}`}>
              {actualValue}{actualValue && kgiInfo.Unit !== 'none' && kgiUnit[kgiInfo.Unit].name}
              {!actualValue && '-'}
            </span>
          </div>
          <div className="kgi-card__value text-h7 font-bold px-4 py-2.5 border-l border-blue2-lighten-20 blue1 lighten-7 border-b border-blue2-lighten-20">
            <span className="blue2--text">目標：</span>
            <span className={`grey--text ${targetValue ? "" : "text--lighten-45"}`}>
              {targetValue}{targetValue && kgiInfo.Unit !== 'none' && kgiUnit[kgiInfo.Unit].name}
              {!targetValue && '未設定'}
            </span>
          </div>
          <div className="kgi-card__percent text-h2 font-bold self-center justify-self-center">
            {achieveValue ? achieveValue + "%" : "-"}
          </div>
        </div>
      </React.Fragment>
    )
  }

  /**
   * [React.Fragment] KGIカード情報[定性]エリア
   * @param {Object} props KGIオブジェクト
   * @returns React.Fragment
   */
  const KgiCardQualitativeArea = (props) => {
    const kgiInfo = props.kgiInfo;

    return (
      <React.Fragment>
        <div className="kgi-card__main grow">
          <div className="kgi-card__title py-4 px-6 flex gap-x-2.5 items-center">
            <div className="kgi-card__title-icon icon-32px kgi--text">
              <span className="icon-graph"></span>
            </div>
            <div className="kgi-card__title-text text-h4 font-bold">{kgiInfo.Title}</div>
          </div>
          <div className="kgi-card__title-sub text-caption-2 font-medium blue1--text pb-4 px-6">{kgiInfo.Description}</div>
          <div className="kgi-card__kpi pt-2 pb-2.5 pl-7 pr-6 flex gap-x-1 border-t border-blue2-lighten-20">
            <div className="kgi-card__title-icon flex flex gap-x-1 kpi--text">
              <span className="icon-person text-2xl leading-none"></span>
              <span className="text-caption-2 font-medium">KPI：</span>
            </div>
            <div className="kgi-card__title-title blue2--text text-caption-2 font-medium">
              {kgiInfo.Kpi_Key.map((key, idx) => {
                return (idx !== 0 ? "、" : "") + masterKpiInfo.find(m => m.Key === key)?.Name;
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  /**
   * [React.Fragment] KPIカード情報エリア
   * @param {Object} property KPIキー
   * @returns React.Fragment
   */
  const KpiCardArea = (property) => {
    const kpi = property.kpi;
    const master = masterKpiInfo.find(m => m.Key === kpi);
    const mIdx = master ? (master.Key.split(".")[0] - 1) : 0;
    const sIdx = master ? (master.Key.split(".")[1] - 1) : 0;
    const unit = master?.Unit;

    // KPIの有効判定;
    let active = props.kpiPage.dashboardList.some(list => {
      return list.dashboard.some(dashboard => {
        if (dashboard.key === 'S2.' + kpi) {
          if (businessPlanInfo.Mode === analysisMode.standalone && dashboard.activeFlg) {
            return true;
          }
          if (businessPlanInfo.Mode === analysisMode.consolidated && dashboard.groupFlg) {
            return true;
          }
        }
        return false;
      });
    });
    // 権限判定
    if (active) {
      active = authConf?.DashboardList.some(dashboard => {
        return dashboard.Dashboard.some(d => d === 'S2.' + kpi)
      })
    }

    // 目標値／実績値を抽出
    const actualValue = kpiActualInfo.find(info => {
      return info.KpiKey === kpi && info.AccountingYear === actualYear + '年'
    })?.ActualValue;
    const targetValue = kpiTargetInfo?.TargetValue.find(info => {
      return info.Key === kpi
    })?.Value[targetYear + '年']?.Value;

    // 差分
    const diffValue = (actualValue - targetValue);
    const sign = Math.sign(diffValue);
    return (
      <React.Fragment>
        <div className="kpi-card__title p-4 flex gap-x-2.5 items-center border-b border-blue2-lighten-20">
          <div className="kpi-card__title-icon icon-32px kpi--text">
            <span className="icon-person"></span>
          </div>
          {active && <React.Fragment>
            <Link underline="none" component={RouterLink}
              to={props.kpiPage.url + mIdx + "/" + sIdx} target="_blank">
              <div className="kpi-card__title-title text-h5 font-bold">
                {master?.Name}
              </div>
            </Link>
          </React.Fragment>}
          {!active && <React.Fragment>
            <div className="kpi-card__title-title text-h5 font-bold">
              {master?.Name}
            </div>
          </React.Fragment>}
        </div>
        <div className="kpi-card__body">
          <div className="kpi-card__value text-h7 font-bold pl-4 pr-0.5 py-2.5 lighten-7">
            <span className="blue2--text">実績：</span>
            <span className="grey--text">{formatKpiNumber(actualValue, unit)}</span>
          </div>
          <div className="kpi-card__value text-h7 font-bold pl-4 pr-0.5 py-2.5 border-l border-blue2-lighten-20 lighten-7">
            <span className="blue2--text">目標：</span>
            <span className="grey--text">{formatKpiNumber(targetValue, unit, '未設定')}</span>
          </div>
          {sign === 1 &&
            <div className={`kpi-card__percent text-h2 font-bold self-center justify-self-center px-4 pt-5 pb-6
             ${master?.Operator ? master?.Operator === operator.LE.value ? "is-not-good" : "is-good" : ""}`}>
              <span>+{formatKpiNumber(diffValue, unit)}</span>
            </div>
          }
          {sign === -1 &&
            <div className={`kpi-card__percent text-h2 font-bold self-center justify-self-center px-4 pt-5 pb-6
             ${master?.Operator ? master?.Operator === operator.LE.value ? "is-good" : "is-not-good" : ""}`}>
              <span>-{formatKpiNumber(Math.abs(diffValue), unit)}</span>
            </div>
          }
          {sign !== 1 && sign !== -1 &&
            <div className="kpi-card__percent text-h2 font-bold self-center justify-self-center px-4 pt-5 pb-6">
              <span>{formatKpiNumber(diffValue, unit)}</span>
            </div>
          }
        </div>
      </React.Fragment>
    )
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="category-page-header flex items-center my-6">
              <div className="category-page-header__img ml-4 mr-8"><img src={props.kgiPage.img} alt="" /></div>
              <div className="category-page-header__text pl-8 relative">
                <div className="category-page-header__subtitle flex items-center red--text">
                  <span className="category-page-header__subtitle-number text-h4-en">{props.kgiPage.key}</span>
                  <span className="category-page-header__subtitle-text text-h6-en ml-7">{pageText[props.kgiPage.key].nameEng}</span>
                </div>
                <h1 className="category-page-header__title">
                  <span className="category-page-header__title-text text-h2">{pageText[props.kgiPage.key].name}</span>
                  {mode === analysisMode.consolidated && <span className="grey--text text-lg">［連結］</span>}
                </h1>
                <p className="category-page-header__description text-caption-2 grey--text text--lighten-70">{pageText[props.kgiPage.key].explanation}</p>
              </div>
            </header>
            {/* コンテンツボディ */}
            <div className="page-body">
              {businessPlanList.length === 0 &&
                <div className="box-graph rounded-2xl white">
                  <div className="my-44 text-center">
                    <p className="text-body-2 font-bold red--text text-center">閲覧可能な経営戦略KGIはありません</p>
                    <p className="mt-4 text-caption-2 link--text text-center">※管理者による登録および公開が必要です。</p>
                  </div>
                </div>
              }
              {businessPlanList.length !== 0 &&
                <div className="box-graph rounded-2xl white">
                  {/* カテゴリータイトルとコントロール */}
                  <div className=" dashboard-title-area flex justify-between pl-12 pr-8">
                    <div className="dashboard-title pt-11">
                      <h2 className="dashboard-title__name text-h3 font-bold">{businessPlanInfo.Title}</h2>
                    </div>
                    <div className="form-control pt-8">
                      <div className="select-control">
                        <select className="form-select select-p-large rounded-lg text-h7 pr-10" style={{ width: "200px" }} value="none"
                          onChange={(e) => changeBusinessPnalInfo(e.target.value)}>
                          <option value="none">過去分を選択</option>
                          {businessPlanList.map((plan, idx) => {
                            if (plan.Key === businessPlanInfo.Key) { return }
                            return <option key={idx} value={plan.Key}>{plan.Title}</option>
                          })}
                        </select>
                        <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                          <span className="icon-arrow rotate-90"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" kgi-summary is-active">
                    {/* <!-- ===== サマリーコンテンツ ===== --> */}
                    <div className="kgi-summary__content">
                      <div className="kgi-summary__content-inner">
                        <div className="px-12 pb-8">
                          <p className="blue1--text text-h6 font-bold pt-3 mb-8">{businessPlanInfo.SubTitle}</p>
                          <div className="flex gap-x-4 items-start ">
                            <dl className="flex overflow-hidden rounded-md border-blue2 border text-extra-small font-bold text-nowrap">
                              <dt className="px-4 py-1 blue2 white--text">期間</dt>
                              <dd className="px-4 py-1 blue2--text">{businessPlanInfo.StartPeriod}{props.accPeriod}期 〜 {businessPlanInfo.EndPeriod}{props.accPeriod}期</dd>
                            </dl>
                            {props.isParent &&
                              <dl className="flex overflow-hidden rounded-md border-blue2 border text-extra-small font-bold text-nowrap">
                                <dt className="px-4 py-1 blue2 white--text">対象</dt>
                                {businessPlanInfo.Mode === analysisMode.standalone &&
                                  <dd className="px-4 py-1 red2--text">単体</dd>
                                }
                                {businessPlanInfo.Mode !== analysisMode.standalone &&
                                  <dd className="px-4 py-1 green2--text">連結</dd>
                                }
                              </dl>
                            }
                            <p className={`text-caption-2 font-medium ml-auto ${props.isParent ? "basis-2/3" : "pl-16 basis-5/6"}`}>
                              {businessPlanInfo.Explanation.split('\n').map((txt, idx) => {
                                return (
                                  <React.Fragment key={idx}>{txt}<br /></React.Fragment>
                                )
                              })}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- ===== /サマリーコンテンツ ===== --> */}
                    {/* <!-- ===== サマリー開閉ボタン ===== --> */}
                    <div className="kgi-summary__btn-wrapper px-12 flex justify-center relative">
                      <button type="button" className="kgi-summary__btn text-extra-small font-bold inline-flex gap-x-2 items-center leading-none px-4 white relative"
                        onClick={(e) => changeSummaryView(e)}>
                        <span className="icon-chevron-mark text-2xl leading-none"></span>
                        <span className="kgi-summary__btn-text--hide">概要を隠す</span>
                        <span className="kgi-summary__btn-text--open">概要を開く</span>
                      </button>
                    </div>
                    {/* <!-- ===== /サマリー開閉ボタン ===== --> */}
                  </div>
                  {/* <!-- ===== 表示対象年度（実績）・表示対象年度（目標）コントロール ===== --> */}
                  <div className=" pt-16 pb-7 flex justify-center gap-x-16">
                    <div className="box-graph__target-fiscal-year flex justify-center items-center">
                      <div className="text-h7 font-bold">表示対象年度（<span className="red--text">実績</span>）：</div>
                      {/* <!-- 年度選択 --> */}
                      <div className="form-control pl-4 pr-2">
                        <div className="select-control">
                          <select className="form-select select-p-large rounded-lg text-h7 pr-10"
                            value={actualYear} onChange={(e) => setActualYear(e.target.value)}>
                            {yearList.map((year, idx) => {
                              return <option key={idx} value={year}>{year}年{props.accPeriod}期</option>
                            })}
                          </select>
                          <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                            <span className="icon-arrow rotate-90"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-graph__target-fiscal-year flex justify-center items-center">
                      <div className="text-h7 font-bold">表示対象年度（<span className="red--text">目標</span>）：</div>
                      {/* <!-- 年度選択 --> */}
                      <div className="form-control pl-4 pr-2">
                        <div className="select-control">
                          <select className="form-select select-p-large rounded-lg text-h7 pr-10"
                            value={targetYear} onChange={(e) => setTargetYear(e.target.value)}>
                            {yearList.map((year, idx) => {
                              return <option key={idx} value={year}>{year}年{props.accPeriod}期</option>
                            })}
                          </select>
                          <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                            <span className="icon-arrow rotate-90"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- ===== /表示対象年度（実績）・表示対象年度（目標）コントロール ===== --> */}
                  {/* <!-- ===== タブ表示 ===== --> */}
                  <div className=" tabs pb-32">
                    <div className="tabs__btns grid grid-cols-2 justify-center gap-x-2 border-b border-blue1">
                      <button type="button" className={`tabs__btn text-h6 font-bold text-center px-2.5 py-7 rounded-t-lg ${tabId === 'KGI' ? 'is-active' : ''}`}
                        onClick={() => setTabId('KGI')}>
                        <span>経営戦略KGI / マテリアリティ</span>
                      </button>
                      <button type="button" className={`tabs__btn text-h6 font-bold text-center px-2.5 py-7 rounded-t-lg ${tabId === 'KPI' ? 'is-active' : ''}`}
                        onClick={() => setTabId('KPI')}>
                        <span>人的資本KPI</span>
                      </button>
                    </div>
                    <div className="tabs__contents">
                      {/* タブ：経営戦略KGI / マテリアリティ */}
                      <div className={`tabs__content tabs__content--kgi ${tabId !== 'KGI' ? "hidden" : ""}`}>
                        {businessPlanInfo.KgiTree.length > 0 &&
                          <div className="tabs__content-inner pt-8 pb-16 is-quantitative">
                            {/* <!-- ===== 定量（親） ===== --> */}
                            {businessPlanInfo.KgiTree.map((tree, idx) => {
                              return <React.Fragment key={idx}>
                                {/* 親KGI */}
                                {tree.Kind === '定量' &&
                                  <div className="kgi-card border border-kgi rounded-lg mt-16 first:mt-0 flex overflow-hidden is-parent is-start">
                                    <KgiCardQuantitativeArea kgiInfo={tree} />
                                  </div>
                                }
                                {tree.Kind === '定性' &&
                                  <div className="kgi-card border border-kgi rounded-lg mt-16 first:mt-0 overflow-hidden is-parent is-qualitative">
                                    <KgiCardQualitativeArea kgiInfo={tree} />
                                  </div>
                                }
                                {tree.Sub_Kgi.map((subTree, idx2) => {
                                  return <React.Fragment key={idx2}>
                                    {subTree.Kind === '定量' &&
                                      <div className="kgi-card border border-kgi rounded-lg mt-2 flex overflow-hidden is-child">
                                        <KgiCardQuantitativeArea kgiInfo={subTree} />
                                      </div>
                                    }
                                    {subTree.Kind === '定性' &&
                                      <div className="kgi-card border border-kgi rounded-lg mt-2 flex overflow-hidden is-child">
                                        <KgiCardQualitativeArea kgiInfo={subTree} />
                                      </div>
                                    }
                                  </React.Fragment>
                                })}
                              </React.Fragment>
                            })}
                          </div>
                        }
                        <hr className="mx-12 border-t border-blue2-lighten-20" />
                        <div className="tabs__content-inner pt-16">
                          {/* <!-- ===== 定常モニタリングKPIカード ===== --> */}
                          <div className="kgi-card border border-blue1 rounded-lg overflow-hidden is-parent">
                            <div className="kgi-card__main grow">
                              <div className="kgi-card__title blue1 py-4 px-6 flex gap-x-2.5 items-center">
                                <div className="kgi-card__title-icon icon-32px link--text text--extra-light">
                                  <span className="icon-monitoring"></span>
                                </div>
                                <div className="kgi-card__title-title white--text text-h4 font-bold">
                                  定常モニタリングKPI
                                </div>
                              </div>
                              <div className="kgi-card__kpi pt-2 pb-2.5 pl-7 pr-6 flex gap-x-1 border-t border-blue2-lighten-20">
                                <div className="kgi-card__title-icon flex flex gap-x-1 kpi--text">
                                  <span className="icon-person text-2xl leading-none"></span>
                                  <span className="text-caption-2 font-medium">KPI：</span>
                                </div>
                                <div className="kgi-card__title-title blue2--text text-caption-2 font-medium">
                                  {businessPlanInfo.ContinuousMonitoringKpi.map((key, idx) => {
                                    return (idx !== 0 ? "、" : "") + masterKpiInfo.find(m => m.Key === key)?.Name;
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- ===== /定常モニタリングKPIカード ===== --> */}
                        </div>
                      </div>
                      {/* タブ：人的資本KPI */}
                      <div className={`tabs__content tabs__content--kpi ${tabId !== 'KPI' ? "hidden" : ""}`}>
                        {businessPlanInfo.KgiTree.length > 0 &&
                          <div className="tabs__content-inner pt-8 pb-16">
                            <div className="tabs__kpi-cards grid grid-cols-4">
                              {businessPlanInfo.KgiTree.map((tree, idx) => {
                                // KPI
                                return <React.Fragment key={idx}>
                                  {tree.Kpi_Key.map((kpi, idx2) => {
                                    // カードスタイル
                                    let style = '';
                                    if (idx2 === 0) {
                                      style += ' is-start'
                                    }
                                    if (tree.Sub_Kgi.length === 0 && idx2 === tree.Kpi_Key.length - 1) {
                                      style += ' is-end'
                                    }
                                    return <React.Fragment key={idx2}>
                                      {/* <!-- ===== 定量（親） ===== --> */}
                                      <div className={`kpi-card rounded-lg is-parent ${style}`}>
                                        <div className="kpi-card__category__title rounded-t-lg text-h7 font-bold py-1 px-4 border-b border-blue2-lighten-20">
                                          <span className="relative z-10" title={tree.Title}>{tree.Title}</span>
                                        </div>
                                        <KpiCardArea kpi={kpi} />
                                      </div>
                                    </React.Fragment>
                                  })}
                                  {tree.Sub_Kgi.map((subTree, idx2) => {
                                    return <React.Fragment key={idx2}>
                                      {subTree.Kpi_Key.map((kpi, idx3) => {
                                        // カードスタイル
                                        let style = '';
                                        // 最後のスタイル
                                        if (idx2 === tree.Sub_Kgi.length - 1 && idx3 === subTree.Kpi_Key.length - 1) {
                                          style += ' is-end'
                                        }
                                        return <React.Fragment key={idx3}>
                                          {/* <!-- ===== 定量（子） ===== --> */}
                                          <div className={`kpi-card rounded-lg is-child ${style}`}>
                                            <div className="kpi-card__category rounded-t-lg text-h7 font-bold py-1 px-4 border-b border-blue2-lighten-20">
                                              <span className="relative z-10" title={`${tree.Title} / ${subTree.Title}`}>{tree.Title} / {subTree.Title}</span>
                                            </div>
                                            <KpiCardArea kpi={kpi} />
                                          </div>
                                        </React.Fragment>
                                      })}
                                    </React.Fragment>
                                  })}
                                </React.Fragment>
                              })}

                            </div>
                          </div>
                        }
                        <hr className="mx-12 border-t border-blue2-lighten-20" />
                        {/* <!-- ===== 定常モニタリングKPI ===== --> */}
                        <div className="tabs__content-inner pt-16">
                          <div className="blue1 py-4 px-6 mb-8 flex gap-x-2.5 items-center rounded-lg">
                            <div className="icon-32px link--text text--extra-light">
                              <span className="icon-monitoring"></span>
                            </div>
                            <div className="white--text text-h4 font-bold">定常モニタリングKPI</div>
                          </div>
                          <div className="tabs__kpi-cards grid grid-cols-4 is-bottom">
                            {businessPlanInfo.ContinuousMonitoringKpi.map((key, idx) => {
                              return <React.Fragment key={idx}>
                                <div className="kpi-card rounded-lg">
                                  <KpiCardArea kpi={key} />
                                </div>
                              </React.Fragment>
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </section>
      </main>
      {/* ========== /コンテンツ ========== */}
    </React.Fragment>
  )
};

const Kgi = () => {
  const state = useSelector((state) => state);
  const kgiPage = kgiPageInfoList.find(page => { return page.key === "S1"; });
  const kpiPage = mainPageInfoList.find(page => { return page.key === "S2"; });

  return <KgiContent
    userId={state.user.sub}
    kgiPage={kgiPage}
    kpiPage={kpiPage}
    corporationNumber={state.company.corporationNumber}
    isParent={state.company.isParent}
    accPeriod={state.company.accPeriod}
    kpiStartYear={state.company.kpiStartYear}
    mode={state.analysis.mode}
    authConf={state.authority.Dashboard_AuthInfo.Auth_Conf}
  />;
};

export default (props) => <Kgi {...props} params={useParams()} />;
