"use strict";
import * as React from "react";
import { CircularProgress } from "@mui/material"
import RefreshIcon from "@mui/icons-material/Refresh"

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * スピナーボタン
 * @param {Object} props プロパティ
 * @returns ボタン
 */
export const SpinnerButton = (props) => {
    const name = props.name;
    const className = props.className;
    const disabled = props.disabled;
    const onClick = props.onClick;
    // 処理中フラグ
    const isProsessing = React.useRef(false);
    // スピナー表示
    const [isSpinner, setIsSpinner] = React.useState(false);

    const handleClick = async (e) => {
        if (isProsessing.current) {
            return;
        }
        try {
            isProsessing.current = true;
            setIsSpinner(true);
            await sleep(200);
            await onClick(e);
        } finally {
            await sleep(300);
            isProsessing.current = false;
            setIsSpinner(false);
        }
    }

    return (
        <button type="button" className={className} disabled={disabled || isSpinner} onClick={(e) => handleClick(e)}>
            {isSpinner ? (
                <div style={{ position: 'relative' }}>
                    <span className="pr-3" style={{ position: 'absolute', left: '25px', top: '2px' }}>
                        <CircularProgress size={18} style={{ color: 'white' }} />
                    </span>
                    <span>処理中</span>
                </div>
            ) : (
                <span>{name}</span>
            )}
        </button>
    );
}

export const RefleshButton = (props) => {
    const name = props.name;
    const className = props.className;
    const disabled = props.disabled;
    const onClick = props.onClick;
    // 処理中フラグ
    const isProsessing = React.useRef(false);
    // アニメーション表示
    const [isAnimation, setIsAnimation] = React.useState(false);

    const handleClick = async (e) => {
        if (isProsessing.current) {
            return;
        }
        try {
            isProsessing.current = true;
            setIsAnimation(true);
            await sleep(200);
            await onClick(e);
        } finally {
            await sleep(300);
            isProsessing.current = false;
            setIsAnimation(false);
        }
    }

    return (
        <button type="button" className={className} disabled={disabled || isAnimation} onClick={(e) => handleClick(e)}>
            {name}<RefreshIcon className={isAnimation ? "reflesh-animation" : ""} />
        </button>
    );

}