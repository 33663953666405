"use strict";
import * as React from "react";

export const ToastContext = React.createContext(() => { });
ToastContext.displayName = 'ToastContext';

export const useToast = () => {
  return React.useContext(ToastContext);
}

export const ToastProvider = ({ children }) => {
  const [toastMessage, setToastMessage] = React.useState('');

  /**
   * トーストメッセージを表示する
   * @param {メッセージ } message 
   */
  const showToast = (message) => {
    setToastMessage(message);
    const boxToastTimeoutSec = 4000;
    _addToastClassName();
    setTimeout(() => {
      _removeToastClassName();
    }, boxToastTimeoutSec);
  }

  /**
   * トースト表示
   */
  const _addToastClassName = () => {
    const buttonMenu = document.getElementById("box-toast");
    buttonMenu.classList.add('is-active');
  };

  /**
   * トースト非表示
   */
  const _removeToastClassName = () => {
    const buttonMenu = document.getElementById("box-toast");
    buttonMenu.classList.remove('is-active');
  }

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <div id="box-toast" className="box-toast flex items-center pl-8 blue1 rounded-b-2xl fixed left-1/2 z-20">
        <div className="box-toast__text text-h6 white--text mr-1">{toastMessage}</div>
        <button type="button" className="box-toast__btn white--text p-5 leading-none" onClick={() => _removeToastClassName()}><span className="icon-close-s"></span></button>
      </div>
    </ToastContext.Provider>
  )
}