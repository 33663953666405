"use strict";
import * as React from "react";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";
import { viewId, titleName, table, analysisMode, kpiUnit, kpiOperator } from "../../_constants/Code";
import { getDynamoDB, getKpi as getKpiDB, registDynamoDB, updateDynamoDB } from "../../common/utils/DynamoDbUtils";
import { mainPageInfoList } from "../../_constants/PageInfoList";
import { errorLog } from "../../common/logger/Logger";
import { formatDate } from "../../common/utils/DateUtils";

import Forbidden from "../../_components/Forbidden";

import '../../assets/css/vendor/normalize.css'
import '../../assets/css/vendor/animate.min.css'
import '../../assets/css/vendor/hamburgers.css'
import '../../assets/icon/style.css'
import '../../assets/css/style.css'
import '../../assets/js/tailwindcss.js'
import '../../assets/js/adobe_fonts.js'
// import '../assets/js/kpi-target-setting.js'

let kpi = [];
let superKpi = [];
let childKpi = [];
let kpiYear = [];
let initMode = null;
const KpiContent = (props) => {
  const [kpiTgtInfo, setKpiTgtInfo] = React.useState([]);
  const [stdKpiTgtInfo, setStdKpiTgtInfo] = React.useState([]);
  const [consKpiTgtInfo, setConsKpiTgtInfo] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [tgtMode, setTgtMode] = React.useState();
  const isParent = props.isParent;
  const authority = props.authority;
  const S2PageKey = "S2";
  const page = mainPageInfoList.find(page => { return page.key === S2PageKey; });

  {/** kpi-target-setting.jsの処理を移行 開始 */}
  const get_fotmat_input_digit = (rawValue, fractionDigits) => {
    if (rawValue === "") {
      //ユーザーが空白にしたなら空白にして終了
      return "";
    }
    //入力値を数値だけ半角変換
    let halfWidthVal = rawValue.replace(/[０-９]/g, function (x) {
      return String.fromCharCode(x.charCodeAt(0) - 0xfee0);
    });
    //カンマを除去（入力値が数値か否かを確認のため）
    let commaRemovedVal = halfWidthVal.replaceAll(",", "").replaceAll("、", "");
    if (isNaN(commaRemovedVal)) {
      //数値でなければ空白にする
      return "";
    } else {
      //入力値をカンマ入り数字テキストに再変換
      return new Intl.NumberFormat("ja-JP", {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: 0,  // 小数点以下がなければ表示しない
        roundingMode: "trunc", // 切り捨て
      }).format(commaRemovedVal);
    }
  }

  // 入力時：テキスト入力フィールドのカンマ削除
  const focusKpiTgtValue = (e) => {
    // 入力値取得
    const val = e.target.value;
    // 未入力ならば処理をしない
    if (val == "") {
      return;
    }
    // カンマ除去
    let comma_removed_val = val.replaceAll(",", "");
    if (isNaN(comma_removed_val) || val.indexOf(",,") != -1) {
      //数値でなければ処理をしない
      return;
    }
    e.target.value = comma_removed_val;
  }

  // 入力完了時：テキスト入力フィールドのカンマ付加
  const blurKpiTgtValue = (cldKpi, year, e) => {
    // 未入力ならば処理をしない
    if (e.target.value == "") {
      return;
    }
    let val = get_fotmat_input_digit(e.target.value, kpiUnit[cldKpi.Unit].numDecPlaces);
    let vKpiTgtInfo = kpiTgtInfo.map((v) => {
      if (v.Key == cldKpi.Key) {
        v.Value[year].Value = val;
      }
      return v;
    });
    setKpiTgtInfo(vKpiTgtInfo);
    e.target.value = val;
  }

  const scrollLeft = (e) => {
    const $parent = e.target.closest('.setting-table');
    const $tableWrapper = $parent.querySelector('.setting-table__scroll');
    $tableWrapper.scrollBy({
      left: -130,
      behavior: "smooth",
    });
  }

  const scrollRight = (e) => {
    const $parent = e.target.closest('.setting-table');
    const $tableWrapper = $parent.querySelector('.setting-table__scroll');
    $tableWrapper.scrollBy({
      left: 130,
      behavior: "smooth",
    });
  }
  const toggleVisibility = () => {
    document.querySelectorAll('.setting-table__scroll').forEach(function (element) {
      element.addEventListener('scroll', (event) => {
        // 要素の取得
        const $parent = event.target.closest('.setting-table');
        const $tableWrapper = $parent.querySelector('.setting-table__scroll');
        const $btnScrollLeft = $parent.querySelector('.setting-table__btn-scroll-left');
        const $btnScrollRight = $parent.querySelector('.setting-table__btn-scroll-right');
        const isScrollEnd = $tableWrapper.scrollWidth - ($tableWrapper.scrollLeft + $tableWrapper.clientWidth) < 1;
    
        // ＜＞ボタンのdisabledの付け替え
        if ($tableWrapper.scrollLeft === 0) {
          // スクロール位置の左端
          if (!$btnScrollLeft.hasAttribute('disabled')) {
            $btnScrollLeft.setAttribute('disabled', '');
          }
        } else if ($tableWrapper.scrollLeft > 0 && !isScrollEnd) {
          // スクロール位置の中間
          if ($btnScrollLeft.hasAttribute('disabled')) {
            $btnScrollLeft.removeAttribute('disabled');
          }
          if ($btnScrollRight.hasAttribute('disabled')) {
            $btnScrollRight.removeAttribute('disabled');
          }
        } else if (isScrollEnd) {
          // スクロール位置の右端
          if (!$btnScrollRight.hasAttribute('disabled')) {
            $btnScrollRight.setAttribute('disabled', '');
          }
        }
      });
    });
  }
  {/** kpi-target-setting.jsの処理を移行 終了 */}

  const getKpiTgtInfo = async () => {
    try {
      let kpiTgtInfo = await getDynamoDB(
        table.KpiTargetInfo,
        null,
        props.corporationNumber,
      );
      return kpiTgtInfo;
    } catch (error) {
      errorLog("E000001", props.userId, viewId.kpiTargetSetting, "getKpiTgtInfo", error);
    }
  };

  const getTgtVal = (tgt) => {
    try {
      return childKpi.map((item) => {
        let value = {};
        let operator = kpiOperator.none;
        let tgtInfo = tgt.find(v => v.Key == item.Key);
        if (tgtInfo == undefined) {
          kpiYear.map((year) => {
            value[year] = {
              "Value": ""
            }
          });
        } else {
          operator = tgtInfo.Operator;
          kpiYear.map((year) => {
            value[year] = {
              "Value": get_fotmat_input_digit(tgtInfo.Value[year].Value, kpiUnit[item.Unit].numDecPlaces)
            }
          });
        }
        let rtnValue = {
          "Key": item.Key,
          "Operator": operator,
          "Value": value
        }
        return rtnValue;
      });
    } catch (error) {
      errorLog("E000001", props.userId, viewId.kpiTargetSetting, "getTgtVal", error);
    }
  }

  const setKpiTgt = async () => {
    try {
      let kpiTgtInfo = await getKpiTgtInfo();
      let stdKpiTgtInfo = kpiTgtInfo.filter(v => v.Mode == analysisMode.standalone);
      let consKpiTgtInfo = kpiTgtInfo.filter(v => v.Mode == analysisMode.consolidated);
      stdKpiTgtInfo = getTgtVal(stdKpiTgtInfo.length > 0 ? stdKpiTgtInfo[0].TargetValue : []);
      consKpiTgtInfo = getTgtVal(consKpiTgtInfo.length > 0 ? consKpiTgtInfo[0].TargetValue : []);
      setKpiTgtInfo(initMode == analysisMode.standalone ? stdKpiTgtInfo : consKpiTgtInfo);
      setStdKpiTgtInfo(stdKpiTgtInfo);
      setConsKpiTgtInfo(consKpiTgtInfo);
    } catch (error) {
      errorLog("E000001", props.userId, viewId.kpiTargetSetting, "setKpiTgt", error);
    }
  };

  const changeTgt = (mode) => {
    try {
      let vKpiTgtInfo = kpiTgtInfo;
      if (mode == analysisMode.standalone) {
        let vStdKpiTgtInfo = stdKpiTgtInfo;
        setConsKpiTgtInfo(vKpiTgtInfo);
        setKpiTgtInfo(vStdKpiTgtInfo);
        setTgtMode(analysisMode.standalone);
      } else if (mode == analysisMode.consolidated) {
        let vConsKpiTgtInfo = consKpiTgtInfo;
        setStdKpiTgtInfo(vKpiTgtInfo);
        setKpiTgtInfo(vConsKpiTgtInfo);
        setTgtMode(analysisMode.consolidated);
      }
    } catch (error) {
      errorLog("E000001", props.userId, viewId.kpiTargetSetting, "changeTgt", error);
    }
  }

  const changeOperetor = (cldKey, e) => {
    try {
      let vKpiTgtInfo = kpiTgtInfo.map((v) => {
        if (v.Key == cldKey) {
          v.Operator = e.target.value;
        }
        return v;
      });
      setKpiTgtInfo(vKpiTgtInfo);
    } catch (error) {
      errorLog("E000001", props.userId, viewId.kpiTargetSetting, "changeOperetor", error);
    }
  }

  const changeKpiTgtValue = (cldKpi, year, e) => {
    let vKpiTgtInfo = kpiTgtInfo.map((v) => {
      if (v.Key == cldKpi.Key) {
        v.Value[year].Value = e.target.value;
      }
      return v;
    });
    setKpiTgtInfo(vKpiTgtInfo);
  }

  const removeComma = (kpiTgtInfo) => {
    return kpiTgtInfo.map(kpiTgt => {
      for (let key in kpiTgt.Value) {
        kpiTgt.Value[key].Value = kpiTgt.Value[key].Value.replaceAll(",", "");
      }
      return kpiTgt
    });
  }

  const registKpi = async () => {
    try {
      let vdbKpiTgtInfo = await getKpiTgtInfo();
      let vdbStdKpiTgtInfo = vdbKpiTgtInfo.find(v => v.Mode == analysisMode.standalone);
      let vdbConsKpiTgtInfo = vdbKpiTgtInfo.find(v => v.Mode == analysisMode.consolidated);
      const nowDate = formatDate(new Date());
      let vKpiTgtInfo = removeComma(kpiTgtInfo);
      let vStdKpiTgtInfo = removeComma(stdKpiTgtInfo);
      // 単体
      if (vdbStdKpiTgtInfo == undefined) {
        // 登録
        const request = [
          {
            Mode: analysisMode.standalone,
            TargetValue: tgtMode == analysisMode.standalone ? vKpiTgtInfo : vStdKpiTgtInfo,
            CreateDateTime: nowDate,
            CreateUserId: props.userId,
            UpdateDateTime: nowDate,
            UpdateUserId: props.userId,
          },
        ];
        registDynamoDB(table.KpiTargetInfo, "CorporationNumber", props.corporationNumber, request, props.userId);
      } else {
        // 更新
        const pk = "CorporationNumber";
        const key = {
          CorporationNumber: props.corporationNumber,
          Mode: analysisMode.standalone,
        };
        const item = {
          TargetValue: tgtMode == analysisMode.standalone ? vKpiTgtInfo : vStdKpiTgtInfo,
          UpdateDateTime: nowDate,
          UpdateUserId: props.userId,
        };
        updateDynamoDB(table.KpiTargetInfo, pk, props.corporationNumber, key, item, props.userId);
      }
      // 連結
      if (isParent) {
        let vConsKpiTgtInfo = removeComma(consKpiTgtInfo);
        if (vdbConsKpiTgtInfo == undefined) {
          // 登録
          const request = [
            {
              Mode: analysisMode.consolidated,
              TargetValue: tgtMode == analysisMode.consolidated ? vKpiTgtInfo : vConsKpiTgtInfo,
              CreateDateTime: nowDate,
              CreateUserId: props.userId,
              UpdateDateTime: nowDate,
              UpdateUserId: props.userId,
            },
          ];
          registDynamoDB(table.KpiTargetInfo, "CorporationNumber", props.corporationNumber, request, props.userId);
        } else {
          // 更新
          const pk = "CorporationNumber";
          const key = {
            CorporationNumber: props.corporationNumber,
            Mode: analysisMode.consolidated,
          };
          const item = {
            TargetValue: tgtMode == analysisMode.consolidated ? vKpiTgtInfo : vConsKpiTgtInfo,
            UpdateDateTime: nowDate,
            UpdateUserId: props.userId,
          };
          updateDynamoDB(table.KpiTargetInfo, pk, props.corporationNumber, key, item, props.userId);
        }
      }
      const boxToastTimeoutSec = 4000;
      addToastClassName();
      setTimeout(() => {
        removeToastClassName();
      }, boxToastTimeoutSec);
    } catch (error) {
      errorLog("E000001", props.userId, viewId.kpiTargetSetting, "registKpi", error);
    }
  }

  const getKpi = async() => {
    kpi = await getKpiDB(props.corporationNumber);
  }

  const setKpi = async () => {
    await getKpi();
    superKpi =
      kpi
        .filter(v => v.SuperKey == "")
        .sort((a, b) => {
          a = Number(a.Key);
          b = Number(b.Key);
          return kpiSort(a, b);
        });
    childKpi =
      kpi
        .filter(v => v.SuperKey != "")
        .sort((a, b) => {
          a = Number(a.Key.split('.')[1]);
          b = Number(b.Key.split('.')[1]);
          return kpiSort(a, b);
        });
  }

  const setKpiYear = () => {
    let startYear = props.accPeriod != "12月" ? (Number(props.kpiStartYear.slice(0, 4)) + 1) : Number(props.kpiStartYear.slice(0, 4));
    for (let i = 0; i < 10; i++) {
      let year = (startYear + i) + props.kpiStartYear.slice(-1);
      kpiYear.push(year);
    }
  }

  const kpiSort = (a, b) => {
    return a < b ? -1 : 1;
  }

  const addToastClassName = () => {
    const buttonMenu = document.getElementById("box-toast");
    buttonMenu.classList.add('is-active');
  };

  const removeToastClassName = () => {
    const buttonMenu = document.getElementById("box-toast");
    buttonMenu.classList.remove('is-active');
  }

  const checkLinkStatus = (dashboardInfo) => {
    // ヘッダー部のモードと対象で選択されているモードが異なる場合はテキスト出力
    if (tgtMode != initMode) {
      return false;
    }
    // リリース準備中のダッシュボードはテキスト出力
    if (tgtMode == analysisMode.standalone && !dashboardInfo.activeFlg) {
      return false;
    }
    // 連結＆セグメントで表示しないダッシュボードはテキスト出力
    if (tgtMode == analysisMode.consolidated && !dashboardInfo.groupFlg) {
      return false;
    }
    // それ以外はリンク
    return true;
  }

  // 初期表示時、＜ボタンにdisabledをつける
  React.useEffect(() => {
    if (isLoaded) {
      const btnScrollLefts = document.querySelectorAll(".setting-table__btn-scroll-left");
      btnScrollLefts.forEach((v) => {
        v.setAttribute('disabled', '');
      });
    }
  }, [isLoaded]);

  // スクロールイベント登録
  React.useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  // 初期表示の処理
  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    window.scrollTo(0, 0);
    const fetchData = async () => {
      initMode = props.mode;
      setTgtMode(initMode)
      await setKpi();
      setKpiYear();
      await setKpiTgt();
      setIsLoaded(true);
    }
    fetchData();
  }, []);

  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <div id="box-toast" className="box-toast flex items-center pl-8 blue1 rounded-b-2xl fixed left-1/2 z-20">
        <div className="box-toast__text text-h6 white--text mr-1">目標設定を保存しました</div>
        <button type="button" className="box-toast__btn white--text p-5 leading-none" onClick={() => removeToastClassName()}><span className="icon-close-s"></span></button>
      </div>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">人的資本KPI目標設定</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div className="centered-container-1056">
                {/*  ===== リード ===== */}
                <p className="text-body text-center">
                  S2 人的資本KPIに表示するKPIの目標値を入力してください。
                </p>
                {/*  ===== /リード ===== */}
                <div className="box-bordered mt-10 mb-16 pt-12 pb-16">
                  {/*  ===== ご注意事項 ===== */}
                  <div className="flex justify-center mb-10">
                    <ul className="text-list w-2/3 grey--text text--lighten-70 ml-auto mr-auto text-center">
                      <li className="-indent-4 ml-4">※ 現在の会計年度を含め、10年分の目標値を設定できます。</li>
                      <li className="-indent-4 ml-4">※ 目標値は半角数字で入力してください。</li>
                      <li className="-indent-4 ml-4">※ 目標値の設定がない場合は目標値=0としてKPIダッシュボードに表示されます。</li>
                    </ul>
                  </div>
                  {/*  ===== /ご注意事項 ===== */}
                  {/*  ===== 対象 ===== */}
                  {/* 
                    ■単体/連携の選択ラジオのコンポーネントについて
                    単体・連携&セグメント分析を利用しない場合はdiv.box-xy-borderedにhiddenクラスを追加
                  */}
                  {/* ===== KPI設定 ===== */}
                  <div className="box-xy-bordered rounded-2xl mt-8 -mx-16 pt-10 pb-16 px-16">
                    {/* ===== 対象 ===== */}
                    <div className={`${isParent && authority.Company_AuthInfo.Availability ? "" : "hidden"}`}>
                      <div className="flex justify-center items-center">
                        <div className="text-h6 mr-12">対象</div>
                        <ul className="flex gap-x-8">
                          <li>
                            <label className="ui-radio single">
                              <input type="radio" className="ui-radio__input" name="target" value="single" defaultChecked={tgtMode == analysisMode.standalone} onChange={() => changeTgt(analysisMode.standalone)} />
                              <span className="ui-radio__icon"></span>
                              <span className="ui-radio__ripple"></span>
                              <span className="ui-radio__label text-h7 font-Regular">単体</span>
                            </label>
                          </li>
                          <li>
                            <label className="ui-radio multiple">
                              <input type="radio" className="ui-radio__input" name="target" value="multiple" defaultChecked={tgtMode == analysisMode.consolidated} onChange={() => changeTgt(analysisMode.consolidated)} />
                              <span className="ui-radio__icon"></span>
                              <span className="ui-radio__ripple"></span>
                              <span className="ui-radio__label text-h7 font-Regular">連結</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <p className="text-caption-2 mt-5 grey--text text--lighten-45 text-center">
                        「単体」を指定すると、画面の右上に表示されている会社が対象になります。
                      </p>
                      {/* ===== /対象 ===== */}
                      <hr className={`${!props.isParent ? "blue2 lighten-20" : initMode == analysisMode.standalone ? "kpisetting single" : "kpisetting multiple"} my-10`} />
                    </div>

                    {superKpi.filter(v => v.DeleteFlg == 0).map((supKpi, supIdx) => {
                      return (
                      <div key={supIdx}>
                        <h3 className="text-h5 mt-10 mb-2 font-bold">{supKpi.Key}. {supKpi.Name}</h3>
                        <div className="setting-table relative">
                          {/*  左にスクロールするボタン
                            非活性時はdisabled属性を追加
                          */}
                          <button type="button" className="setting-table__btn-scroll-left button w-8 h-8 flex items-center justify-center absolute" onClick={(e) => scrollLeft(e)}>
                            <span className="icon-arrow white--text rotate-180"></span>
                          </button>
                          {/*  右にスクロールするボタン
                            非活性時はdisabled属性を追加
                          */}
                          <button type="button" className="setting-table__btn-scroll-right button w-8 h-8 flex items-center justify-center absolute" onClick={(e) => scrollRight(e)}>
                            <span className="icon-arrow white--text"></span>
                          </button>
                          {/*  グラデーション1 */}
                          <div className="setting-table__gradation--left"></div>
                          {/*  グラデーション2 */}
                          <div className="setting-table__gradation--right"></div>
                          {/*  表組み */}
                          <div className="setting-table__scroll overflow-x-auto">
                            <table className="setting-table__table w-full">
                              <thead>
                                <tr>
                                  <th className="sticky top-0 left-0 z-10">設定単位</th>
                                  {kpiYear.map((item, idx) => {
                                    return <th key={idx}>{item}{props.accPeriod}期</th>
                                  })}
                                </tr>
                              </thead>
                              <tbody>
                                {childKpi.filter(v => v.SuperKey == supKpi.Key).map((cldKpi, cldIdx) => {
                                  let kpiTgt = kpiTgtInfo.find(v => v.Key == cldKpi.Key);
                                  let mIdx = (cldKpi.Key.split(".")[0] - 1);
                                  let sIdx = (cldKpi.Key.split(".")[1] - 1);
                                  let dashboardInfo = page.dashboardList[mIdx].dashboard[sIdx];
                                  let linkFlg = checkLinkStatus(dashboardInfo);
                                  let disabledFlg = tgtMode == analysisMode.consolidated && !dashboardInfo.activeFlg;
                                  let unit = kpiUnit[cldKpi.Unit].name;
                                  let availability = false;
                                  let authInfo = authority.Dashboard_AuthInfo;
                                  if (authInfo?.Availability) {  // ダッシュボードへの参照権限がある
                                    let dashboardAuthConf = authInfo.Auth_Conf.find(auth => auth.Key === S2PageKey);
                                    if (dashboardAuthConf?.Availability) {  // 大見出しレベルで参照権限がある
                                      let dashboardList = dashboardAuthConf.DashboardList.find(d => d.Key === S2PageKey + '.' + String(mIdx + 1));
                                      if (dashboardList?.Availability) {  // 中見出しレベルで参照権限がある
                                        let authDashboard = dashboardList.Dashboard.find(d => d === S2PageKey + '.' + cldKpi.Key);
                                        if (authDashboard) {  // 小見出しレベルで参照権限がある
                                          availability = true;
                                        }
                                      }
                                    }
                                  }
                                  return (
                                    <tr key={cldIdx}>
                                      <th className="sticky left-0 z-10 setting-table__col-name">
                                        <div className="flex items-center setting-heading">
                                          {(() => {
                                            if (linkFlg && availability) {
                                              return (
                                                <div className="setting-heading__name">
                                                  <Link underline="none" href={page.url + mIdx + "/" + sIdx} target="_blank">
                                                    {cldKpi.Key} {cldKpi.Name}
                                                  </Link>
                                                </div>
                                              );
                                            } else {
                                              return (
                                                <div className={`setting-heading__name ${disabledFlg || !availability ? "is-disabled" : ""}`}>
                                                  {cldKpi.Key} {cldKpi.Name}
                                                </div>
                                              )
                                            }
                                          })()}
                                          <div className="setting-heading__unit">{!disabledFlg ? unit : (unit == "" ? unit : "-")}</div>
                                          <div className="setting-heading__select">
                                            <div className="select-control">
                                              <select name="select_3_1" className="select-control__select rounded-full text-extra-small" value={kpiTgt.Operator} onChange={(e) => changeOperetor(cldKpi.Key, e)} disabled={disabledFlg}>
                                                <option value={kpiOperator.none}>{kpiOperator.none}</option>
                                                <option value={kpiOperator.more}>{kpiOperator.more}</option>
                                                <option value={kpiOperator.less}>{kpiOperator.less}</option>
                                              </select>
                                              <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                                                <span className={`icon-arrow rotate-90 ${!disabledFlg ? "" : "is-disabled"}`}></span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </th>
                                      {kpiYear.map((year, yearIdx) => {
                                        return (
                                          <td key={yearIdx}>
                                            <input className="text-input format-digit" type="text" name={"text_" + yearIdx} placeholder={!disabledFlg ? "数値を入力" : ""} value={kpiTgt.Value[year].Value} onChange={(e) => changeKpiTgtValue(cldKpi, year, e)} onFocus={(e) => focusKpiTgtValue(e)} onBlur={(e) => blurKpiTgtValue(cldKpi, year, e)} disabled={disabledFlg} />
                                          </td>
                                        )
                                      })}
                                    </tr>
                                )})}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      )
                    })}

                    <hr className={`${!props.isParent ? "blue2 lighten-20" : initMode == analysisMode.standalone ? "kpisetting single" : "kpisetting multiple"} mt-16 mb-8`} />

                    {/*  登録ボタン */}
                    <div className="text-center">
                      <button className="btn btn-register-kpi-target white--text grey rounded-lg px-16 text-h5 font-medium" disabled={!authority.Kpi_AuthInfo.Auth_Conf.Regist} onClick={() => {registKpi()}}>登録</button>
                    </div>
                  </div>
                  {/*  ===== /KPI設定 ===== */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </ React.Fragment>
  );
}

const KpiSetting = () => {
  const state = useSelector((state) => state);

  if (state.authority.Kpi_AuthInfo.Availability && state.authority.Kpi_AuthInfo.Auth_Conf.View) {
    return <KpiContent
      userId={state.user.sub}
      pageTitle={titleName.kpiSetting + titleName.common}
      corporationNumber={state.company.corporationNumber}
      accPeriod={state.company.accPeriod}
      kpiStartYear={state.company.kpiStartYear}
      isParent={state.company.isParent}
      mode={state.analysis.mode}
      authority={state.authority}
    />;
  } else {
    return <Forbidden />
  }
};

export default KpiSetting;
