"use strict";
import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Link, Checkbox, Switch, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CheckBoxRountedIcon from "@mui/icons-material/CheckBoxRounded";
import stringfy from "json-stable-stringify";
import { SpinnerButton } from "../common/CustomButton"
import { Roll, viewId, titleName, table, analysisMode } from "../../_constants/Code";
import { mainPageInfoList, monitoringInfoList } from "../../_constants/PageInfoList";
import { errorLog } from "../../common/logger/Logger";
import { getDynamoDB, registDynamoDB, updateDynamoDB, deleteDynamoDB, nextSequence } from "../../common/utils/DynamoDbUtils";
import Forbidden from "../../_components/Forbidden";
import { useModal } from "../../_components/Modal"
import { getMessage } from "../../common/utils/MessageUtils";
import { getPageText, getYamlObject, downloadGuide } from "../../common/utils/FileUtils";
import { formatDate, formatStringYm } from "../../common/utils/DateUtils";
import { mergeDeep } from "../../common/utils/ObjectUtils";
import { PathInfoList } from "../../_constants/PathInfoList";
import config from "../../_constants/Config";

// マスタ情報
let analysisTargetInfo = {};
let dashboardList = [];
let fileUploadTaskList = [];
// エラー項目
const errors = {
  errorMessage: null,
  authErrors: []
}

const AuthorityGroupContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [sortKey, setSortKey] = React.useState();
  // 画面入力情報
  const [copyName, setCopyName] = React.useState();
  const [authorityGroupInfo, setAuthorityGroupInfo] = React.useState();
  const [isOpenNode, setIsOpenNode] = React.useState();
  const [radioInfo, setRadioInfo] = React.useState({});
  const [inputError, setInputError] = React.useState({});

  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    init();
  }, []);

  // 入力エラー時にスクロールをトップへ
  React.useEffect(() => {
    if (inputError.errorMessage) {
      window.scrollTo(0, 0);
    }
  }, [inputError]);

  /**
   * 初期表示
   */
  const init = async () => {
    // 権限グループ初期設定
    let authorityGroupData = {
      AuthGroupName: '',
      AuthKind: props.isParent ? 'reference' : 'function',
      RefAuthInfo: _getInitRefAuthInfo(),
      AuthInfo: _getInitAuthInfo(),
      CreateDateTime: null,
      CreateUserName: props.userName,
      UpdateDateTime: null,
      UpdateUserName: props.userName,
    };
    if (props.id) {
      // 権限グループ情報を取得
      const dynamoDBData = isNaN(props.id) ? [] :
        await getDynamoDB(table.AuthorityGroup, null, props.corporationNumber,
          { Key: parseInt(props.id) }
        );
      if (dynamoDBData.length) {
        authorityGroupData = mergeDeep(authorityGroupData, dynamoDBData[0]);
        delete authorityGroupData.CorporationNumber;
        delete authorityGroupData.Key;
        if (!dynamoDBData[0].AuthKind) {
          authorityGroupData.AuthKind = 'function';
        }
        if (authorityGroupData.AuthKind === 'reference') {
          authorityGroupData.AuthInfo = _getInitAuthInfo();
        } else {
          authorityGroupData.RefAuthInfo = _getInitRefAuthInfo();
        }
        if (props.viewMode === 'copy') {
          // 複製時
          setCopyName(authorityGroupData.AuthGroupName);
          authorityGroupData.AuthGroupName = '';
        } else if (props.viewMode === 'change') {
          // 更新時
          setSortKey(props.id);
        }
      } else {
        authorityGroupData.Deleted = true;
      }
    }
    setAuthorityGroupInfo(authorityGroupData);
    // 会社情報を取得
    const analysisTargetData = await getDynamoDB(table.AnalysisTargetInfo,
      null, props.corporationNumber
    );
    if (analysisTargetData.length) {
      analysisTargetInfo = analysisTargetData[0]
      // セグメントをソートする
      analysisTargetInfo.TargetSegment = analysisTargetInfo.TargetSegment.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      // 新規作成時「グループ会社参照権限」はすべてチェックONにする
      if (props.viewMode === 'new') {
        const authInfo = authorityGroupData.RefAuthInfo.Company_AuthInfo;
        // 会社
        const set = new Set();
        analysisTargetInfo.Corporation.forEach(corp => {
          corp.CorporationList.forEach(node => set.add(node.Number));
        });
        authInfo.Company_List = Array.from(set);
        // セグメント
        const seg = analysisTargetInfo.TargetSegment.map(seg => seg.Code);
        authInfo.Segment_List = seg;
      }
    }
    // ダッシュボード情報を取得
    const pageText = await getPageText();
    dashboardList = _getDashboardList(pageText, mainPageInfoList);
    dashboardList = dashboardList.concat(_getDashboardList(pageText, monitoringInfoList));
    // アップロードファイル情報を取得
    const objectKeyName = PathInfoList.Folder.dataCreationFolder + PathInfoList.Objectkeyname.uploadTaskConfigFile;
    fileUploadTaskList = await getYamlObject(props.bucketName, objectKeyName);
    // 開閉ボタン初期化
    setIsOpenNode(_getOpenNode(authorityGroupData));
    // ラジオボタン情報初期化
    setRadioInfo(_getRadioInfo(authorityGroupData));
    setInputError(errors);
    setIsLoaded(true);
  }

  const _getInitRefAuthInfo = () => {
    return {
      Company_AuthInfo: {
        RefAuthKind: 'all',
        Company_List: [],
        Segment_List: [],
      }
    }
  }

  const _getInitAuthInfo = () => {
    return {
      Dashboard_AuthInfo: {
        Availability: false,
        Auth_Conf: [],
      },
      Kgi_AuthInfo: {
        Availability: false,
        Auth_Conf: {
          View: false,
          Regist: false
        },
      },
      Kpi_AuthInfo: {
        Availability: false,
        Auth_Conf: {
          View: false,
          Regist: false
        },
      },
      Upload_AuthInfo: {
        Availability: false,
        Auth_Conf: {
          View: false,
          Regist: false,
          Data: {
            Availability: false,
            Create: false,
            Lock: false,
            Unlock: false,
          },
          Uploadable_Monthly: {
            Availability: false,
            Files: [],
          },
          Uploadable_Quarter: {
            Availability: false,
            Files: [],
          },
          Uploadable_Year: {
            Availability: false,
            Files: [],
          },
          Uploadable_Accounting: {
            Availability: false,
            Standalone: false,
            Consolidated: false,
          }
        },
      },
      CategoryTag_AuthInfo: {
        Availability: false,
        Auth_Conf: {
          CategoryTag_Set: false
        },
      },
      Master_AuthInfo: {
        Availability: false,
        Auth_Conf: {
          Dashboard_Set: false,
          Segment_Set: false,
          Department_Set: false,
        },
      },
    }
  }

  const _getDashboardList = (pageText, pageInfo) => {
    return pageInfo.reduce((acc1, cur1) => {
      // S1は除く
      if (cur1.key.charAt(0) === 'S1') {
        return acc1;
      }
      const mainPage = pageText[cur1.key];
      acc1.push({
        key: cur1.key,
        name: mainPage.id + ' ' + mainPage.name,
        list: cur1.dashboardList.reduce((acc2, cur2) => {
          const subPage = mainPage.dashboardList.find(node => node.name.indexOf(cur2.key) === 0);
          const list = cur2.dashboard.reduce((acc3, cur3) => {
            // 非アクティブは除く
            if (!(cur3.activeFlg || cur3.groupFlg)) {
              return acc3;
            }
            const subSubPage = subPage.dashboard.find(node => node.name.indexOf(cur3.key) === 0);
            acc3.push({
              key: cur3.key,
              name: subSubPage.name
            });
            return acc3;
          }, []);
          // 小分類がなければ設定しない
          if (!list.length) {
            return acc2;
          }
          acc2.push({
            key: cur2.key,
            name: subPage.name,
            list: list
          });
          return acc2;
        }, [])
      });
      return acc1;
    }, []);
  }

  const _getOpenNode = (authorityGroupData) => {
    // 開閉ボタン初期化
    let isOpenNode = {};
    // ダッシュボード参照権限
    const Dashboard_AuthInfo = authorityGroupData.AuthInfo.Dashboard_AuthInfo;
    isOpenNode.dashboard = Dashboard_AuthInfo.Availability;
    dashboardList.map(dashboard1 => {
      // 大分類
      const authDashboard1 = Dashboard_AuthInfo.Auth_Conf.find(
        (node) => { return node.Key === dashboard1.key }
      );
      isOpenNode['dashboard_' + dashboard1.key] = authDashboard1 ? authDashboard1.Availability : false;
      dashboard1.list.map(dashboard2 => {
        // 中分類
        const authDashboard2 = authDashboard1?.DashboardList.find(
          (node) => { return node.Key === dashboard2.key }
        );
        isOpenNode['dashboard_' + dashboard2.key] = authDashboard2 ? authDashboard2.Availability : false;
        dashboard2.list.map(dashboard3 => {
          // 小分類
          const authDashboard3 = authDashboard2?.Dashboard.find(
            (node) => { return node === dashboard3.key }
          );
          isOpenNode['dashboard_' + dashboard3.key] = authDashboard3 ? true : false;
        });
      });
    });
    // 経営戦略KGI管理権限
    const Kgi_AuthInfo = authorityGroupData.AuthInfo.Kgi_AuthInfo;
    isOpenNode.kgi = Kgi_AuthInfo.Availability;
    // 人的資本KPI管理権限
    const Kpi_AuthInfo = authorityGroupData.AuthInfo.Kpi_AuthInfo;
    isOpenNode.kpi = Kpi_AuthInfo.Availability;
    // データアップロード権限
    const Upload_AuthInfo = authorityGroupData.AuthInfo.Upload_AuthInfo;
    isOpenNode.upload = Upload_AuthInfo.Availability;
    isOpenNode.upload_operation = true;
    isOpenNode.upload_monthly = true;
    isOpenNode.upload_quarter = true;
    isOpenNode.upload_year = true;
    // 用途タグ登録権限
    const CategoryTag_AuthInfo = authorityGroupData.AuthInfo.CategoryTag_AuthInfo;
    isOpenNode.category = CategoryTag_AuthInfo.Availability;
    // マスター登録権限
    const Master_AuthInfo = authorityGroupData.AuthInfo.Master_AuthInfo;
    isOpenNode.master = Master_AuthInfo.Availability;
    return isOpenNode
  }

  const _getRadioInfo = (authorityGroupData) => {
    // ラジオボタン初期化
    let radioInfo = {
      kgi: 'view',
      kpi: 'view',
      upload: 'view',
    }
    // 経営戦略KGI管理権限
    const Kgi_AuthInfo = authorityGroupData.AuthInfo.Kgi_AuthInfo;
    if (Kgi_AuthInfo.Auth_Conf.Regist) {
      radioInfo.kgi = 'regist';
    }
    // 人的資本KPI管理権限
    const Kpi_AuthInfo = authorityGroupData.AuthInfo.Kpi_AuthInfo;
    if (Kpi_AuthInfo.Auth_Conf.Regist) {
      radioInfo.kpi = 'regist';
    }
    // データアップロード権限
    const Upload_AuthInfo = authorityGroupData.AuthInfo.Upload_AuthInfo;
    if (Upload_AuthInfo.Auth_Conf.Data.Availability
      || Upload_AuthInfo.Auth_Conf.Uploadable_Monthly.Availability
      || Upload_AuthInfo.Auth_Conf.Uploadable_Quarter.Availability
      || Upload_AuthInfo.Auth_Conf.Uploadable_Year.Availability
      || Upload_AuthInfo.Auth_Conf.Uploadable_Accounting.Availability
    ) {
      radioInfo.upload = 'regist';
    }
    return radioInfo
  }

  /**
   * 権限グループ名変更
   */
  const changeAuthGroupName = (e) => {
    setAuthorityGroupInfo({ ...authorityGroupInfo, AuthGroupName: e.target.value });
  }

  /**
   * 権限種別変更
   */
  const changeAuthKind = (e) => {
    setAuthorityGroupInfo({ ...authorityGroupInfo, AuthKind: e.target.value });
  }

  /**
   * React.Fragment : グループ会社参照権限
   * @param {権限情報} props 
   */
  const CampanyContent = (props) => {
    const authInfo = props.authInfo;

    /**
     * 権限種別の値を変更する
     */
    const changeRefAuthKind = (e) => {
      authInfo.RefAuthKind = e.target.value;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * グループ会社参照権限．子会社の値を変更する
     * @param {イベント} e 
     */
    const changeCampanyAuthCompany = (e) => {
      let list = authInfo.Company_List;
      if (list.includes(e.target.value)) {
        list = list.filter(node => node !== e.target.value);
      } else {
        list.push(e.target.value);
      }
      authInfo.Company_List = list;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * グループ会社参照権限．カテゴリの値を変更する
     * @param {インデックス} idx
     */
    const changeCampanyAuthCategory = (corporationList) => {
      let list = authInfo.Company_List;
      const fil = corporationList.filter((child) => {
        return list.includes(child.Number);
      });
      if (fil.length) {
        list = list.filter((node) => {
          return !corporationList.some(corp => corp.Number === node);
        });
      } else {
        list = list.concat(corporationList.map(node => { return node.Number }));
      }
      authInfo.Company_List = list;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * グループ会社参照権限．セグメントの値を変更する
     * @param {イベント} e 
     */
    const changeCampanyAuthSegment = (e) => {
      let list = authInfo.Segment_List;
      if (list.includes(e.target.value)) {
        list = list.filter(node => node !== e.target.value);
      } else {
        list.push(e.target.value);
      }
      authInfo.Segment_List = list;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * すべて選択（会社）コンポーネント
     * @returns React.Fragment
     */
    const CheckboxCompanyAll = () => {

      /**
       * グループ会社参照権限．子会社の値をすべて変更する
       * @param {チェック状態} someCheck 
       */
      const changeCampanyAuthCompanyAll = (someCheck) => {
        if (someCheck) {
          authInfo.Company_List = [];
        } else {
          const set = new Set();
          analysisTargetInfo.Corporation.forEach(corp => {
            corp.CorporationList.forEach(node => set.add(node.Number));
          });
          authInfo.Company_List = Array.from(set);
        }
        setAuthorityGroupInfo({ ...authorityGroupInfo });
      }

      const someCheck = analysisTargetInfo.Corporation.some(corp => {
        return corp.CorporationList.some((node) => {
          return authInfo.Company_List.includes(node.Number);
        });
      });
      const isCheckAll = analysisTargetInfo.Corporation.every(corp => {
        return corp.CorporationList.every((node) => {
          return authInfo.Company_List.includes(node.Number);
        });
      });

      return (
        <React.Fragment>
          <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
            indeterminate={someCheck && !isCheckAll}
            checked={isCheckAll}
            onChange={(e) => changeCampanyAuthCompanyAll(someCheck)} />
          <span className="ui-checkbox__label text-h7 font-Regular">すべてを選択</span>
        </React.Fragment>
      )
    }

    /**
     * すべて選択（セグメント）コンポーネント
     * @returns React.Fragment
     */
    const CheckboxSegmentAll = () => {

      /**
       * グループ会社参照権限．セグメントの値をすべて変更する
       * @param {チェック状態} someCheck
       */
      const changeCampanyAuthSegmentAll = (someCheck) => {
        if (someCheck) {
          authInfo.Segment_List = [];
        } else {
          const seg = analysisTargetInfo.TargetSegment.map(seg => seg.Code);
          authInfo.Segment_List = seg;
        }
        setAuthorityGroupInfo({ ...authorityGroupInfo });
      }

      const someCheck = analysisTargetInfo.TargetSegment.some(seg => {
        return authInfo.Segment_List.includes(seg.Code);
      });
      const isCheckAll = analysisTargetInfo.TargetSegment.every(seg => {
        return authInfo.Segment_List.includes(seg.Code);
      });

      return (
        <React.Fragment>
          <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
            indeterminate={someCheck && !isCheckAll}
            checked={isCheckAll}
            onChange={(e) => changeCampanyAuthSegmentAll(someCheck)} />
          <span className="ui-checkbox__label text-h7 font-Regular">すべてを選択</span>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <dl className="collapse-node collapse-parent w-full is-open">
          <dt className="flex shrink-0 text-h5 px-3 pt-3">
            <label className="mb-3" style={{ "width": "280px" }}>グループ会社参照権限</label>
            <span className="mb-3">* ｢連結＆セグメント｣モードでデータ参照を可能とする会社／セグメントを設定できます</span>
          </dt>
          <dd className="content">
            <div className="text-h6 font-Regular my-3 ml-20">
              <span>* 会社とセグメントは、それぞれ1つ以上選択してください</span>
            </div>
            <div className="my-3 ml-20">
              <ul className="flex gap-x-8">
                <li>
                  <label className="ui-radio">
                    <input type="radio" className="radioBmAll ui-radio__input" name="refAuthKind" value="all"
                      defaultChecked={authInfo.RefAuthKind === 'all'} onChange={(e) => changeRefAuthKind(e)} />
                    <span className="ui-radio__icon"></span>
                    <span className="ui-radio__ripple"></span>
                    <span className="ui-radio__label text-h7 font-Regular">すべて</span>
                  </label>
                </li>
                <li>
                  <label className="ui-radio">
                    <input type="radio" className="radioBmAll ui-radio__input" name="refAuthKind" value="company"
                      defaultChecked={authInfo.RefAuthKind === 'company'} onChange={(e) => changeRefAuthKind(e)} />
                    <span className="ui-radio__icon"></span>
                    <span className="ui-radio__ripple"></span>
                    <span className="ui-radio__label text-h7 font-Regular">会社</span>
                  </label>
                </li>
                <li>
                  <label className="ui-radio">
                    <input type="radio" className="radioBmAll ui-radio__input" name="refAuthKind" value="segment"
                      defaultChecked={authInfo.RefAuthKind === 'segment'} onChange={(e) => changeRefAuthKind(e)} />
                    <span className="ui-radio__icon"></span>
                    <span className="ui-radio__ripple"></span>
                    <span className="ui-radio__label text-h7 font-Regular">セグメント</span>
                  </label>
                </li>
              </ul>
            </div>
            {authInfo.RefAuthKind === "company" && <>
              <dl className="collapse-node collapse-child ml-20 is-open">
                <dt className="flex shrink-0 px-3">
                  <span className="mt-2">会社選択</span>
                </dt>
                <dd className="px-10">
                  <div className="text-h6 font-Regular mt-3">
                    <span>* データの参照を可能とする会社を選択してください</span>
                  </div>
                  <ul className="flex flex-wrap">
                    <li>
                      <label className="ui-checkbox my-5">
                        <CheckboxCompanyAll />
                      </label>
                    </li>
                  </ul>
                  <div className="checkbox-group-node">
                    {analysisTargetInfo.Corporation.map((corp, idx) => {
                      const someCheck = corp.CorporationList.some((node) => {
                        return authInfo.Company_List.includes(node.Number);
                      });
                      const isCheckAll = corp.CorporationList.every((node) => {
                        return authInfo.Company_List.includes(node.Number);
                      });
                      return <React.Fragment key={idx}>
                        <div className="checkbox-group">
                          {corp.Category &&
                            <ul className="flex flex-wrap">
                              <li>
                                <label className="ui-checkbox mb-5 ml-10">
                                  <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                    indeterminate={someCheck && !isCheckAll}
                                    checked={isCheckAll}
                                    onChange={(e) => changeCampanyAuthCategory(corp.CorporationList)} />
                                  <span className="ui-checkbox__label text-h7 font-Regular">{corp.Category}</span>
                                </label>
                              </li>
                            </ul>
                          }
                          <ul className={corp.Category ? "gap-x-8 flex flex-wrap ml-10" : "gap-x-8 gap-y-6"}>
                            {corp.CorporationList.map((child, idx2) => {
                              return <React.Fragment key={idx2}>
                                <li>
                                  <label className="ui-checkbox mb-5 ml-10">
                                    <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                      value={child.Number}
                                      checked={authInfo.Company_List.includes(child.Number)}
                                      onChange={(e) => changeCampanyAuthCompany(e)} />
                                    <span className="ui-checkbox__label text-h7 font-Regular">{child.Name}</span>
                                  </label>
                                </li>
                              </React.Fragment>
                            })}
                          </ul>
                        </div>
                      </React.Fragment>
                    })}
                  </div>
                </dd>
              </dl>
            </>}
            {authInfo.RefAuthKind === "segment" && <>
              <dl className="collapse-node collapse-child ml-20 is-open">
                <dt className="flex shrink-0 px-3">
                  <span className="mt-2">セグメント選択</span>
                </dt>
                <dd className="px-10">
                  {analysisTargetInfo.TargetSegment.length === 0 ? (
                    <div className="my-5 ml-10">
                      <span className="text-h6 red--text">セグメントが未登録です。</span>
                    </div>
                  ) : 
                    <React.Fragment>
                      <div className="text-h6 font-Regular mt-3">
                        <span>* データの参照を可能とするセグメントを選択してください</span>
                      </div>
                      {analysisTargetInfo.TargetSegment.length === 1 ? (
                        <React.Fragment>
                          {(() => {
                            const seg = analysisTargetInfo.TargetSegment[0];
                            return <div className="my-5 ml-10">
                              <span className="text-h7 font-Regular">{seg.Name}</span>
                              {seg.SegmentValidEndMonth && <span className="badge-segment ml-3">{formatStringYm(seg.SegmentValidEndMonth)}まで</span>}
                            </div>
                          })()}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <ul className="flex flex-wrap">
                            <li>
                              <label className="ui-checkbox my-5">
                                <CheckboxSegmentAll />
                              </label>
                            </li>
                          </ul>
                          <ul className="flex flex-wrap">
                            {analysisTargetInfo.TargetSegment.map((seg, idx) => {
                              return <React.Fragment key={idx}>
                                <li>
                                  <label className="ui-checkbox mb-5 ml-10">
                                    <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                      value={seg.Code}
                                      checked={authInfo.Segment_List.includes(seg.Code)}
                                      onChange={(e) => changeCampanyAuthSegment(e)} />
                                    <span className="ui-checkbox__label text-h7 font-Regular">{seg.Name}</span>
                                    {seg.SegmentValidEndMonth && <span className="badge-segment ml-3">{formatStringYm(seg.SegmentValidEndMonth)}まで</span>}
                                  </label>
                                </li>
                              </React.Fragment>
                            })}
                          </ul>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  }
                </dd>
              </dl>
            </>}
          </dd>
        </dl>
      </React.Fragment>
    );
  }

  /**
   * React.Fragment : ダッシュボード参照権限
   * @param {権限情報} props 
   */
  const DashboardContent = (props) => {
    const authInfo = props.authInfo;

    /**
     * ダッシュボード参照権限の値を変更する
     * @param {イベント} e 
     */
    const changeDashboardAvailability = (e) => {
      authInfo.Availability = !authInfo.Availability;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
      setIsOpenNode({ ...isOpenNode, dashboard: authInfo.Availability });
    }

    /**
     * ダッシュボード参照権限．大分類の値を変更する
     * @param {イベント} e 
     */
    const changeDashboardAuthDashboard1 = (e, dashboard1) => {
      let dashboard = authInfo.Auth_Conf.find(
        (node) => { return node.Key === dashboard1.key }
      );
      if (dashboard) {
        dashboard.Availability = !dashboard.Availability;
      } else {
        dashboard = {
          Key: dashboard1.key,
          Availability: true,
          DashboardList: []
        };
        authInfo.Auth_Conf.push(dashboard);
      }
      setAuthorityGroupInfo({ ...authorityGroupInfo });
      const d = {}
      d['dashboard_' + dashboard1.key] = dashboard.Availability;
      setIsOpenNode({ ...isOpenNode, ...d });
    }

    /**
     * ダッシュボード参照権限．中分類の値を変更する
     * @param {イベント} e 
     */
    const changeDashboardAuthDashboard2 = (e, dashboard1, dashboard2) => {
      // 大分類
      let parent = authInfo.Auth_Conf.find(
        (node) => { return node.Key === dashboard1.key }
      );
      if (parent) {
        // 中分類
        let dashboard = parent.DashboardList.find(
          (node) => { return node.Key === dashboard2.key }
        );
        if (dashboard) {
          dashboard.Availability = !dashboard.Availability;
          if (dashboard.Availability) {
            dashboard.Dashboard = dashboard2.list.map(node => node.key);
          } else {
            dashboard.Dashboard = [];
          }
        } else {
          parent.DashboardList.push({
            Key: dashboard2.key,
            Availability: true,
            Dashboard: dashboard2.list.map(node => node.key)
          });
        }
      }
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * ダッシュボード参照権限．小分類の値を変更する
     * @param {イベント} e 
     */
    const changeDashboardAuthDashboard3 = (e, dashboard1, dashboard2, dashboard3) => {
      // 大分類
      let grandParent = authInfo.Auth_Conf.find(
        (node) => { return node.Key === dashboard1.key }
      );
      // 中分類
      let parent = grandParent.DashboardList.find(
        (node) => { return node.Key === dashboard2.key }
      );
      if (parent) {
        // 小分類
        if (parent.Dashboard.includes(dashboard3.key)) {
          parent.Dashboard = parent.Dashboard.filter(node => node !== dashboard3.key);
          if (!parent.Dashboard.length) {
            // 小分類が0件
            parent.Availability = false;
          }
        } else {
          grandParent.Availability = true;
          parent.Availability = true;
          parent.Dashboard.push(dashboard3.key);
        }
      } else {
        // 中分類がない未設定の場合
        grandParent.DashboardList.push({
          Key: dashboard2.key,
          Availability: true,
          Dashboard: [dashboard3.key]
        });
      }
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * ダッシュボード一覧ダウンロード処理
     * @param {イベント} e 
     */
    const fileDownload = (e) => {
      e.preventDefault();
      try {
        downloadGuide(process.env.REACT_APP_S3_WEB_CHROFY_COMMON,
          PathInfoList.Folder.guideFolder + PathInfoList.Objectkeyname.dashboardGuide);
      } catch (error) {
        errorLog("E000001", props.userId, viewId.footer, "fileDownload", error);
      }
    }

    return (
      <React.Fragment>
        <dl className={`collapse-node collapse-parent w-full ${isOpenNode.dashboard ? 'is-open' : ''}`}>
          <dt className="flex shrink-0 text-h5 px-3 pt-3">
            <label className="mb-3">
              <FormControlLabel label="ダッシュボード参照権限" sx={{ width: '280px' }}
                control={<Switch defaultChecked={authInfo.Availability}
                  onChange={(e) => changeDashboardAvailability(e)} />} />
            </label>
            <span className="mt-2">* 個々のダッシュボードの参照可否を設定できます</span>
            <button className="dropdown-button mt-2" disabled={!authInfo.Availability}
              onClick={(e) => setIsOpenNode({ ...isOpenNode, dashboard: !isOpenNode.dashboard })} />
          </dt>
          <dd className="content">
            <div className="text-h6 font-Regular flex mt-1 mb-2 ml-20 item-center">
              <span>* 参照可能なダッシュボードを選択してください</span>
              <Link color="inherit" className="text-link px-12" style={{ margin: "0 0 0 auto" }}
                href="#" onClick={(e) => fileDownload(e)}>ダッシュボード一覧ダウンロード</Link>
            </div>
            {dashboardList.map((dashboard1, idx) => {
              const authDashboard1 = authInfo.Auth_Conf.find(
                (node) => { return node.Key === dashboard1.key }
              )
              return <React.Fragment key={idx}>
                <dl className={`collapse-node collapse-child ml-20 ${isOpenNode['dashboard_' + dashboard1.key] ? 'is-open' : ''}`}>
                  <dt className="flex shrink-0 pl-3">
                    <FormControlLabel label={dashboard1.name}
                      control={<Switch defaultChecked={authDashboard1?.Availability}
                        onChange={(e) => changeDashboardAuthDashboard1(e, dashboard1)} />} />
                    <button className="dropdown-child-button mr-3"
                      disabled={!authDashboard1?.Availability}
                      onClick={(e) => {
                        const d = {}
                        d['dashboard_' + dashboard1.key] = !isOpenNode['dashboard_' + dashboard1.key];
                        setIsOpenNode({ ...isOpenNode, ...d });
                      }} />
                  </dt>
                  {dashboard1.list.map((dashboard2, idx2) => {
                    const authDashboard2 = authDashboard1?.DashboardList.find(
                      (node) => { return node.Key === dashboard2.key }
                    )
                    const someCheck = dashboard2.list.some((node) => {
                      return authDashboard2?.Dashboard.includes(node.key);
                    });
                    const isCheckAll = dashboard2.list.every((node) => {
                      return authDashboard2?.Dashboard.includes(node.key);
                    });
                    return <React.Fragment key={idx2}>
                      <dd className="px-10">
                        <ul className="flex flex-wrap">
                          <li>
                            <label className="ui-checkbox my-5">
                              <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                value={dashboard2.key}
                                indeterminate={someCheck && !isCheckAll}
                                checked={isCheckAll}
                                onChange={(e) => changeDashboardAuthDashboard2(e, dashboard1, dashboard2)} />
                              <span className="ui-checkbox__label text-h7 font-Regular">{dashboard2.name}</span>
                            </label>
                          </li>
                        </ul>
                        <ul className="flex flex-wrap">
                          {dashboard2.list.map((dashboard3, idx3) => {
                            const authDashboard3 = authDashboard2?.Dashboard.find(
                              (node) => { return node === dashboard3.key }
                            )
                            return <React.Fragment key={idx3}>
                              <li>
                                <label className="ui-checkbox mb-5 ml-10">
                                  <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                    value={dashboard3.key}
                                    checked={authDashboard3 === dashboard3.key}
                                    onChange={(e) => changeDashboardAuthDashboard3(e, dashboard1, dashboard2, dashboard3)} />
                                  <span className="ui-checkbox__label text-h7 font-Regular">{dashboard3.name}</span>
                                </label>
                              </li>
                            </React.Fragment>
                          })}
                        </ul>
                      </dd>
                    </React.Fragment>
                  })}
                </dl>
              </React.Fragment>
            })}
          </dd>
        </dl>
      </React.Fragment>
    );
  }

  /**
   * React.Fragment : 経営戦略KGI管理権限
   * @param {権限情報} props 
   */
  const KgiContent = (props) => {
    const authInfo = props.authInfo;

    /**
     * 経営戦略KGI管理権限の値を変更する
     * @param {イベント} e 
     */
    const changeKgiAvailability = (e) => {
      authInfo.Availability = !authInfo.Availability;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
      setIsOpenNode({ ...isOpenNode, kgi: authInfo.Availability });
    }

    /**
     * 経営戦略KGI管理権限．経営計画参照・登録の値を変更する
     * @param {イベント} e 
     */
    const changeKgiViewRegistAvailability = (e) => {
      setRadioInfo({ ...radioInfo, kgi: e.target.value });
    }

    /**
     * 経営戦略KGI管理権限．経営計画参照の値を変更する
     * @param {イベント} e 
     */
    const changeKgiViewAvailability = (e) => {
      authInfo.Auth_Conf.View = !authInfo.Auth_Conf.View;
      if (!authInfo.Auth_Conf.View) {
        authInfo.Auth_Conf.Regist = false;
      }
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * 経営戦略KGI管理権限．経営計画登録の値を変更する
     * @param {イベント} e 
     */
    const changeKgiRegistAvailability = (e) => {
      authInfo.Auth_Conf.Regist = !authInfo.Auth_Conf.Regist;
      if (authInfo.Auth_Conf.Regist) {
        authInfo.Auth_Conf.View = true;
      }
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    return (
      <React.Fragment>
        <dl className={`collapse-node collapse-parent w-full ${isOpenNode.kgi ? 'is-open' : ''}`}>
          <dt className="flex shrink-0 text-h5 px-3 pt-3">
            <label className="mb-3">
              <FormControlLabel label="経営戦略KGI管理権限" sx={{ width: '280px' }}
                control={<Switch defaultChecked={authInfo.Availability} onChange={(e) => changeKgiAvailability(e)} />} />
            </label>
            <span className="mt-2">* 経営計画の登録可否を設定できます</span>
            <button className="dropdown-button mt-2" disabled={!authInfo.Availability}
              onClick={(e) => setIsOpenNode({ ...isOpenNode, kgi: !isOpenNode.kgi })} />
          </dt>
          <dd className="content">
            <ul className="text-h6 font-Regular mt-3 ml-10">
              <li>* 経営計画（KGIツリー、常時モニタリングKPI）の設定、登録が可能になります。</li>
              <li>* ｢参照｣をチェックすると、経営戦略KGI管理メニューが表示されます。</li>
              <li>* ｢経営計画登録｣権限を付与すると、自動的に｢参照｣権限も付与されます。</li>
            </ul>
            <div className="flex">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="my-4 ml-20"
                value={radioInfo.kgi}
                onChange={(e) => changeKgiViewRegistAvailability(e)}
              >
                <FormControlLabel value="view" control={<Radio size="small" />} label={<span className="mr-20 text-h7 font-Regular">参照</span>} />
                <FormControlLabel value="regist" control={<Radio size="small" />} label={<span className="mr-20 text-h7 font-Regular">経営計画登録</span>} />
              </RadioGroup>
            </div>
          </dd>
        </dl>
      </React.Fragment>
    );
  }

  /**
   * React.Fragment : 人的資本KPI管理権限
   * @param {権限情報} props 
   */
  const KpiContent = (props) => {
    const authInfo = props.authInfo;

    /**
     * 人的資本KPI管理権限の値を変更する
     * @param {イベント} e 
     */
    const changeKpiAvailability = (e) => {
      authInfo.Availability = !authInfo.Availability;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
      setIsOpenNode({ ...isOpenNode, kpi: authInfo.Availability });
    }

    /**
     * 人的資本KPI管理権限．参照・登録の値を変更する
     * @param {イベント} e 
     */
    const changeKpiViewRegistAvailability = (e) => {
      setRadioInfo({ ...radioInfo, kpi: e.target.value });
    }

    /**
     * 人的資本KPI管理権限．参照の値を変更する
     * @param {イベント} e 
     */
    const changeKpiViewAvailability = (e) => {
      authInfo.Auth_Conf.View = !authInfo.Auth_Conf.View;
      if (!authInfo.Auth_Conf.View) {
        authInfo.Auth_Conf.Regist = false;
      }
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * 人的資本KPI管理権限．登録の値を変更する
     * @param {イベント} e 
     */
    const changeKpiRegistAvailability = (e) => {
      authInfo.Auth_Conf.Regist = !authInfo.Auth_Conf.Regist;
      if (authInfo.Auth_Conf.Regist) {
        authInfo.Auth_Conf.View = true;
      }
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    return (
      <React.Fragment>
        <dl className={`collapse-node collapse-parent w-full ${isOpenNode.kpi ? 'is-open' : ''}`}>
          <dt className="flex shrink-0 text-h5 px-3 pt-3">
            <label className="mb-3">
              <FormControlLabel label="人的資本KPI管理権限" sx={{ width: '280px' }}
                control={<Switch defaultChecked={authInfo.Availability} onChange={(e) => changeKpiAvailability(e)} />} />
            </label>
            <span className="mt-2">* 人的資本KPIの目標設定可否を設定できます</span>
            <button className="dropdown-button mt-2" disabled={!authInfo.Availability}
              onClick={(e) => setIsOpenNode({ ...isOpenNode, kpi: !isOpenNode.kpi })} />
          </dt>
          <dd className="content">
            <ul className="text-h6 font-Regular mt-3 ml-10">
              <li>* 人的資本KPIに対する目標設定の設定が可能になります。</li>
              <li>* ｢人的資本KPI目標設定｣権限を付与すると、自動的に｢参照｣権限も付与されます。</li>
            </ul>
            <div className="flex">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="my-4 ml-20"
                value={radioInfo.kpi}
                onChange={(e) => changeKpiViewRegistAvailability(e)}
              >
                <FormControlLabel value="view" control={<Radio size="small" />} label={<span className="mr-20 text-h7 font-Regular">参照</span>} />
                <FormControlLabel value="regist" control={<Radio size="small" />} label={<span className="mr-20 text-h7 font-Regular">人的資本KPI目標設定</span>} />
              </RadioGroup>
            </div>
          </dd>
        </dl>
      </React.Fragment>
    );
  }

  /**
   * React.Fragment : データアップロード権限
   * @param {権限情報} props 
   */
  const UploadContent = (props) => {
    const authInfo = props.authInfo;

    /**
     * データアップロード権限の値を変更する
     * @param {イベント} e 
     */
    const changeUploadAvailability = (e) => {
      authInfo.Availability = !authInfo.Availability;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
      setIsOpenNode({ ...isOpenNode, upload: authInfo.Availability });
    }

    /**
     * データアップロード権限．参照・更新の値を変更する
     * @param {イベント} e 
     */
    const changeUploadViewUploadDataAvailability = (e) => {
      setRadioInfo({ ...radioInfo, upload: e.target.value });
    }

    /**
     * データアップロード権限．データ作成の値を変更する
     * @param {イベント} e 
     */
    const changeUploadDataCreate = (e) => {
      authInfo.Auth_Conf.Data.Create = !authInfo.Auth_Conf.Data.Create;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * データアップロード権限．データ確定の値を変更する
     * @param {イベント} e 
     */
    const changeUploadDataLock = (e) => {
      authInfo.Auth_Conf.Data.Lock = !authInfo.Auth_Conf.Data.Lock;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * データアップロード権限．データ確定解除の値を変更する
     * @param {イベント} e 
     */
    const changeUploadDataUnlock = (e) => {
      authInfo.Auth_Conf.Data.Unlock = !authInfo.Auth_Conf.Data.Unlock;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * データアップロード権限．月次の値を変更する
     * @param {権限管理用キー} permissionKey
     */
    const changeUploadMonthlyFiles = (permissionKey) => {
      let list = authInfo.Auth_Conf.Uploadable_Monthly.Files;
      if (list.includes(permissionKey)) {
        list = list.filter(node => node !== permissionKey);
      } else {
        list.push(permissionKey);
      }
      authInfo.Auth_Conf.Uploadable_Monthly.Files = list;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * データアップロード権限．四半期の値を変更する
     * @param {権限管理用キー} permissionKey
     */
    const changeUploadQuarterFiles = (permissionKey) => {
      let list = authInfo.Auth_Conf.Uploadable_Quarter.Files;
      if (list.includes(permissionKey)) {
        list = list.filter(node => node !== permissionKey);
      } else {
        list.push(permissionKey);
      }
      authInfo.Auth_Conf.Uploadable_Quarter.Files = list;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * データアップロード権限．年次の値を変更する
     * @param {権限管理用キー} permissionKey
     */
    const changeUploadYearFiles = (permissionKey) => {
      let list = authInfo.Auth_Conf.Uploadable_Year.Files;
      if (list.includes(permissionKey)) {
        list = list.filter(node => node !== permissionKey);
      } else {
        list.push(permissionKey);
      }
      authInfo.Auth_Conf.Uploadable_Year.Files = list;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * データアップロード権限．財務データ次の値を変更する
     */
    const changeUploadAccountingFiles = (value) => {
      const accounting = authInfo.Auth_Conf.Uploadable_Accounting;
      if (value === analysisMode.standalone) {
        accounting.Standalone = !accounting.Standalone;
      } else {
        accounting.Consolidated = !accounting.Consolidated;
      }
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    return (
      <React.Fragment>
        <dl className={`collapse-node collapse-parent w-full ${isOpenNode.upload ? 'is-open' : ''}`}>
          <dt className="flex shrink-0 text-h5 px-3 pt-3">
            <label className="mb-3">
              <FormControlLabel label="データアップロード権限" sx={{ width: '280px' }}
                control={<Switch defaultChecked={authInfo.Availability} onChange={(e) => changeUploadAvailability(e)} />} />
            </label>
            <span className="mt-2">* 分析データ作成のためのデータファイルのアップロード可否／データの作成可否を設定できます</span>
            <button className="dropdown-button mt-2" disabled={!authInfo.Availability}
              onClick={(e) => setIsOpenNode({ ...isOpenNode, upload: !isOpenNode.upload })
              } />
          </dt>
          <dd className="content">
            <ul className="text-h6 font-Regular mt-3 ml-10">
              <li>* データファイルのアップロード、レポートデータの更新、確定／確定解除が可能になります。</li>
              <li>* ｢参照｣をチェックすると、データ更新メニューが表示されます。</li>
            </ul>
            <div className="flex">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="my-4 ml-20"
                value={radioInfo.upload}
                onChange={(e) => changeUploadViewUploadDataAvailability(e)}
              >
                <FormControlLabel value="view" control={<Radio size="small" />} label={<span className="mr-20 text-h7 font-Regular">参照</span>} />
                <FormControlLabel value="regist" control={<Radio size="small" />} label={<span className="mr-20 text-h7 font-Regular">更新</span>} />
              </RadioGroup>
            </div>
            <dl className={`collapse-node collapse-child ml-20 ${isOpenNode.upload_operation ? 'is-open' : ''}`}>
              <dt className="flex shrink-0 px-3">
                <span className="mt-2">データ更新／確定可否</span>
                <button className="dropdown-child-button" // disabled={!authInfo.Auth_Conf.Data?.Availability}
                  onClick={(e) => setIsOpenNode({ ...isOpenNode, upload_operation: !isOpenNode.upload_operation })} />
              </dt>
              <dd className="px-10">
                <div className="text-h6 font-Regular mt-3 mb-5">
                  <span>* データの更新・確定可否を選択してください。</span>
                </div>
                <div className="flex">
                  <label className="ui-checkbox mb-5 ml-10">
                    <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                      disabled={radioInfo.upload === 'view'}
                      checked={authInfo.Auth_Conf.Data.Create}
                      onChange={(e) => changeUploadDataCreate(e)} />
                    <span className="ui-checkbox__label text-h7 font-Regular">データ更新</span>
                  </label>
                  <label className="ui-checkbox mb-5 ml-10">
                    <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                      disabled={radioInfo.upload === 'view'}
                      checked={authInfo.Auth_Conf.Data.Lock}
                      onChange={(e) => changeUploadDataLock(e)} />
                    <span className="ui-checkbox__label text-h7 font-Regular">データ確定</span>
                  </label>
                  <label className="ui-checkbox mb-5 ml-10">
                    <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                      disabled={radioInfo.upload === 'view'}
                      checked={authInfo.Auth_Conf.Data.Unlock}
                      onChange={(e) => changeUploadDataUnlock(e)} />
                    <span className="ui-checkbox__label text-h7 font-Regular">データ確定解除</span>
                  </label>
                </div>
              </dd>
            </dl>
            <dl className={`collapse-node collapse-child ml-20 ${isOpenNode.upload_monthly ? 'is-open' : ''}`}>
              <dt className="flex shrink-0 px-3">
                <span className="mt-2">アップロード可能ファイル（月次）</span>
                <button className="dropdown-child-button" // disabled={!authInfo.Auth_Conf.Uploadable_Monthly?.Availability}
                  onClick={(e) => setIsOpenNode({ ...isOpenNode, upload_monthly: !isOpenNode.upload_monthly })} />
              </dt>
              <dd className="px-10">
                <div className="text-h6 font-Regular mt-3 mb-5">
                  <span>* アップロード可能なファイルを選択してください。</span>
                </div>
                <ul className="flex flex-wrap">
                  {fileUploadTaskList.monthly.FileUploadTask.map((task, idx) => {
                    return <React.Fragment key={idx}>
                      <li>
                        <label className="ui-checkbox mb-5 ml-10">
                          <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                            disabled={radioInfo.upload === 'view'}
                            checked={authInfo.Auth_Conf.Uploadable_Monthly?.Files.includes(task.PermissionKey)}
                            onChange={() => changeUploadMonthlyFiles(task.PermissionKey)} />
                          <span className="ui-checkbox__label text-h7 font-Regular">{task.FileKind}</span>
                        </label>
                      </li>
                    </React.Fragment>
                  })}
                </ul>
              </dd>
            </dl>
            <dl className={`collapse-node collapse-child ml-20 ${isOpenNode.upload_quarter ? 'is-open' : ''}`}>
              <dt className="flex shrink-0 px-3">
                <span className="mt-2">アップロード可能ファイル（四半期）</span>
                <button className="dropdown-child-button" // disabled={!authInfo.Auth_Conf.Uploadable_Quarter?.Availability}
                  onClick={(e) => setIsOpenNode({ ...isOpenNode, upload_quarter: !isOpenNode.upload_quarter })} />
              </dt>
              <dd className="px-10">
                <div className="text-h6 font-Regular mt-3 mb-5">
                  <span>* アップロード可能なファイルを選択してください。</span>
                </div>
                <ul className="flex flex-wrap">
                  {fileUploadTaskList.quarter.FileUploadTask.map((task, idx) => {
                    return <React.Fragment key={idx}>
                      <li>
                        <label className="ui-checkbox mb-5 ml-10">
                          <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                            disabled={radioInfo.upload === 'view'}
                            checked={authInfo.Auth_Conf.Uploadable_Quarter?.Files.includes(task.PermissionKey)}
                            onChange={() => changeUploadQuarterFiles(task.PermissionKey)} />
                          <span className="ui-checkbox__label text-h7 font-Regular">{task.FileKind}</span>
                        </label>
                      </li>
                    </React.Fragment>
                  })}
                </ul>
              </dd>
            </dl>
            <dl className={`collapse-node collapse-child ml-20 ${isOpenNode.upload_year ? 'is-open' : ''}`}>
              <dt className="flex shrink-0 px-3">
                <span className="mt-2">アップロード可能ファイル（年次）</span>
                <button className="dropdown-child-button" // disabled={!authInfo.Auth_Conf.Uploadable_Year?.Availability}
                  onClick={(e) => setIsOpenNode({ ...isOpenNode, upload_year: !isOpenNode.upload_year })} />
              </dt>
              <dd className="px-10">
                <div className="text-h6 font-Regular mt-3 mb-5">
                  <span>* アップロード可能なファイルを選択してください。</span>
                </div>
                <ul className="flex flex-wrap">
                  {fileUploadTaskList.year.FileUploadTask.map((task, idx) => {
                    return <React.Fragment key={idx}>
                      <li>
                        <label className="ui-checkbox mb-5 ml-10">
                          <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                            disabled={radioInfo.upload === 'view'}
                            checked={authInfo.Auth_Conf.Uploadable_Year?.Files.includes(task.PermissionKey)}
                            onChange={() => changeUploadYearFiles(task.PermissionKey)} />
                          <span className="ui-checkbox__label text-h7 font-Regular">{task.FileKind}</span>
                        </label>
                      </li>
                    </React.Fragment>
                  })}
                </ul>
              </dd>
            </dl>
            <dl className={`collapse-node collapse-child ml-20 ${isOpenNode.upload_year ? 'is-open' : ''}`}>
              <dt className="flex shrink-0 px-3">
                <span className="mt-2">財務データアップロード（月次／四半期／年次）</span>
                <button className="dropdown-child-button"
                  onClick={(e) => setIsOpenNode({ ...isOpenNode, upload_year: !isOpenNode.upload_year })} />
              </dt>
              <dd className="px-10">
                <div className="text-h6 font-Regular mt-3 mb-5">
                  <span>* 財務データのアップロード可否を選択してください。</span>
                </div>
                <ul className="flex flex-wrap">
                  <li>
                    <label className="ui-checkbox mb-5 ml-10">
                      <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                        disabled={(radioInfo.upload === 'view') || // 参照、もしくは月次・四半期・年次のすべてで財務データアップロードタスクがない場合
                          (!fileUploadTaskList.monthly?.AccountingUploadTask && !fileUploadTaskList.quarter?.AccountingUploadTask && !fileUploadTaskList.year?.AccountingUploadTask)}
                        checked={authInfo.Auth_Conf.Uploadable_Accounting?.Standalone}
                        onChange={() => changeUploadAccountingFiles(analysisMode.standalone)} />
                      <span className="ui-checkbox__label text-h7 font-Regular">単体</span>
                    </label>
                  </li>
                  <li>
                    <label className="ui-checkbox mb-5 ml-10">
                      <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                        disabled={!props.isParent || radioInfo.upload === 'view' || // 子会社がいない、もしくは参照、もしくは月次・四半期・年次のすべてで財務データアップロードタスクがない場合
                          (!fileUploadTaskList.monthly?.AccountingUploadTask && !fileUploadTaskList.quarter?.AccountingUploadTask && !fileUploadTaskList.year?.AccountingUploadTask)}
                        checked={authInfo.Auth_Conf.Uploadable_Accounting?.Consolidated}
                        onChange={() => changeUploadAccountingFiles(analysisMode.consolidated)} />
                      <span className="ui-checkbox__label text-h7 font-Regular">連結</span>
                    </label>
                  </li>
                </ul>
              </dd>
            </dl>
          </dd>
        </dl>
      </React.Fragment>
    );
  }

  /**
   * React.Fragment : 用途タグ登録権限
   * @param {権限情報} props 
   */
  const CategoryTagContent = (props) => {
    const authInfo = props.authInfo;

    /**
     * 用途タグ登録権限の値を変更する
     * @param {イベント} e 
     */
    const changeCategoryAvailability = (e) => {
      authInfo.Availability = !authInfo.Availability;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
      setIsOpenNode({ ...isOpenNode, category: authInfo.Availability });
    }

    /**
     * 用途タグ登録権限．用途タグ登録の値を変更する
     * @param {イベント} e 
     */
    const changeCategoryTagSet = (e) => {
      authInfo.Auth_Conf.CategoryTag_Set = !authInfo.Auth_Conf.CategoryTag_Set;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    return (
      <React.Fragment>
        <dl className={`collapse-node collapse-parent w-full ${isOpenNode.category ? 'is-open' : ''}`}>
          <dt className="flex shrink-0 text-h5 px-3 pt-3">
            <label className="mb-3">
              <FormControlLabel label="用途タグ登録権限" sx={{ width: '280px' }}
                control={<Switch defaultChecked={authInfo.Availability} onChange={(e) => changeCategoryAvailability(e)} />} />
            </label>
            <span className="mt-2">* 用途タグの登録可否を設定できます</span>
            <button className="dropdown-button mt-2" disabled={!authInfo.Availability}
              onClick={(e) => setIsOpenNode({ ...isOpenNode, category: !isOpenNode.category })} />
          </dt>
          <dd className="content">
            <div className="text-h6 font-Regular mt-3 ml-10">
              <span>* 用途タグの登録を許可する場合はチェックをONにしてください。</span>
            </div>
            <ul className="flex flex-wrap">
              <li>
                <label className="ui-checkbox my-5 ml-20">
                  <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                    checked={authInfo.Auth_Conf.CategoryTag_Set}
                    onChange={(e) => changeCategoryTagSet(e)} />
                  <span className="ui-checkbox__label text-h7 font-Regular">用途タグ登録</span>
                </label>
              </li>
            </ul>
          </dd>
        </dl>
      </React.Fragment>
    );
  }

  /**
   * React.Fragment : マスター管理権限
   * @param {権限情報} props 
   */
  const MasterContent = (props) => {
    const authInfo = props.authInfo;

    /**
     * マスター管理権限の値を変更する
     * @param {イベント} e 
     */
    const changeMasterAvailability = (e) => {
      authInfo.Availability = !authInfo.Availability;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
      setIsOpenNode({ ...isOpenNode, master: authInfo.Availability });
    }

    /**
     * マスター管理権限．ダッシュボード設定の値を変更する
     * @param {イベント} e 
     */
    const changeDashboardSet = (e) => {
      authInfo.Auth_Conf.Dashboard_Set = !authInfo.Auth_Conf.Dashboard_Set;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * マスター管理権限．セグメント情報の値を変更する
     * @param {イベント} e 
     */
    const changeSegmentSet = (e) => {
      authInfo.Auth_Conf.Segment_Set = !authInfo.Auth_Conf.Segment_Set;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    /**
     * マスター管理権限．組織情報の値を変更する
     * @param {イベント} e 
     */
    const changeDepartmentSet = (e) => {
      authInfo.Auth_Conf.Department_Set = !authInfo.Auth_Conf.Department_Set;
      setAuthorityGroupInfo({ ...authorityGroupInfo });
    }

    return (
      <React.Fragment>
        <dl className={`collapse-node collapse-parent w-full ${isOpenNode.master ? 'is-open' : ''}`}>
          <dt className="flex shrink-0 text-h5 px-3 pt-3">
            <label className="mb-3">
              <FormControlLabel label="マスター管理権限" sx={{ width: '280px' }}
                control={<Switch defaultChecked={authInfo.Availability} onChange={(e) => changeMasterAvailability(e)} />} />
            </label>
            <span className="mt-2">* マスターの登録可否を設定できます</span>
            <button className="dropdown-button mt-2" disabled={!authInfo.Availability}
              onClick={(e) => setIsOpenNode({ ...isOpenNode, master: !isOpenNode.master })} />
          </dt>
          <dd className="content">
            <div className="text-h6 font-Regular mt-3 ml-10">
              <span>* 登録を可能とする情報を選択してください。</span>
            </div>
            <ul className="flex flex-wrap">
              {/* <li>
                <label className="ui-checkbox my-5 ml-20">
                  <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                    checked={authInfo.Auth_Conf.Dashboard_Set}
                    onChange={(e) => changeDashboardSet(e)} />
                  <span className="ui-checkbox__label text-h7 font-Regular">ダッシュボード設定</span>
                </label>
              </li> */}
              {/* 親会社でない場合はセグメントを表示しない */}
              {props.isParent &&
                <li>
                  <label className="ui-checkbox my-5 ml-20">
                    <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                      checked={authInfo.Auth_Conf.Segment_Set}
                      onChange={(e) => changeSegmentSet(e)} />
                    <span className="ui-checkbox__label text-h7 font-Regular">セグメント情報</span>
                  </label>
                </li>
              }
              {!props.isParent &&
                <div className="text-center my-5 ml-20">
                  <span className="text-h5 red--text">登録可能なマスター情報がありません。</span>
                </div>
              }
              {/* <li>
                <label className="ui-checkbox my-5 ml-20">
                  <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                    checked={authInfo.Auth_Conf.Department_Set}
                    onChange={(e) => changeDepartmentSet(e)} />
                  <span className="ui-checkbox__label text-h7 font-Regular">組織情報</span>
                </label>
              </li> */}
            </ul>
          </dd>
        </dl>
      </React.Fragment>
    );
  }

  // 最上位の権限設定がOFFの場合は配下も全てOFFとする。配下がすべてOFFの場合は上位もOFFとする
  const _setAvailability = () => {
    if (authorityGroupInfo.AuthKind === 'reference') {
      // グループ会社参照権限
      const Company_AuthInfo = authorityGroupInfo.RefAuthInfo.Company_AuthInfo;
      if (Company_AuthInfo.RefAuthKind === 'all') {
        Company_AuthInfo.Company_List = [];
        Company_AuthInfo.Segment_List = [];
      }
      if (Company_AuthInfo.RefAuthKind === 'company') {
        Company_AuthInfo.Segment_List = [];
      }
      if (Company_AuthInfo.RefAuthKind === 'segment') {
        Company_AuthInfo.Company_List = [];
      }
      authorityGroupInfo.AuthInfo = {};
    } else {
      // ダッシュボート参照権限
      const Dashboard_AuthInfo = authorityGroupInfo.AuthInfo.Dashboard_AuthInfo;
      if (Dashboard_AuthInfo.Availability) {
        // 大分類の権限設定がOFFの場合は中分類をクリアする
        Dashboard_AuthInfo.Auth_Conf.forEach(d1 => {
          if (d1.Availability) {
            // 中分類がすべてOFFなら大分類もOFF
            if (d1.DashboardList.every(d => !d.Availability)) {
              d1.Availability = false;
            }
          } else {
            d1.DashboardList = [];
          }
        });
        // 大分類がすべてOFFなら参照権限もOFF
        if (Dashboard_AuthInfo.Auth_Conf.every(d => !d.Availability)) {
          Dashboard_AuthInfo.Availability = false;
        }
      } else {
        Dashboard_AuthInfo.Auth_Conf = [];
      }
      // 経営戦略KGI管理権限
      if (authorityGroupInfo.AuthInfo.Kgi_AuthInfo.Availability) {
        if (radioInfo.kgi === 'view') {
          authorityGroupInfo.AuthInfo.Kgi_AuthInfo.Auth_Conf.View = true;
          authorityGroupInfo.AuthInfo.Kgi_AuthInfo.Auth_Conf.Regist = false;
        } else {
          authorityGroupInfo.AuthInfo.Kgi_AuthInfo.Auth_Conf.View = true;
          authorityGroupInfo.AuthInfo.Kgi_AuthInfo.Auth_Conf.Regist = true;
        }
      } else {
        authorityGroupInfo.AuthInfo.Kgi_AuthInfo.Auth_Conf.View = false;
        authorityGroupInfo.AuthInfo.Kgi_AuthInfo.Auth_Conf.Regist = false;
      }
      // 人的資本KPI管理権限
      if (authorityGroupInfo.AuthInfo.Kpi_AuthInfo.Availability) {
        if (radioInfo.kpi === 'view') {
          authorityGroupInfo.AuthInfo.Kpi_AuthInfo.Auth_Conf.View = true;
          authorityGroupInfo.AuthInfo.Kpi_AuthInfo.Auth_Conf.Regist = false;
        } else {
          authorityGroupInfo.AuthInfo.Kpi_AuthInfo.Auth_Conf.View = true;
          authorityGroupInfo.AuthInfo.Kpi_AuthInfo.Auth_Conf.Regist = true;
        }
      } else {
        authorityGroupInfo.AuthInfo.Kpi_AuthInfo.Auth_Conf.View = false;
        authorityGroupInfo.AuthInfo.Kpi_AuthInfo.Auth_Conf.Regist = false;
      }
      // データアップロード／作成権限
      const Upload_AuthInfo = authorityGroupInfo.AuthInfo.Upload_AuthInfo;
      if (Upload_AuthInfo.Availability) {
        Upload_AuthInfo.Auth_Conf.View = true;
        if (radioInfo.upload === 'regist') {
          Upload_AuthInfo.Auth_Conf.Regist = true;
          // データ作成／確定可否
          const Data = Upload_AuthInfo.Auth_Conf.Data;
          if (Data.Create || Data.Lock || Data.Unlock) {
            Data.Availability = true;
          } else {
            Data.Availability = false;
          }
          // アップロード可能ファイル（月次）
          const Monthly = Upload_AuthInfo.Auth_Conf.Uploadable_Monthly;
          if (Monthly.Files.length) {
            Monthly.Availability = true;
          } else {
            Monthly.Availability = false;
          }
          // アップロード可能ファイル（四半期）
          const Quarter = Upload_AuthInfo.Auth_Conf.Uploadable_Quarter;
          if (Quarter.Files.length) {
            Quarter.Availability = true;
          } else {
            Quarter.Availability = false;
          }
          // アップロード可能ファイル（年次）
          const Year = Upload_AuthInfo.Auth_Conf.Uploadable_Year;
          if (Year.Files.length) {
            Year.Availability = true;
          } else {
            Year.Availability = false;
          }
          // アップロード可能ファイル（財務）
          const Accounting = Upload_AuthInfo.Auth_Conf.Uploadable_Accounting;
          if (Accounting.Standalone || Accounting.Consolidated) {
            Accounting.Availability = true;
          } else {
            Accounting.Availability = false;
          }
          // 大分類がすべてOFFなら参照権限もOFF
          if (!Data.Availability && !Monthly.Availability && !Quarter.Availability && !Year.Availability && !Accounting.Availability) {
            Upload_AuthInfo.Availability = false;
          }
        } else {
          Upload_AuthInfo.Auth_Conf.Regist = false;
          Upload_AuthInfo.Auth_Conf.Data.Availability = false;
          Upload_AuthInfo.Auth_Conf.Data.Create = false;
          Upload_AuthInfo.Auth_Conf.Data.Lock = false;
          Upload_AuthInfo.Auth_Conf.Data.Unlock = false;
          Upload_AuthInfo.Auth_Conf.Uploadable_Monthly.Availability = false;
          Upload_AuthInfo.Auth_Conf.Uploadable_Monthly.Files = [];
          Upload_AuthInfo.Auth_Conf.Uploadable_Quarter.Availability = false;
          Upload_AuthInfo.Auth_Conf.Uploadable_Quarter.Files = [];
          Upload_AuthInfo.Auth_Conf.Uploadable_Year.Availability = false;
          Upload_AuthInfo.Auth_Conf.Uploadable_Year.Files = [];
          Upload_AuthInfo.Auth_Conf.Uploadable_Accounting.Availability = false;
          Upload_AuthInfo.Auth_Conf.Uploadable_Accounting.Standalone = false;
          Upload_AuthInfo.Auth_Conf.Uploadable_Accounting.Consolidated = false;
        }
      } else {
        Upload_AuthInfo.Auth_Conf.View = false;
        Upload_AuthInfo.Auth_Conf.Regist = false;
        Upload_AuthInfo.Auth_Conf.Data.Availability = false;
        Upload_AuthInfo.Auth_Conf.Data.Create = false;
        Upload_AuthInfo.Auth_Conf.Data.Lock = false;
        Upload_AuthInfo.Auth_Conf.Data.Unlock = false;
        Upload_AuthInfo.Auth_Conf.Uploadable_Monthly.Availability = false;
        Upload_AuthInfo.Auth_Conf.Uploadable_Monthly.Files = [];
        Upload_AuthInfo.Auth_Conf.Uploadable_Quarter.Availability = false;
        Upload_AuthInfo.Auth_Conf.Uploadable_Quarter.Files = [];
        Upload_AuthInfo.Auth_Conf.Uploadable_Year.Availability = false;
        Upload_AuthInfo.Auth_Conf.Uploadable_Year.Files = [];
        Upload_AuthInfo.Auth_Conf.Uploadable_Accounting.Availability = false;
        Upload_AuthInfo.Auth_Conf.Uploadable_Accounting.Standalone = false;
        Upload_AuthInfo.Auth_Conf.Uploadable_Accounting.Consolidated = false;
      }
      // 用途タグ設定権限
      if (authorityGroupInfo.AuthInfo.CategoryTag_AuthInfo.Availability) {
        if (!authorityGroupInfo.AuthInfo.CategoryTag_AuthInfo.Auth_Conf.CategoryTag_Set) {
          authorityGroupInfo.AuthInfo.CategoryTag_AuthInfo.Availability = false;
        }
      } else {
        authorityGroupInfo.AuthInfo.CategoryTag_AuthInfo.Auth_Conf.CategoryTag_Set = false;
      }
      // マスター管理権限
      if (authorityGroupInfo.AuthInfo.Master_AuthInfo.Availability) {
        if (Object.values(authorityGroupInfo.AuthInfo.Master_AuthInfo.Auth_Conf).every(v => v === false)) {
          authorityGroupInfo.AuthInfo.Master_AuthInfo.Availability = false;
        }
      } else {
        Object.keys(authorityGroupInfo.AuthInfo.Master_AuthInfo.Auth_Conf).forEach(key => {
          authorityGroupInfo.AuthInfo.Master_AuthInfo.Auth_Conf[key] = false;
        });
      }
      authorityGroupInfo.RefAuthInfo = {};
    }
  }

  /**
   * 入力チェック
   */
  const _checkInput = async (masterList) => {

    let inputError = {}
    try {
      // -- 必須 --
      if (!authorityGroupInfo.AuthGroupName) {
        inputError.AuthGroupName = getMessage("E010001", "権限グループ名");
      }
      // -- 文字長 --
      if (authorityGroupInfo.AuthGroupName.length > 30) {
        inputError.AuthGroupName = getMessage("E010006", "権限グループ名", 30);
      }
      // -- 予約語 --
      if (config["reservedWords"].includes(authorityGroupInfo.AuthGroupName)) {
        inputError.AuthGroupName = getMessage("E011008");
      }
      // -- 相関 --
      if (masterList.some(dbData => {
        // 権限グループ名重複チェック
        return dbData.Key !== parseInt(sortKey) && dbData.AuthGroupName === authorityGroupInfo.AuthGroupName
      })) {
        inputError.AuthGroupName = "同名の権限グループが既に存在します。"
      };
      // データ参照権限
      inputError.authErrors = [];
      if (authorityGroupInfo.AuthKind === 'reference') {
        // グループ会社参照権限
        const Company_AuthInfo = authorityGroupInfo.RefAuthInfo.Company_AuthInfo;
        if (Company_AuthInfo.RefAuthKind === 'company') {
          if (!Company_AuthInfo.Company_List.length) {
            inputError.authErrors.push("グループ会社参照権限の会社を1つ以上選択してください。");
          }
        }
        if (Company_AuthInfo.RefAuthKind === 'segment') {
          if (!Company_AuthInfo.Segment_List.length) {
            inputError.authErrors.push("グループ会社参照権限のセグメントを1つ以上選択してください。");
          }
        }
      } else {
        // データアップロード権限
        const Upload_AuthInfo = authorityGroupInfo.AuthInfo.Upload_AuthInfo;
        if (Upload_AuthInfo.Availability && radioInfo.upload === 'regist') {
          const Data = Upload_AuthInfo.Auth_Conf.Data;
          const Monthly = Upload_AuthInfo.Auth_Conf.Uploadable_Monthly;
          const Quarter = Upload_AuthInfo.Auth_Conf.Uploadable_Quarter;
          const Year = Upload_AuthInfo.Auth_Conf.Uploadable_Year;
          const accounting = Upload_AuthInfo.Auth_Conf.Uploadable_Accounting;
          if (!Data.Create && !Data.Lock && !Data.Unlock
            && !Monthly.Files.length && !Quarter.Files.length && !Year.Files.length
            && !accounting.Standalone && !accounting.Consolidated) {
            inputError.authErrors.push("データアップロードの更新権限を1つ以上選択してください。");
          }
        }
      }
      if (!inputError.authErrors.length) {
        delete inputError.authErrors;
      }
      // エラー判定
      if (Object.keys(inputError).length === 0) {
        return false;
      } else {
        inputError.errorMessage = '入力内容に誤りがあります。'
        return true;
      }
    } finally {
      // 入力エラーを設定
      inputError = { ...errors, ...inputError }
      setInputError(inputError);
    }
  }

  /**
   * 警告用チェック
   */
  const _checkWarning = async (masterList, callback) => {
    if (masterList.some(dbData => dbData.Key !== parseInt(sortKey) &&
      stringfy(dbData.AuthInfo) === stringfy(authorityGroupInfo.AuthInfo))) {
      const title = '権限グループ設定';
      const body = <React.Fragment>
        <div className="text-center">同じ内容の権限グループが存在しています。</div>
        <div className="text-center">この権限グループ設定を登録してよろしいですか？</div>
      </React.Fragment>;
      const button = [{
        name: '登録',
        className: 'footer-btn button_base',
        click: async () => {
          await callback();
        }
      }];
      props.showModal({ title: title, body: body, button: button, isWide: false });
    } else {
      callback()
    }
  }

  /**
   * 登録処理
   */
  const registAuthority = async () => {

    // 処理
    const exec = async () => {
      try {
        // シーケンス発行
        const sequence = await nextSequence(table.AuthorityGroup, props.corporationNumber, props.userId)
        const nowDate = formatDate(new Date());
        authorityGroupInfo.CreateDateTime = nowDate;
        authorityGroupInfo.UpdateDateTime = nowDate;

        _setAvailability();
        // 登録
        await registDynamoDB(
          table.AuthorityGroup,
          'CorporationNumber',
          props.corporationNumber,
          [{ ...authorityGroupInfo, Key: sequence.key }],
          props.userId
        );
        setSortKey(sequence.key);
        setAuthorityGroupInfo(authorityGroupInfo);
        props.navigate('/auth/authorityGroupList', { state: { message: '権限グループ設定を保存しました。' } });
      } catch (error) {
        errorLog("E000001", props.userId, viewId.authorityGroupInfo, "registAuthority", error);
      }
    }
    const dynamoDBData = await getDynamoDB(table.AuthorityGroup, null, props.corporationNumber);
    if (await _checkInput(dynamoDBData)) {
      return;
    }
    _checkWarning(dynamoDBData, exec);
  }

  /**
   * 更新処理
   */
  const updateAuthority = async () => {

    // 処理
    const exec = async () => {
      try {
        const nowDate = formatDate(new Date());
        authorityGroupInfo.UpdateUserName = props.userName;
        authorityGroupInfo.UpdateDateTime = nowDate;

        _setAvailability();
        // 更新
        await updateDynamoDB(
          table.AuthorityGroup,
          'CorporationNumber',
          props.corporationNumber,
          {
            CorporationNumber: props.corporationNumber,
            Key: parseInt(sortKey)
          },
          authorityGroupInfo,
          props.userId
        );
        setAuthorityGroupInfo(authorityGroupInfo);
        props.navigate('/auth/authorityGroupList', { state: { message: '権限グループ設定を保存しました。' } });
      } catch (error) {
        errorLog("E000001", props.userId, viewId.authorityGroupInfo, "updateAuthority", error);
      }
    }
    const dynamoDBData = await getDynamoDB(table.AuthorityGroup, null, props.corporationNumber);
    if (await _checkInput(dynamoDBData)) {
      return;
    }
    _checkWarning(dynamoDBData, exec);
  }

  /**
   * 削除処理
   */
  const deleteAuthority = async () => {

    const dynamoDBData = await getDynamoDB(table.User, null, props.corporationNumber);
    // 削除可否判定
    if (dynamoDBData.some(dbData => dbData.AuthGroupList.includes(parseInt(sortKey)))) {
      const title = '権限グループ設定の削除確認';
      const body = <React.Fragment>
        <div className="text-center">この権限グループ設定は使用されているため削除できません。</div>
      </React.Fragment>;
      props.showModal({ title: title, body: body, isWide: false });
    } else {
      const title = '権限グループ設定の削除確認';
      const body = <React.Fragment>
        <div className="text-center">この権限グループ設定を削除しますか？</div>
      </React.Fragment>;
      const button = [{
        name: '削除',
        className: 'footer-btn button_delete',
        click: async () => {
          try {
            // 削除
            await deleteDynamoDB(
              table.AuthorityGroup,
              {
                CorporationNumber: props.corporationNumber,
                Key: parseInt(sortKey)
              },
              props.corporationNumber,
              props.userId
            );
            props.navigate('/auth/authorityGroupList', { state: { message: '権限グループ設定を削除しました。' } });
          } catch (error) {
            errorLog("E000001", props.userId, viewId.authorityGroupInfo, "deleteAuthority", error);
          }
        }
      }];
      props.showModal({ title: title, body: body, button: button, isWide: false });
    }
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* 上階層に戻る */}
            <div className="bg main">
              <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/auth/authorityGroupList">
                  <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                    <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                    <span className="text-h5 font-bold">{titleName.authorityGroupList}に戻る</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{titleName.authorityGroupInfo}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div className="centered-container-1456">
                {!authorityGroupInfo.Deleted ? (
                  <React.Fragment>
                    {/*  ===== リード ===== */}
                    <p className="text-body text-center mb-10">
                      選択中の権限グループの権限を設定します。<br />
                      全てをオフ（権限なし）にするには、トグルボタンをオフにしてください。<br />
                      登録ボタンを押下すると、設定が更新されます。
                    </p>
                    {/*  ===== /リード ===== */}
                    {copyName && <div className="text-body text-link">※「{copyName}」の権限設定を複製しました。新しい権限グループ名を設定して登録してください。</div>}
                    <div>
                      <span style={{ color: "red" }}>{inputError.errorMessage}</span>
                    </div>
                    <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                      <div className="content-area">
                        <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">権限の設定</h2>
                        <div className="flex my-5">
                          <ul className="text-body-2-medium grow">
                            <li>権限グループの名前を入力してください。</li>
                          </ul>
                        </div>
                        {/*  ===== 基本情報 ===== */}
                        <table className="form-table form-control w-full">
                          <tbody className="text-caption-2">
                            <tr>
                              <th className="form-table__col-head">
                                <label htmlFor="authGroupName" className="block text-caption-1">権限グループ名<span className="red--text">*</span></label>
                              </th>
                              <td className="form-table__col-data">
                                <input id="authGroupName" placeholder="権限グループ名を入力（全角30文字以内）" type="text"
                                  className={`block rounded-lg px-3 py-1 w-full ${inputError.AuthGroupName ? "is-error" : ""}`}
                                  value={authorityGroupInfo.AuthGroupName} onChange={(e) => changeAuthGroupName(e)} />
                                <span className="red--text">{inputError.AuthGroupName}</span>
                              </td>
                            </tr>
                            {props.isParent &&
                              <tr>
                                <th className="form-table__col-head">
                                  <label htmlFor="authGroupName" className="block text-caption-1">権限種別<span className="red--text">*</span></label>
                                </th>
                                <td className="form-table__col-data">
                                  <ul className="flex gap-x-8">
                                    <li>
                                      <label className="ui-radio">
                                        <input type="radio" className="radioBmAll ui-radio__input" name="authKind" value="reference"
                                          disabled={props.viewMode !== "new"}
                                          defaultChecked={authorityGroupInfo.AuthKind === 'reference'} onChange={(e) => changeAuthKind(e)} />
                                        <span className="ui-radio__icon"></span>
                                        <span className="ui-radio__ripple"></span>
                                        <span className="ui-radio__label text-h7 font-Regular">データ参照</span>
                                      </label>
                                    </li>
                                    <li>
                                      <label className="ui-radio">
                                        <input type="radio" className="radioBmAll ui-radio__input" name="authKind" value="function"
                                          disabled={props.viewMode !== "new"}
                                          defaultChecked={authorityGroupInfo.AuthKind === 'function'} onChange={(e) => changeAuthKind(e)} />
                                        <span className="ui-radio__icon"></span>
                                        <span className="ui-radio__ripple"></span>
                                        <span className="ui-radio__label text-h7 font-Regular">機能利用</span>
                                      </label>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                        {/*  ===== /基本情報 ===== */}
                      </div>
                      <div className="content-area">
                        <div className="border-t border-blue1" />
                        {/*  ===== 権限設定 ===== */}
                        {inputError.authErrors.map((e, idx) => {
                          return (
                            <div key={idx} className={idx === 0 ? "mt-5" : ""}>
                              <span className="red--text text-h6">{e}</span>
                            </div>
                          );
                        })}
                        <div className="flex my-5">
                          <ul className="text-body-2-medium grow">
                            <li>グループに割り当てる権限を設定してください。</li>
                          </ul>
                        </div>
                        {authorityGroupInfo.AuthKind === 'reference' ? <>
                          <div className="box-xy-bordered collapse-list">
                            {/*  ===== グループ会社参照権限 ===== */}
                            <CampanyContent authInfo={authorityGroupInfo.RefAuthInfo.Company_AuthInfo} />
                          </div>
                        </> : <>
                          <h3 className="text-h5 mb-1 font-bold">■閲覧ユーザー向け</h3>
                          <div className="box-xy-bordered collapse-list">
                            {/*  ===== ダッシュボード参照権限 ===== */}
                            <DashboardContent authInfo={authorityGroupInfo.AuthInfo.Dashboard_AuthInfo} />
                          </div>
                          <h3 className="text-h5 mb-1 mt-5 font-bold">■管理ユーザー向け</h3>
                          <div className="box-xy-bordered collapse-list">
                            {/*  ===== 経営戦略KGI管理権限 ===== */}
                            <KgiContent authInfo={authorityGroupInfo.AuthInfo.Kgi_AuthInfo} />
                            {/*  ===== 人的資本KPI管理権限 ===== */}
                            <KpiContent authInfo={authorityGroupInfo.AuthInfo.Kpi_AuthInfo} />
                            {/*  ===== データアップロード権限 ===== */}
                            <UploadContent authInfo={authorityGroupInfo.AuthInfo.Upload_AuthInfo} isParent={props.isParent} />
                            {/*  ===== 用途タグ登録権限 ===== */}
                            <CategoryTagContent authInfo={authorityGroupInfo.AuthInfo.CategoryTag_AuthInfo} />
                            {/*  ===== マスター管理権限 ===== */}
                            <MasterContent authInfo={authorityGroupInfo.AuthInfo.Master_AuthInfo} isParent={props.isParent} />
                          </div>
                        </>
                        }
                        {/*  ===== /権限設定===== */}
                      </div>
                      <div className="content-footer mt-16 mb-8">
                        <div className="border-t border-blue1" />
                        {/*  ===== フッターボタン ===== */}
                        <div className="footer_button_area mt-8">
                          <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/auth/authorityGroupList">
                            <button type="button" className="footer-btn button_back">キャンセル</button>
                          </Link>
                          <SpinnerButton name="登録" className="footer-btn button_base"
                            onClick={() => sortKey ? updateAuthority() : registAuthority()} />
                          {sortKey &&
                            <button type="button" className="footer-btn button_delete"
                              onClick={() => deleteAuthority()}>削除</button>
                          }
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                      <div className="content-area">
                        <h2 className="text-h4-em text-center text--lighten-70 mb-2">選択した権限グループ設定はすでに削除されているか存在しません。</h2>
                        <h2 className="text-h4-em text-center text--lighten-70 mb-2">{titleName.authorityGroupList}に戻って操作をやり直してください。</h2>
                      </div>
                      <div className="content-footer mt-16">
                        <div className="border-t border-blue1" />
                        {/*  ===== フッターボタン ===== */}
                        <div className="footer_button_area mt-8">
                          <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/auth/authorityGroupList">
                            <button type="button" className="footer-btn button_back">戻る</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment >
  );
}

const AuthorityGroupInfo = (props) => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const showModal = useModal();
  // 権限がない場合は表示しない
  if (state.user.role.split('_')[1] !== Roll.administrator) {
    return <Forbidden />
  }
  // 特定のモード以外は表示しない
  if (!['new', 'change', 'copy'].includes(props.params.mode)) {
    return <Forbidden />
  }
  // モード"new"：ID指定は表示しない
  if (props.params.mode === 'new' && props.params.key) {
    return <Forbidden />
  }
  return <AuthorityGroupContent
    id={props.params.key}
    viewMode={props.params.mode}
    userId={state.user.sub}
    userName={state.user.userName}
    pageTitle={titleName.authorityGroupInfo + titleName.common}
    corporationNumber={state.company.corporationNumber}
    isParent={state.company.isParent}
    bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber}
    navigate={navigate}
    showModal={showModal}
  />;
};

export default (props) => <AuthorityGroupInfo {...props} params={useParams()} />;
