import moji from "moji";

export const UnitChangeForByte = (size) => {
  if (size === undefined || size === null) {
    return
  }
  const { target, unit } = getTarget(size);
  const newSize =
    target !== null
      ? (
          Math.floor((size / target) * Math.pow(10, 2)) / Math.pow(10, 2)
        ).toFixed(1)
      : size;

  return newSize + unit;
};

const getTarget = (size) => {
  const kb = 1024;
  const mb = Math.pow(kb, 2);
  const gb = Math.pow(kb, 3);
  const tb = Math.pow(kb, 4);

  const returnData = (target, unit) => ({ target, unit });

  if (size >= tb) return returnData(tb, "TB");
  if (size >= gb) return returnData(gb, "GB");
  if (size >= mb) return returnData(mb, "MB");
  if (size >= kb) return returnData(kb, "KB");

  return returnData(null, "byte");
};

// 半角ｶﾅを全角カナに変換
export const hankanaToZenkana = (str) => {
  return moji(str).convert("HK", "ZK").toString()
};

// 小文字を大文字に変換
export const convertToUpperCase = (str) => {
  return str.toUpperCase();
};

// 半角英数を全角英数に変換
export const haneisuToZeneinu = (str) => {
  return moji(str).convert("HE", "ZE").toString();
};
