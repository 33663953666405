import { Auth } from "aws-amplify";

export const sessionUtil = async () => {
  const sessionTimeout = 60 * 60 * 1000;
  try {
    // トークンの更新
    await Auth.currentSession();
    // セッションタイムアウト
    const currentTime = Date.now();
    const lastActionTime = localStorage.getItem("chrofyLastActionTime");
    if (lastActionTime && currentTime - lastActionTime > sessionTimeout) {
      window.location.href = "/logout";
    }
    localStorage.setItem("chrofyLastActionTime", currentTime);
  } catch (e) {
    window.location.href = "/logout";
  }
};