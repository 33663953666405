import React from "react";
import {
    getDescendants,
    getBackendOptions,
    MultiBackend,
    DndProvider,
    Tree,
} from "@minoru/react-dnd-treeview";
import { CustomNode } from "./tree/CustomNode";
import { CustomDragPreview } from "./tree/CustomDragPreview";
import { CustomPlaceholder } from "./tree/CustomPlaceholder";

import '../../assets/css/tree.css'

export const TreeView = (props) => {
    const descendants = getDescendants(props.tree, props.rootId);

    return (
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
            <Tree
                tree={descendants}
                onDrop={props.onDrop}
                initialOpen={true}
                sort={false}
                insertDroppableFirst={false}
                rootId={props.rootId}
                classes={{
                    root: "tree--treeRoot",
                    draggingSource: "tree--draggingSource",
                    dropTarget: "tree--dropTarget",
                    placeholder: "tree--placeholderContainer"
                }}
                canDrag={props.canDrag}
                canDrop={props.canDrop}
                dropTargetOffset={5}
                placeholderRender={(node, {depth}) => (
                    <CustomPlaceholder node={node} depth={depth} />
                )}
                dragPreviewRender={(monitorProps) => (
                    <CustomDragPreview monitorProps={monitorProps} />
                )}
                render={(node, options) => (
                    <CustomNode node={node} {...options} onDelete={props.onDelete} styles={props.nodeStyles} />
                )}
            />
        </DndProvider>
    );
}

