"use strict";
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SpinnerButton } from "../common/CustomButton"
import { getDynamoDB, updateDynamoDB } from "../../common/utils/DynamoDbUtils";
import { Roll, viewId, titleName, table } from "../../_constants/Code";
import { Link, Checkbox } from "@mui/material";
import CheckBoxRountedIcon from "@mui/icons-material/CheckBoxRounded";
import { PathInfoList } from "../../_constants/PathInfoList";
import { formatDate } from "../../common/utils/DateUtils";
import { restApiKey } from "../../_constants/Code";
import { httpGet } from '../../services/api/AmplifyApi';
import { Link as RouterLink } from "react-router-dom";
import { errorLog } from "../../common/logger/Logger";
import Forbidden from "../../_components/Forbidden";

const AuthorityGroupListContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [authorityGroupRefList, setAuthorityGroupRefList] = React.useState([]);
  const [authorityGroupFuncList, setAuthorityGroupFuncList] = React.useState([]);
  const [userInfoData, setUserInfoData] = React.useState();
  const [emailData, setEmailData] = React.useState();

  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    init();
  }, []);

  const init = async () => {
    await getAuthorityGroupListData();
    await getUserInfoData();
    await getEmail();
    setIsLoaded(true);
  }

  /**
   * 初期表示
   */
  const getAuthorityGroupListData = async () => {
    // 権限グループ情報取得
    const authorityGroup = await getDynamoDB(table.AuthorityGroup, null, props.corporationNumber);
    setAuthorityGroupRefList(authorityGroup.filter(auth => auth.AuthKind === 'reference'));
    setAuthorityGroupFuncList(authorityGroup.filter(auth => auth.AuthKind !== 'reference'));
  }

  const getUserInfoData = async () => {
    // ユーザー情報取得
    const userInfo = await getDynamoDB(table.User, null, props.corporationNumber, { UserId: props.sub });
    if (userInfo.length) {
      setUserInfoData(userInfo[0]);
    }
  }

  const getEmail = async () => {
    // Eメール情報取得
    try {
      const init = {
        queryStringParameters: {
          sub: props.sub
        },
        headers: {},
      };
      const email = await httpGet(
        restApiKey.CHROFY_REST_API,
        PathInfoList.Resource.getEmail(props.corporationNumber),
        init
      );
      setEmailData(email[0])
    } catch (error) {
      errorLog("E000001", props.sub, viewId.userPermission, "getEmail", error);
    }
  }

  /**
   * データ参照権限グループを変更する
   */
  const changeAuthorityGroupRef = (e) => {
    setUserInfoData({ ...userInfoData, RefAuthGroupKey: e.target.value });
  }

  /**
   * 機能利用権限グループを変更する
   * @param {権限グループキー} key
   */
  const changeAuthorityGroup = (key) => {
    let list = userInfoData.AuthGroupList;
    if (list.includes(key)) {
      list = list.filter(node => node !== key);
    } else {
      list.push(key);
    }
    userInfoData.AuthGroupList = list;
    setUserInfoData({ ...userInfoData });
  }

  /**
 * 更新処理
 */
  const updateUserInfoData = async () => {

    // 処理
    try {
      const nowDate = formatDate(new Date());
      delete userInfoData.CorporationNumber;
      delete userInfoData.UserId;
      userInfoData.UpdateUserName = props.userName;
      userInfoData.UpdateDateTime = nowDate;

      // 更新
      await updateDynamoDB(
        table.User,
        'CorporationNumber',
        props.corporationNumber,
        {
          CorporationNumber: props.corporationNumber,
          UserId: props.sub
        },
        userInfoData,
        props.userId
      );
      props.navigate('/user/userList', { state: { message: 'ユーザー権限設定を保存しました。' } });
    } catch (error) {
      errorLog("E000001", props.sub, viewId.userPermission, "updateUserInfoData", error);
    }
  }


  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* 上階層に戻る */}
            <div className="bg main">
              <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/user/userList">
                  <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                    <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                    <span className="text-h5 font-bold">{titleName.userList}に戻る</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{titleName.userPermission}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div className="centered-container-1056">
                {userInfoData ? (
                  <React.Fragment>
                    {/*  ===== リード ===== */}
                    <p className="text-body text-center mb-10">
                      選択したユーザーに付与する権限グループを選択してください。<br />
                      1人のユーザーに複数の権限グループを割り当てることもできます。
                    </p>
                    {/*  ===== /リード ===== */}
                    <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                      <div className="content-area">
                        <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">権限グループ割当て設定</h2>
                        <div className="flex my-5"></div>
                        <table className="form-table form-control w-full">
                          <tbody className="text-caption-2">
                            <tr>
                              <th width="15%">ユーザー名</th>
                              <td width="35%">{emailData.name}</td>
                              <th width="15%">ユーザーID</th>
                              <td width="35%">{emailData.id}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/*  ===== 権限グループ一覧 ===== */}
                      <div className="content-area">
                        <div className="border-t border-blue1" />
                        <div className="flex mt-5">
                          <ul className="text-body-2-medium grow">
                            <li>ユーザーに割り当てる権限グループを選択してください</li>
                          </ul>
                        </div>
                        {props.isParent && <>
                          <h3 className="text-h5 mb-1 font-bold mt-5">■グループ会社参照権限</h3>
                          <table className="base-table w-full">
                            <thead className="text-h7">
                              <tr>
                                <th width="8%">選択</th>
                                <th width="92%">権限グループ名</th>
                              </tr>
                            </thead>
                            <tbody className="text-caption-2">
                              <tr>
                                <th className="text-center">
                                  <div className="my-1 ml-2">
                                    <label className="ui-radio">
                                      <input type="radio" className="ui-radio__input" name="refAuthGroup" value=""
                                        defaultChecked={authorityGroupRefList.length === 0 || !userInfoData.RefAuthGroupKey}
                                        onChange={(e) => changeAuthorityGroupRef(e)} />
                                      <span className="ui-radio__icon"></span>
                                      <span className="ui-radio__ripple"></span>
                                    </label>
                                  </div>
                                </th>
                                <td className="non-auth-info">
                                  <span className="ui-checkbox__label text-h6 font-Regular">権限なし</span>
                                  <span className="ui-checkbox__label text-caption-2 ml-10">※グループ会社のデータ参照不可としたい場合は、この権限グループを割当ててください</span>
                                </td>
                              </tr>
                              {authorityGroupRefList
                                .sort((a, b) => a.AuthGroupName.localeCompare(b.AuthGroupName))
                                .map((data, idx) => {
                                  return (
                                    <React.Fragment key={data.Key}>
                                      <tr key={idx}>
                                        <th className="text-center">
                                          <div className="my-1 ml-2">
                                            <label className="ui-radio">
                                              <input type="radio" className="ui-radio__input" name="refAuthGroup" value={data.Key}
                                                defaultChecked={String(data.Key) === userInfoData.RefAuthGroupKey} onChange={(e) => changeAuthorityGroupRef(e)} />
                                              <span className="ui-radio__icon"></span>
                                              <span className="ui-radio__ripple"></span>
                                            </label>
                                          </div>
                                        </th>
                                        <td>
                                          <span className="ui-checkbox__label text-h6 font-Regular">{data.AuthGroupName}</span>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  )
                                })}
                            </tbody>
                          </table>
                          {authorityGroupRefList.length === 0 ? (
                            <span className="red--text text-caption-2">データ参照用の権限グループが登録されていません。「権限グループ管理」から権限グループを登録してください。</span>
                          ) : (
                            <span></span>
                          )}
                        </>}
                        <h3 className="text-h5 mb-1 font-bold mt-5">■機能利用権限</h3>
                        {authorityGroupFuncList.length > 0 ? (
                          <table className="base-table w-full">
                            <thead className="text-h7">
                              <tr>
                                <th width="8%">選択</th>
                                <th width="92%">権限グループ名</th>
                              </tr>
                            </thead>
                            <tbody className="text-caption-2">
                              {authorityGroupFuncList
                                .sort((a, b) => a.AuthGroupName.localeCompare(b.AuthGroupName))
                                .map((data, idx) => {
                                  return (
                                    <React.Fragment key={data.Key}>
                                      <tr key={idx}>
                                        <th className="text-center">
                                          <div className="my-1 ml-2">
                                            <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                              checked={userInfoData.AuthGroupList.includes(data.Key)}
                                              onChange={() => changeAuthorityGroup(data.Key)} />
                                          </div>
                                        </th>
                                        <td>
                                          <span className="ui-checkbox__label text-h6 font-Regular">{data.AuthGroupName}</span>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  )
                                })}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center my-10 pt-10">
                            <span className="text-h5 red--text">権限グループは設定されていません。</span>
                          </div>
                        )}
                      </div>
                      <div className="content-footer mt-16 mb-8">
                        <div className="border-t border-blue1" />
                        {/*  ===== フッターボタン ===== */}
                        <div className="footer_button_area mt-8">
                          <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/user/userList">
                            <button type="button" className="footer-btn button_back">キャンセル</button>
                          </Link>
                          <SpinnerButton name="登録"
                            className={`footer-btn button_base ${(authorityGroupRefList.length + authorityGroupFuncList.length) ? "" : "is-disabled"}`}
                            disabled={!(authorityGroupRefList.length + authorityGroupFuncList.length)}
                            onClick={() => updateUserInfoData()} />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                      <div className="content-area">
                        <h2 className="text-h4-em text-center text--lighten-70 mb-2">選択したユーザー権限設定はすでに削除されているか存在しません。</h2>
                        <h2 className="text-h4-em text-center text--lighten-70 mb-2">ユーザー権限管理画面に戻って操作をやり直してください。</h2>
                      </div>
                      <div className="content-footer mt-16">
                        <div className="border-t border-blue1" />
                        {/*  ===== フッターボタン ===== */}
                        <div className="footer_button_area mt-8">
                          <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/user/userList">
                            <button type="button" className="footer-btn button_back">戻る</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment >
  )
}

const AuthorityGroupList = (props) => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  // 権限がない場合は表示しない
  if (state.user.role.split('_')[1] !== Roll.administrator) {
    return <Forbidden />
  }
  return <AuthorityGroupListContent
    pageTitle={titleName.userPermission + titleName.common}
    corporationNumber={state.company.corporationNumber}
    isParent={state.company.isParent}
    userId={state.user.sub}
    userName={state.user.userName}
    sub={props.params.sub}
    navigate={navigate}
  />;
};

export default (props) => <AuthorityGroupList {...props} params={useParams()} />;
