"use strict";
import * as React from "react";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";

import { mainPageInfoList, monitoringInfoList } from "../../_constants/PageInfoList";
import { viewId, titleName, allConditions, table, analysisMode } from "../../_constants/Code";
import { errorLog } from "../../common/logger/Logger";
import { Message } from "../../_components/MessagePage";
import { getPageText } from "../../common/utils/FileUtils";
import { formatDate } from "../../common/utils/DateUtils";
import { getDynamoDB, registDynamoDB, updateDynamoDB, deleteDynamoDB } from "../../common/utils/DynamoDbUtils";
import { getNowDispStateCtgSize, getNowDispStateBdgSize } from "../../services/calc/CalcDispSize";

import '../../assets/css/vendor/normalize.css'
import '../../assets/css/vendor/animate.min.css'
import '../../assets/css/vendor/hamburgers.css'

import '../../assets/css/vendor/normalize.css'
import '../../assets/css/vendor/animate.min.css'
import '../../assets/css/vendor/hamburgers.css'
import '../../assets/icon/style.css'
import '../../assets/css/style.css'
import '../../assets/js/tailwindcss.js'
import '../../assets/js/adobe_fonts.js'
import img_category_dashboard from '../../assets/img/category/img_category_dashboard.png'
import img_CAS from '../../assets/img/badge/img_CAS.png'
import img_ISO from '../../assets/img/badge/img_ISO.png'
import img_GRI from '../../assets/img/badge/img_GRI.png'

const Error = () => {
  const messageList = [
    "ダッシュボード一覧情報の取得に失敗しました。画面を再表示してください。",
  ];
  return (
    <Message
      title="ダッシュボード一覧"
      messageList={messageList}
      loginBtn={false}
      topBtn={false}
    />
  );
};

class DashboardListContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      pageText: null,
      bmSet: [],
      bmVersion: [],
      bmMemo: '',
      bmDispStatus: null,
      bmDashboardKey: '',
      bmDashboardName: '',
      bmError: false,
      isAndOr: true,
      ctSet: [],
      ctCategoryKey: [],
      ctCategoryName: [],
      ctDashboardKey: '',
      ctMemo: [],
      ctCategory: [],
      ctVersion: [],
      ctError: false,
      searchAndOr: "And",
      nowSearchAndOr: '',
      dispStateBm: '',
      dispStateCtg: '',
      dispStateAndOr: '',
      dispStateBdg: '',
      radioBmAllFlg: true,
      radioBmHaveFlg: false,
      radioBmNohaveFlg: false,
      chBoxCtgAllFlg: true,
      chBoxCtgFlg: [],
      chBoxBdgAllFlg: true,
      chBoxBdgFlg: [],
      dashboardAccount: 0,
    };
    this.isError = false;
    this.mode = this.props.mode;
    this.dashboardAuthConf = this.props.authority.Dashboard_AuthInfo;
    this.categoryTagAuthConf = this.props.authority.CategoryTag_AuthInfo;
  }

  bmList = [];
  bmSet = [];
  bmVersion = [];
  setBmSet = () => {
    this.bmList.map(
      (item) => this.bmSet[item["DashboardKey"]] = item["Memo"]
    );
    this.bmList.map(
      (item) => this.bmVersion[item["DashboardKey"]] = item["Version"]
    );
    this.setState({
      bmSet: this.bmSet,
      bmVersion: this.bmVersion,
    });
  };

  getBm = async () => {
    try {
      this.bmList = [];
      this.bmSet = [];
      this.bmVersion = [];
      this.bmList = await getDynamoDB(
        table.Bookmark,
        "CorporationNumber-index",
        this.props.corporationNumber,
        null,
        {
          UserId: this.props.userId,
        }
      );
      this.setBmSet();
    } catch (error) {
      this.isError = true;
      errorLog("E000001", this.props.userId, viewId.dashboardList, "getBm", error);
    }
  };

  groupByMethod = (arr, fnOrProperty) => {
    return arr.map(typeof fnOrProperty === 'function' ? fnOrProperty : val => val[fnOrProperty])
      .reduce((acc, val, i) => {
        acc[val] = (acc[val] || []).concat(arr[i]);
        return acc;
      }, {});
  }

  ctSet = [];
  tmpCtList = [];
  setCtInfo = () => {
    this.tmpCtList = [];
    this.catList.map((itemCat) => {
      this.ctList.map((item) => {
        if (itemCat["CategoryKey"] == item.CategoryKey) {
          this.tmpCtList.push(item);
        }
      })
    });
    this.ctList = this.tmpCtList;
    this.ctSet = this.groupByMethod(this.ctList, 'DashboardKey');
    this.setState({
      ctSet: this.ctSet,
    });
  };

  ctList = [];
  getCt = async () => {
    try {
      this.ctList = [];
      this.ctList = await getDynamoDB(
        table.CategoryTag,
        null,
        this.props.corporationNumber
      );
      this.setCtInfo();
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getCt", error);
    }
  };

  ctgCategoryKey = [];
  ctgCategoryName = [];
  setCatInfo = () => {
    if (this.catList == undefined || this.catList == null) {
      this.ctgCategoryKey = [];
      this.ctgCategoryName = [];
    } else {
      this.catList.map(
        (item) => this.ctgCategoryKey[item.CategoryKey] = item.CategoryKey
      );
      this.catList.map(
        (item) => this.ctgCategoryName[item.CategoryKey] = item.CategoryName
      );
    }
    this.setState({
      ctCategoryKey: this.ctgCategoryKey,
      ctCategoryName: this.ctgCategoryName,
    });
  };

  objectArraySort = (objectN, objectM) => {
    return objectN["Sort"] - objectM["Sort"];
  }

  catList = [];
  getCat = async () => {
    try {
      this.catList = [];
      this.catList = await getDynamoDB(table.Category, null, this.props.corporationNumber);
      this.catList = this.catList.filter(function (value) {
        return value["DeleteFlg"] == 0;
      });
      this.setCatInfo();
      this.catList = this.catList.sort(this.objectArraySort);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getCat", error);
    }
  };

  componentDidMount = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const pageTextList = await getPageText(user);
    this.setState({
      pageText: pageTextList,
    });
    await this.getBm();
    await this.getCat();
    await this.getCt();
    this.setChBoxFlg();
    // タイトル設定
    document.title = this.props.pageTitle;
    this.setState({
      isLoaded: true,
    });
  };

  setChBoxFlg = () => {
    let tmpChBoxCtgFlg = [];
    if (this.catList != undefined && this.catList != null && this.catList != []) {
      for (let i = 0; i <= this.catList.length; i++) {
        tmpChBoxCtgFlg[i] = true;
      }
    }
    let tmpChBoxBdgFlg = [];
    tmpChBoxBdgFlg[0] = true;
    tmpChBoxBdgFlg[1] = true;
    tmpChBoxBdgFlg[2] = true;
    tmpChBoxBdgFlg[3] = true;
    this.setState({
      chBoxCtgFlg: tmpChBoxCtgFlg,
      chBoxBdgFlg: tmpChBoxBdgFlg,
    });
  }

  handleChangeBmMemo = (e) => {
    this.setState({
      bmMemo: e.target.value,
    });
  };

  openBmModal = (dashboardKey, dashboardName) => {
    this.setState({
      bmDashboardKey: dashboardKey,
      bmDashboardName: dashboardName,
    });

    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.add("is-active");
    modalBg.classList.remove("animate__fadeOut");
    modalBg.classList.add("animate__fadeIn");
    if (this.state.bmSet[dashboardKey] == undefined) {
      this.setState({
        bmDispStatus: "entry",
        bmMemo: "",
      });
      const modalBmEntry = document.querySelector("#modal-window--bookmark-entry");
      modalBmEntry.classList.add("is-active");
      modalBmEntry.classList.remove("animate__fadeOut");
      modalBmEntry.classList.add("animate__fadeIn");
    } else {
      this.setState({
        bmDispStatus: "on",
        bmMemo: this.state.bmSet[dashboardKey] == null ? "" : this.state.bmSet[dashboardKey],
      });
      const modalBmOn = document.querySelector("#modal-window--bookmark-on");
      modalBmOn.classList.add("is-active");
      modalBmOn.classList.remove("animate__fadeOut");
      modalBmOn.classList.add("animate__fadeIn");
      const modalBmDeleteConfirm = document.querySelector("#modal-window--bookmark-delete-confirm");
      modalBmDeleteConfirm.classList.remove("animate__fadeOut");
      modalBmDeleteConfirm.classList.add("animate__fadeIn");
    }
  };

  closeBmModal = () => {
    this.setState({
      bmError: false,
    });
    if (this.state.bmDispStatus === "entry") {
      const bookmarkBg = document.querySelector("#modal-window--bookmark-entry");
      bookmarkBg.classList.remove("animate__fadeIn");
      bookmarkBg.classList.add("animate__fadeOut");
    } else if (this.state.bmDispStatus === "on") {
      const bookmarkBg = document.querySelector("#modal-window--bookmark-on");
      bookmarkBg.classList.remove("animate__fadeIn");
      bookmarkBg.classList.add("animate__fadeOut");
    } else if (this.state.bmDispStatus === "deleteConf") {
      const bookmarkBg = document.querySelector("#modal-window--bookmark-delete-confirm");
      bookmarkBg.classList.remove("animate__fadeIn");
      bookmarkBg.classList.add("animate__fadeOut");
    }
    this.setState({
      bmDispStatus: null,
    });
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.remove("animate__fadeIn");
    modalBg.classList.add("animate__fadeOut");
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      modalBg.classList.remove("is-active");
      document
        .querySelector(".modal-window.is-active")
        .classList.remove("is-active");
    }, fadeOutTimeoutSec);
  };


  registBm = async () => {
    try {
      const nowDate = formatDate(new Date());
      const request = [
        {
          UserId: this.props.userId,
          DashboardKey: this.state.bmDashboardKey,
          Memo: this.state.bmMemo,
          CreateDateTime: nowDate,
          UpdateDateTime: nowDate,
          Version: 1,
        },
      ];
      const result = await registDynamoDB(table.Bookmark, "UserId", this.props.corporationNumber, request, this.props.userId);
      await this.getBm();
      this.closeBmModal();
      this.showToast(
        this.state.bmDashboardName + "を ブックマークしました"
      );
    } catch (error) {
      this.setState({
        bmError: true,
      });
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "registBm",
        error
      );
    }
  };

  updateBm = async () => {
    try {
      const nowDate = formatDate(new Date());
      const pk = "UserId";
      const key = {
        UserId: this.props.userId,
        DashboardKey: this.state.bmDashboardKey,
      };
      const item = {
        Memo: this.state.bmMemo,
        UpdateDateTime: nowDate,
        Version: this.state.bmVersion[this.state.bmDashboardKey] + 1,
      };
      const result = await updateDynamoDB(table.Bookmark, pk, this.props.corporationNumber, key, item, this.props.userId);
      await this.getBm();
      this.showToast("ブックマークメモを更新しました");
      this.closeBmModal();
    } catch (error) {
      this.setState({
        bmError: true,
      });
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "updateBm",
        error
      );
    }
  };

  deleteBm = async () => {
    try {
      const key = {
        UserId: this.props.userId,
        DashboardKey: this.state.bmDashboardKey,
      };
      const result = await deleteDynamoDB(table.Bookmark, key, this.props.corporationNumber, this.props.userId);
      await this.getBm();
      this.showToast("ブックマークを削除しました");
      this.closeBmModal();
    } catch (error) {
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "deleteBm",
        error
      );
    }
  };

  deleteConfBm = async () => {
    this.setState({
      bmDispStatus: "deleteConf",
    });
    document
      .querySelector(".modal-window.is-active")
      .classList.remove("is-active");
    const modalBookmarkDeleteConfirm = document.querySelector(
      "#modal-window--bookmark-delete-confirm"
    );
    modalBookmarkDeleteConfirm.classList.add("is-active");
  };

  deleteBackBm = async () => {
    this.setState({
      bmDispStatus: "on",
    });
    document
      .querySelector(".modal-window.is-active")
      .classList.remove("is-active");
    const modalBookmarkOn = document.querySelector(
      "#modal-window--bookmark-on"
    );
    modalBookmarkOn.classList.add("is-active");
  };

  showToast = (message) => {
    this.setState({
      toastMessage: message,
    });
    const boxToastTimeoutSec = 4300;
    const fadeOutTimeoutSec = 300;
    const boxToast = document.querySelector(".box-toast");
    setTimeout(() => {
      boxToast.classList.add("is-active");
    }, fadeOutTimeoutSec);
    setTimeout(() => {
      this.removeToast();
    }, boxToastTimeoutSec);
  };

  removeToast = () => {
    const boxToast = document.querySelector(".box-toast");
    boxToast.classList.remove("is-active");
  };

  ctgMemo = [];
  ctgCategory = [];
  ctgVersion = [];
  setCtSet = (ctDashboardKey) => {
    if (this.state.ctSet[ctDashboardKey] == undefined || this.state.ctSet[ctDashboardKey] == null) {
      this.ctgMemo = [];
      this.ctgCategory = [];
      this.ctgVersion = [];
    } else {
      this.ctSet[ctDashboardKey].map(
        (item) => this.ctgMemo[item["CategoryKey"]] = item["Memo"]
      );
      this.ctSet[ctDashboardKey].map(
        (item) => this.ctgCategory[item["CategoryKey"]] = item["CategoryKey"]
      );
      this.ctSet[ctDashboardKey].map(
        (item) => this.ctgVersion[item["CategoryKey"]] = item["Version"]
      );
    }
    this.setState({
      ctMemo: this.ctgMemo,
      ctCategory: this.ctgCategory,
      ctVersion: this.ctgVersion,
    });
  };

  openCtModal = async (dashboardKey) => {
    await this.getCt();
    this.setCtSet(dashboardKey);
    this.setState({
      ctDashboardKey: dashboardKey,
    });
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.add("is-active");
    modalBg.classList.remove("animate__fadeOut");
    modalBg.classList.add("animate__fadeIn");
    if (this.state.ctSet[dashboardKey] == undefined || this.state.ctSet[dashboardKey] == null) {
      this.setState({
        ctMemo: [],
        ctCategory: [],
        ctVersion: [],
      });
    } else {
      this.ctgMemo = [];
      this.ctSet[dashboardKey].map(
        (item) => this.ctgMemo[item["CategoryKey"]] = item["Memo"]
      );
      this.ctgCategory = [];
      this.ctSet[dashboardKey].map(
        (item) => this.ctgCategory[item["CategoryKey"]] = item["CategoryKey"]
      );
    }
    let chBoxByNm = document.getElementsByClassName('chBox');
    let chLiByNm = document.getElementsByName('chLi');
    let len = chBoxByNm.length;
    let rootLen = 0;
    for (let i = 0; i < len; i++) {
      chLiByNm[i].classList.remove('is-active');
      if (rootLen < Number(chBoxByNm[i].value)) {
        rootLen = Number(chBoxByNm[i].value);
      }
    }
    let isCatFlg = false;
    for (let i = 0; i < rootLen; i++) {
      isCatFlg = false;
      this.catList.map((item) => {
        if (item["CategoryKey"] == this.ctgCategory[i + 1]) {
          isCatFlg = true;
        }
      })
      let chBox = document.getElementById('checkbox'.concat(i + 1));
      let chTextarea = document.getElementById('chTextarea'.concat(i + 1));
      let chLi = document.getElementById('chLi'.concat(i + 1));
      if (this.ctgCategory[i + 1] == i + 1 && isCatFlg) {
        chBox.checked = true;
        chTextarea.disabled = false;
        chLi.classList.add('is-active');
        if (this.ctgMemo[i + 1] == undefined || this.ctgMemo[i + 1] == null || this.ctgMemo[i + 1] == '') {
          chTextarea.placeholder = "メモを入力（任意、最大120文字）";
        } else {
          chTextarea.value = this.ctgMemo[i + 1];
        }
      } else {
        if (chBox != null) {
          chBox.checked = false;
          chTextarea.value = "";
          chTextarea.disabled = true;
        }
      }
    }
    const modalCtOn = document.querySelector("#modal-window--tag-list");
    modalCtOn.classList.add("is-active");
    modalCtOn.classList.remove("animate__fadeOut");
    modalCtOn.classList.add("animate__fadeIn");
  };

  closeCtModal = () => {
    this.setState({
      ctError: false,
    });

    const categoryBg = document.querySelector("#modal-window--tag-list");
    categoryBg.classList.remove("animate__fadeIn");
    categoryBg.classList.add("animate__fadeOut");
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.remove("animate__fadeIn");
    modalBg.classList.add("animate__fadeOut");
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      modalBg.classList.remove("is-active");
      document
        .querySelector("#modal-window--tag-list")
        .classList.remove("is-active");
    }, fadeOutTimeoutSec);
  };

  checkOne = () => {
    let count = 0;
    let chBox = document.getElementsByClassName('chBox');
    let chTextarea = document.getElementsByName('chTextarea');
    let chLi = document.getElementsByName('chLi');
    let len = chBox.length;
    for (let i = 0; i < len; i++) {
      chLi[i].classList.remove('is-active');
      if (chBox[i].checked) {
        chTextarea[i].disabled = false;
        chLi[i].classList.add('is-active');
        count++;
      } else {
        chTextarea[i].disabled = true;
      }
    }
  };

  ctgMemoSave = [];
  handleChangeCtgMemo = (categoryKey, e) => {
    this.ctgMemoSave = this.state.ctMemo;
    this.ctgMemoSave[categoryKey] = e.target.value;
    this.setState({
      ctMemo: this.ctgMemoSave,
    });
  };

  saveCategory = () => {
    let chBox = document.getElementsByClassName('chBox');
    let len = chBox.length;
    for (let i = 0; i < len; i++) {
      if (this.state.ctSet[this.state.ctDashboardKey] == undefined || this.state.ctSet[this.state.ctDashboardKey] == null) {
        if (chBox[i].checked) {
          this.registCt(chBox[i].value);
        }
      } else {
        if (chBox[i].checked) {
          let lenCtSet = this.state.ctSet[this.state.ctDashboardKey].length;
          let isHaveCategoryKey = false
          for (let j = 0; j < lenCtSet; j++) {
            if (chBox[i].value == this.state.ctSet[this.state.ctDashboardKey][j]["CategoryKey"]) {
              isHaveCategoryKey = true;
            }
          }
          if (isHaveCategoryKey) {
            this.updateCt(chBox[i].value);
          } else {
            this.registCt(chBox[i].value);
          }
        } else {
          this.deleteCt(chBox[i].value);
        }
      }
    }
    this.showToast("用途タグを保存しました");
  };

  registCt = async (categoryKey) => {
    try {
      const nowDate = formatDate(new Date());
      const request = [
        {
          SearchKey: this.state.ctDashboardKey + "#" + categoryKey,
          DashboardKey: this.state.ctDashboardKey,
          CategoryKey: categoryKey,
          Memo: this.state.ctMemo[categoryKey],
          CreateDateTime: nowDate,
          UpdateDateTime: nowDate,
          Version: 1,
        },
      ];
      const result = await registDynamoDB(table.CategoryTag, "UserId", this.props.corporationNumber, request, this.props.userId);
      await this.getCt();
      this.closeCtModal();
    } catch (error) {
      this.setState({
        ctError: true,
      });
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "registCt",
        error
      );
    }
  };

  updateCt = async (categoryKey) => {
    try {
      const nowDate = formatDate(new Date());
      const pk = "CorporationNumber";
      const key = {
        CorporationNumber: this.props.corporationNumber,
        SearchKey: this.state.ctDashboardKey + "#" + categoryKey,
      };
      const item = {
        Memo: this.state.ctMemo[categoryKey],
        UpdateDateTime: nowDate,
        Version: this.state.ctVersion[categoryKey] + 1,
      };
      const result = await updateDynamoDB(table.CategoryTag, pk, this.props.corporationNumber, key, item, this.props.userId);
      await this.getCt();
      this.closeCtModal();
    } catch (error) {
      this.setState({
        ctError: true,
      });
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "updateCt",
        error
      );
    }
  };

  deleteCt = async (categoryKey) => {
    try {
      const key = {
        CorporationNumber: this.props.corporationNumber,
        SearchKey: this.state.ctDashboardKey + "#" + categoryKey,
      };
      const result = await deleteDynamoDB(table.CategoryTag, key, this.props.corporationNumber, this.props.userId);
      await this.getCt();
      this.closeCtModal();
    } catch (error) {
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "deleteCt",
        error
      );
    }
  };

  openSetModal = () => {
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.add("is-active");
    modalBg.classList.remove("animate__fadeOut");
    modalBg.classList.add("animate__fadeIn");
    // ブックマーク
    let radioBmAll = document.querySelector("#radioBmAll");
    let radioBmHave = document.querySelector("#radioBmHave");
    let radioBmNohave = document.querySelector("#radioBmNohave");
    radioBmAll.checked = this.state.radioBmAllFlg;
    radioBmHave.checked = this.state.radioBmHaveFlg;
    radioBmNohave.checked = this.state.radioBmNohaveFlg;
    // 用途タグ
    let chBoxCtgAll = document.getElementById('chBoxCtgAll');
    chBoxCtgAll.indeterminate = !this.state.chBoxCtgAllFlg;
    chBoxCtgAll.checked = this.state.chBoxCtgAllFlg;
    let chBoxCtg = document.getElementsByClassName('chBoxCtg');
    let lenCtg = chBoxCtg.length;
    if (this.state.chBoxCtgFlg == undefined || this.state.chBoxCtgFlg == null || this.state.chBoxCtgFlg == []) {
      for (let i = 0; i < lenCtg; i++) {
        chBoxCtg[i].checked = true;
      }
    } else {
      for (let i = 0; i < lenCtg; i++) {
        chBoxCtg[i].checked = this.state.chBoxCtgFlg[i];
      }
    }
    // バッジ
    let chBoxBdgAll = document.getElementById('chBoxBdgAll');
    chBoxBdgAll.indeterminate = !this.state.chBoxBdgAllFlg;
    chBoxBdgAll.checked = this.state.chBoxBdgAllFlg;
    let chBoxBdg = document.getElementsByClassName('chBoxBdg');
    let lenBdg = chBoxBdg.length;
    if (this.state.chBoxBdgFlg == undefined || this.state.chBoxBdgFlg == null || this.state.chBoxBdgFlg == []) {
      for (let i = 0; i < lenBdg; i++) {
        chBoxBdg[i].checked = true;
      }
    } else {
      for (let i = 0; i < lenBdg; i++) {
        chBoxBdg[i].checked = this.state.chBoxBdgFlg[i];
      }
    }
    this.saveButtonType();

    const modalCtOn = document.querySelector("#modal-window--dashboard-filter");
    modalCtOn.classList.remove("animate__fadeOut");
    modalCtOn.classList.add("animate__fadeIn");
    modalCtOn.classList.add("is-active");
  };

  closeSetModal = () => {
    let modalCtOn = document.querySelector("#modal-window--dashboard-filter");
    modalCtOn.classList.remove("animate__fadeIn");
    modalCtOn.classList.add("animate__fadeOut");
    modalCtOn.classList.remove("is-active");
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.remove("animate__fadeIn");
    modalBg.classList.add("animate__fadeOut");
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      modalBg.classList.remove("is-active");
      modalCtOn.classList.remove("is-active");
    }, fadeOutTimeoutSec);
  };

  radioOne = (radioFlg) => {
    if (radioFlg == "bmAll") {
      let radioBmNohave = document.querySelector("#radioBmNohave");
      let radioBmHave = document.querySelector("#radioBmHave");
      radioBmNohave.checked = false;
      radioBmHave.checked = false;
    }
    if (radioFlg == "have") {
      let radioBmAll = document.querySelector("#radioBmAll");
      let radioBmNohave = document.querySelector("#radioBmNohave");
      radioBmAll.checked = false;
      radioBmNohave.checked = false;
    }
    if (radioFlg == "nohave") {
      let radioBmAll = document.querySelector("#radioBmAll");
      let radioBmHave = document.querySelector("#radioBmHave");
      radioBmAll.checked = false;
      radioBmHave.checked = false;
    }
    this.saveButtonType();
  };

  checkAllCtg = () => {
    let chBoxCtgAll = document.getElementById('chBoxCtgAll');
    let chBoxCtg = document.getElementsByClassName('chBoxCtg');
    let len = chBoxCtg.length;

    let checkFlg = false;
    for (let i = 0; i < len; i++) {
      if (chBoxCtg[i].checked) {
        checkFlg = true;
      }
    }
    if (checkFlg) {
      chBoxCtgAll.checked = false;
      for (let i = 0; i < len; i++) {
        chBoxCtg[i].checked = false;
      }
    } else {
      for (let i = 0; i < len; i++) {
        if (chBoxCtgAll.checked) {
          chBoxCtg[i].checked = true;
        } else {
          chBoxCtg[i].checked = false;
        }
      }
    }
    this.saveButtonType();
  };

  checkOneCtg = () => {
    let count = 0;
    let chBoxCtg = document.getElementsByClassName('chBoxCtg');
    let len = chBoxCtg.length;
    for (let i = 0; i < len; i++) {
      if (chBoxCtg[i].checked) {
        count++;
      }
    }
    if (count == len) {
      document.getElementById('chBoxCtgAll').indeterminate = false;
      document.getElementById('chBoxCtgAll').checked = true;
    } else if (count == 0) {
      document.getElementById('chBoxCtgAll').indeterminate = false;
      document.getElementById('chBoxCtgAll').checked = false;
    } else {
      document.getElementById('chBoxCtgAll').checked = false;
      document.getElementById('chBoxCtgAll').indeterminate = true;
    }
    this.saveButtonType();
  }

  searchAnd = (isAndOr) => {
    this.setState({
      isAndOr: isAndOr,
    });
    this.setState({
      searchAndOr: "And",
    });
    this.saveButtonType();
  };

  searchOr = (isAndOr) => {
    this.setState({
      isAndOr: isAndOr,
    });
    this.setState({
      searchAndOr: "Or",
    });
    this.saveButtonType();
  };

  checkAllBdg = () => {
    let chBoxBdgAll = document.getElementById('chBoxBdgAll');
    let chBoxBdg = document.getElementsByClassName('chBoxBdg');
    let len = chBoxBdg.length;
    let checkFlg = false;
    for (let i = 0; i < len; i++) {
      if (chBoxBdg[i].checked) {
        checkFlg = true;
      }
    }
    if (checkFlg) {
      chBoxBdgAll.checked = false;
      for (let i = 0; i < len; i++) {
        chBoxBdg[i].checked = false;
      }
    } else {
      for (let i = 0; i < len; i++) {
        if (chBoxBdgAll.checked) {
          chBoxBdg[i].checked = true;
        } else {
          chBoxBdg[i].checked = false;
        }
      }
    }
    this.saveButtonType();
  };

  checkOneBdg = () => {
    let count = 0;
    let chBoxBdg = document.getElementsByClassName('chBoxBdg');
    let len = chBoxBdg.length;
    for (let i = 0; i < len; i++) {
      if (chBoxBdg[i].checked) {
        count++;
      }
    }
    if (count == len) {
      document.getElementById('chBoxBdgAll').indeterminate = false;
      document.getElementById('chBoxBdgAll').checked = true;
    } else if (count == 0) {
      document.getElementById('chBoxBdgAll').indeterminate = false;
      document.getElementById('chBoxBdgAll').checked = false;
    } else {
      document.getElementById('chBoxBdgAll').checked = false;
      document.getElementById('chBoxBdgAll').indeterminate = true;
    }
    this.saveButtonType();
  }

  saveButtonType = () => {
    let tmpRadioBmAllFlg = true;
    let tmpRadioBmHaveFlg = false;
    let tmpRadioBmNohaveFlg = false;
    let tmpChBoxCtgAllFlg = true;
    let tmpChBoxCtgFlg = [];
    let tmpChBoxBdgAllFlg = true;
    let tmpChBoxBdgFlg = [];
    let radioBmAll = document.querySelector("#radioBmAll");
    let radioBmHave = document.querySelector("#radioBmHave");
    let radioBmNohave = document.querySelector("#radioBmNohave");
    if (radioBmAll.checked) {
      tmpRadioBmAllFlg = true;
    } else {
      tmpRadioBmAllFlg = false;
    }
    if (radioBmHave.checked) {
      tmpRadioBmHaveFlg = true;
    } else {
      tmpRadioBmHaveFlg = false;
    }
    if (radioBmNohave.checked) {
      tmpRadioBmNohaveFlg = true;
    } else {
      tmpRadioBmNohaveFlg = false;
    }

    let chBoxCtgBtnFlg = false;
    let chBoxCtgAll = document.getElementById('chBoxCtgAll');
    if (chBoxCtgAll.checked) {
      chBoxCtgBtnFlg = true;
      tmpChBoxCtgAllFlg = true;
    } else {
      tmpChBoxCtgAllFlg = false;
    }
    let chBoxCtg = document.getElementsByClassName('chBoxCtg');
    let lenCtg = chBoxCtg.length;
    for (let i = 0; i < lenCtg; i++) {
      if (chBoxCtg[i].checked) {
        chBoxCtgBtnFlg = true;
        tmpChBoxCtgFlg[i] = true;
      } else {
        tmpChBoxCtgFlg[i] = false;
      }
    }

    let chBoxBdgBtnFlg = false;
    let chBoxBdgAll = document.getElementById('chBoxBdgAll');
    if (chBoxBdgAll.checked) {
      chBoxBdgBtnFlg = true;
      tmpChBoxBdgAllFlg = true;
    } else {
      tmpChBoxBdgAllFlg = false;
    }
    let chBoxBdg = document.getElementsByClassName('chBoxBdg');
    let lenBdg = chBoxBdg.length;
    for (let i = 0; i < lenBdg; i++) {
      if (chBoxBdg[i].checked) {
        chBoxBdgBtnFlg = true;
        tmpChBoxBdgFlg[i] = true;
      } else {
        tmpChBoxBdgFlg[i] = false;
      }
    }

    this.setState({
      radioBmAllFlg: tmpRadioBmAllFlg,
      radioBmHaveFlg: tmpRadioBmHaveFlg,
      radioBmNohaveFlg: tmpRadioBmNohaveFlg,
      chBoxCtgAllFlg: tmpChBoxCtgAllFlg,
      chBoxCtgFlg: tmpChBoxCtgFlg,
      chBoxBdgAllFlg: tmpChBoxBdgAllFlg,
      chBoxBdgFlg: tmpChBoxBdgFlg,
    });
    let saveDashboardBtn = document.getElementById('saveDashboardBtn');
    let saveDashboardLabel = document.getElementById('saveDashboardLabel');
    saveDashboardBtn.classList.remove('display-none');
    saveDashboardBtn.classList.remove('display-block');
    saveDashboardLabel.classList.remove('display-none');
    saveDashboardLabel.classList.remove('display-block');
    if (chBoxCtgBtnFlg && chBoxBdgBtnFlg) {
      saveDashboardBtn.classList.add('display-block');
      saveDashboardLabel.classList.add('display-none');
    } else {
      saveDashboardBtn.classList.add('display-none');
      saveDashboardLabel.classList.add('display-block');
    }
  }

  saveDashboard = () => {
    this.closeSetModal();
    // ブックマーク
    let nowBmValue = [];
    const radioCtBmAll = document.querySelector("#radioBmAll");
    const radioBmHave = document.querySelector("#radioBmHave");
    const radioBmNohave = document.querySelector("#radioBmNohave");
    if (radioCtBmAll.checked) {
      nowBmValue[0] = "all";
    }
    if (radioBmHave.checked) {
      nowBmValue[1] = radioBmHave.value;
    }
    if (radioBmNohave.checked) {
      nowBmValue[2] = radioBmNohave.value;
    }
    // 用途タグ
    let nowCtgValue = [];
    let chBoxCtg = document.getElementsByName('chBoxCtg');
    let len = chBoxCtg.length;
    for (let i = 0; i < len; i++) {
      if (chBoxCtg[i].checked) {
        nowCtgValue[i + 1] = i + 1;
      }
    }
    let chBoxCtgAll = document.getElementById('chBoxCtgAll');
    let chBoxCtgNull = document.getElementById('chBoxCtgNull');
    if (chBoxCtgAll.checked) {
      nowCtgValue[0] = "all";
    } else {
      if (chBoxCtgNull.checked) {
        nowCtgValue[0] = "null";
      }
    }
    // バッジ
    let nowBdgValue = [];
    let chBoxBdgAll = document.getElementById('chBoxBdgAll');
    let chBoxBdgNull = document.getElementById('chBoxBdgNull');
    if (chBoxBdgAll.checked) {
      nowBdgValue[0] = "all";
    } else {
      if (chBoxBdgNull.checked) {
        nowBdgValue[0] = "null";
      }
      let chBoxBdgCAS = document.getElementById('chBoxBdgCAS');
      if (chBoxBdgCAS.checked) {
        nowBdgValue[1] = chBoxBdgCAS.value;
      }
      let chBoxBdgISO = document.getElementById('chBoxBdgISO');
      if (chBoxBdgISO.checked) {
        nowBdgValue[2] = chBoxBdgISO.value;
      }
      let chBoxBdgGRI = document.getElementById('chBoxBdgGRI');
      if (chBoxBdgGRI.checked) {
        nowBdgValue[3] = chBoxBdgGRI.value;
      }
    }
    // ブックマーク
    let nowDispStateBm = "";
    if (nowBmValue[0] != "all") {
      if (nowBmValue[1] == "1") {
        nowDispStateBm = "ブックマークあり";
      } else if (nowBmValue[2] == "2") {
        nowDispStateBm = "ブックマークなし";
      }
    } else {
      nowDispStateBm = allConditions.allBookmark;
    }
    // 用途タグ
    let nowDispStateCtg = "";
    let nowDispStateAndOr = "";
    if (!chBoxCtgAll.checked) {
      if (chBoxCtgNull.checked) {
        nowDispStateCtg = nowDispStateCtg + "用途タグなし" + "、";
      }
      this.catList.map((item, index) => {
        if (chBoxCtg[index].checked) {
          nowDispStateCtg = nowDispStateCtg + item["CategoryName"] + "、";
        }
      })
      if (nowDispStateCtg.length > 0 && nowDispStateCtg.substring(nowDispStateCtg.length - 1) == "、") {
        nowDispStateCtg = nowDispStateCtg.substring(0, nowDispStateCtg.length - 1);
      }
    } else {
      nowDispStateCtg = allConditions.allCategory;
    }
    if (this.state.searchAndOr == "Or") {
      nowDispStateAndOr = "または";
    } else {
      nowDispStateAndOr = "かつ";
    }
    // バッジ
    let nowDispStateBdg = "";
    if (!chBoxBdgAll.checked) {
      if (chBoxBdgNull.checked) {
        nowDispStateBdg = nowDispStateBdg + "バッジなし" + "、";
      }
      if (nowBdgValue[1] == "CAS") {
        nowDispStateBdg = nowDispStateBdg + "CAS、";
      }
      if (nowBdgValue[2] == "ISO") {
        nowDispStateBdg = nowDispStateBdg + "ISO30414、";
      }
      if (nowBdgValue[3] == "GRI") {
        nowDispStateBdg = nowDispStateBdg + "GRI、";
      }
      if (nowDispStateBdg.length > 0 && nowDispStateBdg.substring(nowDispStateBdg.length - 1) == "、") {
        nowDispStateBdg = nowDispStateBdg.substring(0, nowDispStateBdg.length - 1);
      }
    } else {
      nowDispStateBdg = nowDispStateBdg + allConditions.allBadge;
    }

    this.setState({
      nowBm: nowBmValue,
      nowCtg: nowCtgValue,
      nowBdg: nowBdgValue,
      nowSearchAndOr: this.state.searchAndOr,
      dispStateBm: nowDispStateBm,
      dispStateCtg: nowDispStateCtg,
      dispStateAndOr: nowDispStateAndOr,
      dispStateBdg: nowDispStateBdg,
    });
  };

  resetAll = () => {
    let nowBmValue = [];
    nowBmValue[0] = "all";
    let nowCtgValue = [];
    nowCtgValue[0] = "all";
    let nowBdgValue = [];
    nowBdgValue[0] = "all";
    this.setState({
      nowBm: nowBmValue,
      nowCtg: nowCtgValue,
      nowBdg: nowBdgValue,
      nowSearchAndOr: "And",
      dispStateBm: "",
      dispStateCtg: "",
      dispStateAndOr: "",
      dispStateBdg: "",
    });

    let tmpRadioBmAllFlg = true;
    let tmpRadioBmHaveFlg = false;
    let tmpRadioBmNohaveFlg = false;
    let tmpChBoxCtgAllFlg = true;
    let tmpChBoxCtgFlg = [];
    let tmpChBoxBdgAllFlg = true;
    let tmpChBoxBdgFlg = [];
    tmpRadioBmAllFlg = true;
    tmpRadioBmHaveFlg = false;
    tmpRadioBmNohaveFlg = false;
    tmpChBoxCtgAllFlg = true;
    tmpChBoxBdgAllFlg = true;
    if (this.catList != undefined && this.catList != null && this.catList != []) {
      for (let i = 0; i <= this.catList.length; i++) {
        tmpChBoxCtgFlg[i] = true;
      }
    }
    tmpChBoxBdgFlg[0] = true;
    tmpChBoxBdgFlg[1] = true;
    tmpChBoxBdgFlg[2] = true;
    tmpChBoxBdgFlg[3] = true;
    this.setState({
      radioBmAllFlg: tmpRadioBmAllFlg,
      radioBmHaveFlg: tmpRadioBmHaveFlg,
      radioBmNohaveFlg: tmpRadioBmNohaveFlg,
      chBoxCtgAllFlg: tmpChBoxCtgAllFlg,
      chBoxCtgFlg: tmpChBoxCtgFlg,
      chBoxBdgAllFlg: tmpChBoxBdgAllFlg,
      chBoxBdgFlg: tmpChBoxBdgFlg,
      isAndOr: true,
    });
    this.searchAnd(true);
    document.getElementById("searchAnd").defaultChecked = true;
    document.getElementById("searchAnd").checked = "checked";
    document.getElementById("searchOr").defaultChecked = false;
    document.getElementById("searchOr").checked = "";
  };

  allAccount = 0;
  noBadgeAccount = 0;
  casAccount = 0;
  isoAccount = 0;
  griAccount = 0;
  catAccount = [];
  ctAccount = 0;
  getPageInfoList = (pageInfoList) => {
    let tmpHtml = pageInfoList.map((monitoringInfo) => (
      monitoringInfo.dashboardList.map((dashboardList) => (
        dashboardList.dashboard.map((dashboardTable, index) => {
          if (this.state.ctSet[dashboardTable.key] != undefined) {
            this.ctAccount = this.ctAccount + 1;
          }
          this.catList.map((item, index) => {
            if (this.state.ctSet[dashboardTable.key] != undefined) {
              this.state.ctSet[dashboardTable.key].map((itemSet) => {
                if (itemSet["CategoryKey"] != undefined && itemSet["CategoryKey"] == item["CategoryKey"]) {
                  this.catAccount[index] = this.catAccount[index] + 1;
                }
              })
            }
          })
          this.allAccount = this.allAccount + 1;
          if (dashboardTable.badge["CAS"]) {
            this.casAccount = this.casAccount + 1;
          }
          if (dashboardTable.badge["ISO"]) {
            this.isoAccount = this.isoAccount + 1;
          }
          if (dashboardTable.badge["GRI"]) {
            this.griAccount = this.griAccount + 1;
          }
          if (!dashboardTable.badge["CAS"] && !dashboardTable.badge["ISO"] && !dashboardTable.badge["GRI"]) {
            this.noBadgeAccount = this.noBadgeAccount + 1;
          }
          let nowCtgFlg = false;
          if (this.state.nowCtg == undefined || this.state.nowCtg[0] == "all") {
            nowCtgFlg = true;
          } else {
            if (this.state.ctSet[dashboardTable.key] != undefined) {
              this.state.ctSet[dashboardTable.key].map((item) => {
                let len = this.state.nowCtg.length;
                for (let i = 1; i <= len; i++) {
                  if (this.state.nowCtg[i] == item["CategoryKey"]) {
                    nowCtgFlg = true;
                  }
                }
              })
            } else {
              if (this.state.nowCtg[0] == "null") {
                nowCtgFlg = true;
              }
            }
          }
          let nowBdgFlg = false;
          if (this.state.nowBdg == undefined || this.state.nowBdg[0] == "all") {
            nowBdgFlg = true;
          } else {
            if (dashboardTable.badge[this.state.nowBdg[1]]
              || dashboardTable.badge[this.state.nowBdg[2]]
              || dashboardTable.badge[this.state.nowBdg[3]]) {
              nowBdgFlg = true;
            }
            if (!dashboardTable.badge["CAS"]
              && !dashboardTable.badge["ISO"]
              && !dashboardTable.badge["GRI"]
              && this.state.nowBdg[0] == "null") {
              nowBdgFlg = true;
            }
          }

          let nowCtgBdgFlg = true;
          if (this.state.nowSearchAndOr != undefined && this.state.nowSearchAndOr != '') {
            if (this.state.nowSearchAndOr == "Or") {
              if (nowCtgFlg || nowBdgFlg) {
                nowCtgBdgFlg = true;
              } else {
                nowCtgBdgFlg = false;
              }
            } else {
              if (nowCtgFlg && nowBdgFlg) {
                nowCtgBdgFlg = true;
              } else {
                nowCtgBdgFlg = false;
              }
            }
          }
          if ((this.state.nowBm == undefined || this.state.nowBm[0] == "all"
            || (this.state.nowBm[1] == "1" && this.state.bmSet[dashboardTable.key] != undefined)
            || (this.state.nowBm[2] == "2" && this.state.bmSet[dashboardTable.key] == undefined))
            && nowCtgBdgFlg) {
            this.state.dashboardAccount = this.state.dashboardAccount + 1;
            // 権限制御
            const lDashboardId = dashboardTable.key.split('.')[0];
            const mDashboardId = dashboardTable.key.split('.', 2).join('.');
            let availability = false;
            const dashboardAuthConf = this.dashboardAuthConf.Auth_Conf.find(auth => auth.Key === lDashboardId);
            if (dashboardAuthConf?.Availability) {  // 大見出しレベルで参照権限がある
              const dashboardList = dashboardAuthConf.DashboardList.find(d => d.Key === mDashboardId);
              if (dashboardList?.Availability) {  // 中見出しレベルで参照権限がある
                const authDashboard = dashboardList.Dashboard.find(d => d === dashboardTable.key);
                if (authDashboard) {  // 小見出しレベルで参照権限がある
                  if (  // アクティブフラグの判定
                    (this.mode === analysisMode.standalone && dashboardTable.activeFlg)
                    || (this.mode === analysisMode.consolidated && dashboardTable.groupFlg)
                  ) {
                    availability = true;
                  }
                }
              }
            }
            return (<tr key={index}>
              <td className="dashboard-table__td--name-1 text-caption-2 py-2">{this.state.pageText[monitoringInfo.key].id} {this.state.pageText[monitoringInfo.key].name}</td>
              <td className="dashboard-table__td--name-2 text-caption-2 py-2">{(this.state.pageText[monitoringInfo.key].dashboardList)[(dashboardTable.key.split(".")[1] - 1)].name}</td>
              <td className={`dashboard-table__td--name-3 text-caption-2 py-2 ${!availability ? ' is-disabled' : ''}`}>
                {(() => {
                  const dashboardName = ((this.state.pageText[monitoringInfo.key].dashboardList)[(dashboardTable.key.split(".")[1] - 1)].dashboard)[dashboardTable.key.split(".")[2] - 1]?.name + (dashboardTable.quarterFlg ? " [Y/Q]" : "")
                  if (availability) {
                    return (
                      <Link color="inherit" className="text-link" href={monitoringInfo.url + (dashboardTable.key.split(".")[1] - 1) + "/" + (dashboardTable.key.split(".")[2] - 1) + "/"} target="_blank">
                        {dashboardName}
                      </Link>
                    )
                  } else {
                    return (
                      <p className="text-link">{dashboardName}</p>
                    )
                  };
                })()}
              </td>
              <td className="dashboard-table__td--preview py-2">
                <span className="preview-img"><img src={dashboardTable.img} alt="" /></span>
              </td>
              <td className="dashboard-table__td--badge py-2">
                <ul className="badge-list">
                  {
                    (dashboardTable.badge && dashboardTable.badge.CAS) ?
                      (<li className="badge-list__item"><img src={img_CAS} alt="" /></li>) :
                      ''
                  }
                  {
                    (dashboardTable.badge && dashboardTable.badge.ISO) ?
                      (<li className="badge-list__item"><img src={img_ISO} alt="" /></li>) :
                      ''
                  }
                  {
                    (dashboardTable.badge && dashboardTable.badge.GRI) ?
                      (<li className="badge-list__item"><img src={img_GRI} alt="" /></li>) :
                      ''
                  }
                </ul>
              </td>
              <td className="dashboard-table__td--tag text-caption-2 py-4">
                {
                  (this.state.ctSet[dashboardTable.key] === undefined) ?
                    ""
                    :
                    ((this.state.ctSet[dashboardTable.key].map((item, index) => {
                      return (
                        <ul className="tag-list" key={index}>
                          <li className="tag-list__item inline">
                            <span>{this.ctgCategoryName[item["CategoryKey"]]}</span>
                            <button className="tag-memo-btn" disabled={(item["Memo"] == null || item["Memo"] == '') ? 'disabled' : ''}>
                              <span className="icon-memo"></span>
                              <div className="box-tag-memo rounded">
                                <span>{item["Memo"]}</span>
                              </div>
                            </button>
                          </li>
                        </ul>
                      );
                    })))
                }
                {
                  (((this.mode === analysisMode.standalone && dashboardTable.activeFlg) || (this.mode === analysisMode.consolidated && dashboardTable.groupFlg))
                    && (this.categoryTagAuthConf.Availability && this.categoryTagAuthConf.Auth_Conf.CategoryTag_Set) && availability) ?  // 権限制御（用途タグ）
                    ((this.state.ctSet[dashboardTable.key] === undefined) ?
                      (<button className="text-link" onClick={() => this.openCtModal(dashboardTable.key)}>
                        追加する
                      </button>)
                      :
                      (<button className="text-link mt-0.5" onClick={() => this.openCtModal(dashboardTable.key)}>
                        編集する
                      </button>))
                    :
                    ((this.state.ctSet[dashboardTable.key] === undefined) ?
                      (<div className="grey--text text--lighten-45 text-center">ー</div>)
                      :
                      "")
                }
              </td>
              <td className="dashboard-table__td--bookmark text-caption-2 py-2">
                <div className="flex items-center gap-x-4">
                  <div className="shrink-0 grow-0 leading-none">
                    <button type="button" disabled={!((this.mode === analysisMode.standalone && dashboardTable.activeFlg) || (this.mode === analysisMode.consolidated && dashboardTable.groupFlg)) || !availability} onClick={() => this.openBmModal(dashboardTable.key, ((this.state.pageText[monitoringInfo.key].dashboardList)[(dashboardTable.key.split(".")[1] - 1)].dashboard)[dashboardTable.key.split(".")[2] - 1].name)}>
                      {this.state.bmSet[dashboardTable.key] != undefined ?
                        <span className="icon-bookmark-on"></span> :
                        <span className="icon-bookmark-off"></span>
                      }
                    </button>
                  </div>
                  <div className="grow">
                    {
                      (this.state.bmSet[dashboardTable.key] === undefined) ?
                        (<div className="grey--text text--lighten-45 text-center">ー</div>) :
                        (
                          (this.state.bmSet[dashboardTable.key] === null || this.state.bmSet[dashboardTable.key] === '') ?
                            (<div className="grey--text text--lighten-45">メモがありません</div>) :
                            this.state.bmSet[dashboardTable.key]
                        )
                    }
                  </div>
                </div>
              </td>
            </tr>)
          }
        })
      ))
    ));
    return tmpHtml;
  }

  getDashboardTitle = () => {
    if (this.state.dashboardAccount == 0) {
      return (
        <div className="text-center">
          <p className="text-h4 py-20">
            表示できるダッシュボードがありません。<br></br>
            表示条件を変更して再度お試し下さい。
          </p>
        </div>
      );
    } else {
      return (<tr>
        <th className="dashboard-table__th--name text-list-em grey--text text--lighten-70 py-2" colSpan="3">ダッシュボード名</th>
        <th className="dashboard-table__th--preview text-list-em grey--text text--lighten-70 py-2">プレビュー</th>
        <th className="dashboard-table__th--badge text-list-em grey--text text--lighten-70 py-2">バッジ</th>
        <th className="dashboard-table__th--tag text-list-em grey--text text--lighten-70 py-2">用途タグ</th>
        <th className="dashboard-table__th--bookmark text-list-em grey--text text--lighten-70 py-2">ブックマーク</th>
      </tr>)
    }
  }

  getBytesCount(str) {
    var cnt = 0;
    for (var i = 0; i < str.length; i++) {
      var c = str.charAt(i);
      if (/^[\u0000-\u00ff]$/.test(c)) {
        cnt++;
      } else {
        cnt += 2;
      }
    }
    return cnt;
  }

  getDispStateCtgBdgHtml = () => {
    let nowDispStateBm = this.state.dispStateBm;
    let nowDispStateCtg = this.state.dispStateCtg;
    let nowDispStateAndOr = this.state.dispStateAndOr;
    let nowDispStateBdg = this.state.dispStateBdg;
    let nowDispStateCtgSize = 0;
    let nowDispStateBdgSize = 0;
    let btyeCtgLength = this.getBytesCount(nowDispStateCtg);
    let btyeBdgLength = this.getBytesCount(nowDispStateBdg);
    nowDispStateCtgSize = getNowDispStateCtgSize(btyeCtgLength, nowDispStateBm, nowDispStateCtg, nowDispStateBdg);
    nowDispStateBdgSize = getNowDispStateBdgSize(btyeBdgLength, nowDispStateBm, nowDispStateCtg, nowDispStateBdg);
    if (nowDispStateBm == null || nowDispStateBm == "" || nowDispStateBm == allConditions.allBookmark) {
      if ((nowDispStateCtg == null || nowDispStateCtg == "" || nowDispStateCtg == allConditions.allCategory)
        && (nowDispStateBdg == null || nowDispStateBdg == "" || nowDispStateBdg == allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner">
              <ul className="dashboard-filter-box-inner-list bookmark">
                <li className="theme-text text-pulldown px-5 py-3">すべてを表示中</li>
              </ul>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.state.dashboardAccount} / {this.allAccount} ）</p>
          </div>
        );
      } else if ((nowDispStateCtg == null || nowDispStateCtg == "" || nowDispStateCtg == allConditions.allCategory)
        && (nowDispStateBdg != null && nowDispStateBdg != "" && nowDispStateBdg != allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex nobm">
              {/* // ↑ 申し送り
                指定が有効な（用途タグ指定、バッジ指定の両方がある）ときはこの <span> を表示 */}
              <ul className="dashboard-filter-box-inner-list badges px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                バッジ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="theme-text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateBdg != undefined && nowDispStateBdg != null && nowDispStateBdg != '') ? nowDispStateBdg : allConditions.allBadge}</li>
              </ul>
              {/* バッジ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 translate-x-1/2 `} style={{ right: (nowDispStateBdgSize) + 'px' }}>{nowDispStateBdg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.state.dashboardAccount} / {this.allAccount} ）</p>
          </div>
        );
      } else if ((nowDispStateCtg != null && nowDispStateCtg != "" && nowDispStateCtg != allConditions.allCategory)
        && (nowDispStateBdg == null || nowDispStateBdg == "" || nowDispStateBdg == allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex nobm nobadge">
              {/* // ↑ 申し送り
                初期表示の際、および用途タグもブックマークも指定がない場合はこの <div> 全体を消去
                用途タグの<li>を増やしていくとこのボックスが広がっていき、最大幅に達すると横スクロール表示となります */}
              <ul className="dashboard-filter-box-inner-list myds-usage-nobm-nobadge px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                用途タグ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="theme-text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateCtg != undefined && nowDispStateCtg != null && nowDispStateCtg != '') ? nowDispStateCtg : allConditions.allCategory}</li>
              </ul>
              {/* 用途タグ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 -translate-x-1/2 `} style={{ left: (nowDispStateCtgSize) + 'px' }}>{nowDispStateCtg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.state.dashboardAccount} / {this.allAccount} ）</p>
          </div>
        );
      } else {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex nobm">
              {/* // ↑ 申し送り
                初期表示の際、および用途タグもブックマークも指定がない場合はこの <div> 全体を消去
                用途タグの<li>を増やしていくとこのボックスが広がっていき、最大幅に達すると横スクロール表示となります */}
              <ul className="dashboard-filter-box-inner-list myds-usage-nobm px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                用途タグ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="theme-text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateCtg != undefined && nowDispStateCtg != null && nowDispStateCtg != '') ? nowDispStateCtg : allConditions.allCategory}</li>
              </ul>
              {/* 用途タグ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 -translate-x-1/2 `} style={{ left: (nowDispStateCtgSize) + 'px' }}>{nowDispStateCtg}</div>
              <span className="text-link text-pulldown px-3 py-2 ">{(nowDispStateAndOr != undefined && nowDispStateAndOr != null && nowDispStateAndOr != '') ? nowDispStateAndOr : "かつ"}</span>
              {/* // ↑ 申し送り
                指定が有効な（用途タグ指定、バッジ指定の両方がある）ときはこの <span> を表示 */}
              <ul className="dashboard-filter-box-inner-list badges px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                バッジ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="theme-text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateBdg != undefined && nowDispStateBdg != null && nowDispStateBdg != '') ? nowDispStateBdg : allConditions.allBadge}</li>
              </ul>
              {/* バッジ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 translate-x-1/2 `} style={{ right: (nowDispStateBdgSize) + 'px' }}>{nowDispStateBdg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.state.dashboardAccount} / {this.allAccount} ）</p>
          </div>
        );
      }
    } else {
      if ((nowDispStateCtg == null || nowDispStateCtg == "" || nowDispStateCtg == allConditions.allCategory)
        && (nowDispStateBdg == null || nowDispStateBdg == "" || nowDispStateBdg == allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner">
              {/* // ↑ 申し送り
                ブックマーク指定がない（すべてを表示）場合はこの <div> 全体を消去 */}
              <ul className="dashboard-filter-box-inner-list bookmark">
                <li className="theme-text text-pulldown px-5 py-3">{(nowDispStateBm != undefined && nowDispStateBm != null && nowDispStateBm != '') ? nowDispStateBm : allConditions.allBookmark}</li>
                {/* // ↑ 申し送り
                  初期表示の際はこの<li>のテキストを以下に変更
                  ダッシュボード一覧→ 「すべてのダッシュボード」 マイダッシュボード→ 「すべてのマイダッシュボード」  */}
              </ul>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.state.dashboardAccount} / {this.allAccount} ）</p>
          </div>
        );
      } else if ((nowDispStateCtg == null || nowDispStateCtg == "" || nowDispStateCtg == allConditions.allCategory)
        && (nowDispStateBdg != null && nowDispStateBdg != "" && nowDispStateBdg != allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner">
              {/* // ↑ 申し送り
                ブックマーク指定がない（すべてを表示）場合はこの <div> 全体を消去 */}
              <ul className="dashboard-filter-box-inner-list bookmark">
                <li className="theme-text text-pulldown px-5 py-3">{(nowDispStateBm != undefined && nowDispStateBm != null && nowDispStateBm != '') ? nowDispStateBm : allConditions.allBookmark}</li>
                {/* // ↑ 申し送り
                  初期表示の際はこの<li>のテキストを以下に変更
                  ダッシュボード一覧→ 「すべてのダッシュボード」 マイダッシュボード→ 「すべてのマイダッシュボード」  */}
              </ul>
            </div>
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex nobm">
              {/* // ↑ 申し送り
                指定が有効な（用途タグ指定、バッジ指定の両方がある）ときはこの <span> を表示 */}
              <ul className="dashboard-filter-box-inner-list badges px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                バッジ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="theme-text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateBdg != undefined && nowDispStateBdg != null && nowDispStateBdg != '') ? nowDispStateBdg : allConditions.allBadge}</li>
              </ul>
              {/* バッジ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 translate-x-1/2 `} style={{ right: (nowDispStateBdgSize) + 'px' }}>{nowDispStateBdg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.state.dashboardAccount} / {this.allAccount} ）</p>
          </div>
        );
      } else if ((nowDispStateCtg != null && nowDispStateCtg != "" && nowDispStateCtg != allConditions.allCategory)
        && (nowDispStateBdg == null || nowDispStateBdg == "" || nowDispStateBdg == allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner">
              {/* // ↑ 申し送り
                ブックマーク指定がない（すべてを表示）場合はこの <div> 全体を消去 */}
              <ul className="dashboard-filter-box-inner-list bookmark">
                <li className="theme-text text-pulldown px-5 py-3">{(nowDispStateBm != undefined && nowDispStateBm != null && nowDispStateBm != '') ? nowDispStateBm : allConditions.allBookmark}</li>
                {/* // ↑ 申し送り
                  初期表示の際はこの<li>のテキストを以下に変更
                  ダッシュボード一覧→ 「すべてのダッシュボード」 マイダッシュボード→ 「すべてのマイダッシュボード」  */}
              </ul>
            </div>
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex nobm nobadge">
              {/* // ↑ 申し送り
                初期表示の際、および用途タグもブックマークも指定がない場合はこの <div> 全体を消去
                用途タグの<li>を増やしていくとこのボックスが広がっていき、最大幅に達すると横スクロール表示となります */}
              <ul className="dashboard-filter-box-inner-list myds-usage-nobadge px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                用途タグ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="theme-text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateCtg != undefined && nowDispStateCtg != null && nowDispStateCtg != '') ? nowDispStateCtg : allConditions.allCategory}</li>
              </ul>
              {/* 用途タグ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 -translate-x-1/2 `} style={{ left: (nowDispStateCtgSize) + 'px' }}>{nowDispStateCtg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.state.dashboardAccount} / {this.allAccount} ）</p>
          </div>
        );
      } else {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner">
              {/* // ↑ 申し送り
                ブックマーク指定がない（すべてを表示）場合はこの <div> 全体を消去 */}
              <ul className="dashboard-filter-box-inner-list bookmark">
                <li className="theme-text text-pulldown px-5 py-3">{(nowDispStateBm != undefined && nowDispStateBm != null && nowDispStateBm != '') ? nowDispStateBm : allConditions.allBookmark}</li>
                {/* // ↑ 申し送り
                  初期表示の際はこの<li>のテキストを以下に変更
                  ダッシュボード一覧→ 「すべてのダッシュボード」 マイダッシュボード→ 「すべてのマイダッシュボード」  */}
              </ul>
            </div>
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex">
              {/* // ↑ 申し送り
                初期表示の際、および用途タグもブックマークも指定がない場合はこの <div> 全体を消去
                用途タグの<li>を増やしていくとこのボックスが広がっていき、最大幅に達すると横スクロール表示となります */}
              <ul className="dashboard-filter-box-inner-list myds-usage px-4 pt-2 pb-1 flex" id="myCtgUi">
                {/* // ↑ 申し送り
                用途タグ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="theme-text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateCtg != undefined && nowDispStateCtg != null && nowDispStateCtg != '') ? nowDispStateCtg : allConditions.allCategory}</li>
              </ul>
              {/* 用途タグ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 -translate-x-1/2 `} style={{ left: (nowDispStateCtgSize) + 'px' }}>{nowDispStateCtg}</div>
              <span className="text-link text-pulldown px-3 py-2 ">{(nowDispStateAndOr != undefined && nowDispStateAndOr != null && nowDispStateAndOr != '') ? nowDispStateAndOr : "かつ"}</span>
              {/* // ↑ 申し送り
                指定が有効な（用途タグ指定、バッジ指定の両方がある）ときはこの <span> を表示 */}
              <ul className="dashboard-filter-box-inner-list badges px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                バッジ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="theme-text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateBdg != undefined && nowDispStateBdg != null && nowDispStateBdg != '') ? nowDispStateBdg : allConditions.allBadge}</li>
              </ul>
              {/* バッジ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 translate-x-1/2 `} style={{ right: (nowDispStateBdgSize) + 'px' }}>{nowDispStateBdg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.state.dashboardAccount} / {this.allAccount} ）</p>
          </div>
        );
      }
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return null;
    }
    if (this.isError) {
      return <Error />;
    }
    let dashboardTableList = [];
    let monitoringTableList = [];
    let dashboardTitle = "";
    let dispStateCtgBdg = "";
    this.state.dashboardAccount = 0;
    this.allAccount = 0;
    this.noBadgeAccount = 0;
    this.casAccount = 0;
    this.isoAccount = 0;
    this.griAccount = 0;
    this.catAccount = [];
    this.catList.map((item, index) => {
      this.catAccount[index] = 0;
    })
    this.ctAccount = 0;
    dashboardTableList = this.getPageInfoList(mainPageInfoList);
    monitoringTableList = this.getPageInfoList(monitoringInfoList);
    dashboardTitle = this.getDashboardTitle();
    dispStateCtgBdg = this.getDispStateCtgBdgHtml();
    return (
      <main className="page-container">
        {/* トースト表示枠 */}
        <div className="box-toast flex items-center pl-8 blue1 rounded-b-2xl fixed left-1/2 z-20">
          <div className="box-toast__text text-h6 white--text mr-1">{this.state.toastMessage}</div>
          <button type="button" className="box-toast__btn white--text p-5 leading-none"><span className="icon-close-s" onClick={() => this.removeToast()}></span></button>
        </div>
        <section className="page-content">
          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="category-page-header flex items-center my-6">
              <div className="category-page-header__img ml-4 mr-8"><img src={img_category_dashboard} alt="" /></div>
              <div className="category-page-header__text pl-8 relative">
                <div className="category-page-header__subtitle flex items-center red--text">
                  <span className="category-page-header__subtitle-text text-h6-en category-page-header__subtitle-text--no-line">Dashboard Index</span>
                </div>
                <h1 className="category-page-header__title">
                  <span className="category-page-header__title-text text-h2">ダッシュボード一覧</span>
                </h1>
                <p className="category-page-header__description text-caption-2 grey--text text--lighten-70">
                  全てのダッシュボードにワンクリックでアクセス。ブックマークやタグの編集にも便利です。
                </p>
              </div>
            </header>
            {/* コンテンツボディ */}
            <div className="page-body">
              {/* ===== 表示設定 ===== */}
              <div className="dashboard-filter grey rounded-t-lg px-8 flex">
                {/* // ↑ 申し送り
                ダッシュボード一覧→ `rounded-lg` マイダッシュボード→ `rounded-t-lg` をclass 指定するとバーの形状が変わります */}
                <h6 className="text-h6 white--text py-6 pr-2">表示対象：</h6>
                {dispStateCtgBdg}
                <div className="dashboard-filter-box button-box my-3">
                  <div className="dashboard-filter-box-inner buttons flex">
                    <button type="button" className="filter-btn white--text text-h7" onClick={() => { this.openSetModal() }}>表示条件を設定</button>
                    <button type="button" className="text-h7 text-link py-3 pl-6" onClick={() => { this.resetAll() }}>リセット</button>
                  </div>
                </div>
              </div>
              {/* ===== 表示設定 ===== */}
              <table className={`w-full mt-8 ${this.state.dashboardAccount == 0 ? '' : ' dashboard-table'}`}>
                <thead>
                  {dashboardTitle}
                </thead>
                <tbody>
                  {dashboardTableList}
                  {monitoringTableList}
                </tbody>
              </table>
              {/* /.dashboard-table */}
            </div>
          </div>
        </section>

        {/* ========== モーダルウィンドウ ========== */}
        <div className="bg-modal-window w-screen h-screen fixed top-0 left-0 grey lighten-70 z-50" onClick={() => this.closeBmModal()}></div>
        {/* ===== ブックマーク入力 ===== */}
        <div className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn" id="modal-window--bookmark-entry">
          <div className="modal-window__header">
            <div className="modal-window__title flex items-center justify-center py-10">
              <span className="modal-window__title-icon icon-bookmark-on grey--text mr-2"></span>
              <span className="modal-window__title-text text-h3 font-medium">ブックマーク</span>
            </div>
            <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => this.closeBmModal()}><span className="icon-close"></span></button>
          </div>
          <div className="modal-window__content px-8 pb-10">
            {/* エラー時表示 */}
            {this.state.bmError &&
              <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">
                エラーが発生しました。<br />
                時間をおいて再度お試しください。
              </div>
            }
            {/* /エラー時表示 */}
            <div className="form-control">
              <textarea maxLength="200" placeholder="メモを入力（任意、最大120文字）" className="modal-window__content-textarea rounded-lg px-4 py-3 w-full" onChange={(e) => this.handleChangeBmMemo(e)} value={this.state.bmMemo}></textarea>
            </div>
            <div className="modal-window__btns text-center mt-8">
              <button className="btn btn-modal-bookmark white--text grey rounded-lg px-16 text-h5 font-medium" onClick={() => this.registBm()}>ブックマークする</button>
            </div>
          </div>
        </div>
        {/* ===== /ブックマーク入力 ===== */}
        {/* ===== ブックマーク中 ===== */}
        <div className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn" id="modal-window--bookmark-on">
          <div className="modal-window__header">
            <div className="modal-window__title flex items-center justify-center py-10">
              <span className="modal-window__title-icon icon-bookmark-on red--text mr-2"></span>
              <span className="modal-window__title-text text-h3 font-medium">ブックマーク中</span>
            </div>
            <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => this.closeBmModal()}><span className="icon-close"></span></button>
          </div>
          <div className="modal-window__content px-8 pb-10">
            {/* エラー時表示 */}
            {this.state.bmError &&
              <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">
                エラーが発生しました。<br />
                時間をおいて再度お試しください。
              </div>
            }
            {/* /エラー時表示 */}
            <div className="form-control">
              <textarea maxLength="200" placeholder="メモを入力（任意、最大120文字）" className="modal-window__content-textarea rounded-lg px-4 py-3 w-full" onChange={(e) => this.handleChangeBmMemo(e)} value={this.state.bmMemo}></textarea>
            </div>
            <div className="modal-window__btns text-center mt-8">
              <div className="flex gap-4">
                <button className="btn btn-outline btn-modal-delete-bookmark rounded-lg px-16 text-h5 font-medium" onClick={() => this.deleteConfBm()}>ブックマークを削除</button>
                <button className="btn btn-modal-save white--text grey rounded-lg px-16 text-h5 font-medium" onClick={() => { this.updateBm() }}>保存</button>
              </div>
            </div>
          </div>
        </div>
        {/* ===== /ブックマーク中 ===== */}
        {/* ===== ブックマークの削除確認 ===== */}
        <div className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn" id="modal-window--bookmark-delete-confirm">
          <div className="modal-window__header">
            <div className="modal-window__title flex items-center justify-center py-10">
              <span className="modal-window__title-icon icon-bookmark-on red--text mr-2"></span>
              <span className="modal-window__title-text text-h3 font-medium">ブックマークの削除確認</span>
            </div>
            <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => this.closeBmModal()}><span className="icon-close"></span></button>
          </div>
          <div className="modal-window__content px-8 pb-10">
            <div className="text-body text-center mb-14">
              ブックマーク及び入力したメモを<br />
              削除してもよろしいでしょうか？
            </div>
            <div className="modal-window__btns text-center mt-8">
              <div className="flex gap-4">
                <button className="btn btn-outline btn-modal-back rounded-lg px-16 text-h5 font-medium" onClick={() => this.deleteBackBm()}>戻る</button>
                <button className="btn btn-modal-delete white--text red rounded-lg px-16 text-h5 font-medium" onClick={() => this.deleteBm()}>削除</button>
              </div>
            </div>
          </div>
        </div>
        {/* ===== /ブックマークの削除確認 ===== */}
        {/* ===== 用途タグ中 ===== */}
        <div className="modal-window modal-window__wide fixed bg sub z-50 rounded-2xl" id="modal-window--tag-list">
          <div className="modal-window__header">
            <div className="modal-window__title flex items-center justify-center pt-10">
              <span className="modal-window__title-icon icon-tag-fill red--text mr-2"></span>
              <span className="modal-window__title-text text-h3 font-medium">用途タグ一覧</span>
            </div>
            <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => this.closeCtModal()}><span className="icon-close"></span></button>
          </div>
          <div className="modal-window__content px-8 py-10">
            {/* エラー時表示 */}
            {this.state.ctError &&
              <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">
                エラーが発生しました。<br />
                時間をおいて再度お試しください。
              </div>
            }
            {/* /エラー時表示 */}
            <div id="form--tag-list">
              <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">※設定したタグや入力したメモは全員に表示されます。</div>
              <div className="form-control">
                <ul>
                  {/* チェックボックスがONのとき、そのリストアイテム（li）に対して is-activeクラスを追加してください。
                  <li className="px-6 py-3 is-active">
                  ※ そのリストアイテムの中の *__inactive クラスのついている要素が非表示になります
                  ※ そのリストアイテムの中の *__active クラスのついている要素が表示になります */}
                  {this.catList.map((item, index) => {
                    return (
                      <li className="px-6 py-3 is-active" key={index} id={`chLi${item["CategoryKey"]}`} name="chLi">
                        <dl className="flex items-center">
                          <dt className="mr-6">
                            <label className="ui-checkbox">
                              <input type="checkbox" id={`checkbox${item["CategoryKey"]}`} className="chBox ui-checkbox__input" value={item["CategoryKey"]} onClick={() => this.checkOne()}></input>
                              <span className="ui-checkbox__icon"></span>
                              <span className="ui-checkbox__ripple"></span>
                              <span className="ui-checkbox__label text-h7 font-Regular">{item["CategoryName"]}</span>
                            </label>
                          </dt>
                          <dd>
                            <span className="__inactive text-caption-2 grey--text text--lighten-35 px-4 py-2">未設定</span>
                            <textarea maxLength="200" placeholder="メモを入力（任意、最大120文字）" id={`chTextarea${item["CategoryKey"]}`} name="chTextarea" className="__active modal-window__content-textarea rounded-lg px-4 py-3 w-full" onChange={(e) => this.handleChangeCtgMemo(item["CategoryKey"], e)}></textarea>
                          </dd>
                        </dl>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="text-center mt-8 div-mid">
                <button className="btn btn-outline modal-window__btn-close rounded-lg px-16 mr-8 text-h5 font-medium btn-margin" onClick={() => this.closeCtModal()}>戻る</button>
                <button className="btn btn-modal-tag-apply white--text grey rounded-lg px-16 text-h5 font-medium btn-margin" onClick={() => this.saveCategory()}>設定</button>
              </div>
            </div>
          </div>
        </div>
        {/* ===== /用途タグ中 ===== */}
        {/* ===== 表示条件詳細 ===== */}
        <div className="modal-window modal-window__wide modal-window--dashboard-filter fixed bg sub z-50 rounded-2xl animate__fadeIn" id="modal-window--dashboard-filter">
          <div className="modal-window__header">
            <div className="modal-window__title flex items-center justify-center pt-10">
              <span className="modal-window__title-text text-h3 font-medium">表示条件詳細</span>
            </div>
            <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => this.closeSetModal()}><span className="icon-close"></span></button>
          </div>
          <div className="modal-window__content px-8 py-10">
            <div id="form--dashboard-filter">
              <div className="box-xy-bordered flex rounded-xl py-8">
                <dl className="flex px-8 items-center">
                  <dt className="shrink-0 w-48 text-h5">
                    <div className="flex items-center"><span className="icon-bookmark-off red--text mr-4"></span>ブックマーク</div>
                  </dt>
                  <dd>
                    <ul className="flex gap-x-8">
                      <li>
                        <label className="ui-radio">
                          <input type="radio" id="radioBmAll" className="radioBmAll ui-radio__input" name="bookmark" value="0" onClick={() => this.radioOne("bmAll")}></input>
                          <span className="ui-radio__icon"></span>
                          <span className="ui-radio__ripple"></span>
                          <span className="ui-radio__label text-h7 font-Regular">すべてを表示</span>
                        </label>
                      </li>
                      <li>
                        <label className="ui-radio">
                          <input type="radio" id="radioBmHave" className="radioBm ui-radio__input" name="bookmark" value="1" onClick={() => this.radioOne("have")}></input>
                          <span className="ui-radio__icon"></span>
                          <span className="ui-radio__ripple"></span>
                          <span className="ui-radio__label text-h7 font-Regular">ブックマークあり（{this.bmList.length}）</span>
                        </label>
                      </li>
                      <li>
                        <label className="ui-radio">
                          <input type="radio" id="radioBmNohave" className="radioBm ui-radio__input" name="bookmark" value="2" onClick={() => this.radioOne("nohave")}></input>
                          <span className="ui-radio__icon"></span>
                          <span className="ui-radio__ripple"></span>
                          {/* ブックマークなし（000） */}
                          <span className="ui-radio__label text-h7 font-Regular">ブックマークなし（{(this.allAccount - this.bmList.length)}）</span>
                        </label>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
              <div className="box-xy-bordered rounded-xl mt-4 py-8">
                <dl className="flex px-8">
                  <dt className="shrink-0 w-48 text-h5">
                    <div className="flex items-center"><span className="icon-tag-outline red--text mr-4"></span>用途タグ</div>
                  </dt>
                  <dd>
                    {/* 一部が選択されている状態を表現するには
                      input[type="checkbox"] が indeterminate（未確定）状態になるか、 classに 'indeterminate' を追加します
                     */}
                    <ul className="flex flex-wrap">
                      <li>
                        <label className="ui-checkbox mb-6">
                          <input type="checkbox" id="chBoxCtgAll" name="tag_all" value="0" className="chBoxCtgAll ui-checkbox__input" onClick={() => this.checkAllCtg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          <span className="ui-checkbox__label text-h7 font-Regular">すべてを表示</span>
                        </label>
                      </li>
                    </ul>
                    <ul className="flex flex-wrap ml-10">
                      <li>
                        <label className="ui-checkbox mb-6">
                          <input type="checkbox" id="chBoxCtgNull" name="tag[]" value="99" className="chBoxCtg ui-checkbox__input" onClick={() => this.checkOneCtg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          <span className="ui-checkbox__label text-h7 font-Regular">用途タグなし（{(this.allAccount - this.ctAccount)}）</span>
                        </label>
                      </li>
                    </ul>
                    <ul className="flex flex-wrap gap-x-8 gap-y-6 ml-10">
                      {this.catList.map((item, index) => {
                        return (
                          <li key={index}>
                            <label className="ui-checkbox">
                              <input type="checkbox" id={`chBoxCtg${item["CategoryKey"]}`} name="chBoxCtg" value={item["CategoryKey"]} className="chBoxCtg ui-checkbox__input" onClick={() => this.checkOneCtg()}></input>
                              <span className="ui-checkbox__icon"></span>
                              <span className="ui-checkbox__ripple"></span>
                              <span className="ui-checkbox__label text-h7 font-Regular">{item["CategoryName"]}（{this.catAccount[index]}）</span>
                            </label>
                          </li>
                        )
                      })}
                    </ul>
                  </dd>
                </dl>
                <div className="divider my-8">
                  <div className="btn-group-container flex justify-center">
                    <input type="radio" id="searchAnd" className="ui-radio__input" name="and_or" value="and" onClick={() => this.searchAnd(true)} defaultChecked={this.state.isAndOr}></input>
                    <label className="btn text-h7 px-9" htmlFor="searchAnd">
                      <span className="ui-radio__label">かつ（ AND ）</span>
                    </label>
                    <input type="radio" id="searchOr" className="ui-radio__input" name="and_or" value="or" onClick={() => this.searchOr(false)} defaultChecked={!this.state.isAndOr}></input>
                    <label className="btn text-h7 px-9" htmlFor="searchOr">
                      <span className="ui-radio__label">または（ OR ）</span>
                    </label>
                  </div>
                </div>
                <dl className="flex px-8">
                  <dt className="shrink-0 w-48 text-h5">
                    <div className="flex items-center"><span className="icon-badge-outline red--text mr-4"></span>バッジ</div>
                  </dt>
                  <dd>
                    <ul className="flex flex-wrap">
                      <li>
                        <label className="ui-checkbox mb-6">
                          <input type="checkbox" id="chBoxBdgAll" name="badge_all" className="chBoxBdgAll ui-checkbox__input" value="0" onClick={() => this.checkAllBdg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          <span className="ui-checkbox__label text-h7 font-Regular">すべてを表示</span>
                        </label>
                      </li>
                    </ul>
                    <ul className="flex flex-wrap ml-10">
                      <li>
                        <label className="ui-checkbox mb-6">
                          <input type="checkbox" id="chBoxBdgNull" name="badge[]" className="chBoxBdg ui-checkbox__input" value="99" onClick={() => this.checkOneBdg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          <span className="ui-checkbox__label text-h7 font-Regular">バッジなし（{this.noBadgeAccount}）</span>
                        </label>
                      </li>
                    </ul>
                    <ul className="flex flex-wrap gap-x-8 gap-y-6 ml-10">
                      <li>
                        <label className="ui-checkbox">
                          <input type="checkbox" id="chBoxBdgCAS" name="chBoxBdg" className="chBoxBdg ui-checkbox__input" value="CAS" onClick={() => this.checkOneBdg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          <span className="ui-checkbox__label text-h7 font-Regular">CAS（{this.casAccount}）</span>
                        </label>
                      </li>
                      <li>
                        <label className="ui-checkbox">
                          <input type="checkbox" id="chBoxBdgISO" name="chBoxBdg" className="chBoxBdg ui-checkbox__input" value="ISO" onClick={() => this.checkOneBdg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          <span className="ui-checkbox__label text-h7 font-Regular">ISO30414（{this.isoAccount}）</span>
                        </label>
                      </li>
                      <li>
                        <label className="ui-checkbox">
                          <input type="checkbox" id="chBoxBdgGRI" name="chBoxBdg" className="chBoxBdg ui-checkbox__input" value="GRI" onClick={() => this.checkOneBdg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          {/* GRI（33） */}
                          <span className="ui-checkbox__label text-h7 font-Regular">GRI（{this.griAccount}）</span>
                        </label>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
              <div className="modal-window__btns text-center mt-8">
                <button id="saveDashboardBtn" className="btn btn-modal-dashboard-filter-apply white--text grey rounded-lg px-16 text-h5 font-medium btn-mid" onClick={() => { this.saveDashboard() }}>この条件で表示</button>
                <button id="saveDashboardLabel" className="btn white--text grey-disabled rounded-lg px-16 text-h5 btn-mid display-none">この条件で表示</button>
              </div>
            </div>
          </div>
        </div>
        {/* ===== /表示条件詳細 ===== */}
        {/* ========== /モーダルウィンドウ ========== */}
      </main>
    );
  }
}

const DashboardList = () => {
  const state = useSelector((state) => state);
  return <DashboardListContent
    userId={state.user.sub}
    corporationNumber={state.company.corporationNumber}
    pageTitle={titleName.dashboardList + titleName.common}
    mode={state.analysis.mode}
    authority={state.authority}
  />;
};

export default DashboardList;
