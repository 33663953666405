"use strict";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { getDynamoDB } from "../../common/utils/DynamoDbUtils";
import { titleName, table } from "../../_constants/Code";
import { useToast } from "../../_components/Toast";

const BenchmarkTargetListContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [myList, setMyList] = React.useState([]);
  const [sharedList, setSharedList] = React.useState([]);

  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    init();
    if (props.message) {
      props.showToast(props.message);
    }
  }, []);

  /**
   * 初期表示
   */
  const init = async () => {
    // ベンチマーク対象指定条件テーブルを取得
    let benchmarkTargetList = await getDynamoDB(
      table.BenchmarkTarget,
      null,
      props.corporationNumber,
      null,
      null
    );
    // ユーザマスタを取得
    let user = await getDynamoDB(table.User,
      null,
      props.corporationNumber,
      null,
      null
    );
    if (benchmarkTargetList.length > 0) {
      benchmarkTargetList = benchmarkTargetList.filter(b => user.find(u => b.UserId === u.UserId));
      let myList = benchmarkTargetList.filter(b => b.UserId === props.userId);
      myList = myList.sort((a, b) => {
        if (a.Title > b.Title) return 1;
        if (b.Title > a.Title) return -1;
        return 0;
      });
      let sharedList = benchmarkTargetList.filter(b => b.UserId !== props.userId && b.SharedFlag);
      sharedList = sharedList.sort((a, b) => {
        if (a.Title > b.Title) return 1;
        if (b.Title > a.Title) return -1;
        return 0;
      });
      setMyList(myList);
      setSharedList(sharedList);
    }
    setIsLoaded(true);
  }

  const _getConditionKindName = (conditionKind, companyList = []) => {
    if (conditionKind === "1") return "属性指定";
    else if (conditionKind === "2") return "個別指定 / " + companyList.length + "社";
    else return "";
  }

  // 説明列 ツールチップ対策でコンポーネント化
  const DescriptionColumn = ({ props }) => {
    const [isTruncated, setIsTruncated] = React.useState(false);
    const descriptionRef = React.useRef();
    React.useEffect(() => {
      const element = descriptionRef.current;
      if (element) {
        const isOverflowing = element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight;
        setIsTruncated(isOverflowing);
      }
    }, [props.description]);
    return (
      <div className="tooltip" key={props.idx}>
        <div className="tooltip__base truncate" ref={descriptionRef}>
          {props.description}
        </div>
        {isTruncated && 
          <div className="tooltip__box rounded w-full">
            <span>{props.description}</span>
          </div>
        }
      </div>
    )
  };

  const baseTable = (isMine, list) => {
    return (
      <div className="setting-table is-benchmark">
        <table className="setting-table__table table-fixed w-full">
          <thead>
            <tr>
              <th className="setting-table__col-benchmark-name">タイトル</th>
              <th className="setting-table__col-benchmark-classification">設定の種類・対象の数</th>
              <th className="setting-table__col-benchmark-explanation">説明</th>
              <th className="setting-table__col-benchmark-registrant">登録者</th>
              <th className="setting-table__col-benchmark-last-update">最終更新日時</th>
            </tr>
          </thead>
          <tbody className="text-caption-1">
            {list.map((data, idx) => {
              return (
                <tr key={data.Key}>
                  <th className="!whitespace-normal">
                    <Link variant="button" underline="none" component={RouterLink} to={"/benchmark/benchmarkTargetInfo/" + (isMine ? "change/" : "reference/") + data.Key}>
                      <span style={{ fontSize: "16px", textTransform: "none" }}>{data.Title}</span>
                    </Link>
                    {isMine && data.SharedFlag && <span className="badge-shared ml-2">共有中</span>}
                  </th>
                  <td>{_getConditionKindName(data.ConditionKind, data.ConditionInfo.CompanyList)}</td>
                  <td>
                    <DescriptionColumn props={{ "idx": idx , "description": data.Description }} />
                  </td>
                  <td>{data.CreateUserName}</td>
                  <td>{data.UpdateDateTime.slice(0, -3)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-16">{titleName.benchmarkTargetList}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              {/*  ===== リード ===== */}
              <p className="text-body text-center">
                ベンチマーク機能に対応したダッシュボードで比較対象とする企業を設定します。
              </p>
              {/*  ===== /リード ===== */}
              <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl mt-20 pt-10 pb-16 px-16 border-blue1">
                <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">ベンチマーク設定一覧</h2>
                {/*  ===== 登録済みの設定 ===== */}
                <div className="flex items-center justify-between my-6">
                  <div className="text-body-2-medium grow">
                    <div>登録済みの設定</div>
                    <div className="mt-1 link--text font-normal">自分で登録した設定です。タイトルをクリックして設定内容を確認・編集できます。</div>
                  </div>
                  <div className="pl-10 py-3 border-l border-blue2-lighten-20">
                    <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/benchmark/benchmarkTargetInfo/new">
                      <button type="button" className="btn white--text link rounded-lg py-1.5 pl-7 pr-10 inline-flex items-center">
                        <span className="icon-plus-line icon-32px mr-1.5 my-px"></span>
                        <span className="-translate-y-px text-h7">新規登録</span>
                      </button>
                    </Link>
                  </div>
                </div>
                {(() => {
                  if (myList.length !== 0 ) {
                    return baseTable(true, myList);
                  } else {
                    return (
                      <div className="text-center my-10 pt-10">
                        <span className="text-h5 red--text">登録されているベンチマーク設定がありません</span>
                      </div>
                    );
                  }
                })()}
                <div className="border-t border-blue1 mt-16 mb-7 -mx-16"></div>
                {/*  ===== 共有された設定 ===== */}
                <div className="flex items-center justify-between my-6">
                  <div className="text-body-2-medium grow">
                    <div>共有された設定</div>
                    <div className="mt-1 link--text font-normal">他のユーザーが登録し、共有された設定です。編集はできません。</div>
                  </div>
                </div>
                {(() => {
                  if (sharedList.length !== 0 ) {
                    return baseTable(false, sharedList);
                  } else {
                    return (
                      <div className="text-center my-10 pt-10">
                        <span className="text-h5 red--text">共有されているベンチマーク設定がありません</span>
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  )
}

const BenchmarkTargetList = () => {
  const state = useSelector((state) => state);
  const location = useLocation();
  const showToast = useToast();

  return <BenchmarkTargetListContent
    pageTitle={titleName.benchmarkTargetList + titleName.common}
    corporationNumber={state.company.corporationNumber}
    userId={state.user.sub}
    message={location.state?.message}
    showToast={showToast}
  />;
};

export default BenchmarkTargetList;
