import { analysisMode } from "../../_constants/Code";

export const hasGroupFlg = (list) => {
  for (const item of list) {
    if (item.groupFlg) {
      return true;
    }
  }
  return false;
}

export const hasActiveFlg = (list) => {
  for (const item of list) {
    if (item.activeFlg) {
      return true;
    }
  }
  return false;
}

export const hasActiveFlgOrGroupFlg = (list) => {
  for (const item of list) {
    if (item.activeFlg || item.groupFlg) {
      return true;
    }
  }
  return false;
}

// 中見出しの判定
export const availabilityMiddleTitle = (mode, authConf, dashboardList, middleId) => {
  const authDashboard = authConf?.DashboardList.find(d => d.Key === middleId);
  if (authDashboard && authDashboard.Availability) {
    if (mode === analysisMode.standalone) {  // 独立会社もしくは単体モードの場合
      return hasActiveFlg(dashboardList);
    } else {  // 連結モードの場合
      return hasGroupFlg(dashboardList);
    }
  } else {
    return false;
  }
}

// 小見出しの判定
export const availabilitySmallTitle = (mode, authConf, dashboard, middleId, idx) => {
  const smallId = middleId + '.' + String(idx + 1);
  let authDashboard = authConf.DashboardList.find(d => d.Key === middleId);
  authDashboard = authDashboard.Dashboard.find(d => d === smallId);
  if (authDashboard) {
    if (mode === analysisMode.standalone) {  // 独立会社もしくは単体モードの場合
      return dashboard.activeFlg;
    } else {  // 連結モードの場合
      return dashboard.groupFlg;
    }
  } else {
    return false;
  }
}
