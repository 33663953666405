import * as React from "react";
import { titleName } from "../_constants/Code";
import { Message } from "../_components/MessagePage";

const Forbidden = () => {
  const messageList = [
    "指定したページへのアクセスは禁止されています。"
  ];
  // タイトル設定
  document.title = titleName.forbidden + titleName.common;
  return (
    <Message
      title="アクセスできません"
      messageList={messageList}
      loginBtn={false}
      topBtn={true}
    />
  );
};

export default Forbidden;
