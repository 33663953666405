import { compose, createStore } from "redux";
import persistState from "redux-localstorage";

/*const action = {
  type: "SET_LOGIN",
};*/

const initialState = {
  login: false,
  user: null,
  company: null,
  analysis: null,
  authority: null
};

const finalCreateStore = compose(persistState());

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOGIN":
      return { login: true, user: action.payload.user, company: action.payload.company, analysis: action.payload.analysis, authority: action.payload.authority };
    case "SET_LOGOUT":
      return { login: false, user: null, company: null, analysis: null, authority: null };
    case "SET_ANALISYS_MODE":
      return { login: state.login, user: state.user, company: state.company, analysis: action.payload.analysis, authority: state.authority };
    case "SET_AUTHORITY":
      return { ...state, authority: action.payload.authority}
      default:
      return state;
  }
};

const store = createStore(reducer, finalCreateStore);

//store.dispatch(action);

export default store;
