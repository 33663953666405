"use strict";
import * as React from "react";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import { viewId, titleName } from "../../_constants/Code";
import { errorLog } from "../../common/logger/Logger";

import { getMessage } from "../../common/utils/MessageUtils";

const ChangePasswordContent = (props) => {
  // タイトル設定
  document.title = props.pageTitle;
  window.scrollTo(0, 0);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState("");
  const [error, setError] = React.useState("");

  const handleChangeOldPassword = (e) => {
    setOldPassword(e.target.value);
  };

  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleChangeNewPasswordConfirm = (e) => {
    setNewPasswordConfirm(e.target.value);
  };

  const change = async () => {
    setError("");
    let errorMessage = "";
    if (!oldPassword) {
      errorMessage = getMessage("E010001", "現在のパスワード");
    } else if (!newPassword) {
      errorMessage = getMessage("E010001", "新しいパスワード");
    } else if (!newPasswordConfirm) {
      errorMessage = getMessage("E010001", "新しいパスワード（確認）");
    } else if (newPassword !== newPasswordConfirm) {
      errorMessage = getMessage("E011001", "新しいパスワード", "新しいパスワード（確認）");
    }
    if (errorMessage === "") {
      await Auth.currentAuthenticatedUser()
        .then(async (user) => {
          await Auth.changePassword(user, oldPassword, newPassword)
            .then(() => {
              const boxToastTimeoutSec = 4000;
              setError("");
              setOldPassword("");
              setNewPassword("");
              setNewPasswordConfirm("");
              addToastClassName();
              setTimeout(() => {
                removeToastClassName();
              }, boxToastTimeoutSec);
            })
            .catch((e) => {
              switch (e.code) {
                case "NotAuthorizedException":
                  // oldPassword が誤っている場合に起こる。
                  errorMessage = getMessage("E010002", "現在のパスワード");
                  break;
                case "InvalidParameterException":
                case "InvalidPasswordException":
                  // パスワードポリシーに違反している場合に起こる。
                  errorMessage = getMessage("E010007", "新しいパスワード");
                  break;
                case "LimitExceededException":
                  // oldPassword を間違え続けた場合に起こる。
                  // デフォルトは5回
                  errorMessage = getMessage("E010003", "現在のパスワード");
                  break;
                default:
                  // その他のエラー
                  errorMessage = getMessage("E011004", "パスワード変更処理");
                  errorLog("E000001", this.props.userId, viewId.changePassword, "change", e);
              }
            });
        })
        .catch((e) => {
          errorMessage = getMessage("E011004", "パスワード変更処理");
          errorLog("E000001", this.props.userId, viewId.changePassword, "change", e);
        });
    }
    if (errorMessage !== "") {
      removeToastClassName();
      setError(errorMessage);
    }
  };

  const addToastClassName = () => {
    const buttonMenu = document.getElementById("box-toast");
    buttonMenu.classList.add('is-active');
  };
  const removeToastClassName = () => {
    const buttonMenu = document.getElementById("box-toast");
    buttonMenu.classList.remove('is-active');
  }

  return (
    <React.Fragment>
      <div id="box-toast" className="box-toast flex items-center pl-8 blue1 rounded-b-2xl fixed left-1/2 z-20">
        <div className="box-toast__text text-h6 white--text mr-1">パスワードが変更されました</div>
        <button type="button" className="box-toast__btn white--text p-5 leading-none" onClick={() => removeToastClassName()}><span className="icon-close-s"></span></button>
      </div>
      <main className="page-container">
      {/* トースト表示枠 */}
        <section className="page-content">
          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">パスワード変更 </h1>
            </header>

            {/* コンテンツボディ */}
            <div className="page-body">
              {/* ===== フォーム ===== */}
              <div className="form-container">
                <div>
                  <span style={{ color: "red" }}>{error}</span>
                </div>
                <div className="form-control">
                  <label htmlFor="current_password" className="block text-caption-1 mb-2">現在のパスワード</label>
                  <input type="password" placeholder="現在のパスワード" id="current_password" autoComplete="off" className="block rounded-lg px-6 py-3 w-full" onChange={handleChangeOldPassword} value={oldPassword}/>
                </div>
                <div className="form-control mt-10">
                  <label htmlFor="new_password" className="block text-caption-1 mb-2">新しいパスワード</label>
                  <input type="password" placeholder="新しいパスワード" id="new_password" autoComplete="off" className="block rounded-lg px-6 py-3 w-full" onChange={handleChangeNewPassword} value={newPassword}/>
                </div>
                <div className="form-control mt-10">
                  <label htmlFor="new_password_confirm" className="block text-caption-1 mb-2">新しいパスワード（確認）</label>
                  <input type="password" placeholder="新しいパスワード（確認）" id="new_password_confirm" autoComplete="off" className="block rounded-lg px-6 py-3 w-full" onChange={handleChangeNewPasswordConfirm} value={newPasswordConfirm}/>
                </div>
                <div className="text-center mt-16">
                  <button className="btn btn-change-password white--text grey rounded-lg px-16" onClick={() => change()}>変更</button>
                </div>
              </div>
              {/* ===== /フォーム ===== */}
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

const ChangePassword = () => {
  const state = useSelector((state) => state);
  return <ChangePasswordContent
    userId={state.user.sub}
    pageTitle={titleName.changePassword + titleName.common}
  />;
};

export default ChangePassword;
