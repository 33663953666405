import React, { useState } from "react";

export const LoginContext = React.createContext({
  login: false,
  setLogin: (login) => {},
  user: null,
});

export const LoginContextProvider = (props) => {
  const setLogin = (login, user) => {
    setState({ ...state, login , user});
  };

  const initState = {
    login: false,
    setLogin: setLogin,
    user: null,
  };

  const [state, setState] = useState(initState);
  return (
    <LoginContext.Provider value={state}>
      {props.children}
    </LoginContext.Provider>
  );
};
