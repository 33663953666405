"use strict";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import 'react-datepicker/dist/react-datepicker.css';
import { getDynamoDB } from "../../common/utils/DynamoDbUtils";
import { titleName, table } from "../../_constants/Code";
import { useToast } from "../../_components/Toast";
import Forbidden from "../../_components/Forbidden";

const masterListTemplate = [
  {
    "id": "dashboard",
    "name": "ダッシュボード設定",
    "url": "", // 【TODO】
    "outline": "ダッシュボードの表示に使用する各種設定値を管理します",
    "lastUpdateDate": "",
    "lastUpdateUser": ""
  },
  {
    "id": "segment",
    "name": "セグメント情報",
    "url": "/master/segment/segmentInfo",
    "outline": "グループ会社全体の事業セグメントの有効期間等の情報を管理します",
    "lastUpdateDate": "",
    "lastUpdateUser": ""
  },
  {
    "id": "department",
    "name": "組織情報",
    "url": "", // 【TODO】
    "outline": "CHORFY上で分析対象となる組織階層を管理します",
    "lastUpdateDate": "",
    "lastUpdateUser": ""
  },
]

const MasterListContent = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [masterList, setMasterList] = React.useState([]);
  const authConf = props.authority.Master_AuthInfo.Auth_Conf;

  React.useEffect(() => {
    document.title = props.pageTitle;
    init();
    if (props.message) {
      props.showToast(props.message);
    }
  }, []);

  const init = async () => {
    const masterList = [];
    // ダッシュボード設定
    // 【TODO】

    // セグメント情報
    // 親会社でない、もしくは権限を持っていない場合は表示しない
    if (props.isParent && authConf.Segment_Set) {
      let analysisTargetInfo = await getDynamoDB(
        table.AnalysisTargetInfo,
        null,
        props.corporationNumber,
        null,
        null
      );
      if (analysisTargetInfo.length) {
        const l = masterListTemplate.find(p => p.id === "segment");
        l.lastUpdateDate = analysisTargetInfo[0].UpdateDateTime.slice(0, -3);
        l.lastUpdateUser = analysisTargetInfo[0].UpdateUser; // DB定義の変更が必要

        masterList.push(l);
      }
    }

    // 組織情報
    // 【TODO】

    setMasterList(masterList);
    setIsLoaded(true);
  };

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-16">{titleName.masterList}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              {/*  ===== リード ===== */}
              <p className="text-body text-center mb-10">
                {/* TODO */}
              </p>
              {/*  ===== /リード ===== */}
              <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-16 px-16 border-blue1">
                <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">マスター一覧</h2>
                {/*  ===== マスター一覧 ===== */}
                <div className="flex items-center justify-between my-6">
                  <div className="text-body-2-medium grow">
                  </div>
                </div>
                {masterList.length ? (
                  <div className="setting-table is-master">
                    <table className="setting-table__table table-fixed w-full">
                      <thead>
                        <tr>
                          <th className="setting-table__col-master-name">マスター名</th>
                          <th className="setting-table__col-master-outline">マスター概要</th>
                          <th className="setting-table__col-master-last-update-date">最終更新日時</th>
                          <th className="setting-table__col-master-last-update-user">最終更新者</th>
                        </tr>
                      </thead>
                      <tbody className="text-caption-1">
                        {masterList.map((m, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <Link variant="button" underline="none" component={RouterLink} to={m.url}>
                                  <span style={{ fontSize: "16px", textTransform: "none" }}>{m.name}</span>
                                </Link>
                              </td>
                              <td>{m.outline}</td>
                              <td>{m.lastUpdateDate}</td>
                              <td>{m.lastUpdateUser}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="text-center my-10 pt-10">
                    <span className="text-h5 red--text">更新可能なマスター情報がありません。</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

const MasterList = () => {
  const state = useSelector((state) => state);
  const location = useLocation();
  const showToast = useToast();

  // 権限を持っていない場合は画面表示しない
  if (!state.authority.Master_AuthInfo.Availability) {
    return <Forbidden />
  }

  return <MasterListContent
    pageTitle={titleName.masterList + titleName.common}
    corporationNumber={state.company.corporationNumber}
    userId={state.user.sub}
    isParent={state.company.isParent}
    message={location.state?.message}
    showToast={showToast}
    authority={state.authority}
  />;
};

export default MasterList;
