"use strict";
import * as React from "react";
import { useSelector } from "react-redux";
import { SpinnerButton } from "../CustomButton"
import { table } from "../../../_constants/Code";
import { formatDate } from "../../../common/utils/DateUtils";
import { errorLog } from "../../../common/logger/Logger";
import { getDynamoDB, updateDynamoDB, registDynamoDB, deleteDynamoDB } from "../../../common/utils/DynamoDbUtils";
import { useToast } from "../../../_components/Toast";

const BookmarkContent = (props) => {
  const [bmInfo, setBmInfo] = React.useState();
  const [bmDispStatus, setBmDispStatus] = React.useState();
  const [bmMemo, setBmMemo] = React.useState();
  const [bmError, setBmError] = React.useState();

  // 初期表示の処理
  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await getBm();
  }

  const getBm = async () => {
    const dynamoDBData = await getDynamoDB(table.Bookmark,
      'CorporationNumber-index', props.corporationNumber, null,
      {
        UserId: props.userId,
      }
    );
    setBmInfo(dynamoDBData.find(d => d.DashboardKey == props.dashboardKey));
  }

  const handleChangeBmMemo = (e) => {
    setBmMemo(e.target.value);
  };

  const openBmModal = () => {
    const modalBg = document.querySelector(".bookmark-bg-modal-window");
    modalBg.classList.add("is-active");
    modalBg.classList.remove("animate__fadeOut");
    modalBg.classList.add("animate__fadeIn");
    if (!bmInfo) {
      setBmDispStatus("entry");
      setBmMemo("");
      const modalBmEntry = document.querySelector("#modal-window--bookmark-entry");
      modalBmEntry.classList.add("is-active");
      modalBmEntry.classList.remove("animate__fadeOut");
      modalBmEntry.classList.add("animate__fadeIn");
    } else {
      setBmDispStatus("on");
      setBmMemo((bmInfo.Memo == null || bmInfo.Memo == '') ? "" : bmInfo.Memo);
      const modalBmOn = document.querySelector("#modal-window--bookmark-on");
      modalBmOn.classList.add("is-active");
      modalBmOn.classList.remove("animate__fadeOut");
      modalBmOn.classList.add("animate__fadeIn");
      const modalBmDeleteConfirm = document.querySelector("#modal-window--bookmark-delete-confirm");
      modalBmDeleteConfirm.classList.remove("animate__fadeOut");
      modalBmDeleteConfirm.classList.add("animate__fadeIn");
    }
  };

  const closeBmModal = () => {
    setBmError(false);
    if (bmDispStatus === "entry") {
      const bookmarkBg = document.querySelector("#modal-window--bookmark-entry");
      bookmarkBg.classList.remove("animate__fadeIn");
      bookmarkBg.classList.add("animate__fadeOut");
    } else if (bmDispStatus === "on") {
      const bookmarkBg = document.querySelector("#modal-window--bookmark-on");
      bookmarkBg.classList.remove("animate__fadeIn");
      bookmarkBg.classList.add("animate__fadeOut");
    } else if (bmDispStatus === "deleteConf") {
      const bookmarkBg = document.querySelector("#modal-window--bookmark-delete-confirm");
      bookmarkBg.classList.remove("animate__fadeIn");
      bookmarkBg.classList.add("animate__fadeOut");
    }
    setBmDispStatus(null);
    const modalBg = document.querySelector(".bookmark-bg-modal-window");
    modalBg.classList.remove("animate__fadeIn");
    modalBg.classList.add("animate__fadeOut");
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      modalBg.classList.remove("is-active");
      document
        .querySelector(".modal-window.is-active")
        ?.classList.remove("is-active");
    }, fadeOutTimeoutSec);
  };

  const registBm = async () => {
    try {
      const nowDate = formatDate(new Date());
      const request = [
        {
          UserId: props.userId,
          DashboardKey: props.dashboardKey,
          Memo: bmMemo,
          CreateDateTime: nowDate,
          UpdateDateTime: nowDate,
          Version: 1,
        },
      ];
      await registDynamoDB(table.Bookmark, "UserId", props.corporationNumber, request, props.userId);
      await getBm();
      closeBmModal();
      props.showToast(props.dashboardName + "を ブックマークしました");
    } catch (error) {
      setBmError(true);
      errorLog("E000001", props.userId, props.pageId, "registBm", error);
    }
  };

  const updateBm = async () => {
    try {
      const nowDate = formatDate(new Date());
      const pk = "UserId";
      const key = {
        UserId: props.userId,
        DashboardKey: props.dashboardKey,
      };
      const item = {
        Memo: bmMemo,
        UpdateDateTime: nowDate,
        Version: bmInfo.Version + 1,
      };
      await updateDynamoDB(table.Bookmark, pk, props.corporationNumber, key, item, props.userId);
      await getBm();
      props.showToast("ブックマークメモを更新しました");
      closeBmModal();
    } catch (error) {
      setBmError(true);
      errorLog("E000001", props.userId, props.pageId, "updateBm", error);
    }
  };

  const deleteBm = async () => {
    try {
      const key = {
        UserId: props.userId,
        DashboardKey: props.dashboardKey,
      };
      await deleteDynamoDB(table.Bookmark, key, props.corporationNumber, props.userId);
      await getBm();
      props.showToast("ブックマークを削除しました");
      closeBmModal();
    } catch (error) {
      errorLog("E000001", props.userId, props.pageId, "deleteBm", error);
    }
  };

  const deleteConfBm = async () => {
    setBmDispStatus("deleteConf");
    document
      .querySelector(".modal-window.is-active")
      .classList.remove("is-active");
    const modalBookmarkDeleteConfirm = document.querySelector(
      "#modal-window--bookmark-delete-confirm"
    );
    modalBookmarkDeleteConfirm.classList.add("is-active");
  };

  const deleteBackBm = async () => {
    setBmDispStatus("on");
    document
      .querySelector(".modal-window.is-active")
      .classList.remove("is-active");
    const modalBookmarkOn = document.querySelector(
      "#modal-window--bookmark-on"
    );
    modalBookmarkOn.classList.add("is-active");
  };

  /**
   * 描画処理
   */
  return (
    <React.Fragment>
      <div className="action-area__bookmark mr-4">
        <button type="button" className={`grid rounded-xl btn-bookmark ${bmInfo ? ' is-active' : ''}`} onClick={() => openBmModal()}>
          <span className="icon-bookmark-on self-center justify-self-center"></span>
          <span className="icon-bookmark-off self-center justify-self-center"></span>
        </button>
      </div>
      {/* ===== ブックマーク入力 ===== */}
      <div className="bookmark-bg-modal-window w-screen h-screen fixed top-0 left-0 grey lighten-70 z-50" onClick={() => closeBmModal()}></div>
      <div className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn" id="modal-window--bookmark-entry">
        <div className="modal-window__header">
          <div className="modal-window__title flex items-center justify-center py-10">
            <span className="modal-window__title-icon icon-bookmark-on grey--text mr-2"></span>
            <span className="modal-window__title-text text-h3 font-medium">ブックマーク</span>
          </div>
          <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => closeBmModal()}><span className="icon-close"></span></button>
        </div>
        <div className="modal-window__content px-8 pb-10">
          {/* エラー時表示 */}
          {bmError &&
            <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">
              エラーが発生しました。<br />
              時間をおいて再度お試しください。
            </div>
          }
          {/* /エラー時表示 */}
          <div className="form-control">
            <textarea maxLength="200" placeholder="メモを入力（任意、最大120文字）" className="modal-window__content-textarea rounded-lg px-4 py-3 w-full" onChange={(e) => handleChangeBmMemo(e)} value={bmMemo}></textarea>
          </div>
          <div className="modal-window__btns text-center mt-8">
            <SpinnerButton name="ブックマークする" className="btn btn-modal-bookmark white--text grey rounded-lg px-16 text-h5 font-medium"
              onClick={() => registBm()} />
          </div>
        </div>
      </div>
      {/* ===== /ブックマーク入力 ===== */}
      {/* ===== ブックマーク中 ===== */}
      <div className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn" id="modal-window--bookmark-on">
        <div className="modal-window__header">
          <div className="modal-window__title flex items-center justify-center py-10">
            <span className="modal-window__title-icon icon-bookmark-on red--text mr-2"></span>
            <span className="modal-window__title-text text-h3 font-medium">ブックマーク中</span>
          </div>
          <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => closeBmModal()}><span className="icon-close"></span></button>
        </div>
        <div className="modal-window__content px-8 pb-10">
          {/* エラー時表示 */}
          {bmError &&
            <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">
              エラーが発生しました。<br />
              時間をおいて再度お試しください。
            </div>
          }
          {/* /エラー時表示 */}
          <div className="form-control">
            <textarea maxLength="200" placeholder="メモを入力（任意、最大120文字）" className="modal-window__content-textarea rounded-lg px-4 py-3 w-full" onChange={(e) => handleChangeBmMemo(e)} value={bmMemo}></textarea>
          </div>
          <div className="modal-window__btns text-center mt-8">
            <div className="flex gap-4">
              <button className="btn btn-outline btn-modal-delete-bookmark rounded-lg px-16 text-h5 font-medium" onClick={() => deleteConfBm()}>ブックマークを削除</button>
              <SpinnerButton name="保存"
                className="btn btn-modal-save white--text grey rounded-lg px-16 text-h5 font-medium"
                onClick={() => updateBm()} />
            </div>
          </div>
        </div>
      </div>
      {/* ===== /ブックマーク中 ===== */}
      {/* ===== ブックマークの削除確認 ===== */}
      <div className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn" id="modal-window--bookmark-delete-confirm">
        <div className="modal-window__header">
          <div className="modal-window__title flex items-center justify-center py-10">
            <span className="modal-window__title-icon icon-bookmark-on red--text mr-2"></span>
            <span className="modal-window__title-text text-h3 font-medium">ブックマークの削除確認</span>
          </div>
          <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => closeBmModal()}><span className="icon-close"></span></button>
        </div>
        <div className="modal-window__content px-8 pb-10">
          <div className="text-body text-center mb-14">
            ブックマーク及び入力したメモを<br />
            削除してもよろしいでしょうか？
          </div>
          <div className="modal-window__btns text-center mt-8">
            <div className="flex gap-4">
              <button className="btn btn-outline btn-modal-back rounded-lg px-16 text-h5 font-medium" onClick={() => deleteBackBm()}>戻る</button>
              <SpinnerButton name="削除"
              className="btn btn-modal-delete white--text red rounded-lg px-16 text-h5 font-medium"
              onClick={() => deleteBm()} />
            </div>
          </div>
        </div>
      </div>
      {/* ===== /ブックマークの削除確認 ===== */}
    </ React.Fragment>
  );
}

const Bookmark = (props) => {
  const state = useSelector((state) => state);
  const showToast = useToast();

  return <BookmarkContent
    dashboardKey={props.dashboardKey}
    dashboardName={props.dashboardName}
    pageId={props.pageId}
    corporationNumber={state.company.corporationNumber}
    userId={state.user.sub}
    showToast={showToast}
  />
}

export const BookmarkEmpty = () => {
  return (
    <div className="action-area__bookmark mr-4">
      <button type="button" className={`grid rounded-xl btn-bookmark `}>
        <span className="icon-bookmark-on self-center justify-self-center"></span>
        <span className="icon-bookmark-off self-center justify-self-center"></span>
      </button>
    </div>
  )
}

export default (props) => <Bookmark {...props} />;
