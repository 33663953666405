"use strict";
import * as React from "react";
import Select from '@mui/material/Select';
import { kgiStatus } from "../../../_constants/Code";
import { getMessage } from "../../../common/utils/MessageUtils";

// 決算期リスト
let kpiYearList = [''];

/**
 * 共通パーツ「基本情報」
 */
const basicInfo = {
  Mode: '',
  Title: '',
  SubTitle: '',
  Explanation: '',
  StartPeriod: '',
  EndPeriod: '',
  Status: '',
};

/**
 * 基本情報エラー項目
 */
const basicInfoError = {
  Title: null,
  SubTitle: null,
  Explanation: null,
  StartPeriod: null,
  EndPeriod: null,
  Status: null,
};

const BasicInfoContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  // 画面入力情報
  const [businessPlanInfo, setBusinessPlanInfo] = React.useState();
  const [inputError, setInputError] = React.useState();

  React.useEffect(() => {
    init();
  }, []);

  /**
   * 初期表示
   */
  const init = async () => {
    // 決算期リスト
    kpiYearList = [''];
    let startYear = Number(props.kpiStartYear.slice(0, 4));
    if (props.accPeriod !== '12月') {
      startYear = startYear + 1;
    }
    for (let i = 0; i < 10; i++) {
      const year = (startYear + i) + props.kpiStartYear.slice(-1);
      kpiYearList.push(year);
    }
    if (props.newMode) {
      Object.keys(basicInfo).forEach(key => basicInfo[key] = '');
      basicInfo.Mode = props.mode;
      basicInfo.Status = 'tempRegist';
    }
    setBusinessPlanInfo(basicInfo);
    setInputError(basicInfoError);
    setIsLoaded(true);
  }

  /**
   * 対象の値を変更する
   * @param {イベント} e 
   */
  const changeMode = (e) => {
    basicInfo.Mode = e.target.value;
    setBusinessPlanInfo({ ...businessPlanInfo, Mode: e.target.value });
  }

  /**
   * タイトルの値を変更する
   * @param {イベント} e 
   */
  const changeTitle = (e) => {
    basicInfo.Title = e.target.value;
    setBusinessPlanInfo({ ...businessPlanInfo, Title: e.target.value });
  }

  /**
   * サブタイトルの値を変更する
   * @param {イベント} e 
   */
  const changeSubTitle = (e) => {
    basicInfo.SubTitle = e.target.value;
    setBusinessPlanInfo({ ...businessPlanInfo, SubTitle: e.target.value });
  }

  /**
   * 説明の値を変更する
   * @param {イベント} e 
   */
  const changeExplanation = (e) => {
    basicInfo.Explanation = e.target.value;
    setBusinessPlanInfo({ ...businessPlanInfo, Explanation: e.target.value });
  }

  /**
   *開始の値を変更する
   * @param {イベント} e 
   */
  const changeStartPeriod = (e) => {
    basicInfo.StartPeriod = e.target.value;
    setBusinessPlanInfo({ ...businessPlanInfo, StartPeriod: e.target.value });
  }

  /**
   * 期間終了の値を変更する
   * @param {イベント} e 
   */
  const changeEndPeriod = (e) => {
    basicInfo.EndPeriod = e.target.value;
    setBusinessPlanInfo({ ...businessPlanInfo, EndPeriod: e.target.value });
  }

  /**
   * 状態の値を変更する
   * @param {イベント} e 
   */
  const changeStatus = (e) => {
    basicInfo.Status = e.target.value;
    setBusinessPlanInfo({ ...businessPlanInfo, Status: e.target.value });
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <React.Fragment>
      {/* ===== 基本情報フォーム ===== */}
      <table className="form-table form-control w-full">
        <tbody className="text-caption-2">
          {props.isParent &&
            <tr>
              <th className="form-table__col-head">
                <label className="text-h7 font-bold">対象<span className="text-h5 red--text">*</span></label>
              </th>
              <td className="form-table__col-data">
                {props.newMode && <ul className="flex gap-x-8">
                  <li>
                    <label className="ui-radio">
                      <input type="radio" className="radioBmAll ui-radio__input" name="target" value="standalone"
                        defaultChecked={businessPlanInfo.Mode === 'standalone'} onChange={(e) => changeMode(e)} />
                      <span className="ui-radio__icon"></span>
                      <span className="ui-radio__ripple"></span>
                      <span className="ui-radio__label text-h7 font-Regular">単体</span>
                    </label>
                  </li>
                  <li>
                    <label className="ui-radio">
                      <input type="radio" className="radioBmAll ui-radio__input" name="target" value="consolidated"
                        defaultChecked={businessPlanInfo.Mode === 'consolidated'} onChange={(e) => changeMode(e)} />
                      <span className="ui-radio__icon"></span>
                      <span className="ui-radio__ripple"></span>
                      <span className="ui-radio__label text-h7 font-Regular">連結</span>
                    </label>
                  </li>
                </ul>
                }
                {!props.newMode && businessPlanInfo.Mode === 'standalone' && <label>単体</label>}
                {!props.newMode && businessPlanInfo.Mode === 'consolidated' && <label>連結</label>}
              </td>
            </tr>
          }
          <tr>
            <th className="form-table__col-head">
              <label htmlFor="name" className="text-h7 font-bold">タイトル<span className="red--text">*</span></label>
            </th>
            <td className="form-table__col-data">
              <input id="name" placeholder="経営計画のタイトル（名称、呼称など）を入力（全角35文字以内）" type="text"
                className={`block rounded-lg px-3 py-1 w-full ${inputError.Title ? "is-error" : ""}`}
                value={businessPlanInfo.Title} onChange={(e) => changeTitle(e)} />
              <span className="red--text">{inputError.Title}</span>
            </td>
          </tr>
          <tr>
            <th className="form-table__col-head">
              <label htmlFor="subname" className="text-h7 font-bold">サブタイトル</label>
            </th>
            <td className="form-table__col-data">
              <input id="subname" placeholder="サブタイトルがある場合入力。2行目に表示されます（全角100文字以内）" type="text"
                className={`block rounded-lg px-3 py-1 w-full ${inputError.SubTitle ? "is-error" : ""}`}
                value={businessPlanInfo.SubTitle} onChange={(e) => changeSubTitle(e)} />
              <span className="red--text">{inputError.SubTitle}</span>
            </td>
          </tr>
          <tr>
            <th className="form-table__col-head">
              <label htmlFor="description" className="text-h7 font-bold">説明</label>
            </th>
            <td className="form-table__col-data">
              <textarea id="description" rows="4" placeholder="経営計画の概要説明を入力（全角200文字程度）" autoComplete="off"
                className={`block rounded-lg px-3 py-1 w-full ${inputError.Explanation ? "is-error" : ""}`} maxLength="200"
                value={businessPlanInfo.Explanation} onChange={(e) => changeExplanation(e)} />
              <span className="red--text">{inputError.Explanation}</span>
            </td>
          </tr>
          <tr>
            <th className="form-table__col-head">
              <label htmlFor="term_start" className="text-h7 font-bold">期間<span className="text-h5 red--text">*</span></label>
            </th>
            <td className="form-table__col-data">
              <div className="flex">
                <div className="select-control">
                  <select id="term_start" className={`form-select rounded-lg ${inputError.StartPeriod ? "is-error" : ""}`}
                    value={businessPlanInfo.StartPeriod} onChange={(e) => changeStartPeriod(e)} >
                    {kpiYearList.map((item, idx) => {
                      return <option key={idx} value={item}>{item}{item && props.accPeriod + '期'}</option>
                    })}
                  </select>
                  <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                    <span className="icon-arrow rotate-90"></span>
                  </div>
                </div>
                <span className="div-mid mx-5">～</span>
                <div className="select-control">
                  <select id="term_start" className={`form-select rounded-lg pr-10 ${inputError.EndPeriod ? "is-error" : ""}`}
                    value={businessPlanInfo.EndPeriod} onChange={(e) => changeEndPeriod(e)} >
                    {kpiYearList.map((item, idx) => {
                      return <option key={idx} value={item}>{item}{item && props.accPeriod + '期'}</option>
                    })}
                  </select>
                  <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                    <span className="icon-arrow rotate-90"></span>
                  </div>
                </div>
              </div>
              <span className="red--text">{inputError.StartPeriod}</span>
              <span className="red--text">{inputError.EndPeriod}</span>
            </td>
          </tr>
          <tr>
            <th className="form-table__col-head">
              <label htmlFor="status" className="text-h7 font-bold">状態<span className="text-h5 red--text">*</span></label>
            </th>
            <td className="form-table__col-data">
              <div className="flex">
                <div className="select-control">
                  <select id="status" className={`form-select rounded-lg ${inputError.Status ? "is-error" : ""}`}
                    value={businessPlanInfo.Status} onChange={(e) => changeStatus(e)} >
                    {Object.keys(kgiStatus).map((key) => {
                      return <option key={key} value={key}>{kgiStatus[key].name}</option>
                    })}
                  </select>
                  <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                    <span className="icon-arrow rotate-90"></span>
                  </div>
                </div>
              </div>
              <span className="red--text">{inputError.Status}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

/**
 * 基本情報を取得
 * @returns 基本情報
 */
export const getBasicInfo = () => {
  return { ...basicInfo };
}

/**
 * 基本情報を設定
 * @param {基本情報} info 
 */
export const setBasicInfo = (info) => {
  Object.keys(basicInfo).forEach(key => basicInfo[key] = info[key] ? info[key] : '');
}

export const clearCheck = () => {
  // 基本情報部のエラー情報をクリア
  Object.keys(basicInfoError).forEach(key => basicInfoError[key] = null);
}

/**
 * 基本情報入力チェック
 * @returns エラー情報
 */
export const checkBasicInfo = (kgiTree = [], conitoringTree = []) => {

  // 基本情報部のエラー情報をクリア
  Object.keys(basicInfoError).forEach(key => basicInfoError[key] = null);
  // -- 必須 --
  if (!basicInfo.Title) {
    basicInfoError.Title = getMessage("E010001", "タイトル");
  }
  if (!basicInfo.StartPeriod) {
    basicInfoError.StartPeriod = getMessage("E010001", "期間（開始）");
  }
  if (!basicInfo.EndPeriod) {
    basicInfoError.EndPeriod = getMessage("E010001", "期間（終了）");
  }
  if (!basicInfo.Status || basicInfo.Status === 'none') {
    basicInfoError.Status = getMessage("E010001", "状態");
  }
  // -- 文字長 --
  if (basicInfo.Title.length > 35) {
    basicInfoError.Title = getMessage("E010006", "タイトル", 35);
  }
  if (basicInfo.SubTitle.length > 100) {
    basicInfoError.SubTitle = getMessage("E010006", "サブタイトル", 100)
  }
  if (basicInfo.Explanation.length > 200) {
    basicInfoError.Explanation = getMessage("E010006", "説明", 200);
  }
  // -- 相関 --
  if (basicInfo.StartPeriod.slice(0, 4) > basicInfo.EndPeriod.slice(0, 4)) {
    basicInfoError.StartPeriod = getMessage("E011006");
  }
  if (basicInfo.Status === 'release') {
    if (!kgiTree.length && !conitoringTree.length) {
      basicInfoError.Status = getMessage("E011007");
    }
  }
  return Object.keys(basicInfoError).some(key => basicInfoError[key] !== null);
}

export default (props) => <BasicInfoContent {...props} />;
