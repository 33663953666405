/**
 * ソースの内容をターゲットにコピーします
 * @param {Object} target ターゲット
 * @param {Object} source ソース
 * @returns マージ後オブジェクト
 */
export const mergeDeep = (target, source) => {
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (source[key] && typeof source[key] === 'object') {
        if (!target[key] || typeof target[key] !== 'object') {
          target[key] = Array.isArray(source[key]) ? [] : {};
        }
        mergeDeep(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
  return target;
}
