import * as React from "react";
import List from "@mui/material/List";
import { HeaderItems } from "../_components/Header";
import { SubHeaderItems } from "../_components/SubHeader";
import { sideMenu } from "../_components/SideMenu";
import { FooterListItems } from "../_components/Footer";
import { useSelector } from "react-redux";
import { analysisMode } from "../_constants/Code";
import { getPageText, readDashboardId } from "../common/utils/FileUtils";
import Contents from "./Contents"

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      pageText: null,
    };
    this.role;
  }
  componentDidMount() {
    this.checkRole();
  }

  async checkRole() {
    const { role } = this.props;
    this.role = role.split('_')[1];
    this.setState({
      isLoaded: true,
      pageText: await getPageText(),
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return null;
    }
    return (
      <React.Fragment>
        <List component="nav" disablePadding={true}>
          {this.props.isParent &&
            <div className="nav__container fixed z-50">
              {sideMenu(this.props.userId, this.role, this.props.mode, this.props.isParent, this.props.authority, this.state.pageText)}
            </div>
          }
          {!this.props.isParent &&
            <div>
              {sideMenu(this.props.userId, this.role, this.props.mode, this.props.isParent, this.props.authority, this.state.pageText)}
            </div>
          }
        </List>
      </React.Fragment>
    );
  }
}

const PageFrame = ({ Component, DateTime, options }) => {
  const state = useSelector((state) => state);
  const [mode, setMode] = React.useState(null);
  const bucketName = "chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber;

  const getCallback = React.useCallback((mode) => {
    setMode(mode);
  }, []);

  React.useEffect(() => {

    if (!state.company.isParent) {
      document.documentElement.classList.remove('single');
      document.documentElement.classList.remove('multiple');
    } else if (state.analysis.mode == analysisMode.standalone) {
      document.documentElement.classList.remove('multiple');
      document.documentElement.classList.add('single');
    } else {
      document.documentElement.classList.remove('single');
      document.documentElement.classList.add('multiple');
    }

    // 初回読み込む
    async function fetchData() {
      await readDashboardId(bucketName);
    }
    getCallback(state.analysis.mode);
    fetchData();
  }, []);

  if (state) {
    return (
      <div id="wrapper" className={options.hideSubHeader ? "hide-subheader-wrapper" : ""}>
        {/* ========== ヘッダ ========== */}
        <HeaderItems />
        {/* 分析対象設定 サブヘッダー */}
        {!options.hideSubHeader && <SubHeaderItems />}
        {/* トップページのみ header クラスに has-box-shadow クラスが追加されます */}
        {/* script.js読み込みでエラーになるためタグだけ残す */}
        <div className="box-box-toast"></div>
        <div className="box-toast__btn"></div>
        <div className="subheader chrofy-hide">
          <div className="subheader__button"></div>
          <div className="btn-modal-analysis-setting-apply"></div>
        </div>
        <div className="nav__dropdown-content-wrapper"></div>
        {/* ========== /ヘッダ ========== */}

        {/* ========== グローバルナビゲーション ========== */}
        <nav className="nav">
          <div className="nav__bg grey lighten-45 fixed top-0 left-0 w-full h-full z-30"></div>
          <div className="nav__container white fixed z-50">
            <Sidebar role={state.user.role} userId={state.user.sub} mode={mode} isParent={state.company.isParent} authority={state.authority} corporationNumber={state.company.corporationNumber} />
          </div>
        </nav>
        {/* ========== /グローバルナビゲーション ========== */}

        <Contents Component={Component} />

        <FooterListItems userId={state.user.sub} DateTime={DateTime} bucketName={bucketName} accPeriod={state.company.accPeriod} />

      </div>
    );
  }
};

export default PageFrame;
