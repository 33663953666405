"use strict";
import * as React from "react";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import Link from "@mui/material/Link";

import { getSystemDatetime, getDatetimeByYmdhms, getDateByYmd, formatDateYmd, formatDateNumber } from "../../common/utils/DateUtils";
import { PathInfoList } from "../../_constants/PathInfoList";
import { viewId, titleName } from "../../_constants/Code";
import { errorLog } from "../../common/logger/Logger";
import { Message } from "../../_components/MessagePage";
import { getObjectList } from "../../common/utils/FileUtils";

import '../../assets/css/vendor/normalize.css'
import '../../assets/css/vendor/animate.min.css'
import '../../assets/css/vendor/hamburgers.css'
import '../../assets/icon/style.css'
import '../../assets/css/style.css'
import '../../assets/js/tailwindcss.js'
import '../../assets/js/adobe_fonts.js'

//@GlobalCustomizeFunction
const Cookie = () => {
  try {
    const [cookies, setCookie] = useCookies();
    const updateLastDay = () => {
      const date = getSystemDatetime();
      let expires = getSystemDatetime();
      expires.setFullYear(expires.getFullYear() + 5);
      setCookie(
        "lastNoticeViewDatetime",
        date.toLocaleString({ timeZone: "Asia/Tokyo" }),
        { expires: expires, path: "/" }
      );
    };

    React.useEffect(() => {
      updateLastDay();
    }, []);
  } catch (error) {
    errorLog("E000001", this.props.userId, viewId.notice, "Cookie", error);
  }

  return null;
};

const Nodata = () => {
  const messageList = [
    "運営からのお知らせ情報はありません。",
  ];
  return (
    <Message
      title="運営からのお知らせ一覧"
      messageList={messageList}
      loginBtn={false}
      topBtn={false}
    />
  );
};

const Error = () => {
  const messageList = [
    "運営からのお知らせ情報の取得に失敗しました。画面を再表示してください。",
  ];
  return (
    <Message
      title="運営からのお知らせ一覧"
      messageList={messageList}
      loginBtn={false}
      topBtn={false}
    />
  );
};

class NoticeContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      //@ComponentState
      marker: 10,
    };
    //@Component
    this.noticeList_ = [];
    this.isError = false;
    this.loadList = this.loadList.bind(this);
  }

  //@CustomizeFunction
  getNotice = async () => {
    try {
      const noticeList = await getObjectList(process.env.REACT_APP_S3_WEB_CHROFY_COMMON, PathInfoList.Folder.noticeFolder + PathInfoList.Objectkeyname.notice);
      this.noticeList_ = this.generateNoticeList(noticeList);
    } catch (error) {
      this.isError = true;
      ("E000001", this.props.userId, viewId.notice, "getNotice", error);
    } finally {
      this.setState({
        isLoaded: true,
      });
    }
  };

  generateNoticeList = (data) => {
    let noticeList = data.map((data) => {
      return (
        {
          date: formatDateNumber(data.date),
          title: data.title,
          url: data.url,
          // guide: data.guide // ガイド分表示機能を一時オミット
        }
      )
    });
    noticeList = noticeList.sort((a, b) => {
      return a.date > b.date ? -1 : 1; //オブジェクトの降順ソート
    });
    let today = getSystemDatetime();
    noticeList = noticeList
      .filter((notice) => {
        const pubDate = getDatetimeByYmdhms(notice.date);
        return pubDate <= today;
      })
      .map((notice) => {
        const date = getDateByYmd(notice.date);
        date.setDate(date.getDate() + 15);
        let isNew = false;
        if (today <= date) {
          isNew = true;
        }
        return {
          date: formatDateYmd(notice.date),
          title: notice.title,
          url: notice.url,
          // guide: notice.guide, // ガイド分表示機能を一時オミット
          isNew: isNew,
        };
      });
    return noticeList;
  }

  loadList() {
    try {
      this.setState({
        marker: this.state.marker + 10,
      });
    } catch (error) {
      errorLog("E000001", this.props.userId, viewId.notice, "loadList", error);
    }
  }

  componentDidMount = async () => {
    // タイトル設定
    document.title = this.props.pageTitle;
    this.getNotice();
  };
  render() {
    if (!this.state.isLoaded) {
      return null;
    }
    if (this.isError) {
      return <Error />;
    }
    if (this.noticeList_.length <= 0) {
      return <Nodata />;
    }
    const noticeList = this.noticeList_.map((notice, index) => {
      if (notice.url == null || notice.url == "" || notice.url.length == 0) {
        return (<li key={index} className="news-list__item">
          <div className="news-list__link flex pt-6 pb-7" target="_blank">
            {notice.isNew && <span className="news-list__new text-h6-en red white--text px-5 py-1.5 rounded-lg absolute">NEW</span>}
            <span className="news-list__date grey--text text--lighten-70 text-right mr-8">{notice.date}</span>
            <span className="grey--text text--lighten-70 text-right mr-8">
              {notice.title}
              {/* ガイド分表示機能を一時オミット
              <br></br>
              <span className="grey--text text--lighten-70 text-right mr-8">
                {notice.guide}
              </span>
              */}
            </span>
          </div>
        </li>);
      } else {
        return (<li key={index} className="news-list__item">
          <div className="news-list__link flex pt-6 pb-7" target="_blank">
            {notice.isNew && <span className="news-list__new text-h6-en red white--text px-5 py-1.5 rounded-lg absolute">NEW</span>}
            <span className="news-list__date grey--text text--lighten-70 text-right mr-8">{notice.date}</span>
            <span className="news-list__title grow">
              <Link color="inherit" href={notice.url} target="_blank">
                {notice.title}
              </Link>
              {/* ガイド分表示機能を一時オミット
              <br></br>
              <span className="grey--text text--lighten-70 text-right mr-8">
                {notice.guide}
              </span>
              */}
            </span>
          </div>
        </li>);
      }
    });
    return (
      <main className="page-container">
        <Cookie />
        <section className="page-content">
          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-16">運営からのお知らせ一覧</h1>
            </header>

            {/* コンテンツボディ */}
            <div className="page-body">
              {/* ===== ニュース一覧 ===== */}
              <div className="news-list">
                <ul className="news-list__list">
                  {noticeList.slice(0, this.state.marker)}
                </ul>
              </div>
              {/* ===== /ニュース一覧 ===== */}
              <div className="text-center mt-16">
                {this.noticeList_.length > this.state.marker ? (
                  <button className="btn btn-more white--text grey rounded-lg px-16 font-medium" onClick={this.loadList}>もっと見る</button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const Notice = () => {
  const state = useSelector((state) => state);
  return <NoticeContent
    userId={state.user.sub}
    pageTitle={titleName.notice + titleName.common}
  />;
};

export default Notice;
