"use strict";
import { Auth } from "aws-amplify";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { DndContext, DragOverlay, useDroppable, useDraggable, defaultDropAnimationSideEffects } from "@dnd-kit/core";
import LeaderLine from "leader-line-new";
import { Link, IconButton } from "@mui/material"
import { Delete } from "@mui/icons-material";
import { SpinnerButton, RefleshButton } from "../common/CustomButton"
import { errorLog } from "../../common/logger/Logger";
import { putFileNoName, uploadAccountingFile, readCsvFile, readExcelFile, getObject } from "../../common/utils/FileUtils";
import { formatCommaDigit, parseCommaDigit } from "../../common/utils/NumberUtils";
import { viewId, titleName, accountingUploadTaskStatus, periodKind, analysisMode } from "../../_constants/Code";
import { useToast } from "../../_components/Toast";
import { useModal } from "../../_components/Modal";
import { formatDate } from "../../common/utils/DateUtils";
import { getMessage } from "../../common/utils/MessageUtils";
import { getAccountingUploadTask } from "../../common/utils/DynamoDbUtils";
import config from "../../_constants/Config";

const AccountingDataContent = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  // 財務データアップロード情報
  const [accountingUploadInfo, setAccountingUploadInfo] = React.useState();
  // 財務ファイルテーブル
  const [accountingTable, setAccountingTable] = React.useState();
  // ファイル情報
  const [inputFileInfo, setInputFileInfo] = React.useState();
  const inputAccountingFile = React.createRef();
  // 線
  const [lineList, setLineList] = React.useState([]);

  const [dragContent, setDragContent] = React.useState();
  const [inputError, setInputError] = React.useState({});

  // 初期表示の処理
  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    window.scrollTo(0, 0);
    init();
  }, []);

  // 入力エラー時にスクロールをトップへ
  React.useEffect(() => {
    if (inputError.errorMessage) {
      window.scrollTo(0, 0);
    }
  }, [inputError]);

  // 財務テーブル表示の処理
  React.useEffect(() => {
    if (lineList.length || accountingUploadInfo?.Status === accountingUploadTaskStatus.processing.value) {
      return;
    }
    // 線描画
    const timer = setTimeout(() => {
      const newLineList = [];
      accountingUploadInfo?.AccountInfo.forEach(account => {
        const start = document.getElementById("chrofy_account_" + account.AccountCode);
        const end = document.getElementById("accounting_" + account.RowIndex);
        if (start && end) {
          // ライン追加
          const line = _createLine(start, end);
          // スクロールイベントで再描画
          const handler = _setScrollEvent(line, end);
          newLineList.push({
            id: account.AccountCode,
            leaderLine: line,
            handler: handler,
          });
        }
      });
      setLineList(newLineList);
    });
    return () => clearTimeout(timer);
  }, [accountingTable]);

  /**
   * 初期表示
   */
  const init = async () => {
    // 財務アップロードタスクを取得
    const accountingUploadTask = await _getAccountingUploadTask();
    const fileInfo = {
      fileType: "CSV",
      headerRow: 1,
      inputFile: undefined,
      inputError: undefined,
      sheetList: [],
    }
    if (accountingUploadTask) {
      delete accountingUploadTask.SuperKey;
      delete accountingUploadTask.Key;
      // 処理中、登録済みの場合、ファイル情報を設定
      if (accountingUploadTask.Status === accountingUploadTaskStatus.registered.value) {
        const fileData = await getObject(props.bucketName, accountingUploadTask.FilePath);
        fileInfo.fileType = accountingUploadTask.FileType;
        fileInfo.headerRow = accountingUploadTask.HeaderRow;
        fileInfo.inputFile = {
          name: accountingUploadTask.FileName,
          extension: accountingUploadTask.FileName.slice(accountingUploadTask.FileName.lastIndexOf(".") + 1),
          fileData: new File([fileData], accountingUploadTask.FileName, { type: accountingUploadTask.ContentType }),
        }
        // 読み取り処理
        await _readAccountingData(fileInfo);
      }
    }
    setAccountingUploadInfo(accountingUploadTask);
    setInputFileInfo(fileInfo);
    setLineList([]);
    setIsLoaded(true);
  }

  /**
   * 財務アップロードタスク取得
   */
  const _getAccountingUploadTask = async () => {

    const dynamoDBData = await getAccountingUploadTask(
      props.corporationNumber, props.dataCreateTaskKey, props.AccountingKey
    );
    return dynamoDBData;
  }

  /**
   * ファイル選択
   */
  const setInputAccountingFile = (e) => {
    if (e.target.value.length) {
      const fileData = e.target.files[0];
      setInputFileInfo({
        ...inputFileInfo,
        inputFile: {
          name: fileData.name,
          extension: fileData.name.slice(fileData.name.lastIndexOf(".") + 1),
          fileData: fileData
        }
      });
    };
  }

  /**
   * ファイルチェック
   */
  const _checkInputFile = () => {
    let errorMessage = undefined;
    try {
      const extension = inputFileInfo.inputFile.extension.toLowerCase();
      // タイプ：CSV
      if (inputFileInfo.fileType === "CSV" && extension !== "csv") {
        errorMessage = getMessage("E010009", "ファイルの拡張子");
        return true;
      }
      // タイプ：Excel
      if (inputFileInfo.fileType === "EXCEL" && !["xls", "xlsx"].includes(extension)) {
        errorMessage = getMessage("E010009", "ファイルの拡張子");
        return true;
      }
      // ヘッダー行
      if (!Number.isFinite(inputFileInfo.headerRow) || inputFileInfo.headerRow < 1) {
        errorMessage = getMessage("E010010", "ヘッダー行", "1");
        return true;
      }
      // ファイルサイズ
      const size = inputFileInfo.inputFile.fileData.size;
      if (size <= 0) {
        errorMessage = getMessage("E010011", "空ファイル", "読み込み");
        return true;
      }
      if (size > config["maxSizeInBytesAccounting"]) {
        errorMessage = getMessage("E010012", "ファイルサイズ");
        return true;
      }
    } finally {
      setInputFileInfo({
        ...inputFileInfo,
        inputError: errorMessage,
      });
    }
    return false;
  }

  /**
   * ファイル読み取り確認（上書き）
   */
  const confirmAccounting = () => {

    if (_checkInputFile()) {
      return;
    }
    const title = '読み込み確認';
    const body = <React.Fragment>
      <div className="text-center">ファイルを読み込むと、現在のマッピング設定が解除されます。よろしいですか？</div>
    </React.Fragment>;
    const button = [{
      name: '実行',
      className: 'footer-btn button_base',
      click: async () => {
        readAccountingData();
      }
    }];
    props.showModal({ title: title, body: body, button: button, isWide: false });
  }

  /**
   * 税務ファイル読み取り
   */
  const readAccountingData = async () => {
    if (_checkInputFile()) {
      return;
    }
    // 選択状態等を初期化
    _clearSettings();
    // 読み取り処理
    const sheetList = await _readAccountingData(inputFileInfo);
    // 財務アップロードタスク管理に設定
    setAccountingUploadInfo({
      ...accountingUploadInfo,
      // ファイル情報
      FileName: inputFileInfo.inputFile.name,
      ContentType: inputFileInfo.inputFile.fileData.type,
      FileType: inputFileInfo.fileType,
      HeaderRow: inputFileInfo.headerRow,
      FileUpdateDateTime: formatDate(new Date()),
      FileUpdateUserName: props.userName,
      SheetNo: inputFileInfo.fileType === "EXCEL" ? sheetList[0].id : null,
      // ヘッダー選択初期化
      AccountHeaderColIndex: null,
      AmountHeaderColIndex: null,
    });
  }

  /**
   * 財務ファイル読み取り
   */
  const _readAccountingData = async (fileInfo) => {
    const sheetList = [];
    // 拡張子がCSVの場合
    if (fileInfo.fileType === "CSV") {
      const csvData = await readCsvFile(fileInfo.inputFile.fileData);
      setAccountingTable({
        headers: csvData[fileInfo.headerRow - 1],
        records: csvData.slice(fileInfo.headerRow),
      });
    }
    // 拡張子がxlsxの場合
    else if (fileInfo.fileType === "EXCEL") {
      const workbook = await readExcelFile(fileInfo.inputFile.fileData);
      workbook.eachSheet((worksheet, sheetId) => {
        sheetList.push({
          id: sheetId,
          name: worksheet.name,
          worksheet: workbook.getWorksheet(sheetId),
        });
      });
      setAccountingTable(_readWorksheet(sheetList[0].worksheet, fileInfo.headerRow));
    }
    // それ以外は処理なし
    else {
      return;
    }
    setInputFileInfo({
      ...fileInfo,
      inputError: undefined,
      sheetList: sheetList,
    });
    return sheetList;
  }

  /**
   * エクセルワークシート読み取り
   */
  const _readWorksheet = (sheet, rowNum) => {

    const table = {
      headers: [],
      records: [],
    };
    // ヘッダー行
    const headerRow = sheet.getRow(rowNum);
    for (let i = 1; true; i++) {
      const val = headerRow.getCell(i).value;
      if (!val) {
        break;
      }
      table.headers.push(val);
    }
    // レコード行
    for (let i = rowNum + 1; true; i++) {
      const row = sheet.getRow(i);
      if (!row.getCell(1).value) {
        break;
      }
      const rec = [];
      for (let j = 1; j <= table.headers.length; j++) {
        rec.push(row.getCell(j).value);
      }
      table.records.push(rec);
    }
    return table;
  }

  /**
   * Droppableコンポーネント
   */
  const Droppable = (props) => {
    const { isOver, setNodeRef } = useDroppable({ id: props.id });
    const style = { color: isOver ? "green" : undefined }
    return (
      <div ref={setNodeRef} style={style} className="mt-0.5">
        {props.children}
      </div>
    )
  }

  /**
   * Draggableコンポーネント
   */
  const Draggable = (props) => {
    const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({ id: props.id, data: props.val });
    const style = transform ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`
    } : undefined
    return !isDragging && props.children !== null && props.children !== "" ? (
      <td ref={setNodeRef} style={style} className={props.className} {...listeners} {...attributes}>
        {props.children}
      </td>
    ) : (
      <td>
        {props.children}
      </td>
    )
  }

  /**
   * ドラッグ開始処理
   */
  const handleDragStart = (e) => {
    setDragContent(e.active.data.current);
  }

  /**
   * ドラッグ終了処理
   */
  const handleDragEnd = (e) => {
    const { active, over } = e;
    if (!over) {
      return
    }
    const idxs = active.id.split("_");
    const accountIdx = accountingUploadInfo.AccountHeaderColIndex;
    const amountIdx = accountingUploadInfo.AmountHeaderColIndex;
    // 科目
    const currentAccount = accountingTable.records[idxs[0]][accountIdx];
    // 金額
    const currentAmount = accountingTable.records[idxs[0]][amountIdx];
    const amount = Number(parseCommaDigit(String(currentAmount)));
    // CHROFY利用科目に設定
    if (Number.isFinite(amount)) {
      const account = accountingUploadInfo.AccountInfo.find(ele => ele.AccountCode === over.id);
      account.CurrentAccountTitle = currentAccount;
      account.CurrentAmount = amount;
      account.RowIndex = Number(idxs[0]);
      setAccountingUploadInfo({ ...accountingUploadInfo });
      // 線描画
      const start = document.getElementById("chrofy_account_" + over.id);
      const end = document.getElementById("accounting_" + idxs[0]);
      if (start && end) {
        // 旧ラインがあれば消す
        const oldLine = lineList.find(ele => ele.id === over.id);
        if (oldLine) {
          oldLine.leaderLine.remove();
        }
        // ライン追加
        let newLineList = lineList.filter(ele => ele.id !== over.id);
        const line = _createLine(start, end);
        // スクロールイベントで再描画
        const handler = _setScrollEvent(line, end);
        newLineList.push({
          id: over.id,
          leaderLine: line,
          handler: handler,
        });
        setLineList(newLineList);
      }
    }
  }

  /**
   * 線描画
   */
  const _createLine = (start, end) => {
    const line_y = end.getBoundingClientRect().y;
    const accountingTableBottom = document.getElementById("accounting-table-bottom");
    const table_b_y = accountingTableBottom.getBoundingClientRect().y;
    if (line_y >= table_b_y) {
      end = accountingTableBottom;
    }
    return new LeaderLine(start, end, {
      startSocket: "right",
      endSocket: "left",
      path: "straight",
      startPlug: "disc",
      endPlug: "disc",
    })
  }

  /**
   * 財務テーブルのスクロールイベント
   */
  const _setScrollEvent = (line, end) => {
    // スクロールイベントで再描画
    const scloll = document.getElementById("accounting-table__scroll");
    const accountingTableTop = document.getElementById("accounting-table-top");
    const accountingTableBottom = document.getElementById("accounting-table-bottom");
    // 再描画関数
    const reRender = () => {
      try {
        const line_y = end.getBoundingClientRect().y;
        const table_t_y = accountingTableTop.getBoundingClientRect().y;
        const table_b_y = accountingTableBottom.getBoundingClientRect().y;
        if (line_y <= table_t_y) {
          line.end = accountingTableTop;
        } else if (line_y >= table_b_y) {
          line.end = accountingTableBottom;
        } else {
          line.end = end;
          line.position();
        }
      } catch (e) { }
    }
    // スクロールイベント
    scloll.addEventListener("scroll", reRender, false);
    window.addEventListener("resize", reRender, false);
    return reRender;
  }

  /**
   * 描画削除
   */
  const deleteLine = (id) => {
    // CHROFY利用科目を削除
    const account = accountingUploadInfo.AccountInfo.find(e => e.AccountCode === id);
    account.CurrentAccountTitle = null;
    account.CurrentAmount = null;
    account.RowIndex = null;
    setAccountingUploadInfo({ ...accountingUploadInfo });
    // 線を削除
    lineList.forEach(e => {
      if (e.id === id) {
        e.leaderLine.remove();
      }
    })
    setLineList(lineList.filter(e => e.id !== id));
  }

  /**
   * 設定クリア
   */
  const _clearSettings = (isInitHeader = true) => {
    // CHROFY利用科目を初期化
    accountingUploadInfo.AccountInfo.forEach(account => {
      account.CurrentAccountTitle = null;
      account.CurrentAmount = null;
      account.RowIndex = null;
    });
    // 線とスクロールイベントを削除
    const scloll = document.getElementById("accounting-table__scroll");
    lineList.forEach(e => {
      try {
        e.leaderLine.remove();
        scloll.removeEventListener("scroll", e.handler);
      } catch (e) { console.log(e) }
    })
    setLineList([]);
    if (isInitHeader) {
      setAccountingUploadInfo({
        ...accountingUploadInfo,
        // ヘッダー選択を初期化
        AccountHeaderColIndex: null,
        AmountHeaderColIndex: null,
      });
    }
  }

  /**
   * 入力チェック
   */
  const _checkInput = () => {

    let inputError = {}
    try {
      // マッピングチェック
      if (accountingUploadInfo.AccountInfo.some(account => account.CurrentAmount === null)) {
        inputError.errorMessage = getMessage("E011009", "CHROFY利用科目");
      }
      // エラー判定
      if (Object.keys(inputError).length === 0) {
        return false;
      } else {
        return true;
      }
    } finally {
      // 入力エラーを設定
      setInputError({ ...inputError });
    }
  }

  /**
   * アップロード
   */
  const uploadAccountingData = () => {

    if (_checkInput()) {
      return;
    }
    const title = 'データ登録の確認';
    const body = <React.Fragment>
      <div className="text-center">財務データを登録します。よろしいですか？</div>
    </React.Fragment>;
    const button = [{
      name: '実行',
      className: 'footer-btn button_base',
      click: async () => {
        try {
          const uploadInfo = structuredClone(accountingUploadInfo);
          // 日時取得
          const nowDate = formatDate(new Date());
          uploadInfo.Status = accountingUploadTaskStatus.processing.value;
          uploadInfo.UpdateUserName = props.userName;
          uploadInfo.UpdateDateTime = nowDate;
          // 余分な要素を削除
          uploadInfo.AccountInfo.forEach(account => {
            delete account._AccountTitle;
            delete account._SortKey;
            delete account._PreviousAmount;
          });
          delete uploadInfo._PeriodKind;
          delete uploadInfo._PeriodValue;
          // ファイル登録
          await putFileNoName(uploadInfo.FilePath, inputFileInfo.inputFile.fileData);
          const identityId = await Auth.currentUserCredentials().then((userCredential) => {
            return userCredential.identityId;
          });
          // DB更新
          const message = await uploadAccountingFile(props.corporationNumber,
            identityId,
            {
              SuperKey: parseInt(props.dataCreateTaskKey),
              Key: parseInt(props.AccountingKey)
            },
            uploadInfo,
          );
          if (message.length === 0) {
            props.navigate('/data/analysisData/' + props.dataCreateTaskKey, {
              state: {
                message: '財務データ登録を開始しました。処理状況はステータスで確認できます。'
              }
            });
          } else {
            // エラー
            setInputError({ ...inputError, errorMessage: message });
          }
        } catch (error) {
          console.log(error)
          errorLog("E000001", props.userId, viewId.accountingData, "uploadAccountingData", error);
        }
      }
    }];
    props.showModal({ title: title, body: body, button: button, isWide: false });
  }

  /**
   * 権限確認
   */
  const hasAuth = () => {
    if (!props.auth.Availability) {
      return false;
    }
    if (accountingUploadInfo.Mode === analysisMode.standalone) {
      return props.auth.Standalone;
    }
    if (accountingUploadInfo.Mode === analysisMode.consolidated) {
      return props.auth.Consolidated;
    }
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <div id="wrapper">
        {/* ========== コンテンツ ========== */}
        <main className="page-container">
          <section className="page-content">
            <div className="centered-container">
              {/* 上階層に戻る */}
              <div className="bg main">
                <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                  <Link color="inherit" variant="button" underline="none" component={RouterLink} to={"/data/analysisData/" + props.dataCreateTaskKey}>
                    <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                      <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                      <span className="text-h5 font-bold">{titleName.analysisData}に戻る</span>
                    </span>
                  </Link>
                </div>
              </div>
              {/* コンテンツヘッダ */}
              <header className="page-header flex justify-center py-1">
                <h1 className="page-header__name text-h1 grey--text py-8">{titleName.accountingData}</h1>
              </header>
              {/* コンテンツボディ */}
              <div className="page-body">
                <div className="centered-container-1600">
                  {accountingUploadInfo && <>
                    <div className="border-t border-lightgray blue1 lighten-3"></div>
                    <div className="sub-title-area flex justify-center py-10">
                      <h3 className="text-h4 mt-1 font-bold">更新対象データ：</h3>
                      {/* 表組み */}
                      <table className="base-table ml-5">
                        <tbody className="text-h5 text-center">
                          <tr>
                            <td width="100px" className="blue1 white--text">種類</td>
                            <td width="100px" className="border-blue1">{periodKind[accountingUploadInfo._PeriodKind].name}</td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="base-table ml-5">
                        <tbody className="text-h5 text-center">
                          <tr>
                            <td width="100px" className="blue1 white--text">期間</td>
                            <td width="200px" className="border-blue1">{accountingUploadInfo._PeriodValue}</td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="base-table ml-5">
                        <tbody className="text-h5 text-center">
                          <tr>
                            <td width="100px" className="blue1 white--text">対象</td>
                            <td width="100px" className="border-blue1">{accountingUploadInfo.Mode === analysisMode.standalone ? "単体" : "連結"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>}
                  <div>
                    <span style={{ color: "red" }}>{inputError.errorMessage}</span>
                  </div>
                  {accountingUploadInfo ? (
                    <>
                      <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                        <div className="content-area">
                          <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">財務データのアップロードとデータマッピング</h2>
                          <div className="accounting-header flex my-5">
                            {(() => {
                              const isDisabled = !hasAuth() || accountingUploadInfo.Status === accountingUploadTaskStatus.processing.value
                              return (
                                <div className="accounting-btn-group flex text-caption-2">
                                  <span className="file-type font-bold">ファイル形式：</span>
                                  <select className="input-file-type" value={inputFileInfo.fileType}
                                    disabled={isDisabled}
                                    onChange={(e) => setInputFileInfo({ ...inputFileInfo, fileType: e.target.value })}>
                                    <option value="CSV">CSV</option>
                                    <option value="EXCEL">Excel</option>
                                  </select>
                                  <span className="excel-header font-bold">ヘッダー行：</span>
                                  <input type="number" className="input-excel-header"
                                    disabled={isDisabled} min={1}
                                    value={inputFileInfo.headerRow}
                                    onChange={(e) => setInputFileInfo({ ...inputFileInfo, headerRow: Number(e.target.value) })}></input>
                                  <span className={`px-2 text-caption-2 ${!isDisabled ? "input-file" : "input-file-disabled"}`}
                                    onClick={() => !isDisabled ? inputAccountingFile.current.click() : ""}>{inputFileInfo.inputFile ? inputFileInfo.inputFile.name : "未選択"}</span>
                                  <button className={`text-nowrap ${!isDisabled ? "button_file_select" : "button_file_select_disabled"}`}
                                    disabled={isDisabled}
                                    onClick={() => inputAccountingFile.current.click()}>参照</button>
                                  <input type="file" ref={inputAccountingFile} style={{ display: "none" }} onChange={(e) => setInputAccountingFile(e)}></input>
                                  <RefleshButton className="btn read-btn white--text text-nowrap py-1.5 ml-1 pr-10 inline-flex items-center"
                                    disabled={isDisabled || !inputFileInfo.inputFile} name="読み込み"
                                    onClick={() => inputFileInfo.inputFile ? accountingTable ? confirmAccounting() : readAccountingData() : ""} />
                                </div>
                              )
                            })()}
                            <div className="red--text text-caption-2 ml-2">{inputFileInfo.inputError}</div>
                          </div>
                          <div className="border-t border-blue1 pb-5" />
                          {accountingUploadInfo.Status !== accountingUploadTaskStatus.processing.value ? (
                            <>
                              <DndContext autoScroll={false} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                                <div className="flex">
                                  <div className="mt-2">
                                    <span className="text-caption-2 font-bold">CHROFY利用科目</span>
                                    <table className="base-table accounting-account-table w-full mt-3">
                                      <thead className="text-h7">
                                        <tr>
                                          <th>科目</th>
                                          <th>金額(前{periodKind[accountingUploadInfo._PeriodKind].unit})</th>
                                          <th>科目(今{periodKind[accountingUploadInfo._PeriodKind].unit})</th>
                                          <th>金額(今{periodKind[accountingUploadInfo._PeriodKind].unit})</th>
                                        </tr>
                                      </thead>
                                      <tbody className="text-caption-2 text-nowrap">
                                        {accountingUploadInfo.AccountInfo.sort((a, b) => a._SortKey < b._SortKey ? -1 : 1).map((account, idx) => {
                                          return (
                                            <tr id={"chrofy_account_" + account.AccountCode} key={idx}>
                                              <td title={account._AccountTitle}>{account._AccountTitle}</td>
                                              <td className="right">{formatCommaDigit(account._PreviousAmount)}</td>
                                              <td className="title" title={account.CurrentAccountTitle}>{account.CurrentAccountTitle ? (
                                                <span>{account.CurrentAccountTitle}</span>
                                              ) : (
                                                <span className="grey2--text">未設定</span>
                                              )}
                                              </td>
                                              {account.CurrentAmount !== null ? (
                                                <td className="title flex-right">
                                                  <Droppable id={account.AccountCode}>
                                                    {formatCommaDigit(account.CurrentAmount)}
                                                  </Droppable>
                                                  {hasAuth() ? (
                                                    <div className="display-hover">
                                                      <IconButton size="small" onClick={() => deleteLine(account.AccountCode)} sx={{ padding: "0 2" }}>
                                                        <Delete fontSize="small" />
                                                      </IconButton>
                                                    </div>
                                                  ) : (<div className="pr-5"></div>)
                                                  }
                                                </td>
                                              ) : (
                                                <td className="title grey2--text">
                                                  <Droppable id={account.AccountCode}>
                                                    未設定
                                                  </Droppable>
                                                </td>
                                              )}
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className={`mx-2 mt-14 accounting-icon-direction ${lineList.length > 0 ? "is-mapping" : ""}`}>
                                    <span className="link--text icon-direction icon-32px rotate-180 inline-block -translate-y-2 is-active"></span>
                                  </div>
                                  <div className="mt-2">
                                    <div className="accounting-file-header flex middle text-caption-2 mb-3">
                                      <span className="file-name font-bold" title={accountingUploadInfo.FileName}>ファイル名：{accountingUploadInfo.FileName ? accountingUploadInfo.FileName : "未選択"}</span>
                                      {inputFileInfo.sheetList.length > 0 && <>
                                        <span className="excel-sheet font-bold">シート名：</span>
                                        <select className="excel-sheet-list"
                                          onChange={(e) => {
                                            const sheet = inputFileInfo.sheetList.find(ele => ele.id === Number(e.target.value));
                                            setAccountingTable(_readWorksheet(sheet.worksheet, inputFileInfo.headerRow));
                                            setAccountingUploadInfo({ ...accountingUploadInfo, SheetNo: Number(e.target.value) });
                                            // 選択状態等を初期化
                                            _clearSettings();
                                          }}>
                                          {inputFileInfo.sheetList.map((sheet, idx) => {
                                            return <option key={idx} value={sheet.id}>{sheet.name}</option>
                                          })}
                                        </select>
                                      </>}
                                      <div className="red--text text-caption-2 ml-2 accounting-description">
                                        {
                                          (() => {
                                            if (accountingTable) {
                                              if (accountingUploadInfo.AccountHeaderColIndex === null) {
                                                return "科目列を選択してください。"
                                              }
                                              if (accountingUploadInfo.AmountHeaderColIndex === null) {
                                                return "金額列を選択してください。"
                                              }
                                              return "金額を選択してドラッグアンドドロップしてください。"
                                            }
                                            return ""
                                          })()
                                        }
                                      </div>
                                    </div>
                                    <div id="accounting-table-top"></div>
                                    <div id="accounting-table" className="setting-table relative is-accounting">
                                      {/*  表組み */}
                                      <div id="accounting-table__scroll" className="accounting-table__scroll">
                                        {accountingTable ? (
                                          <table className="base-table accounting-file-table w-full">
                                            <thead className="text-h7">
                                              <tr>
                                                <th width="1px"></th>
                                                {
                                                  (() => {
                                                    const scroll = document.getElementById("accounting-table__scroll");
                                                    const width = (scroll?.getBoundingClientRect().width / accountingTable.headers.length) + "px";
                                                    return accountingTable.headers.map((header, idx) => {
                                                      const accountIdx = accountingUploadInfo.AccountHeaderColIndex;
                                                      const amountIdx = accountingUploadInfo.AmountHeaderColIndex;
                                                      const className = (() => {
                                                        if (accountIdx === idx) return "is-selected-account";
                                                        if (amountIdx === idx) return "is-selected-amount";
                                                        if (accountIdx === null) return "select-account";
                                                        if (amountIdx === null) return "select-amount";
                                                      })();

                                                      const handleClick = () => {
                                                        // 選択済み科目列
                                                        if (accountIdx === idx) {
                                                          setAccountingUploadInfo({ ...accountingUploadInfo, AccountHeaderColIndex: null });
                                                        }
                                                        // 選択済み金額列
                                                        else if (amountIdx === idx) {
                                                          setAccountingUploadInfo({ ...accountingUploadInfo, AmountHeaderColIndex: null });
                                                        }
                                                        // 科目列未選択
                                                        else if (accountIdx === null) {
                                                          setAccountingUploadInfo({ ...accountingUploadInfo, AccountHeaderColIndex: idx });
                                                        }
                                                        // 金額列未選択
                                                        else if (amountIdx === null) {
                                                          setAccountingUploadInfo({ ...accountingUploadInfo, AmountHeaderColIndex: idx });
                                                        } else {
                                                          return;
                                                        }
                                                        // 選択状態等を初期化
                                                        _clearSettings(false);
                                                      }
                                                      return <th key={idx} style={{ width: width }} className={hasAuth() ? className : className + " disabled"}>
                                                        <div onClick={hasAuth() ? handleClick : () => { }}>{header ? header : "　"}</div>
                                                      </th>
                                                    })
                                                  })()
                                                }
                                              </tr>
                                            </thead>
                                            <tbody className="text-caption-2 text-nowrap">
                                              {accountingTable.records.map((record, idx) => {
                                                const account = accountingUploadInfo.AccountInfo.find(ele => ele.RowIndex === idx);
                                                const rowClass = account ? "select-row" : undefined;
                                                return (
                                                  <tr key={idx} className={rowClass}>
                                                    <th id={"accounting_" + idx}></th>
                                                    {record.map((col, idx2) => {
                                                      const accountIdx = accountingUploadInfo.AccountHeaderColIndex;
                                                      const amountIdx = accountingUploadInfo.AmountHeaderColIndex;
                                                      const colClass = (() => {
                                                        if (rowClass && amountIdx === idx2) {
                                                          if (hasAuth()) {
                                                            return "draggable-col select-col"
                                                          }
                                                          return "select-col"
                                                        }
                                                      })();
                                                      if (hasAuth() && amountIdx === idx2 && accountIdx !== null) {
                                                        return <Draggable key={idx2} id={idx + '_' + idx2} val={col} className={colClass}>{col}</Draggable>
                                                      } else {
                                                        return <td key={idx2} className={colClass}>{col}</td>
                                                      }
                                                    })}
                                                  </tr>
                                                )
                                              })}
                                            </tbody>
                                          </table>
                                        ) : (
                                          <div className="text-h7 mx-5 my-5">財務データファイルを読み込んでください</div>
                                        )}
                                      </div>
                                    </div>
                                    <div id="accounting-table-bottom"></div>
                                  </div>
                                </div>
                                <DragOverlay dropAnimation={{
                                  sideEffects: defaultDropAnimationSideEffects({
                                    styles: {
                                      active: {},
                                      dragOverlay: {
                                        opacity: "0"
                                      }
                                    }
                                  })
                                }}>
                                  <div>{dragContent}</div>
                                </DragOverlay>
                              </DndContext>
                            </>
                          ) : (
                            <>
                              <div className="text-center my-10 pt-10">
                                <span className="text-h5 red--text">ステータスが「{accountingUploadTaskStatus.processing.name}」のため操作できません。</span>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="content-footer mt-16 mb-8">
                          <div className="border-t border-blue1" />
                          <p className="text-caption-2 red--text text-center mt-4 mb-10">
                            ※レポートデータ更新を行うことで、ダッシュボードに反映されます。
                          </p>
                          {/*  ===== フッターボタン ===== */}
                          <div className="footer_button_area px-24 mt-4">
                            <Link color="inherit" variant="button" underline="none" component={RouterLink} to={"/data/analysisData/" + props.dataCreateTaskKey}>
                              <button type="button" className="btn footer-btn button_back">キャンセル</button>
                            </Link>
                            <SpinnerButton name="アップロード" className="btn footer-btn button_base"
                              disabled={!hasAuth() || accountingUploadInfo.Status === accountingUploadTaskStatus.processing.value}
                              onClick={() => uploadAccountingData()} />
                          </div>
                          {/*  ===== /フッターボタン ===== */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                        <div className="content-area">
                          <h2 className="text-h4-em text-center text--lighten-70 mb-2">選択した財務データアップロードはすでに削除されているか存在しません。</h2>
                          <h2 className="text-h4-em text-center text--lighten-70 mb-2">{titleName.analysisData}に戻って操作をやり直してください。</h2>
                        </div>
                        <div className="content-footer mt-16">
                          <div className="border-t border-blue1" />
                          {/*  ===== フッターボタン ===== */}
                          <div className="footer_button_area mt-8">
                            <Link color="inherit" variant="button" underline="none" component={RouterLink} to={"/data/analysisData/" + props.dataCreateTaskKey}>
                              <button type="button" className="footer-btn button_back">戻る</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </React.Fragment >
  )
}

const AccountingData = (props) => {
  const state = useSelector((state) => state);
  const dataCreateTaskKey = props.params.dataCreateTaskKey;
  const key = props.params.key;
  const showToast = useToast();
  const showModal = useModal();
  const navigate = useNavigate();
  if (state.authority.Upload_AuthInfo.Availability && state.authority.Upload_AuthInfo.Auth_Conf.View) {
    return (
      <AccountingDataContent
        dataCreateTaskKey={dataCreateTaskKey}
        AccountingKey={key}
        userId={state.user.sub}
        userName={state.user.userName}
        pageTitle={titleName.accountingData + titleName.common}
        corporationNumber={state.company.corporationNumber}
        bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber}
        showToast={showToast}
        showModal={showModal}
        navigate={navigate}
        auth={state.authority.Upload_AuthInfo.Auth_Conf.Uploadable_Accounting}
      />
    )
  } else {
    return <Forbidden />
  }
};

export default (props) => <AccountingData {...props} params={useParams()} />;
