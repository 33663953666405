"use strict";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { getDynamoDB } from "../../common/utils/DynamoDbUtils";
import { Roll, titleName, table } from "../../_constants/Code";
import { useToast } from "../../_components/Toast";
import Forbidden from "../../_components/Forbidden";

const AuthorityGroupListContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [AuthorityGroupListData, setAuthorityGroupListData] = React.useState();

  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    init();
    if (props.message) {
      props.showToast(props.message);
    }
  }, []);

  /**
   * 初期表示
   */
  const init = async () => {
    // データ取得
    const authorityGroup = await getDynamoDB(table.AuthorityGroup, null, props.corporationNumber);
    authorityGroup.forEach(auth => { if (!auth.AuthKind) { auth.AuthKind = "function" } });
    setAuthorityGroupListData(authorityGroup.sort((a, b) => {
      if (a.AuthKind > b.AuthKind) return -1;
      if (b.AuthKind > a.AuthKind) return 1;
      return a.AuthGroupName.localeCompare(b.AuthGroupName);
    }));
    setIsLoaded(true);
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{titleName.authorityGroupList}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div className="centered-container-1056">
                {/*  ===== リード ===== */}
                <p className="text-body text-center mb-10">
                  登録済のユーザーに権限を付与するための「権限グループ」を作成します。<br />
                  新規登録ボタンより新たに作成するか、登録済の権限グループを複製して作成してください。
                </p>
                {/*  ===== /リード ===== */}
                <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-16 px-16 border-blue1">
                  <div className="content-area">
                    <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">権限グループ一覧</h2>
                    <div className="flex my-5">
                      <ul className="text-body-2-medium grow">
                        <li>権限グループの作成、編集ができます。</li>
                      </ul>
                      <div className="base-table-header">
                        <div className="btn-group pl-10 border-l border-blue2-lighten-20">
                          <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/auth/AuthorityGroupInfo/new">
                            <span className="header-btn white--text py-1.5 pl-7 pr-10 inline-flex items-center">
                              <span className="icon-plus-line icon-32px mr-1.5 my-px"></span>
                              <span className="-translate-y-px text-h7">新規登録</span>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/*  ===== 権限グループ一覧 ===== */}
                    {AuthorityGroupListData.length > 0 ? (
                      <table className="base-table w-full">
                        <thead className="text-h7">
                          <tr>
                            {props.isParent ? <>
                              <th width="20%">権限種別</th>
                              <th width="70%">権限グループ名</th>
                              <th width="10%"></th>
                            </> : <>
                              <th width="90%">権限グループ名</th>
                              <th width="10%"></th>
                            </>}
                          </tr>
                        </thead>
                        <tbody className="text-caption-1">
                          {AuthorityGroupListData.map((data) => {
                            return (
                              <tr key={data.Key}>
                                {props.isParent && <>
                                  <td className="title">
                                    {data.AuthKind === 'reference' ? "データ参照" : "機能利用"}
                                  </td>
                                </>}
                                <td className="title">
                                  <Link variant="button" underline="none" component={RouterLink} to={"/auth/AuthorityGroupInfo/change/" + data.Key}>
                                    <span className="font-Medium" style={{ textTransform: "none" }}>{data.AuthGroupName}</span>
                                  </Link>
                                </td>
                                <td className="text-center">
                                  <Link variant="button" underline="none" component={RouterLink} to={"/auth/AuthorityGroupInfo/copy/" + data.Key}>
                                    <span className="font-Medium">複製</span>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="text-center my-10 pt-10">
                        <span className="text-h5 red--text">権限グループは登録されていません</span>
                      </div>
                    )
                    }
                    {/*  ===== /権限グループ一覧 ===== */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  )
}

const AuthorityGroupList = () => {
  const state = useSelector((state) => state);
  const location = useLocation();
  const showToast = useToast();

  // 権限がない場合は表示しない
  if (state.user.role.split('_')[1] !== Roll.administrator) {
    return <Forbidden />
  }
  return <AuthorityGroupListContent
    pageTitle={titleName.authorityGroupList + titleName.common}
    corporationNumber={state.company.corporationNumber}
    isParent={state.company.isParent}
    message={location.state?.message}
    showToast={showToast}
  />;
};

export default AuthorityGroupList;
