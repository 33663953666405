"use strict";
import * as React from "react";

import { PathInfoList } from "../../_constants/PathInfoList";
import { titleName } from "../../_constants/Code";

import '../../assets/css/vendor/normalize.css'
import '../../assets/css/vendor/animate.min.css'
import '../../assets/css/vendor/hamburgers.css'
import '../../assets/icon/style.css'
import '../../assets/css/style.css'
import '../../assets/js/tailwindcss.js'
import '../../assets/js/adobe_fonts.js'


//@GlobalCustomizeFunction

// 静的ページを同一アプリ内で用意する場合
/*class privacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      iFrameHeight: '0px',
      //@ComponentState
    };
    //@Component
  }
  
  //@CustomizeFunction
  renderReactFrame() {
    return (
        <iframe url="https://d3mb81qs59p24x.cloudfront.net/PrivacyPolicy.html" width="100%" height="100%" onLoad={()=>{console.log("IFRAME ON LOAD")}}></iframe>
    );
  }

  renderHTMLFrame() {
      return (
          <iframe 
              onLoad={(loadEvent)=>{
                  // NOT WORKING var frameBody = ReactDOM.findDOMNode(this).contentDocument.body; // contentDocument undefined
                  // NOT WORKING obj.nativeEvent.contentWindow.document.body.scrollHeight // contentWindow undefined
              }} 
              ref="iframe" 
              src="https://d3mb81qs59p24x.cloudfront.net/PrivacyPolicy.html" 
              width="100%" 
              height="100%" 
              scrolling="no" 
              frameBorder="0"
              id='iframe'
          />
      );
  }
  
  componentDidMount = async () => {
    //@ComponentDidMount
    const obj = document.getElementById("iframe");
    console.log(obj.contentDocument.documentElement.scrollHeight)
    this.setState({iFrameHeight:  obj.contentDocument.documentElement.scrollHeight + 'px'});
    //this.setState({iFrameHeight:  5000 + 'px'});
  };
  
  render() {
    return (
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container"></div>
          <div style={{width:'100%', height: this.state.iFrameHeight, overflow:'auto'}}>
          <iframe
              src="https://d3mb81qs59p24x.cloudfront.net/PrivacyPolicy.html" 
              width="100%" 
              height="100%" 
              scrolling="no" 
              frameBorder="0"
              id='iframe'
          />
          </div>
        </section>
      </main>
    );
  }
}
*/

// 静的ページを別アプリとして用意する場合
const privacyPolicy = () => {
  const [iFrameHeight, setIFrameHeight] = React.useState();
  const pageInfo = PathInfoList.StaticPage.find(page => {return page.id === 2;});
  // タイトル設定
  document.title = pageInfo.pageTile + titleName.common;
  // キャッシュ対策のためiframeをリロード
  const doReloadTheFrame = () => {
    const iframe = document.getElementById('frametarget');
    iframe.contentWindow.location.reload(true);
  }
  React.useEffect(() => {
    // イベントリスナーを登録
    window.addEventListener(
      "message",
      function (e) {
        var eventName = e.data[0];
        var data = e.data[1];
        switch (eventName) {
          case "setHeight":
            setIFrameHeight(data + "px");
            break;
        }
      },
      false
    );
    window.scrollTo(0, 0);
    doReloadTheFrame();
  }, []);
  return (
    <main className="page-container">
      <section className="page-content">
        <div className="centered-container"></div>
        <div
          id="iframe"
          style={{
            width: "100%",
            height: iFrameHeight,
            overflow: "auto",
          }}
        >
          <iframe
            id="frametarget"
            src={"../staticPage/" + pageInfo.id}
            width="100%"
            height="100%"
            scrolling="no"
            frameBorder="0"
          />
        </div>
      </section>
    </main>
  );
};

export default privacyPolicy;
