import { Amplify, Logger, AWSCloudWatchProvider } from "aws-amplify";
import awsExports from "../../aws-exports";
import { getMessage } from "../../common/utils/MessageUtils";

Amplify.configure({
  ...awsExports,
  Logging: {
    logGroupName: "/aws-amplify-chrofy-front-app-logs",
    logStreamName: "/work-logs",
  },
});

const amplifyLogger = new Logger("", "INFO");
Amplify.register(amplifyLogger);
amplifyLogger.addPluggable(new AWSCloudWatchProvider());

export const infoLog = (messageId, ...args) => {
  const message = getMessage(messageId, args);
  amplifyLogger.info(message);
};

export const warnLog = (messageId, ...args) => {
  const message = getMessage(messageId, ...args);
  amplifyLogger.warnLog(message);
};

export const errorLog = (messageId, ...args) => {
  const message = getMessage(messageId, ...args);
  amplifyLogger.error(message);
};

export const debugLog = (messageId, ...args) => {
  const message = getMessage(messageId, ...args);
  amplifyLogger.debug(message);
};
