import React from "react";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import InsightsIcon from "@mui/icons-material/Insights";

export const CustomDragPreview = (props) => {
  const item = props.monitorProps.item;

  return (
    <div className="tree--dragpreview--root">
      <div className="tree--dragpreview--icon">
        {item.type === 'kgi' && (<InsightsIcon />)}
        {item.type === 'kpi' && (<ViewInArIcon />)}
      </div>
      <div className="tree--dragpreview--label">{item.text}</div>
    </div>
  );
};
