import { getLatestDataSet } from "./FileUtils";

export const getLatestStdFmtData = async (bucketName, accPeriod) => {
  try {
    const noDataMsg = 'データがありません';
    let dispLatestAnnual, dispLatestQuater, dispLatestMonth;
    // 画面表示用
    const latestDataSet = await getLatestDataSet(bucketName);
    // 年次
    let latestAnnual = latestDataSet.annual;
    if (latestAnnual != '') {
      latestAnnual = latestAnnual.split("_")[0];
      dispLatestAnnual = (accPeriod != "12月" ? (Number(latestAnnual) + 1) : latestAnnual) + "年" + accPeriod + "期";
    } else {
      dispLatestAnnual = noDataMsg;
    }
    // 四半期
    let latestQuater = latestDataSet.quarter;
    if (latestQuater != '') {
      latestQuater = latestQuater.split("_", 2);
      dispLatestQuater = (accPeriod != "12月" ? (Number(latestQuater[0]) + 1) : latestQuater[0]) + "年" + accPeriod + "期" + latestQuater[1];
    } else {
      dispLatestQuater = noDataMsg;
    }
    // 月次
    let latestMonth = latestDataSet.month;
    if (latestMonth != '') {
      latestMonth = latestMonth.split(".")[0];
      dispLatestMonth = latestMonth.substr(0, 4) + "年" + Number(latestMonth.substr(4, 2)) + "月度";
    } else {
      dispLatestMonth = noDataMsg;
    }

    return {
      annualData: dispLatestAnnual,
      quaterData: dispLatestQuater,
      monthData: dispLatestMonth,
    }
  } catch (error) {
    throw new Error(error);
  }
};
