"use strict";
import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { SpinnerButton } from "../common/CustomButton";
import { getDynamoDB, registDynamoDB, updateDynamoDB, deleteDynamoDB, nextSequence } from "../../common/utils/DynamoDbUtils";
import BasicInfo, { getBasicInfo, setBasicInfo, clearCheck as clearBasicInfoCheck, checkBasicInfo } from "./parts/BasicInfo";
import ConditionInfo, { getDetailInfo, setDetailInfo, clearCheck as clearDetailInfoCheck, checkDetailInfo } from "./parts/ConditionInfo";
import { titleName, table, viewId } from "../../_constants/Code";
import { formatDate } from "../../common/utils/DateUtils";
import { errorLog } from "../../common/logger/Logger";
import { useModal } from "../../_components/Modal"
import Forbidden from "../../_components/Forbidden";

const BenchmarkTargetInfoContent = (props) => {
  const [benchmarkTargetInfo, setBenchmarkTargetInfo] = React.useState([]);
  const [inputError, setInputError] = React.useState({});
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    init();
  }, []);

  // 入力エラー時にスクロールをトップへ
  React.useEffect(() => {
    if (inputError['errorMessage']) {
      window.scrollTo(0, 0);
    }
  }, [inputError]);

  /**
   * 初期表示
   */
  const init = async () => {
    clearBasicInfoCheck();
    clearDetailInfoCheck();
    if (props.id) {
      // ベンチマーク対象指定条件テーブルを取得
      let dynamoDBData = isNaN(props.id) ? [] :
        await getDynamoDB(table.BenchmarkTarget, null, props.corporationNumber,
          { CorporationNumber: props.corporationNumber, Key: parseInt(props.id) }
        );
      if (dynamoDBData.length) {
        const user = await getDynamoDB(table.User,
          null,
          props.corporationNumber,
          null,
          null
        );
        dynamoDBData = dynamoDBData.filter(b => user.find(u => b.UserId === u.UserId));
        if (!dynamoDBData.length) {  // 退職者（Userマスタに存在しないユーザのデータの場合表示しない）
          setIsLoaded(true);
          return
        }
        const buenchmarkTargetData = dynamoDBData[0];
        const basicInfo = {
          Title: buenchmarkTargetData.Title,
          Description: buenchmarkTargetData.Description,
          SharedFlag: buenchmarkTargetData.SharedFlag,
        };
        const detailInfo = {
          ConditionKind: buenchmarkTargetData.ConditionKind,
          ConditionInfo: buenchmarkTargetData.ConditionInfo,
        };
        setBasicInfo(basicInfo);
        setDetailInfo(detailInfo);
        setBenchmarkTargetInfo(buenchmarkTargetData);
      }
    }
    setIsLoaded(true);
  }

  // ConditionInfoをDB登録データに変換する
  const _convertConditionInfo = (kind, info) => {
    if (kind === '1') {
      info.CompanyList = [];
    } else {
      info.ListingClassification = [];
      info.IndustrySector = [];
      info.CompanySize = [];
      // ConditionInfo.CompanyListを法人番号のリストに変換
      info.CompanyList = info.CompanyList.map(c => c.CorporationNumber);
    }
    return info;
  }

  /**
   * 入力チェック
   */
  const _checkInput = () => {
    const basicResult = checkBasicInfo();
    const detailResult = checkDetailInfo();
    // 基本情報チェック
    if (basicResult || detailResult) {
      setInputError({ errorMessage: '入力内容に誤りがあります。' });
      return true;
    }
    setInputError({});
    return false;
  }

  /**
   * 登録処理
   */
  const registBenchmarkTarget = async () => {
    if (_checkInput()) {
      return;
    }
    try {
      // シーケンス発行
      const sequence = await nextSequence(table.BenchmarkTarget, props.corporationNumber, props.userId);
      // 日時取得
      const nowDate = formatDate(new Date());
      // データ整形
      const benchmarkTarget = {
        CorporationNumber: props.corporationNumber,
        Key: sequence.key,
        UserId: props.userId,
        ...getBasicInfo(),
        ...getDetailInfo(),
        CreateUserName: props.userName,
        UpdateDateTime: nowDate
      };
      benchmarkTarget.ConditionInfo = _convertConditionInfo(benchmarkTarget.ConditionKind, benchmarkTarget.ConditionInfo);
      // 登録
      await registDynamoDB(
        table.BenchmarkTarget,
        'CorporationNumber',
        props.corporationNumber,
        [benchmarkTarget],
        props.userId
      );
      // ベンチマーク設定画面へ遷移
      props.navigate('/benchmark/benchmarkTargetList',
        { state: { message: 'ベンチマーク設定を登録しました。' } }
      );
    } catch (error) {
      errorLog("E000001", props.userId, viewId.benchmarkTargetInfo, "registBenchmarkTarget", error);
    }
  }

  /**
   * 更新処理
   */
  const updateBenchmarkTarget = async () => {
    if (_checkInput()) {
      return;
    }
    try {
      // 日時取得
      const nowDate = formatDate(new Date());
      // データ整形
      const benchmarkTarget = {
        ...benchmarkTargetInfo,
        ...getBasicInfo(),
        ...getDetailInfo(),
        UpdateDateTime: nowDate
      };
      benchmarkTarget.ConditionInfo = _convertConditionInfo(benchmarkTarget.ConditionKind, benchmarkTarget.ConditionInfo);
      const sortKey = benchmarkTarget.Key;
      // 更新時に不要な項目を削除
      delete benchmarkTarget.CorporationNumber;
      delete benchmarkTarget.Key;
      // 更新
      await updateDynamoDB(
        table.BenchmarkTarget,
        'CorporationNumber',
        props.corporationNumber,
        {
          CorporationNumber: props.corporationNumber,
          Key: sortKey
        },
        benchmarkTarget,
        props.userId
      );
      // ベンチマーク設定画面へ遷移
      props.navigate('/benchmark/benchmarkTargetList',
        { state: { message: 'ベンチマーク設定を登録しました。' } }
      );
    } catch (error) {
      errorLog("E000001", props.userId, viewId.benchmarkTargetInfo, "updateBenchmarkTarget", error);
    }
  }

  /**
   * 削除処理
   */
  const deleteBenchmarkTarget = async () => {
    const title = 'ベンチマーク設定の削除確認';
    const body = <React.Fragment>
      <div className="text-center">このベンチマーク設定を削除しますか？</div>
    </React.Fragment>;
    const button = [{
      name: '削除',
      className: 'footer-btn button_delete',
      click: async () => {
        try {
          const sortKey = benchmarkTargetInfo.Key;
          // 削除
          await deleteDynamoDB(
            table.BenchmarkTarget,
            {
              CorporationNumber: props.corporationNumber,
              Key: sortKey
            },
            props.corporationNumber,
            props.userId
          );
          props.navigate('/benchmark/benchmarkTargetList', { state: { message: 'ベンチマーク設定を削除しました。' } });
        } catch (error) {
          errorLog("E000001", props.userId, viewId.benchmarkTargetInfo, "deleteBenchmarkTarget", error);
        }
      }
    }];
    props.showModal({ title: title, body: body, button: button, isWide: false });
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment >
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* 上階層に戻る */}
            <div className="bg main">
              <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/benchmark/benchmarkTargetList">
                  <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                    <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                    <span className="text-h5 font-bold">{titleName.benchmarkTargetList}に戻る</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-16">{titleName.benchmarkTarget}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div className="form-control">
                {props.viewMode === 'new' ||
                  (benchmarkTargetInfo?.UserId &&
                    ((props.viewMode === 'change' && benchmarkTargetInfo.UserId === props.userId) || (props.viewMode === 'reference' && benchmarkTargetInfo.UserId !== props.userId && benchmarkTargetInfo.SharedFlag))) ? (
                  <React.Fragment>
                    <div>
                      <span style={{ color: "red" }}>{inputError.errorMessage}</span>
                    </div>
                    <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pb-16 px-16 border-blue1">
                      {/*  ===== 基本情報の設定 ===== */}
                      <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mx-16 rounded-t-2xl">条件設定</h2>
                      <BasicInfo viewMode={props.viewMode}/>
                      <div className="border-t border-blue1 mt-16 mb-7 -mx-16" />
                      <ConditionInfo viewMode={props.viewMode} corporationNumber={props.corporationNumber} userId={props.userId} showModal={props.showModal} />
                      <div className="mt-32 -mx-16 border-t border-blue1 rounded-b-2xl" />
                      <div className="content-footer mt-16 mb-8">
                        {/*  ===== フッターボタン ===== */}
                        <div className="footer_button_area mt-8">
                          <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/benchmark/benchmarkTargetList">
                            <button type="button" className="btn footer-btn button_back">キャンセル</button>
                          </Link>
                          <SpinnerButton name="登録" className="btn footer-btn button_base"
                            onClick={() => props.viewMode === 'new' ? registBenchmarkTarget() : updateBenchmarkTarget()}
                            disabled={props.viewMode === 'reference'} />
                          {props.viewMode !== 'new' && 
                            <button type="button" className="btn footer-btn button_delete"
                            onClick={() => deleteBenchmarkTarget()}
                            disabled={props.viewMode === 'reference'}>削除</button>
                          }
                        </div>
                        {/*  ===== /フッターボタン ===== */}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                      <div className="content-area">
                        <h2 className="text-h4-em text-center text--lighten-70 mb-2">選択したベンチマーク設定は参照権限がないかすでに存在しません。</h2>
                        <h2 className="text-h4-em text-center text--lighten-70 mb-2">{titleName.benchmarkTargetList}に戻って操作をやり直してください。</h2>
                      </div>
                      <div className="content-footer mt-16 mb-8">
                        <div className="border-t border-blue1" />
                        {/*  ===== フッターボタン ===== */}
                        <div className="footer_button_area mt-8">
                          <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/benchmark/benchmarkTargetList">
                            <button type="button" className="btn footer-btn button_back">戻る</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment >
  )
}

const BenchmarkTargetInfo = (props) => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const showModal = useModal();
  
  // 特定のモード以外は表示しない
  if (!['new', 'change', 'reference'].includes(props.params.mode)) {
    return <Forbidden />
  }
  // モード"new"：ID指定は表示しない
  if (props.params.mode === 'new' && props.params.key) {
    return <Forbidden />
  }
  // モード"change"：他者が登録したデータの場合はForbidden（子コンポーネントで実装）

  return <BenchmarkTargetInfoContent
    pageTitle={titleName.benchmarkTarget + titleName.common}
    corporationNumber={state.company.corporationNumber}
    id={props.params.key}
    userId={state.user.sub}
    userName={state.user.userName}
    message={location.state?.message}
    viewMode={props.params.mode}
    navigate={navigate}
    showModal={showModal}
  />;
};

export default (props) => <BenchmarkTargetInfo {...props} params={useParams()} />;
