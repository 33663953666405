"use strict";
import * as React from "react";

import { PathInfoList } from "../../_constants/PathInfoList";
import { titleName } from "../../_constants/Code";

import "../../assets/css/vendor/normalize.css";
import "../../assets/css/vendor/animate.min.css";
import "../../assets/css/vendor/hamburgers.css";
import "../../assets/icon/style.css";
import "../../assets/css/style.css";
import "../../assets/js/tailwindcss.js";
import "../../assets/js/adobe_fonts.js";;

const TermsOfUse = () => {
  const [iFrameHeight, setIFrameHeight] = React.useState();
  const pageInfo = PathInfoList.StaticPage.find(page => {return page.id === 1;});
  // タイトル設定
  document.title = pageInfo.pageTile + titleName.common;
  // キャッシュ対策のためiframeをリロード
  const doReloadTheFrame = () => {
    const iframe = document.getElementById('frametarget');
    iframe.contentWindow.location.reload(true);
  }
  React.useEffect(() => {
    // イベントリスナーを登録
    window.addEventListener(
      "message",
      function (e) {
        var eventName = e.data[0];
        var data = e.data[1];
        switch (eventName) {
          case "setHeight":
            setIFrameHeight(data + "px");
            break;
        }
      },
      false
    );
    window.scrollTo(0, 0);
    doReloadTheFrame();
  }, []);
  return (
    <main className="page-container">
      <section className="page-content">
        <div className="centered-container"></div>
        <div
          id="iframe"
          style={{
            width: "100%",
            height: iFrameHeight,
            overflow: "auto",
          }}
        >
          <iframe
            id="frametarget"
            src={"../staticPage/" + pageInfo.id}
            width="100%"
            height="100%"
            scrolling="no"
            frameBorder="0"
          />
        </div>
      </section>
    </main>
  );
};

export default TermsOfUse;
