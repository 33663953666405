import * as React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Forbidden from "../_components/Forbidden";
import PageFrame from "../pages/PageFrame";
import StaticPage from "../pages/common/StaticPage";
import { sessionUtil } from "../common/utils/SessionUtil";

const PrivateRoute = ({ Component, ...options }) => {
  sessionUtil();

  const state = useSelector((state) => state);
  // ログインしてなかったらLogin Componentにする
  if (state.login) {
    const role = state.user.role.split('_')[1];
    if (options.roles?.length && !options.roles.includes(role)) {
      return <Forbidden />
    }
    return <PageFrame Component={Component} DateTime={new Date()} options={options} />
  };
  return <Navigate to="/login" />;
};

const StaticRoute = ({ Component, ...options }) => {
  sessionUtil();

  const state = useSelector((state) => state);
  // ログインしてなかったらLogin Componentにする
  if (state.login) return <StaticPage Component={Component} />;
  return <Navigate to="/login" />;
};

export { PrivateRoute, StaticRoute };
