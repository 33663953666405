"use strict";
import * as React from "react";
import { useSelector } from "react-redux";
import { table } from "../../../_constants/Code";
import { formatDate } from "../../../common/utils/DateUtils";
import { errorLog } from "../../../common/logger/Logger";
import { getDynamoDB, updateDynamoDB, registDynamoDB, deleteDynamoDB } from "../../../common/utils/DynamoDbUtils";
import { useToast } from "../../../_components/Toast";

const CategoryTagContent = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [ctgCheckCategoryNm, setCtgCheckCategoryNm] = React.useState([]);
  const [ctList, setCtList] = React.useState([]);
  const [ctgMemo, setCtgMemo] = React.useState({});
  const [ctgCategory, setCtgCategory] = React.useState({});
  const [ctgVersion, setCtgVersion] = React.useState({});
  const [catList, setCatList] = React.useState([]);
  const [ctError, setCtError] = React.useState();

  // 初期表示の処理
  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const category = await getCat();
    await getCt(category);
    setIsLoaded(true);
  }

  const openCtModal = () => {
    const modalBg = document.querySelector(".tag-bg-modal-window");
    modalBg.classList.add("is-active");
    modalBg.classList.remove("animate__fadeOut");
    modalBg.classList.add("animate__fadeIn");
    let chBoxByNm = document.getElementsByClassName('chBox');
    let chTextareaByNm = document.getElementsByName('chTextarea');
    let chLiByNm = document.getElementsByName('chLi');
    let saveCtgBtn = document.getElementById('saveCtgBtn');
    let saveCtgLabel = document.getElementById('saveCtgLabel');
    let len = chBoxByNm.length;
    let isCatFlg = false;
    for (let i = 0; i < len; i++) {
      chLiByNm[i].classList.remove('is-active');
      saveCtgBtn.classList.remove('display-none');
      saveCtgBtn.classList.remove('display-block');
      saveCtgLabel.classList.remove('display-none');
      saveCtgLabel.classList.remove('display-block');
      isCatFlg = false;
      catList.map((item) => {
        if (item["CategoryKey"] == ctgCategory[i + 1]) {
          isCatFlg = true;
        }
      })
      let chBox = document.getElementById('checkbox'.concat(i + 1));
      let chTextarea = document.getElementById('chTextarea'.concat(i + 1));
      let chLi = document.getElementById('chLi'.concat(i + 1));
      if (ctgCategory[i + 1] == i + 1 && isCatFlg) {
        chBox.checked = true;
        chTextarea.disabled = false;
        chLi.classList.add('is-active');
        if (ctgMemo[i + 1] == undefined || ctgMemo[i + 1] == null || ctgMemo[i + 1] == '') {
          chTextarea.placeholder = "メモを入力（任意、最大120文字）";
        } else {
          chTextarea.value = ctgMemo[i + 1];
        }
      } else {
        if (chBox != null) {
          chBox.checked = false;
          chTextarea.value = "";
          chTextarea.disabled = true;
        }
      }
      if (props.authority.CategoryTag_AuthInfo.Availability && props.authority.CategoryTag_AuthInfo.Auth_Conf?.CategoryTag_Set) {
        chBoxByNm[i].disabled = false;
        chTextareaByNm[i].disabled = false;
        saveCtgBtn.classList.add('display-block');
        saveCtgLabel.classList.add('display-none');
      } else {
        chBoxByNm[i].disabled = true;
        chTextareaByNm[i].disabled = true;
        saveCtgBtn.classList.add('display-none');
        saveCtgLabel.classList.add('display-none');
      }
    }
    const modalCtOn = document.querySelector("#modal-window--tag-list");
    modalCtOn.classList.add("is-active");
    modalCtOn.classList.remove("animate__fadeOut");
    modalCtOn.classList.add("animate__fadeIn");
  };

  const closeCtModal = () => {
    setCtError(false);
    const categoryBg = document.querySelector("#modal-window--tag-list");
    categoryBg.classList.remove("animate__fadeIn");
    categoryBg.classList.add("animate__fadeOut");
    const modalBg = document.querySelector(".tag-bg-modal-window");
    modalBg.classList.remove("animate__fadeIn");
    modalBg.classList.add("animate__fadeOut");
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      modalBg.classList.remove("is-active");
      document
        .querySelector("#modal-window--tag-list")
        .classList.remove("is-active");
    }, fadeOutTimeoutSec);
  };

  const checkOne = () => {
    let count = 0;
    let chBox = document.getElementsByClassName('chBox');
    let chTextarea = document.getElementsByName('chTextarea');
    let chLi = document.getElementsByName('chLi');
    let len = chBox.length;
    for (let i = 0; i < len; i++) {
      chLi[i].classList.remove('is-active');
      if (chBox[i].checked) {
        chTextarea[i].disabled = false;
        chLi[i].classList.add('is-active');
        count++;
      } else {
        chTextarea[i].disabled = true;
      }
    }
  };

  const handleChangeCtgMemo = (categoryKey, e) => {
    let ctgMemoSave = ctgMemo;
    ctgMemoSave[categoryKey] = e.target.value;
    setCtgMemo(ctgMemoSave);
  };

  const saveCategory = () => {
    let chBox = document.getElementsByClassName('chBox');
    let len = chBox.length;
    for (let i = 0; i < len; i++) {
      const ctInfo = ctList.find(
        (item) => item.CategoryKey == chBox[i].value
      );
      if (ctInfo == undefined || ctInfo == null) {
        if (chBox[i].checked) {
          registCt(chBox[i].value);
        }
      } else {
        if (chBox[i].checked) {
          updateCt(chBox[i].value);
        } else {
          deleteCt(chBox[i].value);
        }
      }
    }
    closeCtModal();
    props.showToast("用途タグを保存しました");
  };

  const getCat = async () => {
    try {
      const dynamoDBData = await getDynamoDB(
        table.Category, null, props.corporationNumber, null,
        { DeleteFlg: 0 }
      );
      setCatList(dynamoDBData.sort((objectN, objectM) => {
        return objectN["Sort"] - objectM["Sort"];
      }));
      return dynamoDBData;
    } catch (error) {
      errorLog("E000001", props.userId, props.pageId, "getCat", error);
    }
  };

  const getCt = async (category) => {
    try {
      const dynamoDBData = await getDynamoDB(
        table.CategoryTag,
        null,
        props.corporationNumber,
        null,
        { DashboardKey: props.dashboardKey }
      );
      const ctgMemo = {};
      const ctgCategory = {};
      const ctgVersion = {};
      const ctgCheckCategoryNm = [];
      dynamoDBData.map((item) => {
        ctgMemo[item.CategoryKey] = item.Memo;
        ctgCategory[item.CategoryKey] = item.CategoryKey;
        ctgVersion[item.CategoryKey] = item.Version;
        ctgCheckCategoryNm.push(category.find(c => c.CategoryKey === item.CategoryKey)?.CategoryName);
      });
      setCtgMemo(ctgMemo);
      setCtgCategory(ctgCategory);
      setCtgVersion(ctgVersion);
      setCtgCheckCategoryNm(ctgCheckCategoryNm);
      setCtList(dynamoDBData);
    } catch (error) {
      errorLog("E000001", props.userId, props.pageId, "getCt", error);
    }
  };

  const registCt = async (categoryKey) => {
    try {
      const nowDate = formatDate(new Date());
      const request = [
        {
          SearchKey: props.dashboardKey + "#" + categoryKey,
          DashboardKey: props.dashboardKey,
          CategoryKey: categoryKey,
          Memo: ctgMemo[categoryKey],
          CreateDateTime: nowDate,
          UpdateDateTime: nowDate,
          Version: 1,
        },
      ];
      await registDynamoDB(table.CategoryTag, "UserId", props.corporationNumber, request, props.userId);
      await getCt(catList);
    } catch (error) {
      setCtError(true);
      errorLog("E000001", props.userId, props.pageId, "registCt", error);
    }
  };

  const updateCt = async (categoryKey) => {
    try {
      const nowDate = formatDate(new Date());
      const pk = "CorporationNumber";
      const key = {
        CorporationNumber: props.corporationNumber,
        SearchKey: props.dashboardKey + "#" + categoryKey,
      };
      const item = {
        Memo: ctgMemo[categoryKey],
        UpdateDateTime: nowDate,
        Version: ctgVersion[categoryKey] + 1,
      };
      await updateDynamoDB(table.CategoryTag, pk, props.corporationNumber, key, item, props.userId);
      await getCt(catList);
    } catch (error) {
      setCtError(true);
      errorLog("E000001", props.userId, props.pageId, "updateCt", error);
    }
  };

  const deleteCt = async (categoryKey) => {
    try {
      const key = {
        CorporationNumber: props.corporationNumber,
        SearchKey: props.dashboardKey + "#" + categoryKey,
      };
      await deleteDynamoDB(table.CategoryTag, key, props.corporationNumber, props.userId);
      await getCt(catList);
    } catch (error) {
      setCtError(true);
      errorLog("E000001", props.userId, props.pageId, "deleteCt", error);
    }
  };

  /**
   * 描画処理
   */
  return (
    <React.Fragment>
      <div className={`action-area__tag mr-4 tag-container rounded-xl flex items-center ${ctgCheckCategoryNm.length != 0 ? ' is-active' : ''}`}>
        <div className="tag-container__icon grid mx-3"><span
          className="icon icon-tag-outline justify-self-center"></span></div>
        <span className="tag-container__label text-h7 pl-3">用途タグ：</span>
        <div className="tag-container__text text-h7 pr-4">{isLoaded &&
          <span>{ctgCheckCategoryNm.length == 0 ? "未設定" : ctgCheckCategoryNm.join()}</span>
        }</div>
        <button type="button" className="btn-tag text-h7 text-link py-3 px-4" onClick={() => openCtModal()}>設定</button>
      </div>
      {/* ===== 用途タグ中 ===== */}
      <div className="tag-bg-modal-window w-screen h-screen fixed top-0 left-0 grey lighten-70 z-50" onClick={() => closeCtModal()}></div>
      <div className="modal-window modal-window__wide fixed bg sub z-50 rounded-2xl" id="modal-window--tag-list">
        <div className="modal-window__header">
          <div className="modal-window__title flex items-center justify-center pt-10">
            <span className="modal-window__title-icon icon-tag-fill red--text mr-2"></span>
            <span className="modal-window__title-text text-h3 font-medium">用途タグ一覧</span>
          </div>
          <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => closeCtModal()}><span className="icon-close"></span></button>
        </div>
        <div className="modal-window__content px-8 py-10">
          {/* エラー時表示 */}
          {ctError &&
            <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">
              エラーが発生しました。<br />
              時間をおいて再度お試しください。
            </div>
          }
          {/* /エラー時表示 */}
          <div id="form--tag-list">
            <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">※設定したタグや入力したメモは全員に表示されます。</div>
            <div className="form-control">
              <ul>
                {catList.map((item, index) => {
                  return (
                    <li className="px-6 py-3 is-active" key={index} id={`chLi${item["CategoryKey"]}`} name="chLi">
                      <dl className="flex items-center">
                        <dt className="mr-6">
                          <label className="ui-checkbox">
                            <input type="checkbox" id={`checkbox${item["CategoryKey"]}`} className="chBox ui-checkbox__input" value={item["CategoryKey"]} onClick={() => checkOne()}></input>
                            <span className="ui-checkbox__icon"></span>
                            <span className="ui-checkbox__ripple"></span>
                            <span className="ui-checkbox__label text-h7 font-Regular">{item["CategoryName"]}</span>
                          </label>
                        </dt>
                        <dd>
                          <span className="__inactive text-caption-2 grey--text text--lighten-35 px-4 py-2">未設定</span>
                          <textarea maxLength="200" placeholder="メモを入力（任意、最大120文字）" id={`chTextarea${item["CategoryKey"]}`} name="chTextarea" className="__active modal-window__content-textarea rounded-lg px-4 py-3 w-full" onChange={(e) => handleChangeCtgMemo(item["CategoryKey"], e)}></textarea>
                        </dd>
                      </dl>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="text-center mt-8 div-mid">
              <button className="btn btn-outline modal-window__btn-close rounded-lg px-16 mr-8 text-h5 font-medium btn-margin" onClick={() => closeCtModal()}>戻る</button>
              <button id="saveCtgBtn" className="btn btn-modal-tag-apply white--text grey rounded-lg px-16 text-h5 font-medium btn-margin" onClick={() => saveCategory()}>設定</button>
              <label id="saveCtgLabel" className="btn white--text grey-disabled rounded-lg px-16 text-h5 btn-margin display-none">設定</label>
            </div>
          </div>
        </div>
      </div>
      {/* ===== /用途タグ中 ===== */}
    </React.Fragment>
  );
}

const CategoryTag = (props) => {
  const state = useSelector((state) => state);
  const showToast = useToast();

  return <CategoryTagContent
    dashboardKey={props.dashboardKey}
    dashboardName={props.dashboardName}
    pageId={props.pageId}
    corporationNumber={state.company.corporationNumber}
    userId={state.user.sub}
    authority={state.authority}
    showToast={showToast}
  />
}

export const CategoryTagEmpty = () => {
  return (
    <div className={`action-area__tag mr-4 tag-container rounded-xl flex items-center`}>
      <div className="tag-container__icon grid mx-3"><span
        className="icon icon-tag-outline justify-self-center"></span></div>
      <span className="tag-container__label text-h7 pl-3">用途タグ：</span>
      <div className="tag-container__text text-h7 pr-4"><span></span></div>
      <button type="button" className="btn-tag text-h7 text-link py-3 px-4">設定</button>
    </div>
  )
}

export default (props) => <CategoryTag {...props} />;
