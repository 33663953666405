"use strict";
import * as React from "react";
import { Cookies } from "react-cookie";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { titleName, viewId, table, analysisMode } from "../_constants/Code";

import { getSystemDatetime, getDatetimeByYmdhms, formatDateNumber } from "../common/utils/DateUtils";
import { kgiPageInfoList, mainPageInfoList, monitoringInfoList } from "../_constants/PageInfoList";
import { PathInfoList } from "../_constants/PathInfoList";
import { errorLog } from "../common/logger/Logger";
import { getPageText, getObjectList } from "../common/utils/FileUtils";
import { getLatestStdFmtData } from "../common/utils/DataSetUtils";
import { availabilityMiddleTitle, availabilitySmallTitle } from '../common/utils/AnalysisModeUtil';
import { getDynamoDB } from "../common/utils/DynamoDbUtils";

import DataUpdateStatus from "./data/DataUpdateStatus"

//<@StartImportParts>
import img_top_mv from '../assets/img/top/img_top_mv.jpg'
//<@EndImportParts>

const cookies = new Cookies();
const monitoringInfoListForPage = monitoringInfoList.filter(info => {
  return !info.justMenu;
});

class HomeContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      pageText: null,
      annualData: null,
      quaterData: null,
      monthData: null,
    };
    this.hasNewNews = false;
    this.hasNewNotice = false;
    this.mode = this.props.mode;
  }


  dashboardAvailability = (mode, authority, pageInfo) => {
    // 権限制御
    let availability = {
      large: false,  // 大見出しの参照権限
      middleList: Array(pageInfo.dashboardList.length).fill(false)  // 中見出しの参照権限
    };
    if (authority.Dashboard_AuthInfo.Availability) {  // ダッシュボードへの参照権限がある
      const authConf = authority.Dashboard_AuthInfo.Auth_Conf.find(auth => auth.Key === pageInfo.key);
      for (let i = 0; i < pageInfo.dashboardList.length; i++) {
        const middleId = pageInfo.key + '.' + String(i + 1);
        const middleFlg = availabilityMiddleTitle(mode, authConf, pageInfo.dashboardList[i].dashboard, middleId);
        if (middleFlg) {
          const smallFlg = pageInfo.dashboardList[i].dashboard.findIndex((item, idx) => availabilitySmallTitle(mode, authConf, item, middleId, idx));
          if (smallFlg !== -1) {
            availability.large = true;
            availability.middleList[i] = true;
            continue;
          }
        }
        availability.middleList.push(false);
      }
    }
    return availability;
  }

  checkHasNewInfo = (key, list) => {
    try {
      let today = getSystemDatetime();
      const newList = list.filter((news) => {
        const pubDate = getDatetimeByYmdhms(news.date);
        const lastViewDatetime = new Date(cookies.get(key));
        return pubDate <= today && lastViewDatetime < pubDate;
      });
      if (!cookies.get(key) || newList.length > 0) {
        if (key === "lastNewsViewDatetime") {
          this.hasNewNews = true;
        } else if (key === "lastNoticeViewDatetime") {
          this.hasNewNotice = true;
        }
      }
    } catch (error) {
      errorLog("E000001", this.props.userId, viewId.home, "checkHasNewInfo", error);
    }
  }

  formatList = (list) => {
    try {
      return list.map((data) => {
        return (
          {
            date: formatDateNumber(data.date),
            title: data.title,
            url: data.url,
          }
        )
      })
    } catch (error) {
      errorLog("E000001", this.props.userId, viewId.home, "formatList", error);
    }
  }

  checkNewNews = async () => {
    const infoKey = "lastNewsViewDatetime";
    const newsList = await getObjectList(process.env.REACT_APP_S3_WEB_CHROFY_COMMON, PathInfoList.Folder.newsFolder + PathInfoList.Objectkeyname.news);
    this.checkHasNewInfo(infoKey, this.formatList(newsList));
  }

  checkNewNotice = async () => {
    const infoKey = "lastNoticeViewDatetime";
    const noticeList = await getObjectList(process.env.REACT_APP_S3_WEB_CHROFY_COMMON, PathInfoList.Folder.noticeFolder + PathInfoList.Objectkeyname.notice);
    this.checkHasNewInfo(infoKey, this.formatList(noticeList));
  }

  setDataset = async () => {
    try {
      const latestStdFmtData = await getLatestStdFmtData(this.props.bucketName, this.props.accPeriod);
      this.setState({
        annualData: latestStdFmtData.annualData,
        quaterData: latestStdFmtData.quaterData,
        monthData: latestStdFmtData.monthData,
      });
    } catch (error) {
      errorLog(
        "E000001",
        this.props.userId,
        viewId.home,
        "setDataset",
        error
      );
    }
  };

  setBusinessPlan = async () => {
    // 経営計画
    const dynamoDBData = await getDynamoDB(
      table.BusinessPlan, null, this.props.corporationNumber, null,
      { Mode: this.props.mode, Status: 'release', DeleteFlg: 0 }
    )
    dynamoDBData.sort((a, b) => {
      if (a.StartPeriod > b.StartPeriod) return -1;
      if (b.StartPeriod > a.StartPeriod) return 1;
      if (a.Key > b.Key) return -1;
      return 1;
    });
    this.setState({
      businessPlan: dynamoDBData.length ? dynamoDBData[0] : null
    });
  };

  componentDidMount = async () => {
    // タイトル設定
    document.title = this.props.pageTitle;
    await this.checkNewNews();
    await this.checkNewNotice();
    await this.setDataset();
    await this.setBusinessPlan();
    this.setState({
      isLoaded: true,
      pageText: await getPageText(),
    });
  };
  render() {
    if (!this.state.isLoaded) {
      return null;
    }
    return (
      //<@StartRenderContents>
      <main className="page-container">
        <section className="page-content">
          {/* メインビジュアル */}
          <div className="top-main-visual"><img src={img_top_mv} className="w-full object-cover object-center" alt="" /></div>

          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="top-page-header flex items-center justify-between px-16">
              <hgroup className="py-12 py-13">
                <h1 className="top-page-header__name text-display-2 grey--text">{(this.props.isParent && this.mode == analysisMode.consolidated) ? this.props.groupName : this.props.company}</h1>
                <div className="mt-1 text-base">
                  【最終更新】
                  <span className="grey--text text--lighten-70">
                    月次データ：{this.state.monthData} ／ 四半期データ：{this.state.quaterData} ／ 年次データ：{this.state.annualData}
                  </span>
                  {/* ===== データ更新状況 ===== */}
                  <DataUpdateStatus />
                  {/* ===== /データ更新状況 ===== */}
                </div>
              </hgroup>
              <div className="top-page-header__btns">
                {/* 各お知らせがないときはa要素ごと削除 */}
                {/* 運営からのお知らせがあります */}
                {this.hasNewNotice && (
                  <Link color="inherit" underline="none" component={RouterLink} to="/info/notice">
                    <div className="top-page-header__btn-item rounded-full flex items-center grey white--text my-2">
                      <div className="top-page-header__btn-icon flex items-center rounded-full red py-1 pr-4 pl-2.5">
                        <span className="top-page-header__btn-icon mr-1"><span className="icon-info"></span></span>
                        <span className="top-page-header__btn-text text-h6-en">NEW</span>
                      </div>
                      <div className="top-page-header__description flex items-center justify-between pl-4 pr-2.5 grow">
                        <span className="top-page-header__description-text text-h7">運営からのお知らせがあります</span>
                        <span className="top-page-header__description-icon"><span className="icon-arrow ml-3"></span></span>
                      </div>
                    </div>
                  </Link>
                )}
                {/* 運営からのお知らせがあります */}
                {this.hasNewNews && (
                  <Link color="inherit" underline="none" component={RouterLink} to="/info/news">
                    <div className="top-page-header__btn-item rounded-full flex items-center grey white--text my-2">
                      <div className="top-page-header__btn-icon flex items-center rounded-full red py-1 pr-4 pl-2.5">
                        <span className="top-page-header__btn-icon mr-1"><span className="icon-news"></span></span>
                        <span className="top-page-header__btn-text text-h6-en">NEW</span>
                      </div>
                      <div className="top-page-header__description flex items-center justify-between pl-4 pr-2.5 grow">
                        <span className="top-page-header__description-text text-h7">人的資本関連ニュースがあります</span>
                        <span className="top-page-header__description-icon"><span className="icon-arrow ml-3"></span></span>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </header>

            {/* コンテンツボディ */}
            <div className="top-page-body">
              {/*
                ■ category-list__item の非活性表示について
                category-list__item クラスを持つa要素に is-disabled クラスを入れてください
              */}
              {kgiPageInfoList.map((pageInfo, index) => {
                return (
                  <section key={index} className="category-card bg sub pt-10 px-16 pb-16">
                    {/* header */}
                    <header className="category-card__header">
                      <div className="category-header flex items-center">
                        <div className="category-header__img p-2.5"><img src={pageInfo.img} alt="" /></div>
                        <div className="category-header__text py-5 pl-10 relative">
                          <div className="category-header__subtitle flex items-center red--text">
                            <span className="category-header__subtitle-number text-h4-en">{this.state.pageText[pageInfo.key].id}</span>
                            <span className="category-header__subtitle-text text-h6-en ml-7">{this.state.pageText[pageInfo.key].nameEng}</span>
                          </div>
                          <h2 className="category-header__title">
                            {(() => {
                              const content = (isDisabled) => {
                                return (
                                  <div className={`category-header__title-link inline-flex items-center ${isDisabled ? "is-disabled" : ""}`}>
                                    <span className="category-header__title-text text-h1 mr-6">{this.state.pageText[pageInfo.key].name}</span>
                                    <span className="category-header__title-icon"><span className="icon-arrow-circle"></span></span>
                                  </div>
                                );
                              };
                              if (this.state.businessPlan) {
                                return (
                                  <Link color="inherit" underline="none" component={RouterLink} to={pageInfo.url}>
                                    {content(false)}
                                  </Link>
                                );
                              } else {
                                return (
                                  content(true)
                                );
                              };
                            })()}
                          </h2>
                          <p className="category-header__description mt-10 grey--text text--lighten-70">{this.state.pageText[pageInfo.key].explanation}</p>
                        </div>
                      </div>
                    </header>
                    <div className="category-card__body mt-16">
                      {this.state.businessPlan ? (
                        <div className="plan-in-progress rounded-2xl border border-blue2-lighten-15 flex overflow-hidden">
                          <div className="text-h5 font-bold link--text px-16 flex items-center bg parts-blue">進行中の経営計画</div>
                          <div className="pt-8 pb-10 grow white border-l border-blue2-lighten-15">
                            <div className="text-h3 font-normal mb-3 bg text-center">{this.state.businessPlan.Title}</div>
                            <div className="flex gap-x-4 items-start justify-center">
                              <dl className="flex overflow-hidden rounded-md border-blue2 border text-extra-small font-bold">
                                <dt className="px-4 py-1 blue2 white--text">期間</dt>
                                <dd className="px-4 py-1 blue2--text">{this.state.businessPlan.StartPeriod}{this.props.accPeriod}期 〜 {this.state.businessPlan.EndPeriod}{this.props.accPeriod}期</dd>
                              </dl>
                              {this.props.isParent && (
                                <dl className="flex overflow-hidden rounded-md border-blue2 border text-extra-small font-bold">
                                  <dt className="px-4 py-1 blue2 white--text">対象</dt>
                                  {this.state.businessPlan.Mode === 'standalone' &&
                                    <dd className="px-4 py-1 red2--text">単体</dd>
                                  }
                                  {this.state.businessPlan.Mode !== 'standalone' &&
                                    <dd className="px-4 py-1 green2--text">連結</dd>
                                  }
                                </dl>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p className="category-card__no-content text-body-2 red--text text-center font-bold mb-16">
                          閲覧可能な経営戦略KGIはありません。<br />
                          管理者による登録及び公開設定が必要です。
                        </p>
                      )}
                    </div>
                  </section>
                )
              })}
              {mainPageInfoList.map((pageInfo, index) => {
                const availability = this.dashboardAvailability(this.props.mode, this.props.authority, pageInfo);
                return (
                  <section key={index} className="category-card bg sub pt-10 px-16 pb-16">
                    {/* header */}
                    <header className="category-card__header">
                      <div className="category-header flex items-center">
                        <div className="category-header__img p-2.5"><img src={pageInfo.img} alt="" /></div>
                        <div className="category-header__text py-5 pl-10 relative">
                          <div className="category-header__subtitle flex items-center red--text">
                            <span className="category-header__subtitle-number text-h4-en">{this.state.pageText[pageInfo.key].id}</span>
                            <span className="category-header__subtitle-text text-h6-en ml-7">{this.state.pageText[pageInfo.key].nameEng}</span>
                          </div>
                          <h2 className="category-header__title">
                            {(() => {
                              const content = (isDisabled) => {
                                return (
                                  <div className={`category-header__title-link inline-flex items-center ${isDisabled ? "is-disabled" : ""}`}>
                                    <span className="category-header__title-text text-h1 mr-6">{this.state.pageText[pageInfo.key].name}</span>
                                    <span className="category-header__title-icon"><span className="icon-arrow-circle"></span></span>
                                  </div>
                                );
                              };
                              if (availability.large) {
                                return (
                                  <Link color="inherit" underline="none" component={RouterLink} to={pageInfo.url}>
                                    {content(false)}
                                  </Link>
                                );
                              } else {
                                return (
                                  content(true)
                                );
                              };
                            })()}
                          </h2>
                          <p className="category-header__description mt-10 grey--text text--lighten-70">{this.state.pageText[pageInfo.key].explanation}</p>
                        </div>
                      </div>
                    </header>
                    {/* /header */}
                    <div className="category-card__body mt-16">
                      {availability.large && availability.middleList.some((v) => v === true) ? (
                        <div className="category-list grid grid-cols-2 gap-8">
                          {pageInfo.dashboardList.map((item, index) => {
                            const content = (isDisabled) => {
                              return (
                                <div key={index} className={`category-list__item flex items-center rounded-2xl py-4 pl-4 pr-8 ${isDisabled ? "is-disabled category-list__item-no-link" : "white"}`}>
                                  <div className="category-list__img"><img src={item.img} alt="" /></div>
                                  <div className="category-list__text ml-6">
                                    <div className="category-list__title text-h3">{this.state.pageText[pageInfo.key].dashboardList[index].name}</div>
                                  </div>
                                  <div className="category-list__icon ml-auto text-2xl leading-none"><span className="icon-arrow-circle-s"></span></div>
                                </div>
                              );
                            };
                            if (availability.middleList[index]) {
                              return (
                                <Link key={index} color="inherit" underline="none" component={RouterLink} to={pageInfo.url + index}>
                                  {content(false)}
                                </Link>
                              );
                            } else {
                              return (
                                content(true)
                              );
                            };
                          })}
                        </div>
                      ) : (
                        <p className="category-card__no-content text-body-2 red--text text-center font-bold mb-16">
                          閲覧可能な{this.state.pageText[pageInfo.key].name}はありません。<br />
                          管理者による登録が必要です。
                        </p>
                      )}
                    </div>
                  </section>
                )
              })}

              {/* ===== 月次・トピック別・法令等対応 ===== */}
              <section className="guideline-card pt-12 px-16 pb-16 mt-16">
                <h2 className="text-h2 text-center mb-12">月次・トピック別・法令等対応</h2>
                <div className="category-list grid grid-cols-2 gap-8">
                  {monitoringInfoListForPage.map((pageInfo, index) => {
                    const availability = this.dashboardAvailability(this.props.mode, this.props.authority, pageInfo);
                    return (
                      <div key={index}>
                        {pageInfo.dashboardList.map((item, index) => {
                          if (index > 0) { return; }
                          const content = (isDisabled) => {
                            return (
                              <div key={index} className={`category-list__item flex items-center rounded-2xl py-4 pl-4 pr-8 ${isDisabled ? "is-disabled category-list__item-no-link" : "white"}`}>
                                <div className="category-list__img"><img src={item.img} alt="" /></div>
                                <div className="category-list__text ml-6">
                                  <div className="category-list__title text-h3">{this.state.pageText[pageInfo.key].id} {this.state.pageText[pageInfo.key].name}</div>
                                  <div className={`category-list__subtitle text-caption-1 mt-2.5 ${isDisabled ? "" : "blue2--text"}`}>{this.state.pageText[pageInfo.key].explanation}</div>
                                </div>
                                <div className="category-list__icon ml-auto text-2xl leading-none"><span className="icon-arrow-circle-s"></span></div>
                              </div>
                            );
                          };
                          if (availability.large) {
                            return (
                              <Link key={index} color="inherit" underline="none" component={RouterLink} to={pageInfo.url}>
                                {content(false)}
                              </Link>
                            );
                          } else {
                            return (
                              content(true)
                            );
                          };
                        }
                        )}
                      </div>
                    )
                  })}
                </div>
              </section>
              {/* ===== /月次・トピック別・法令等対応 ===== */}
            </div>
          </div>
        </section>
      </main >
      //<@EndRenderContents>
    );
  }
}

const Home = () => {
  const state = useSelector((state) => state);
  return <HomeContent
    company={state.company.name}
    userId={state.user.sub}
    pageTitle={titleName.top + titleName.common}
    bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber}
    mode={state.analysis.mode}
    corporationNumber={state.company.corporationNumber}
    isParent={state.company.isParent}
    groupName={state.company.groupName}
    accPeriod={state.company.accPeriod}
    authority={state.authority}
  />;
};

export default Home;
