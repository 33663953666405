import { useState } from "react";
import { useDragOver } from "@minoru/react-dnd-treeview";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Delete } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const CustomNode = (props) => {
  const [hover, setHover] = useState(false);
  const { id, type, canDelete } = props.node;
  const indent = props.depth * 24;

  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  return (
    // <div className={`"tree--node--root " ${props.style}`} style={{ paddingInlineStart: indent }}
    <div className={`tree--node--root ${props.styles ? props.styles : ""}`} style={{ paddingInlineStart: indent }}
      {...dragOverProps}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={`tree--node--arrow ${props.isOpen ? "isOpen" : ""}`}>
        {props.hasChild && (
          <div onClick={handleToggle}>
            {props.isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </div>
        )}
      </div>
      <div className={`${type === 'KGI' ? "tree--node--filetypeKgi" : type === 'KPI' ? "tree--node--filetypeKpi" : "tree--node--filetype"}`}>
      </div>
      <div className={`${type === 'KGI' ? "tree--node--labelKgi" : type === 'KPI' ? "tree--node--labelKpi" : "tree--node--label"}`}>
        <Typography variant="body2">{props.node.text}</Typography>
      </div>
      {hover && canDelete === true && props.onDelete && (
        <>
          <div className="tree--node--actionButton">
            <IconButton size="small" onClick={() => props.onDelete(id)}>
              <Delete fontSize="small" />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
};
