"use strict";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useToast } from "../../_components/Toast";
import { PathInfoList } from "../../_constants/PathInfoList";
import { Roll, restApiKey } from "../../_constants/Code";
import { httpGet } from '../../services/api/AmplifyApi';
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { viewId, titleName } from "../../_constants/Code";
import { errorLog } from "../../common/logger/Logger";
import Forbidden from "../../_components/Forbidden";

const UserListContent = (props) => {
  const [userList, setUserList] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    init();
    if (props.message) {
      props.showToast(props.message);
    }
  }, []);

  const init = async () => {
    // ユーザ情報取得
    try {
      const init = {
        queryStringParameters: {
          corporationNumber: props.corporationNumber,
        },
        headers: {},
      };
      const response = await httpGet(
        restApiKey.CHROFY_REST_API,
        PathInfoList.Resource.getUserList(props.corporationNumber),
        init
      );
      const sortedData = response.map((item) => {
        const sortedGroup = item.group.split(",").sort((a, b) => a.localeCompare(b)).join(",");
        return { ...item, group: sortedGroup };
      });
      setUserList(sortedData);
    } catch (error) {
      errorLog("E000001", props.sub, viewId.userList, "getUserList", error);
    }
    setIsLoaded(true);
  };

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{titleName.userList}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div className="centered-container-1056">
                {/*  ===== リード ===== */}
                <p className="text-body text-center mb-10">
                  ユーザーごとに権限グループを割り当ててください。<br />
                  権限グループは、予め「権限グループ管理」メニューから登録しておく必要があります。
                </p>
                {/*  ===== /リード ===== */}
                <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-16 px-16 border-blue1">
                  <div className="content-area">
                    <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">ユーザー一覧</h2>
                    <div className="flex my-5">
                      <ul className="text-body-2-medium grow">
                        <li>ユーザーへの権限の割当てができます。</li>
                      </ul>
                    </div>
                    {/*  ===== ユーザー一覧 ===== */}
                    {userList.length > 0 ? (
                      <table className="base-table user-list-table w-full">
                        <thead className="text-h7">
                          <tr>
                            <th width="230px">ユーザー名</th>
                            <th width="250px">ユーザーID</th>
                            {props.isParent && <th width="230px">グループ会社参照権限</th>}
                            <th width="auto">機能利用権限</th>
                          </tr>
                        </thead>
                        <tbody className="text-caption-1">
                          {userList
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((user, idx) => (
                              <tr key={idx}>
                                <td className="title">
                                  <Link variant="button" underline="none" component={RouterLink} to={"/user/userPermission/" + user.sub}>
                                    <span className="font-Medium" style={{ textTransform: "none" }}>{user.name}</span>
                                  </Link>
                                </td>
                                <td>{user.id}</td>
                                {props.isParent && <td className={!user.refGroup ? "grey2--text" : ""}>{user.refGroup ? user.refGroup : "権限なし"}</td>}
                                <td>{user.group}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    ) :
                      <div className="text-center my-10 pt-10">
                        <span className="text-h5 red--text">ユーザ情報が存在しません。管理者までお問い合わせください。</span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main >
    </React.Fragment>
  );
}

const UserList = () => {
  const state = useSelector((state) => state);
  const location = useLocation();
  const showToast = useToast();

  // 権限がない場合は表示しない
  if (state.user.role.split('_')[1] !== Roll.administrator) {
    return <Forbidden />
  }
  return <UserListContent
    pageTitle={titleName.userList + titleName.common}
    corporationNumber={state.company.corporationNumber}
    isParent={state.company.isParent}
    message={location.state?.message}
    showToast={showToast}
  />;
};

export default UserList;