import * as React from "react";
import { Link } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { viewId, analysisMode, table } from "../_constants/Code";
import { errorLog } from "../common/logger/Logger";
import { getDynamoDB } from "../common/utils/DynamoDbUtils";

import logo from '../assets/img/logo.svg'
import icon_user from '../assets/img/icon/icon-user.svg'
import icon_company from '../assets/img/icon/icon-company.svg'
import icon_company_white from '../assets/img/icon/icon-company-white.svg'
import icon_group_segment from '../assets/img/icon/icon-group-segment.svg'
import icon_group_segment_white from '../assets/img/icon/icon-group-segment-white.svg'

const closeMenu = () => {
  const buttonMenu = document.getElementById("header__btn-menu");
  if (buttonMenu.classList.contains('is-active')) {
    buttonMenu.click();
  }
};

export const HeaderItems = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const changeAnalysisMode = (mode) => {
    try {
      dispatch({
        type: "SET_ANALISYS_MODE",
        payload: {
          analysis: {
            mode: mode,
            targets: state.analysis.targets,
            checkedIndex: state.analysis.checkedIndex,
            checkedNames: state.analysis.checkedNames
          }
        },
      });
      window.location.href = "/";
    } catch (error) {
      errorLog("E000001", state.user.sub, viewId.header, "changeAnalysisMode", error);
    }
  };
  return (
    <header className="header white fixed w-full top-0 left-0 flex items-center z-40 has-box-shadow">
      {/* メニューボタン */}
      <button id="header__btn-menu" className="header__btn-menu hamburger hamburger--slider" type="button">
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      {/* ロゴ */}
      <h1 className="header__logo flex items-center pl-10" onClick={() => closeMenu()}>
        <span className="header__logo-img">
          <Link color="inherit" underline="none" component={RouterLink} to="/">
            <img src={logo} width="178" height="32" alt="CHROFY"/>
          </Link>
        </span>
        <span className="header__logo-text red--text text-catch-en ml-6">The Human Capital Reporting</span>
      </h1>
      {/* 分析対象 切り替え */}
      {state.company.isParent && state.authority.Company_AuthInfo.Availability &&
        <div className="header__target">
            <button className="header__target-item is-single" onClick={() => changeAnalysisMode(analysisMode.standalone)}>
              <a>
                <span className="header__target-icon">
                  <img className="header__target-img is-multiple" src={icon_company} width="24" height="24" alt="" />
                  <img className="header__target-img is-single" src={icon_company_white} width="24" height="24" alt="" />
                </span>
                  <span className="header__target-title">単体</span>
                </a>
            </button>
            <button className="header__target-item is-multiple" onClick={() => changeAnalysisMode(analysisMode.consolidated)}>
              <a>
                <span className="header__target-icon">
                  <img className="header__target-img is-single" src={icon_group_segment} width="24" height="24" alt="" />
                  <img className="header__target-img is-multiple" src={icon_group_segment_white} width="24" height="24" alt="" />
                </span>
                <span className="header__target-title">連結&セグメント</span>
              </a>
            </button>
        </div>
      }
      {/* ユーザー表示 */}
      <div className="header__user ml-auto mr-9 relative">
        <button type="button" className="header__user-btn flex items-center">
          <span className="header__user-avatar"><img src={icon_user} width="26" height="26" alt=""/></span>
          <span className="header__user-name text-caption-1 ml-2 mr-7">{state.company.name}　{state.user.userName}</span>
          <span className="header__user-icon"></span>
        </button>
        {/* ユーザーメニュー */}
        <div className="header__user-menu grey absolute z-10 rounded -right-4 top-11">
          <ul className="header__user-menu-list">
            <Link color="inherit" underline="none" component={RouterLink} to="/user/changePassword" onClick={() => closeMenu()}>
              <li className="header__user-menu-item text-h7"><div className="header__user-menu-link white--text block py-4 pl-8 pr-10">パスワード変更</div></li>
            </Link>
            <Link color="inherit" underline="none" component={RouterLink} to="/logout">
              <li className="header__user-menu-item text-h7"><div className="header__user-menu-link white--text block py-4 pl-8 pr-10">ログアウト</div></li>
            </Link>
          </ul>
        </div>
      </div>
      {/*<Account />*/}
    </header>
  )
};