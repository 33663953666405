"use strict";
import * as React from "react";
import { useParams } from "react-router-dom";
import QuickSight from "../common/QuickSight";
import { monitoringInfoList } from "../../_constants/PageInfoList";
import { viewId } from "../../_constants/Code";

const ISO30414 = (props) => {
  const page = monitoringInfoList.find(page => { return page.key === "7"; });
  return (
    <main className="page-container">
      <section className="page-content">
        <div className="centered-container">
          <QuickSight
            pageId={viewId.iso30414}
            pageInfo={page}
            dashboardList={page.dashboardList}
            listNum={props.params.listNum}
            listNumSub={props.params.listNumSub}
            isKpi={false}
            isBenchmark={false}
          />
        </div>
      </section>
    </main>
  );
};

export default (props) => <ISO30414 {...props} params={useParams()} />;
