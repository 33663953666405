const message = {
  E000001: "{0},{1},「{2}」処理に失敗しました（エラー内容：{3}）",
  E010001: "「{0}」を入力してください。",
  E010002: "「{0}」が誤っています。",
  E010003: "「{0}」の入力間違い回数の限度を超えています。時間をおいて変更してください。",
  E010004: "{0}の{1}が不正です。",
  E010005: "{0}のデータサイズは1Gバイト以上です。",
  E010006: "「{0}」は{1}文字以内で入力してください。",
  E010007: "{0}は8文字以上で半角の英大文字、英小文字、数字、記号をそれぞれ1文字含む必要があります。",
  E010008: "{0}が大きすぎます。",
  E010009: "{0}が不正です。",
  E010010: "{0}には{1}以上の数値を入力してください。",
  E010011: "{0}は{1}できません。",
  E010012: "{0}が上限を超えています。",
  E011001: "「{0}」と「{1}」が異なっています。",
  E011002: "ファイル数が{0}を超えているため、アップロードできません。",
  E011003: "「{0}」は{1}を満たしていません。",
  E011004: "{0}で予期せぬエラーが発生しました。",
  E011005: "{0}のアップロードは失敗です。",
  E011006: "期間は、開始年度≦終了年度となるように設定してください。",
  E011007: "モニタリング対象となるKGIまたはKPIが設定されていないため、公開できません。",
  E011008: "「予約後」として登録されているため、入力できません。",
  E011009: "{0}にマッピングされていない科目があるためアップロードできません。",
  W010001: "上書きされます",
};

export default message;
