import { allConditions } from "../../_constants/Code";
export const getNowDispStateCtgSize = (btyeCtgLength, nowDispStateBm, nowDispStateCtg, nowDispStateBdg) => {
  let nowDispStateCtgSize = 0;
  if (btyeCtgLength >= 58
    && (nowDispStateBm != null && nowDispStateBm != "" && nowDispStateBm != allConditions.allBookmark)
    && (nowDispStateCtg != null && nowDispStateCtg != "" && nowDispStateCtg != allConditions.allCategory)
    && (nowDispStateBdg != null && nowDispStateBdg != "" && nowDispStateBdg != allConditions.allBadge)) {
    nowDispStateCtgSize = 436/2;
  } else if (btyeCtgLength >= 86
    && (nowDispStateBm == null || nowDispStateBm == "" || nowDispStateBm == allConditions.allBookmark)
    && (nowDispStateCtg != null && nowDispStateCtg != "" && nowDispStateCtg != allConditions.allCategory)
    && (nowDispStateBdg != null && nowDispStateBdg != "" && nowDispStateBdg != allConditions.allBadge)) {
    nowDispStateCtgSize = 635.98/2;
  } else if (btyeCtgLength >= 120
    && (nowDispStateBm == null || nowDispStateBm == "" || nowDispStateBm == allConditions.allBookmark)
    && (nowDispStateCtg != null && nowDispStateCtg != "" && nowDispStateCtg != allConditions.allCategory)
    && (nowDispStateBdg == null || nowDispStateBdg == "" || nowDispStateBdg == allConditions.allBadge)) {
    nowDispStateCtgSize = 891.95/2;
  } else if (btyeCtgLength >= 92
    && (nowDispStateBm != null && nowDispStateBm != "" && nowDispStateBm != allConditions.allBookmark)
    && (nowDispStateCtg != null && nowDispStateCtg != "" && nowDispStateCtg != allConditions.allCategory)
    && (nowDispStateBdg == null || nowDispStateBdg == "" || nowDispStateBdg == allConditions.allBadge)) {
    nowDispStateCtgSize = 680/2;
  } else if (btyeCtgLength/nowDispStateCtg.length == 2) {
    nowDispStateCtgSize = (btyeCtgLength*7+34.67)/2;
  } else if (btyeCtgLength/nowDispStateCtg.length == 1) {
    nowDispStateCtgSize = (btyeCtgLength*6.645+34.67)/2;
  } else if (btyeCtgLength/nowDispStateCtg.length > 1 && btyeCtgLength/nowDispStateCtg.length <= 1.5 ) {
    nowDispStateCtgSize = (btyeCtgLength*6.8+34.67)/2;
  } else if (btyeCtgLength/nowDispStateCtg.length > 1.5 && btyeCtgLength/nowDispStateCtg.length < 2 ) {
    nowDispStateCtgSize = (btyeCtgLength*7.1+34.67)/2;
  } else {
    nowDispStateCtgSize = (btyeCtgLength*7+34.67)/2;
  }
  return nowDispStateCtgSize;
};

export const getNowDispStateBdgSize = (btyeBdgLength, nowDispStateBm, nowDispStateCtg, nowDispStateBdg) => {
  let nowDispStateBdgSize = 0;
  if (btyeBdgLength >= 20) {
    if ((nowDispStateBm == null || nowDispStateBm == "" || nowDispStateBm == allConditions.allBookmark)
      && (nowDispStateCtg == null || nowDispStateCtg == "" || nowDispStateCtg == allConditions.allCategory)
      && (nowDispStateBdg != null && nowDispStateBdg != "" && nowDispStateBdg != allConditions.allBadge)) {
      nowDispStateBdgSize = 256.15/2;
    } else {
      nowDispStateBdgSize = 217/2;
    }
  } else if (btyeBdgLength/nowDispStateBdg.length == 2) {
    nowDispStateBdgSize = (btyeBdgLength*7+34.67)/2;
  } else if (btyeBdgLength/nowDispStateBdg.length == 1) {
    if (btyeBdgLength == 3) {
      nowDispStateBdgSize = (btyeBdgLength*11.56+34.67)/2;
    } else {
      nowDispStateBdgSize = (btyeBdgLength*9.71+34.67)/2;
    }
  } else if (btyeBdgLength/nowDispStateBdg.length > 1 && btyeBdgLength/nowDispStateBdg.length <= 1.3 ) {
    nowDispStateBdgSize = (btyeBdgLength*7.7+34.67)/2;
  } else if (btyeBdgLength/nowDispStateBdg.length > 1.3 && btyeBdgLength/nowDispStateBdg.length < 2 ) {
    nowDispStateBdgSize = (btyeBdgLength*7.2+34.67)/2;
  } else {
    nowDispStateBdgSize = (btyeBdgLength*7+34.67)/2;
  }
  return nowDispStateBdgSize;
};