"use strict";
import * as React from "react";
import { SpinnerButton } from "../../common/CustomButton"
import { kgiUnit } from "../../../_constants/Code";
import { formatCommaDigit, parseCommaDigit } from "../../../common/utils/NumberUtils";
import { getMessage } from "../../../common/utils/MessageUtils";

// 決算期リスト
let kpiYearList = [];

// エラー項目
const errors = {
  Title: null,
  Description: null
}

/**
 * 共通パーツ「マテリアリティ情報」
 */
const kgiInfo = {
  id: '',
  _Parent: '',
  Title: '',
  Description: '',
  Kind: '定量',
  Unit: 'none',
  Sub_Kgi: [],
  Kpi_Key: [],
  Value_List: {},
};

/**
 * 共通パーツ「KGIツリー」
 */
const kgiTree = [];

const MaterialityTableContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  // 画面入力情報
  const [kgiTreeInfo, setKgiTreeInfo] = React.useState([]);
  const [materialityInfo, setMaterialityInfo] = React.useState();
  const [inputError, setInputError] = React.useState();

  React.useEffect(() => {
    init();
  }, []);

  /**
   * 初期表示
   */
  const init = async () => {
    // 決算期リスト
    kpiYearList.splice(0);
    let startYear = Number(props.kpiStartYear.slice(0, 4));
    if (props.accPeriod !== '12月') {
      startYear = startYear + 1;
    }
    for (let i = 0; i < 10; i++) {
      const year = (startYear + i) + props.kpiStartYear.slice(-1);
      kpiYearList.push(year);
    }
    // 目標値、実績値のリスト
    kpiYearList.forEach(d => {
      kgiTree.forEach(el => {
        if (!el.Value_List[d]) {
          el.Value_List[d] = {
            TargetValue: '',
            ActualValue: '',
          }
        } else {
          // カンマ設定
          _addComma(el.Value_List, kgiUnit[el.Unit].numDecPlaces);
        }
        el.Sub_Kgi.forEach(sub => {
          if (!sub.Value_List[d]) {
            sub.Value_List[d] = {
              TargetValue: '',
              ActualValue: '',
            }
          } else {
            // カンマ設定
            _addComma(sub.Value_List, kgiUnit[sub.Unit].numDecPlaces);
          }
        });
      });
    })
    setMaterialityInfo({ ...kgiInfo });
    setKgiTreeInfo(kgiTree.map(n => n));
    setInputError(errors);
    setIsLoaded(true);
  }

  const _addComma = (value_list, numDecPlaces) => {

    Object.keys(value_list).forEach((key) => {
      value_list[key].TargetValue = formatCommaDigit(value_list[key].TargetValue, numDecPlaces);
      value_list[key].ActualValue = formatCommaDigit(value_list[key].ActualValue, numDecPlaces);
    });
  }

  /**
   * 単位の値を変更する
   * @param {イベント} e 
   */
  const changeUnit = (idx, e) => {
    kgiTreeInfo[idx].Unit = e.target.value;
    setKgiTreeInfo([...kgiTreeInfo]);
  }

  /**
   * 目標値の値を変更する
   * @param {イベント} e 
   */
  const changeTargetValue = (idx, item, e) => {
    kgiTreeInfo[idx].Value_List[item].TargetValue = e.target.value;
    setKgiTreeInfo([...kgiTreeInfo]);
  }

  /**
   * 実績値の値を変更する
   * @param {イベント} e 
   */
  const changeActualValue = (idx, item, e) => {
    kgiTreeInfo[idx].Value_List[item].ActualValue = e.target.value;
    setKgiTreeInfo([...kgiTreeInfo]);
  }

  /**
   * 配下KGI 単位の値を変更する
   * @param {イベント} e 
   */
  const changeSubKgiUnit = (idx, idx2, e) => {
    kgiTreeInfo[idx].Sub_Kgi[idx2].Unit = e.target.value;
    setKgiTreeInfo([...kgiTreeInfo]);
  }

  /**
   * 配下KGI 目標値の値を変更する
   * @param {イベント} e 
   */
  const changeSubKgiTargetValue = (idx, idx2, item, e) => {
    kgiTreeInfo[idx].Sub_Kgi[idx2].Value_List[item].TargetValue = e.target.value;
    setKgiTreeInfo([...kgiTreeInfo]);
  }

  /**
   * 配下KGI 実績値の値を変更する
   * @param {イベント} e 
   */
  const changeSubKgiActualValue = (idx, idx2, item, e) => {
    kgiTreeInfo[idx].Sub_Kgi[idx2].Value_List[item].ActualValue = e.target.value;
    setKgiTreeInfo([...kgiTreeInfo]);
  }

  /**
   * 種別の値を変更する
   * @param {イベント} e 
   */
  const changeKind = (e) => {
    setMaterialityInfo({ ...materialityInfo, Kind: e.target.value });
  }

  /**
   * タイトルの値を変更する
   * @param {イベント} e 
   */
  const changeTitle = (e) => {
    setMaterialityInfo({ ...materialityInfo, Title: e.target.value });
  }

  /**
   * 説明の値を変更する
   * @param {イベント} e 
   */
  const changeDescription = (e) => {
    setMaterialityInfo({ ...materialityInfo, Description: e.target.value });
  }

  /**
   * 上位KGIの値を変更する
   * @param {イベント} e 
   */
  const changeParent = (e) => {
    setMaterialityInfo({ ...materialityInfo, _Parent: e.target.value });
  }

  /**
   * 入力チェック
   */
  const _checkInput = () => {

    let inputError = {}
    try {
      // -- 必須 --
      if (!materialityInfo.Kind) {
        inputError.Kind = getMessage("E010001", "種別");
      }
      if (!materialityInfo.Title) {
        inputError.Title = getMessage("E010001", "タイトル");
      }
      // -- 文字長 --
      if (materialityInfo.Title.length > 35) {
        inputError.Title = getMessage("E010006", "タイトル", 35);
      }
      if (materialityInfo.Description.length > 200) {
        inputError.Description = getMessage("E010006", "説明", 200);
      }
      if (Object.keys(inputError).length === 0) {
        return false;
      } else {
        return true;
      }
    } finally {
      // 入力エラーを設定
      inputError = { ...errors, ...inputError }
      setInputError(inputError);
    }
  }

  /**
   * マテリアリティの追加処理
   */
  const addMaterialityInfo = () => {

    if (_checkInput()) {
      return;
    }
    const parentId = materialityInfo._Parent;
    delete materialityInfo._Parent;
    delete materialityInfo._oldParentId;
    materialityInfo.id = crypto.randomUUID();
    if (parentId) {
      //上位KGIがある場合はその下に追加
      const parent = kgiTreeInfo.find(node => node.id === parentId);
      delete materialityInfo.Sub_Kgi;
      parent.Sub_Kgi.push({ ...materialityInfo });
    } else {
      //上位KGIがない場合は末尾に追加
      kgiTreeInfo.push({ ...materialityInfo });
    }
    setKgiTreeInfo([...kgiTreeInfo]);
    _closeModal();
    // コールバックを実行
    props.onAddMateriality(materialityInfo, parentId);
  }

  /**
   * マテリアリティの変更処理
   */
  const changeMaterialityInfo = () => {

    if (_checkInput()) {
      return;
    }
    const oldParentId = materialityInfo._oldParentId;
    const parentId = materialityInfo._Parent;
    delete materialityInfo._oldParentId;
    delete materialityInfo._Parent;

    let treeInfo = [...kgiTreeInfo];
    if (oldParentId !== parentId) {
      // KGIの付替え
      if (oldParentId) {
        // 配下KGIを削除
        const oldParent = treeInfo.find(node => node.id === oldParentId);
        oldParent.Sub_Kgi = oldParent.Sub_Kgi.filter(node => node.id !== materialityInfo.id);
      } else {
        // 上位KGIを削除
        treeInfo = treeInfo.filter(node => node.id !== materialityInfo.id);
      }
      const newParent = treeInfo.find(node => node.id === parentId);
      if (newParent) {
        // 配下KGIとして追加
        newParent.Sub_Kgi.push({ ...materialityInfo })
      } else {
        // 上位KGIとして追加
        materialityInfo.Sub_Kgi = [];
        treeInfo.push({ ...materialityInfo });
      }
    } else {
      // KGIの入替え
      if (parentId) {
        // 配下KGIを入替え
        const parent = treeInfo.find(node => node.id === parentId);
        parent.Sub_Kgi = parent.Sub_Kgi.map(node => {
          if (node.id === materialityInfo.id) {
            return { ...materialityInfo };
          }
          return node;
        });
      } else {
        // 上位KGIを変更
        const parent = treeInfo.find(node => node.id === materialityInfo.id);
        Object.keys(parent).forEach(key => parent[key] = materialityInfo[key]);
      }
    }
    setKgiTreeInfo([...treeInfo]);
    _closeModal();
    // コールバックを実行
    props.onChangeMateriality(materialityInfo, parentId, oldParentId);
  }

  /**
   * マテリアリティの削除処理
   */
  const removeMaterialityInfo = () => {

    const oldParentId = materialityInfo._oldParentId;
    if (oldParentId) {
      // 配下KGIを削除
      const oldParent = kgiTreeInfo.find(node => node.id === oldParentId);
      oldParent.Sub_Kgi = oldParent.Sub_Kgi.filter(node => node.id !== materialityInfo.id);
      setKgiTreeInfo([...kgiTreeInfo]);
    } else {
      // 上位KGIを削除
      setKgiTreeInfo(kgiTreeInfo.filter(node => node.id !== materialityInfo.id));
    }
    _closeModal();
    // コールバックを実行
    props.onRemoveMateriality(materialityInfo.id, oldParentId);
  }

  /**
   * マテリアリティの追加
   */
  const addMateriality = () => {
    let info = JSON.parse(JSON.stringify(kgiInfo));
    kpiYearList.forEach(d => {
      info.Value_List[d] = {
        TargetValue: '',
        ActualValue: '',
      }
    });
    setMaterialityInfo({ ...info, _Parent: '', _oldParentId: '' });
    setInputError(errors);
    _openModal();
  }

  /**
   * マテリアリティの編集
   * @param {Object} materiality 編集対象
   * @param {String} parentId 上位KGI
   */
  const editMateriality = (materiality, parentId) => {
    let info = JSON.parse(JSON.stringify(kgiInfo));
    Object.keys(info).forEach(key => info[key] = materiality[key]);
    setMaterialityInfo({
      ...info,
      _Parent: parentId ? parentId : '',
      _oldParentId: parentId ? parentId : '',
    });
    setInputError(errors);
    _openModal();
  }

  /**
   * モーダル表示
   */
  const _openModal = () => {
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.add("is-active");
    const modalUploading = document.querySelector("#modal-window--add-materiality");
    modalUploading.classList.remove("animate__fadeOut");
    modalUploading.classList.add("animate__fadeIn");
    modalUploading.classList.add("is-active");
  }

  /**
   * モーダル非表示
   */
  const _closeModal = () => {
    const modal = document.querySelector("#modal-window--add-materiality");
    if (!modal.classList.contains("animate__fadeOut")) {
      modal.classList.remove("animate__fadeIn");
      modal.classList.add("animate__fadeOut");
    }
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      document.querySelector(".bg-modal-window.is-active").classList.remove("is-active");
      document.querySelector(".modal-window.is-active").classList.remove("is-active");
    }, fadeOutTimeoutSec);
  }

  /**
   * 確認ダイアログ
   */
  const _confirmModal = () => {
    const modal = document.querySelector("#modal-window--add-materiality");
    modal.classList.remove("is-active");
    const confirm = document.querySelector("#confirm-window--add-materiality");
    confirm.classList.add("is-active");
  }

  /**
   * 確認ダイアログ 戻る
   */
  const _confirmModalBack = () => {
    const modal = document.querySelector("#modal-window--add-materiality");
    modal.classList.add("is-active");
    const confirm = document.querySelector("#confirm-window--add-materiality");
    confirm.classList.remove("is-active");
  }

  /**
   * KGI表スクロール 左
   * @param {イベント} e 
   */
  const scrollLeft = (e) => {
    const $parent = e.target.closest('.setting-table');
    const $tableWrapper = $parent.querySelector('.indicator-table__scroll');
    $tableWrapper.scrollBy({
      left: -130,
      behavior: "smooth",
    });
  }

  /**
   * KGI表スクロール →
   * @param {イベント} e 
   */
  const scrollRight = (e) => {
    const $parent = e.target.closest('.setting-table');
    const $tableWrapper = $parent.querySelector('.indicator-table__scroll');
    $tableWrapper.scrollBy({
      left: 130,
      behavior: "smooth",
    });
  }

  /**
   * スクロールバー設定
   */
  const setScrollBar = () => {

      // ＜ボタンにdisabledをつける
      const btnScrollLefts = document.querySelectorAll(".setting-table__btn-scroll-left");
      btnScrollLefts.forEach((v) => {
        v.setAttribute('disabled', '');
      });
      // スクロールイベント登録
      document.querySelectorAll('.indicator-table__scroll').forEach(function (element) {
        if (element.classList.contains("is-scroll")) {
          return;
        }
        element.addEventListener('scroll', (event) => {
          // 要素の取得
          const $parent = event.target.closest('.setting-table');
          const $tableWrapper = $parent.querySelector('.indicator-table__scroll');
          const $btnScrollLeft = $parent.querySelector('.setting-table__btn-scroll-left');
          const $btnScrollRight = $parent.querySelector('.setting-table__btn-scroll-right');
          const isScrollEnd = $tableWrapper.scrollWidth - ($tableWrapper.scrollLeft + $tableWrapper.clientWidth) < 1;

          // ＜＞ボタンのdisabledの付け替え
          if ($tableWrapper.scrollLeft === 0) {
            // スクロール位置の左端
            if (!$btnScrollLeft.hasAttribute('disabled')) {
              $btnScrollLeft.setAttribute('disabled', '');
            }
          } else if ($tableWrapper.scrollLeft > 0 && !isScrollEnd) {
            // スクロール位置の中間
            if ($btnScrollLeft.hasAttribute('disabled')) {
              $btnScrollLeft.removeAttribute('disabled');
            }
            if ($btnScrollRight.hasAttribute('disabled')) {
              $btnScrollRight.removeAttribute('disabled');
            }
          } else if (isScrollEnd) {
            // スクロール位置の右端
            if (!$btnScrollRight.hasAttribute('disabled')) {
              $btnScrollRight.setAttribute('disabled', '');
            }
          }
        });
        element.classList.add("is-scroll");
      });
  }

  // 初期表示時、＜ボタンにdisabledをつける
  React.useEffect(() => {
    if (isLoaded) {
      setScrollBar();
    }
  }, [isLoaded, kgiTreeInfo]);

  /**
   * [React.Fragment] 定性目標エリア（行情報）
   * @param {Object} props KGIオブジェクト
   * @returns React.Fragment
   */
  const QualitativeArea = (props) => {
    const kgiInfo = props.kgiInfo;
    const parentId = props.parentId;
    return (
      <React.Fragment>
        <tr>
          <th className={`sticky left-0 z-10 w-78 setting-table__col-title is-qualitative ${parentId ? 'is-child' : 'is-parent'}`}>
            <div className="kgi-title text-extra-small font-bold text-left flex items-center px-4">
              <a className="btn-kgi link-pointer" onClick={() => editMateriality(kgiInfo, parentId)}>
                <span>{kgiInfo.Title}</span>
              </a>
            </div>
          </th>
          <th className="sticky left-78 w-52 z-10 setting-table__col-target">
            <div className="flex items-center setting-heading justify-center">
              <div className="text-extra-small font-bold text-center blue2--text">
                定性目標
              </div>
            </div>
          </th>
          {kpiYearList.map((item, idx2) => {
            return (
              <td key={idx2}><div style={{ width: '114px' }}></div></td>
            )
          })}
        </tr>
      </React.Fragment>
    );
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <React.Fragment>
      <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 mt-10 -mx-16">経営戦略KGI / マテリアリティ設定</h2>
      <div className="flex my-5">
        <ul className="text-body-2-medium grow">
          <li>KPIツリーの構成要素となる、KGIの目標値の登録／編集ができます。</li>
          <li>マテリアリティを設定している場合はこちらから登録ができます。</li>
        </ul>
        <div className="base-table-header">
          <div className="btn-group pl-10 border-l border-blue2-lighten-20">
            <button type="button" className="btn header-btn white--text py-1.5 pl-7 pr-10 inline-flex items-center"
              disabled={!props.authConf.Regist} onClick={() => addMateriality()}>
              <span className="icon-plus-line icon-32px mr-1.5 my-px"></span>
              <span className="-translate-y-px text-h7">新規登録</span>
            </button>
          </div>
        </div>
      </div>
      {kgiTreeInfo.length !== 0 &&
        <div className="setting-table relative is-kgi">
          {/*  左にスクロールするボタン 非活性時はdisabled属性を追加 */}
          <button type="button" className="setting-table__btn-scroll-left button w-8 h-8 flex items-center justify-center absolute" onClick={(e) => scrollLeft(e)}>
            <span className="icon-arrow white--text rotate-180"></span>
          </button>
          {/*  右にスクロールするボタン 非活性時はdisabled属性を追加 */}
          <button type="button" className="setting-table__btn-scroll-right button w-8 h-8 flex items-center justify-center absolute" onClick={(e) => scrollRight(e)}>
            <span className="icon-arrow white--text"></span>
          </button>
          {/*  グラデーション1 */}
          <div className="setting-table__gradation--left"></div>
          {/*  グラデーション2 */}
          <div className="setting-table__gradation--right"></div>
          {/*  表組み */}
          <div className="indicator-table__scroll overflow-x-auto">
            <table className="setting-table__table w-full">
              <thead>
                <tr>
                  <th className="sticky top-0 left-0 z-10 w-78 setting-table__thead-col-title">タイトル</th>
                  <th className="sticky top-0 left-78 z-10 w-52 setting-table__thead-col-target">単位</th>
                  {kpiYearList.map((item, idx) => {
                    return <th key={idx}>{item}{item && props.accPeriod + '期'}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                {kgiTreeInfo.map((tree, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {/* 定量 */}
                      {tree.Kind === '定量' && <React.Fragment>
                        <tr>
                          <th rowSpan="2" className="sticky left-0 z-10 w-78 setting-table__col-title is-parent is-quantitative">
                            <div className="kgi-title text-extra-small font-bold text-left flex items-center px-4">
                              <a className="btn-kgi link-pointer" onClick={() => editMateriality(tree)}>
                                <span>{tree.Title}</span>
                              </a>
                            </div>
                          </th>
                          <th className="sticky left-78 w-52 z-10 setting-table__col-target">
                            <div className="flex items-center setting-heading">
                              <div className="setting-heading__name blue2--text">目標値</div>
                              <div className="setting-heading__unit"></div>
                              <div className="setting-heading__select">
                                <div className="select-control">
                                  <select id="unit" name="select_goal_1" className="select-control__select rounded-full text-extra-small pr-7"
                                    value={tree.Unit} onChange={(e) => changeUnit(idx, e)} >
                                    {Object.keys(kgiUnit).map((key) => {
                                      return <option key={key} value={key}>{kgiUnit[key].name}</option>
                                    })}
                                  </select>
                                  <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                                    <span className="icon-arrow rotate-90"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </th>
                          {kpiYearList.map((item, idx2) => {
                            return (
                              <td key={idx2}>
                                <input className="text-input format-digit" type="text" name="target" placeholder="数値を入力"
                                  value={tree.Value_List[item].TargetValue}
                                  onChange={(e) => changeTargetValue(idx, item, e)}
                                  onFocus={(e) => { e.target.value = parseCommaDigit(e.target.value); }}
                                  onBlur={(e) => {
                                    e.target.value = formatCommaDigit(e.target.value, kgiUnit[tree.Unit].numDecPlaces);
                                    changeTargetValue(idx, item, e);
                                  }}
                                />
                              </td>
                            )
                          })}
                        </tr>
                        <tr>
                          <th className="sticky left-78 z-10 setting-table__col-target">
                            <div className="flex items-center setting-heading">
                              <div className="setting-heading__name blue2--text">実績値</div>
                              <div className="setting-heading__unit"></div>
                              <div className="setting-heading__select"></div>
                            </div>
                          </th>
                          {kpiYearList.map((item, idx2) => {
                            return (
                              <td key={idx2}>
                                <input className="text-input format-digit" type="text" name="actual" placeholder="数値を入力"
                                  value={tree.Value_List[item].ActualValue}
                                  onChange={(e) => changeActualValue(idx, item, e)}
                                  onFocus={(e) => { e.target.value = parseCommaDigit(e.target.value); }}
                                  onBlur={(e) => {
                                    e.target.value = formatCommaDigit(e.target.value, kgiUnit[tree.Unit].numDecPlaces);
                                    changeActualValue(idx, item, e);
                                  }}
                                />
                              </td>
                            )
                          })}
                        </tr>
                      </React.Fragment>}
                      {/* 定性 */}
                      {tree.Kind === '定性' && <QualitativeArea kgiInfo={tree} />}
                      {/* 配下KGI */}
                      {tree.Sub_Kgi.map((subTree, idx2) => {
                        return (
                          <React.Fragment key={idx2}>
                            {/* 定量 */}
                            {subTree.Kind === '定量' && <React.Fragment>
                              <tr>
                                <th rowSpan="2" className="sticky left-0 z-10 w-78 setting-table__col-title is-child is-quantitative">
                                  <div className="kgi-title text-extra-small font-bold text-left flex items-center px-4">
                                    <a className="btn-kgi link-pointer" onClick={() => editMateriality(subTree, tree.id)}>
                                      <span>{subTree.Title}</span>
                                    </a>
                                  </div>
                                </th>
                                <th className="sticky left-78 w-52 z-10 setting-table__col-target">
                                  <div className="flex items-center setting-heading">
                                    <div className="setting-heading__name blue2--text">目標値</div>
                                    <div className="setting-heading__unit"></div>
                                    <div className="setting-heading__select">
                                      <div className="select-control">
                                        <select id="unit" name="select_goal_2" className="select-control__select rounded-full text-extra-small pr-7"
                                          value={subTree.Unit} onChange={(e) => changeSubKgiUnit(idx, idx2, e)} >
                                          {Object.keys(kgiUnit).map((key) => {
                                            return <option key={key} value={key}>{kgiUnit[key].name}</option>
                                          })}
                                        </select>
                                        <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                                          <span className="icon-arrow rotate-90"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </th>
                                {kpiYearList.map((item, idx3) => {
                                  return (
                                    <td key={idx3}>
                                      <input className="text-input format-digit" type="text" name="target" placeholder="数値を入力"
                                        value={subTree.Value_List[item].TargetValue}
                                        onChange={(e) => changeSubKgiTargetValue(idx, idx2, item, e)}
                                        onFocus={(e) => { e.target.value = parseCommaDigit(e.target.value); }}
                                        onBlur={(e) => {
                                          e.target.value = formatCommaDigit(e.target.value, kgiUnit[subTree.Unit].numDecPlaces);
                                          changeSubKgiTargetValue(idx, idx2, item, e);
                                        }}
                                      />
                                    </td>
                                  )
                                })}
                              </tr>
                              <tr>
                                <th className="sticky left-78 z-10 setting-table__col-target">
                                  <div className="flex items-center setting-heading">
                                    <div className="setting-heading__name blue2--text">実績値</div>
                                    <div className="setting-heading__unit"></div>
                                    <div className="setting-heading__select"></div>
                                  </div>
                                </th>
                                {kpiYearList.map((item, idx3) => {
                                  return (
                                    <td key={idx3}>
                                      <input className="text-input format-digit" type="text" name="actual" placeholder="数値を入力"
                                        value={subTree.Value_List[item].ActualValue}
                                        onChange={(e) => changeSubKgiActualValue(idx, idx2, item, e)}
                                        onFocus={(e) => { e.target.value = parseCommaDigit(e.target.value); }}
                                        onBlur={(e) => {
                                          e.target.value = formatCommaDigit(e.target.value, kgiUnit[subTree.Unit].numDecPlaces);
                                          changeSubKgiActualValue(idx, idx2, item, e);
                                        }}
                                      />
                                    </td>
                                  )
                                })}
                              </tr>
                            </React.Fragment>}
                            {/* 定性 */}
                            {subTree.Kind === '定性' && <QualitativeArea kgiInfo={subTree} parentId={tree.id} />}
                          </React.Fragment>
                        )
                      })}
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      }
      {kgiTreeInfo.length === 0 &&
        <p className="text-center text-body-2-bold pt-16 red--text">経営戦略KGI／マテリアリティは設定されていません</p>
      }
      {/* 設定追加モーダル */}
      <div className="bg-modal-window w-screen h-screen fixed top-0 left-0 grey lighten-70 z-50"></div>
      <div id="modal-window--add-materiality"
        className="modal-window modal-window__wide fixed bg sub z-50 rounded-2xl animate__fadeIn">
        <div className="modal-window__header">
          <div className="modal-window__title flex items-center justify-center pt-10">
            <span className="modal-window__title-text text-h3 font-medium">
              経営計画KGI / マテリアリティ設定
            </span>
          </div>
          <button
            type="button"
            className="modal-window__btn-close absolute top-5 right-5"
            onClick={() => _closeModal()}
          >
            <span className="icon-close"></span>
          </button>
        </div>
        <div className="modal-window__content px-8 py-10 !overflow-y-hidden">
          <div className="text-caption-1 font-medium text-center pb-5">
            経営戦略KGI / マテリアリティの概要を入力してください。<br />
            種別「定性」を指定すると、タイトルに入力したテキストだけがKPIツリーに表示されます。
          </div>
          <div className="pt-4 pb-5 mx-auto overflow-y-auto">
            <div>
              <span className="red--text"></span>
            </div>
            <table className="form-table form-control w-full">
              <tbody className="text-caption-2">
                <tr>
                  <th className="form-table__col-head">
                    <label className="text-h7 font-bold">種別<span className="red--text">*</span></label>
                  </th>
                  <td className="form-table__col-data">
                    <ul className="flex gap-x-8">
                      <li>
                        <label className="ui-radio">
                          <input type="radio" className="radioBmAll ui-radio__input" name="type"
                            value="定量" checked={materialityInfo.Kind === '定量'} onChange={(e) => changeKind(e)} />
                          <span className="ui-radio__icon"></span>
                          <span className="ui-radio__ripple"></span>
                          <span className="ui-radio__label text-h7 font-Regular">定量</span>
                        </label>
                      </li>
                      <li>
                        <label className="ui-radio">
                          <input type="radio" className="radioBmAll ui-radio__input" name="type"
                            value="定性" checked={materialityInfo.Kind === '定性'} onChange={(e) => changeKind(e)} />
                          <span className="ui-radio__icon"></span>
                          <span className="ui-radio__ripple"></span>
                          <span className="ui-radio__label text-h7 font-Regular">定性</span>
                        </label>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th className="form-table__col-head">
                    <label htmlFor="name" className="text-h7 font-bold">タイトル<span className="red--text">*</span></label>
                  </th>
                  <td className="form-table__col-data">
                    <input id="name" placeholder="KGI / マテリアリティのタイトルを入力（全角35文字以内）" type="text"
                      className={`block rounded-lg px-3 py-1 w-full ${inputError.Title ? "is-error" : ""}`}
                      value={materialityInfo.Title} onChange={(e) => changeTitle(e)} />
                    <span className="red--text">{inputError.Title}</span>
                  </td>
                </tr>
                <tr>
                  <th className="form-table__col-head">
                    <label htmlFor="description" className="text-h7 font-bold">説明</label>
                  </th>
                  <td className="form-table__col-data">
                    <textarea id="description" rows="4" placeholder="KGI / マテリアリティの概要説明を入力（全角200文字程度）" autoComplete="off"
                      className={`block rounded-lg px-3 py-1 w-full ${inputError.Description ? "is-error" : ""}`} maxLength="200"
                      value={materialityInfo.Description} onChange={(e) => changeDescription(e)} />
                    <span className="red--text">{inputError.Description}</span>
                  </td>
                </tr>
                <tr>
                  <th className="form-table__col-head">
                    <label htmlFor="status" className="text-h7 font-bold">上位KGI</label>
                  </th>
                  <td className="form-table__col-data">
                    <div className="flex">
                      <div className="select-control w-full">
                        <select id="status" className="form-select rounded-lg w-full"
                          value={materialityInfo._Parent}
                          disabled={materialityInfo.Sub_Kgi?.length}
                          onChange={(e) => changeParent(e)} >
                          <option value=""></option>
                          {!materialityInfo.Sub_Kgi?.length &&
                            kgiTreeInfo.filter(node => node.id !== materialityInfo.id).map((node, idx) => {
                              return <option key={idx} value={node.id}>{node.Title}</option>
                            })
                          }
                        </select>
                        <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                          <span className="icon-arrow rotate-90"></span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-content-footer">
            <div className="footer_button_area gap-x-16">
              <button className="btn footer-btn button_back" onClick={() => _closeModal()}>キャンセル</button>
              {!materialityInfo.id &&
                <React.Fragment>
                  <SpinnerButton name="設定" className="btn footer-btn button_base"
                    disabled={!props.authConf.Regist} onClick={() => addMaterialityInfo()} />
                </React.Fragment>
              }
              {materialityInfo.id &&
                <React.Fragment>
                  <SpinnerButton name="設定" className="btn footer-btn button_base"
                    disabled={!props.authConf.Regist} onClick={() => changeMaterialityInfo()} />
                  <button className="btn footer-btn button_delete"
                    disabled={!props.authConf.Regist} onClick={() => _confirmModal()}>削除</button>
                </React.Fragment>
              }
            </div>
          </div>
        </div>
      </div>
      {/* 削除確認モーダル */}
      <div id="confirm-window--add-materiality"
        className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn">
        <div className="modal-window__header">
          <div className="modal-window__title flex items-center justify-center pt-10">
            <span className="modal-window__title-text text-h3 font-medium">
              KGI/マテリアリティの削除確認
            </span>
          </div>
          <button type="button" className="modal-window__btn-close absolute top-5 right-5"
            onClick={() => _closeModal()}>
            <span className="icon-close"></span>
          </button>
        </div>
        <div className="modal-window__content px-8 py-10 !overflow-y-hidden">
          <div className="flex text-center mb-2">KGI / マテリアリティを削除してもよろしいでしょうか？</div>
          <div className="modal-content-footer">
            <div className="footer_button_area">
              <button className="footer-btn button_back" onClick={() => _confirmModalBack()}>戻る</button>
              <SpinnerButton name="削除" className="footer-btn button_delete" onClick={() => removeMaterialityInfo()} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export const setMateriality = (treeInfo) => {
  // 初期化後に設定
  kgiTree.splice(0);
  treeInfo.forEach(el => {
    kgiTree.push(el);
  });
  return kgiTree;
}

export default (props) => <MaterialityTableContent {...props} />;
